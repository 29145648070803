import React, { useState, useEffect } from "react";
import useHumanReadableNumber from "../../hooks/useHumanReadableNumber";

const TradingDataRow = (props) => {
  const formatNumber = useHumanReadableNumber();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const pair = props?.match?.params?.id;
  const symbolName = pair.replace("-", "/").toUpperCase();
  const symbol = pair.replace("-", "").toUpperCase();
  const assetSymbol = pair.split("-")[0].toLowerCase(); // Extract base currency for icon
  function formatNumberWithCommas(number, locales = "en-US", options = {}) {
    return new Intl.NumberFormat(locales, options).format(number);
  }
  // Construct the image URL
  const imageUrl = `https://assets.coincap.io/assets/icons/${assetSymbol}@2x.png`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://api.binance.com/api/v3/ticker/24hr?symbol=${symbol}`
        );
        if (!response.ok) throw new Error("Failed to fetch data");
        const result = await response.json();
        // console.log(result, "result");
        setData(result);
        setError(null);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 5000);
    return () => clearInterval(interval);
  }, [symbol]);

  if (error) return <p className="text-red-500">Error: {error}</p>;
  if (!data) return <p className="text-gray-400">Loading...</p>;

  const priceChangePercent = parseFloat(data.priceChangePercent);
  const priceChangeColor = priceChangePercent < 0 ? "#e2464a" : "#20ac95";

  return (
    <div className="d-flex align-items-center justify-between align-items-center gap-5 bg-gray-900 text-white px-4 py-1 rounded-lg shadow-lg w-full common-bg text-white">
      <div className="d-flex align-items-center gap-2 rowGraphHeading">
        <img
          src={imageUrl}
          alt={`${symbolName} icon`}
          className="rounded-full"
          style={{ width: "20px" }}
          onError={(e) => (e.target.style.display = "none")} // Hide if image fails to load
        />{" "}
        <span className="font-bold d-flex align-items-center ">
          {symbolName}{" "}
          <span
            className="d-flex align-items-center gap-1 "
            style={{ color: priceChangeColor, marginLeft: "8px" }}
          >
            <span className="rowGraphPrice">
              $
              {data.lastPrice < 1
                ? formatNumberWithCommas(parseFloat(data.lastPrice).toFixed(4))
                : formatNumberWithCommas(parseFloat(data.lastPrice).toFixed(2))}
            </span>{" "}
            <span
              style={{ background: priceChangeColor, borderRadius: "3px" }}
              className="rowGraphPercentage"
            >
              {priceChangePercent.toFixed(2)}%
            </span>
          </span>
        </span>
      </div>
      <div className="d-flex flex-column rowGraphDiv">
        <div className="rowGraphSubheading">24h Change</div>{" "}
        <div
          style={{
            color: Number(data.priceChangePercent) < 0 ? "#e2464a" : "#20ac95",
          }}
        >
          {formatNumberWithCommas(Number(data.priceChange)?.toFixed(4))}{" "}
          <span className="ms-1">
            {Number(data.priceChangePercent)?.toFixed(2)}%
          </span>
        </div>
      </div>
      <div className="d-flex flex-column rowGraphDiv">
        <div className="rowGraphSubheading">Mark Price</div>{" "}
        <div>
          $
          {formatNumberWithCommas(parseFloat(data.weightedAvgPrice).toFixed(4))}
        </div>
      </div>
      <div className="d-flex flex-column rowGraphDiv">
        <div className="rowGraphSubheading">Index Price</div>{" "}
        <div>
          ${formatNumberWithCommas(parseFloat(data.prevClosePrice).toFixed(4))}
        </div>
      </div>
      <div className="d-flex flex-column rowGraphDiv">
        <div className="rowGraphSubheading">24h High</div>{" "}
        <div>${formatNumber(parseFloat(data.highPrice).toFixed(4))}</div>
      </div>
      <div className="d-flex flex-column rowGraphDiv">
        <div className="rowGraphSubheading">24h Low</div>{" "}
        <div>${formatNumber(parseFloat(data.lowPrice).toFixed(4))}</div>
      </div>
      <div className="d-flex flex-column rowGraphDiv">
        <div className="rowGraphSubheading">24h Vol</div>{" "}
        <div>{formatNumber(parseFloat(data.volume).toFixed(2))}</div>
      </div>
      <div className="d-flex flex-column rowGraphDiv">
        <div className="rowGraphSubheading">24h Vol (USDT)</div>{" "}
        <div>${formatNumber(parseFloat(data.quoteVolume).toFixed(2))}</div>
      </div>
    </div>
  );
};

export default TradingDataRow;
