import React, { useEffect, useState } from "react";
import Router from "./pages/router";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { ThemeProvider } from "styled-components";
import { darkTheme, lightTheme } from "./pages/Themes";
import { useSelector } from "react-redux";
import './i18n';
import { getDirection } from './i18n';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom/cjs/react-router-dom";
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Toaster } from "react-hot-toast";
import Cookies from "js-cookie"; // Import js-cookie
import { getToken, onMessage } from "firebase/messaging";
import { messaging, VAPID_KEY } from "./firebase/firebaseConfig";
import { deviceTokenUpdate } from "./pages/redux/helpers/api_functions_new";

function App(props) {
  const { i18n } = useTranslation();
  const { switch_theme, profile } = useSelector((state) => state.AuthReducer);
  const { lang } = useParams(); // Get language from URL params
  const [showCookieBanner, setShowCookieBanner] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted cookies
    // if (!Cookies.get("cookieConsent")) {
    //   setShowCookieBanner(true);
    // }

    // Update the HTML `dir` attribute based on the current language
    const currentLang = i18n.language;
    const direction = getDirection(currentLang);
    document.documentElement.dir = direction;
    document.documentElement.lang = currentLang;
    document.body.style.direction = direction;
  }, [i18n.language]);

  // Function to Accept Cookies
  // const acceptCookies = () => {
  //   Cookies.set("cookieConsent", "true", { expires: 365 }); // Set cookie for 1 year
  //   setShowCookieBanner(false);
  //   // Load Tidio Chat after user accepts cookies

  // };

  // Function to load Tidio Chat Script


  // Load Tidio Chat if cookies were already accepted
  useEffect(() => {
    if (Cookies.get("cookieConsent")) {
    }
  }, []);

  async function requestPermission() {
    try {
      
   
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();
    

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: VAPID_KEY,
      });

      //We can send token to server
      localStorage.setItem('fcm', token);
      // gettingNotifications();
    } else if (permission === "denied") {
      //notifications are blocked
      console.log("You denied for the notification");
    }
  } catch (error) {
      console.log(error,"try error")
  }
  }

  // async function gettingNotifications() {
  //   onMessage(messaging, (payload) => {
  //     console.log(payload, "<---------------------------- payload notification")
  //   });
  // }

  useEffect(() => {
    requestPermission();
  }, []);

  useEffect(() => {
    let fcm = localStorage.getItem('fcm');
    if (fcm) {
      deviceTokenUpdate(fcm)
    }
  }, [profile])


  return (
    <ThemeProvider theme={switch_theme === "light" ? lightTheme : darkTheme}>
      <>
        <Router {...props} />
        <NotificationContainer />
        <Toaster position="top-center" reverseOrder={false} />

        {/* Cookie Consent Banner */}
        {showCookieBanner && (
          <div style={{
            position: "fixed",
            bottom: 0,
            width: "100%",
            background: "#0d0d29",
            color: "#fff",
            padding: "15px",
            textAlign: "center",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: "999",
            borderTop: "1px solid #808080a8"
          }}>
            <p style={{ margin: 0, flex: 1 }}>
              Bitcash uses cookies for a better, secure experience. Accept to continue. 🚀
            </p>
            {/* <button
              onClick={acceptCookies}
              className="login_btn"
              style={{ fontSize: "14px", margin: "0px", lineHeight: "20px" }}
            >
              Accept All
            </button> */}
          </div>
        )}
      </>
    </ThemeProvider>
  );
}

export default App;
