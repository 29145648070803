const mode = window.location.host.includes("localhost")
    ? "LOCAL"
    : window.location.host.includes("dev")
        ? "DEV"
        : "PROD";


var COINMARKET_URL = "https://pro-api.coinmarketcap.com/",
    TIDIO_Key = "",
    WEBSITE_URL, API_URL, ADMIN_URL, SOCKET_URL, ORDER_API_URL;

if (mode === "PROD") {
    WEBSITE_URL = "https://bitcashex.com/";
    API_URL = "https://api.bitcashex.com/";
    ADMIN_URL = "https://adminapi.bitcashex.com/";
    SOCKET_URL = "https://adminapi.bitcashex.com/";
    ORDER_API_URL = "https://orderbookapi.bitcashex.com/";
} else if (mode === "DEV") {
    WEBSITE_URL = "https://dev.bitcashex.com/";
    API_URL = "https://dev-api.bitcashex.com/";
    ADMIN_URL = "https://dev-adminapi.bitcashex.com/";
    SOCKET_URL = "https://dev-adminapi.bitcashex.com/";
    ORDER_API_URL = "https://dev-orderbookapi.bitcashex.com/";
}
else {
    WEBSITE_URL = "https://bitcashex.com/";
    API_URL = "https://api.bitcashex.com/";
    ADMIN_URL = "https://adminapi.bitcashex.com/";
    SOCKET_URL = "https://adminapi.bitcashex.com/";
    ORDER_API_URL = "https://orderbookapi.bitcashex.com/";
}

export { WEBSITE_URL, API_URL, ADMIN_URL, SOCKET_URL, ORDER_API_URL, COINMARKET_URL, TIDIO_Key };