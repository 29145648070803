import React, { useEffect, useState } from "react";
import Header from "../../HomeComp/Header";
import "./paymentMethods.css";
import Footer from "../../HomeComp/Footer";
import { Field, Form, Formik } from "formik";
import { NotificationManager } from "react-notifications";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { N_addPaymentMethod, N_getPaymentMethodsInfo } from "../../redux/helpers/api_functions_new";
import { useHistory } from "react-router-dom";
import BackComp from "../../../helper/components/BackComp";
import toast from "react-hot-toast";

const BankTransfer = () => {

  const params = new URLSearchParams(window.location.search);
  const bank_id = params.get("id");
  const history = useHistory();
  const { user, profile } = useSelector((state) => state.AuthReducer);

  const [bankInfo, setBankInfo] = useState(null);

  useEffect(() => {
    if (bank_id) {
      N_getPaymentMethodsInfo(bank_id).then((res) => {
        setData({
          name: res?.data?.name || profile?.name,
          bank_name: res?.data?.bank_name,
          account_number: res?.data?.account_number,
          confirm_account_number: res?.data?.account_number,
          payment_method_type: "bank",
          branch_code: res?.data?.branch_code,
          branch_name: res?.data?.branch_name,
          iban: res?.data?.iban,
          email: profile?.email,
          mobile_number: profile?.mobile_number
        });
      })
    }
  }, [bank_id])

  const Schema = Yup.object().shape({
    name: Yup.string().required("Please Enter Account Holder Name"),
    bank_name: Yup.string().required("Please Enter Bank Name"),
    account_number: Yup.number().required("Please Enter Account Number"),
    confirm_account_number: Yup.number()
      .oneOf([Yup.ref("account_number"), null], "Account Number Must Match")
      .required("Please Enter Account Number"),
    branch_code: Yup.string().required("Please Enter Branch Code"),
    // iban: Yup.string().required("Please Enter Account Type"),
  });
  const [data, setData] = useState({
    name: profile?.name,
    bank_name: "",
    account_number: "",
    confirm_account_number: "",
    payment_method_type: "bank",
    branch_code: "",
    branch_name: "",
    iban: "",
    email: profile?.email,
    mobile_number: profile?.mobile_number,
  });
  const onSubmit = async (values, onSubmitProps) => {
    N_addPaymentMethod({ ...values, bank_id: bank_id ? bank_id : null }).then((res) => {
      if (res.status == 200) {
        toast.success(res.message);
        history.push("/payment-method");
      } else {
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    setData({ ...data, name: profile?.name })
  }, [profile])

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="add_bank_account_page ">
          <div className="row justify-content-center">
            <section className="col-md-10">
              {/* <h2 className="text-white text-center">Add Bank Account</h2> */}

              <div className="d-flex align-items-center">
                <BackComp url={"/payment-method"} text={""} />
                <h6 className="text-white mb-0">  {bank_id ? "Update" : "Create"} Bank Account </h6>
              </div>

              <div className="mb-5">
                <div class="alert alert-info mt-3">
                  Tips: The added payment method will be shown to the buyer
                  during the transaction to accept fiat transfers. Please
                  ensure that the information is correct, real, and matches
                  your KYC information on bitcash.
                </div>
                <Formik
                  initialValues={{ ...data }}
                  enableReinitialize
                  validationSchema={Schema}
                  onSubmit={onSubmit}
                >
                  {({ errors, touched, values }) => (
                    <Form className="mt-4">
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <label htmlFor="host" className="mb-1">
                            Account Holder Name
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            name="name"
                            id="name"
                            placeholder="Enter Account Holder Name"
                            className="form-control"
                            autoComplete="off"
                          />
                          {errors.name && touched.name ? (
                            <small className="text-danger">{errors.name}</small>
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="host" className="mb-1">
                            Bank Name
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            name="bank_name"
                            id="bank_name"
                            placeholder="Enter Bank Name"
                            className="form-control"
                            autoComplete="off"
                          />
                          {errors.bank_name && touched.bank_name ? (
                            <small className="text-danger">
                              {errors.bank_name}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-6">
                          <label htmlFor="host" className="mb-1">
                            Account Number
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="number"
                            name="account_number"
                            id="account_number"
                            placeholder="Enter Account Number"
                            className="form-control"
                            autoComplete="off"
                          />
                          {errors.account_number && touched.account_number ? (
                            <small className="text-danger">
                              {errors.account_number}
                            </small>
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="host" className="mb-1">
                            Confirm Account Number
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            type="number"
                            name="confirm_account_number"
                            id="confirm_account_number"
                            placeholder="Confirm Account Number"
                            className="form-control"
                            autoComplete="off"
                          />
                          {errors.confirm_account_number &&
                            touched.confirm_account_number ? (
                            <small className="text-danger">
                              {errors.confirm_account_number}
                            </small>
                          ) : null}
                        </div>
                      </div>

                      <div className="row mb-4">
                        <div className="col-md-6">
                          <label htmlFor="host" className="mb-1">
                            Branch Code
                            <span className="text-danger">*</span>
                          </label>
                          <Field
                            name="branch_code"
                            id="branch_code"
                            placeholder="Enter Branch Code"
                            className="form-control"
                            autoComplete="off"
                          />
                          {errors.branch_code && touched.branch_code ? (
                            <small className="text-danger">
                              {errors.branch_code}
                            </small>
                          ) : null}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="host" className="mb-1">
                            Branch Name (Optional)
                          </label>
                          <Field
                            name="branch_name"
                            id="branch_name"
                            placeholder="Enter Branch Name"
                            className="form-control"
                            autoComplete="off"
                          />
                          {errors.branch_name && touched.branch_name ? (
                            <small className="text-danger">
                              {errors.branch_name}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mb-4">
                        {" "}
                        <div className="col-md-6">
                          <label htmlFor="host" className="mb-1">
                            IBAN (International Bank Account Number)
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Field
                            name="iban"
                            type="text"
                            id="iban"
                            placeholder="Enter IBAN"
                            className="form-control"
                            autoComplete="off"
                          />
                          {errors.iban && touched.iban ? (
                            <small className="text-danger">{errors.iban}</small>
                          ) : null}
                        </div>
                      </div>

                      <div className="mb-4 text-center">
                        <button
                          type="submit"
                          className="btn common_add_bank_account_button"
                        >
                          {bank_id ? "Update" : "Create"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BankTransfer;
