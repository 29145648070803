import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import { Link } from "react-router-dom";
import {
  isCpass,
  isEmail,
  isNum,
  isPass,
} from "./redux/helpers/form-validator.functions";
import { useDispatch, useSelector } from "react-redux";
import $ from "jquery";
import { NotificationManager } from "react-notifications";
import { useGoogleLogin } from "@react-oauth/google";
// import { NotificationManager } from "react-notifications";
import { AUTH_LOGIN } from "./redux/constant";
// import { ConnectButton } from "@rainbow-me/rainbowkit";
import {
  N_resendOTP,
  N_sendOTP,
  N_userRegister,
  N_verifyOTPNew,
  socialLogin,
} from "./redux/helpers/api_functions_new";
// import { namehash } from "viem";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useToastPromise from "../hooks/toastHook";
import toast from "react-hot-toast";
import { BsArrowLeft } from "react-icons/bs";
export default function Register(props) {
  const showToast = useToastPromise();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralCode = params.get("refer-by");
  const { isLoggedIn, switch_theme } = useSelector(
    (state) => state.AuthReducer
  );
  const [email, setEmail] = useState("");
  const [timer, setTimer] = useState(60);
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState();
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState();
  const [password, setPassword] = useState();
  const [confPassword, setConfPassword] = useState();
  const [activetab, setActivetab] = useState(0);
  const [referral_code, setReferral] = useState(referralCode ?? "");

  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState();
  const [eye, seteye] = useState(false);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  if (isLoggedIn) props.history?.replace("/");
  const saveSettings = async () => {
    // Simulating an API call
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return "Settings saved!";
  };
  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye(true);
    }
  };
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(countdown);
    }
  }, [timer]);

  const goBack = () => {
    setIsOTPSent(false);
  };

  const otpSend = () => {
    if ((isEmail(email) || isNum(phone)) && isPass(password)) {
      if (agree) {
        $(".main_terms").removeClass("border-danger");
        setLoading(true);
        N_userRegister(email.toLowerCase(), phone, password, referral_code)
          .then((res) => {
            if (res.status === 200) {
              setTimer(60);
              showToast(saveSettings, {
                loading: "Processing...",
                success: res.message,
                error: "Failed.....",
              });
              setIsOTPSent(true);
            } else {
              toast.error(res.message);
            }
            setLoading(false);
          })
          .catch((e) => {
            console.log("n-user_reg err", e);
          });
      } else {
        toast.error("Please agree with the terms and conditions!");
        $(".main_terms").addClass("border-danger");
      }
    } else {
      if (!isPass(password)) {
        toast.error("Invalid password. Please enter a correct password.");
      } else if (!isEmail(email)) {
        toast.error("Invalid email. Please enter a correct email.");
      } else {
        toast.error("Please fill in all required data!");
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    N_verifyOTPNew(email, phone, otp).then((res) => {
      if (res.status == 200) {
        toast.success(res.message);
        dispatch({ type: AUTH_LOGIN, data: res });
        history.push("/AccountVerification");
      } else {
        toast.error(res.message);
      }
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      socialLogin(codeResponse.access_token).then((res) => {
        if (res.status) {
          toast.success(res.message);
          dispatch({ type: AUTH_LOGIN, data: res });
          props.history?.replace("/");
        } else {
          toast.success(res.message);
        }
      });
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <div className="register_bg">
      <Header {...props} />
      <div class="container">
        <div class="row align-items-center no-gutter">
          <div class="d-none d-md-block col-md-6 p-0">
            <div className="LoginImg">
              <img src="/img/rewards-1.svg" className="login-img0" />
            </div>
          </div>

          <div class="col-md-6">
            {!isOTPSent && (
              <div class="m-auto login_window signupform cstm_form">
                <div className="d-flex justify-content-center mb-5">
                  <Link to="/">
                    {switch_theme === "dark" ? (
                      <img src="/img/logo.svg" style={{ width: "80px" }} />
                    ) : (
                      <img src="/img/logo.svg" style={{ width: "80px" }} />
                    )}
                  </Link>
                </div>
                <p class="text-white mb-4 mt-0 text-center">
                  Sign Up your account to enjoy trading.
                </p>

                <form className="" method="post" id="signupform">
                  <div className="form-group">
                    <small id="msg">Error Message</small>
                  </div>
                  {activetab == 0 ? (
                    <div className={`form-group`}>
                      <label
                        htmlFor="user email"
                        className="text-white mb-2 fw-bold"
                      >
                        E-mail
                      </label>
                      <input
                        type="email"
                        name="user_email"
                        autoComplete="off"
                        readOnly
                        onFocus={(e) => {
                          e.target.removeAttribute("readOnly");
                        }}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                        id="user_email"
                        placeholder="Enter Email"
                      />
                    </div>
                  ) : (
                    <div className={`form-group`}>
                      <label
                        htmlFor="phone"
                        className="fw-bold text-white mb-2"
                      >
                        Phone Number
                      </label>
                      <input
                        type="phone"
                        name="user_phone"
                        autoComplete="off"
                        readOnly
                        onFocus={(e) => {
                          e.target.removeAttribute("readOnly");
                        }}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        className="form-control"
                        id="user_phone"
                        placeholder="Mobile Number"
                      />
                      {/* <i className="fas fa-check-circle"></i>
                      <i className="fas fa-exclamation-circle"></i>
                      <small></small> */}
                    </div>
                  )}
                  {/* {!isOTPVerified ? (
                    <>
                      <div className={`form-group d-grid`}>
                        <button
                          className="btn btn-secondary mb-2"
                          onClick={(e) => {
                            e.preventDefault();
                            if (isEmail(email)) {
                              N_sendOTP(email, phone, activetab).then(
                                (res) => {
                                  console.log("res", res.status);
                                  if (res.status == 200) {
                                    setIsOTPSent(true);
                                    toast.success(
                                      res.message
                                    );
                                  } else {
                                    toast.error(res.message);
                                  }
                                }
                              );
                            } else {
                              toast.error(
                                "Enter Correct Email!"
                              );
                            }
                          }}
                          disabled={isSendingOTP}
                        >
                            {isOTPSent
                                ? "Didn't receive the OTP? Resend "
                                : "Send Email Verification Code"}
                          </button>
                          {isOTPSent && (
                            <div className={`form-group`}>
                              
                              <div className="input-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  name="user_otp"
                                  id="user_otp"
                                  required=""
                                  value={otp}
                                  onChange={(e) => setOtp(e.target.value)}
                                  placeholder="Enter Verfication Code"
                                />

                                <span className="input-group-text">
                                  {!isOTPVerified && (
                                    <div className="">
                                      <div className="">
                                        <button
                                          className="btn border-0 text-primary border-start rounded-0"
                                          onClick={(e) => {
                                            setIsSendingOTP(true);
                                            e.preventDefault();
                                            N_verifyOTPNew(
                                              email,
                                              phone,
                                              otp
                                            ).then((res) => {
                                              if (res.status == 200) {
                                                setIsOTPVerified(true);
                                                toast.success(
                                                  res.message
                                                );
                                              } else {
                                                toast.error(
                                                  res.message
                                                );
                                              }
                                            });
                                          }}
                                        >
                                          Verify Code
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                    </>
                  ) : null} */}

                  <div className={`form-group`}>
                    <label
                      htmlFor="user_password"
                      className="fw-bold text-white mb-2"
                    >
                      {" "}
                      Password
                    </label>
                    <div className="input-group">
                      <input
                        type="password"
                        className="form-control"
                        name="user_password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="pass"
                        placeholder="Enter Password"
                      />
                      <span className="input-group-text">
                        <a
                          href="#view_qr"
                          className=""
                          onClick={(e) => {
                            hsPassword("pass");
                          }}
                        >
                          {eye ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#666"
                              className="bi bi-eye-slash-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                              <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#666"
                              className="bi bi-eye-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                              <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                            </svg>
                          )}
                        </a>
                      </span>
                    </div>
                    {/* <i className="fas fa-check-circle"></i> */}
                    {/* <i className="fas fa-exclamation-circle"></i> */}
                    <div className="small text-white mt-1 mb-3 fs-10">
                      Password must be contain atleast 1 Capital character , 1
                      digit , 1 symbol and length min 8 digits
                    </div>
                    <small
                      id="passerr"
                      className="text-danger"
                    // style={{ top: "53%" }}
                    ></small>
                  </div>
                  {/* <div className={`form-group`}>
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        className="form-control"
                        name="user_cpassword"
                        id="cpass"
                        required=""
                        value={confPassword}
                        onChange={(e) => setConfPassword(e.target.value)}
                        placeholder="Confirm Password"
                      />
                      <span className="input-group-text">
                        <a
                          href="#view_qr"
                          className=""
                          onClick={(e) => {
                            hsPassword("cpass");
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="#666"
                            className="bi bi-eye"
                            viewBox="0 0 16 16"
                          >
                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                          </svg>
                        </a>
                      </span>
                    </div>
                    <small id="cpasserr" className="text-danger"></small>
                  </div> */}
                  <div className="form-group referral_code">
                    <a
                      className="referral_code text-white"
                      data-bs-toggle="collapse"
                      data-bs-target="#referral_code"
                      aria-expanded="false"
                    >
                      Referral Code (Optional)
                      <span class="fa-solid fa-chevron-down ms-2"></span>
                      <span class="fa-solid fa-chevron-up ms-2"></span>
                    </a>
                    <div className="collapse mt-2" id="referral_code">
                      <input
                        type="text"
                        className="form-control"
                        name="refercode"
                        id="refercode"
                        required=""
                        value={referral_code}
                        onChange={(e) => setReferral(e.target.value)}
                        placeholder="Referral code"
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-2">
                    <input
                      className=""
                      type="checkbox"
                      id="agree_terms"
                      name="agree_terms"
                      checked={agree}
                      style={{ cursor: "pointer" }}
                      onChange={(e) => setAgree(e.target.checked)}
                    />
                    <span className="terms text-white">
                      I agree to Bitcash's
                      <span className="ms-1 terms_condition">
                        <a
                          href="/terms_&_conditions"
                          target="_blank"
                          className="text-gradient"
                        >
                          Terms of Service
                        </a>{" "}
                      </span>
                    </span>
                  </div>
                  <div className="d-grid mt-3">
                    <button
                      type="button"
                      id="btn_submit"
                      className="btn login_btn"
                      onClick={(e) => {
                        otpSend();
                      }}
                    >
                      {loading ? (
                        <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                      ) : null}
                      <span id="reg">Create Account</span>
                    </button>
                  </div>

                  {/* <div className="my-2 text-center">OR</div> */}
                  <div className="d-grid my-3">
                    {/* <button
                      className="login-with-google-btn"
                      onClick={() => googleLogin()}
                    >
                      <img
                        src="./img/google.png"
                        width={20}
                        className="me-2"
                      />{" "}
                      Sign in with Google
                    </button> */}

                    {/* <ConnectButton.Custom>
                      {({
                        account,
                        chain,
                        openAccountModal,
                        openChainModal,
                        openConnectModal,
                        authenticationStatus,
                        mounted
                      }) => {
                        // Note: If your app doesn't use authentication, you
                        // can remove all 'authenticationStatus' checks
                        const ready =
                          mounted && authenticationStatus !== "loading";
                        const connected =
                          ready &&
                          account &&
                          chain &&
                          (!authenticationStatus ||
                            authenticationStatus === "authenticated");

                        return (
                          <div
                            className="d-grid"
                            {...(!ready && {
                              "aria-hidden": true,
                              style: {
                                opacity: 0,
                                pointerEvents: "none",
                                userSelect: "none"
                              }
                            })}
                          >
                            {(() => {
                              if (!connected) {
                                return (
                                  <div className="d-grid mt-3 text-center">
                                    <button
                                      class="btn login_btn_outline"
                                      onClick={openConnectModal}
                                      type="button"
                                    >
                                      <i className="fa fa-wallet fs-5 me-2 text-primary"></i>{" "}
                                      Sign in With Wallet
                                    </button>
                                  </div>
                                );
                              }

                              if (chain.unsupported) {
                                return (
                                  <button
                                    onClick={openChainModal}
                                    type="button"
                                  >
                                    Wrong network
                                  </button>
                                );
                              }

                              return (
                                <div style={{ display: "flex", gap: 12 }}>
                                  <button
                                    onClick={openChainModal}
                                    style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                    type="button"
                                  >
                                    {chain.hasIcon && (
                                      <div
                                        style={{
                                          background: chain.iconBackground,
                                          width: 12,
                                          height: 12,
                                          borderRadius: 999,
                                          overflow: "hidden",
                                          marginRight: 4
                                        }}
                                      >
                                        {chain.iconUrl && (
                                          <img
                                            alt={chain.name ?? "Chain icon"}
                                            src={chain.iconUrl}
                                            style={{
                                              width: 12,
                                              height: 12
                                            }}
                                          />
                                        )}
                                      </div>
                                    )}
                                    {chain.name}
                                  </button>

                                  <button
                                    onClick={openAccountModal}
                                    type="button"
                                  >
                                    {account.displayName}
                                    {account.displayBalance
                                      ? ` (${account.displayBalance})`
                                      : ""}
                                  </button>
                                </div>
                              );
                            })()}
                          </div>
                        );
                      }}
                    </ConnectButton.Custom> */}

                    <div className="col-lg-12">
                      <div className=" text-center text-white mt-2">
                        Already have an account ?
                        <Link className="text-gradient " to="/login">
                          {" "}
                          Log In{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            {isOTPSent && (
              <div class="m-auto login_window signupform cstm_form">
                <div style={{ position: "absolute" }}>
                  <BsArrowLeft
                    size={20}
                    className="font-bold"
                    onClick={goBack}
                    style={{ strokeWidth: 2 }}
                  />
                </div>
                <div className="d-flex justify-content-center mb-5">
                  <img src="/img/logo.svg" style={{ width: "80px" }} />
                </div>
                <div className="page-content-title">
                  <h4 className="mb-lg-5">
                    Enter the 6-digit verification code we sent to :{" "}
                    <span style={{ fontSize: "20px" }}>{email}</span>
                  </h4>
                  <h6>For security, we need to verify it's you.</h6>
                </div>
                <form className="" method="post" id="signupform">
                  <div className="form-group">
                    <small id="msg">Error Message</small>
                  </div>
                  <div className={`form-group`}>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        name="user_otp"
                        id="user_otp"
                        required=""
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter Verfication Code"
                      />
                      <span className="input-group-text">
                        {timer > 0 ? (
                          <div className="btn border-0 text-warning  rounded-0">
                            {timer}
                          </div>
                        ) : (
                          <button
                            className="btn border-0 text-warning rounded-0"
                            onClick={(e) => {
                              e.preventDefault();
                              let dt = email ? 0 : 1;
                              N_resendOTP(email).then((res) => {
                                if (res.status == 200) {
                                  setTimer(60);
                                  toast.success(res.message);
                                } else {
                                  toast.error(res.message);
                                }
                              });
                            }}
                          >
                            Resend
                          </button>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="d-grid mt-3">
                    <button
                      type="button"
                      id="btn_submit"
                      className="btn login_btn"
                      onClick={(e) => {
                        onSubmit(e);
                      }}
                    >
                      {loading ? (
                        <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                      ) : null}
                      <span id="reg">Verify</span>
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
