import React, { useEffect, useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
import Header from "../HomeComp/Header";
import {
  changeStatusPost,
  deletePendingPost,
  getMyAds,
  getP2PCurrencyLimitation,
} from "../redux/helpers/api_functions_new";
import "../sidebar.css";

import { Chip } from "@mui/material";
import { FaDotCircle, FaEye } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../components/Loader";
import NoDataComp from "../components/NoDataComp";
import "./myads.css";

import Swal from "sweetalert2";
import BackComp from "../../helper/components/BackComp";
import toast from "react-hot-toast";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { priceDecimalManage } from "../../helper/common";

const MyAds = () => {
  const router = useHistory();
  const [fiatPrice, setFiatPrice] = useState("");
  const [listing, setListing] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCoin, setSelectedCoin] = useState({
    label: "TRY",
    value: "TRY",
    image: "https://cdn2.iconfinder.com/data/icons/world-currency/512/07-512.png",
  });
  const [coinsData, setCoinsData] = useState([{
    label: "TRY",
    value: "TRY",
    image: "https://cdn2.iconfinder.com/data/icons/world-currency/512/07-512.png",
  }]);

  useEffect(() => {
    fetchAds();
  }, []);

  useEffect(() => {
    getP2PCurrencyLimitation()
      .then((res) => {
        // setCurrencyLimit(res.data);
        if (res.data.fiat && res.data.fiat.length > 0) {
          setCoinsData(res.data.fiat);
          setSelectedCoin(res.data.fiat[0]);
        }
      })
      .catch((error) => console.log(error, "<<---------------- error"));
  }, []);


  const fetchAds = async () => {
    try {
      setLoading(true);
      let response = await getMyAds();
      if (response) {
        // console.log(response, "<<----------response");
        setListing(response?.data?.items);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log("error --->>", error);
    }
  };

  const handleRedirection = async (url, id) => {
    // console.log(id, "<--------------id");

    router.push(url, {
      state: {
        post_id: id,
      },
    });
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deletePendingPost(id).then((res) => {
            if (res.status == 200) {
              fetchAds();
            } else {
              toast.error(res.message);
            }
          }).catch((error) => {
            // console.log('error----', error.message)
            toast.error(error.message);
          });
        }
      });
    } catch (error) {
      console.log(error, "<<---------------- delete post error");
    }
  };
  const handleToggle = (status, id) => {
    changeStatusPost({
      post_id: id,
      status,
    })
      .then((res) => {
        toast.success("Status updated!");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <Header />
      <div className="page-content " style={{ marginTop: "100px" }}>
        <main className="container mt-5 my-postad">
          <div className="row">
            <div className="mb-3 w-full">
              <div className="py-3">
                <div className="d-flex justify-content-between">
                  {/* <h2 className="text-white"> My Ads</h2> */}

                  <div className="d-flex align-items-center">
                    <BackComp url={"/p2pOrder"} text={""} />
                    <h3 className="text-white my-0"> My Ads</h3>
                  </div>
                  <div className="d-flex align-items-center p2p-filter-border">
                    <div
                      className="dropdown me-2 pe-2"
                      style={{ borderRight: "1px solid #474d57" }}
                    >
                      <button
                        className="btn btn-secondary dropdown-toggle d-flex align-items-center"
                        type="button"
                        id="currencyDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        <img
                          src={selectedCoin?.image}
                          alt="USD"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                            borderRadius: "50%",
                          }}
                        />
                        <span className="text-white">{selectedCoin?.value}</span>
                      </button>
                      <ul
                        className="p2pDropdown dropdown-menu"
                        style={{
                          backgroundColor: "#333",
                          padding: "5px 0px",
                          borderRadius: "5px",
                        }}
                        aria-labelledby="currencyDropdown"
                      >
                        {coinsData?.map((item, i) => (
                          <li
                            key={i}
                            onClick={() =>
                              setSelectedCoin({
                                image: item?.image,
                                value: item?.value,
                              })
                            }
                          >
                            <a
                              className="dropdown-item d-flex align-items-center hover-bg-gray"
                              href="#"
                            >
                              <img
                                src={item?.image}
                                alt="TRY"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "5px",
                                  borderRadius: "50%",
                                }}
                              />
                              {item?.value}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <input
                      type="number"
                      className="form-control text-white"
                      placeholder="Enter Amount"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        color: "white",
                        flex: 1,
                      }}
                      onChange={(e) => setFiatPrice(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <table class="table global_table history-p2p-tbl">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Unit price</th>
                        <th scope="col">Available</th>
                        <th scope="col">Limit</th>
                        <th scope="col">Payment Method</th>
                        <th scope="col">Active / Inactive</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className="text-white">
                      {loading ? (
                        <tr>
                          <td colSpan="7" style={{ textAlign: "center" }}>
                            <Loader />
                          </td>
                        </tr>
                      ) : listing?.length > 0 ? (
                        listing.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td scope="row" className="py-3">
                                {index + 1}
                              </td>
                              <td className="py-3">
                                <span className="desc_card text-white fs-18">
                                  {priceDecimalManage(value.selling_price, false)}
                                </span>
                                <span className="ms-1 text-white fs-12">
                                  {value.fiat_name}
                                </span>
                              </td>
                              <td className="py-3">
                                <span className="desc_card text-white fs-18">
                                  {priceDecimalManage(value.total_assets)}
                                </span>
                                <span className="ms-1 text-white fs-12">
                                  {value.asset_name}
                                </span>
                              </td>
                              <td className="py-3">

                                <span className="desc_card text-white fs-18">
                                  {priceDecimalManage(value.min_order_limit)}
                                </span>
                                <span className="ms-1 text-white me-2 fs-12">
                                  {value.asset_name}
                                </span>
                                {" - "}

                                <span className="desc_card text-white ms-2 fs-18">
                                  {priceDecimalManage(value.max_order_limit)}
                                </span>
                                <span className="ms-1 text-white fs-12">
                                  {value.asset_name}
                                </span>
                              </td>
                              <td className="py-3">
                                {value?.payment_method?.map((method) => (
                                  <li className="text-uppercase">
                                    <span className="text-warning me-2 text-bold">
                                      |
                                    </span>
                                    {method}
                                  </li>
                                ))}
                              </td>

                              <td className="py-3 text-capitalize">
                                {value.status != "deleted" &&
                                  value.progress != "complete" ? (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <label className="form-check form-switch">
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          defaultChecked={
                                            value.status == "online"
                                              ? true
                                              : false
                                          }
                                          onChange={() =>
                                            handleToggle(
                                              value.status == "online"
                                                ? "offline"
                                                : "online",
                                              value._id
                                            )
                                          }
                                        />
                                      </label>
                                    </div>
                                  </>
                                ) : (
                                  <>N/A</>
                                )}
                              </td>

                              <td className="py-3">
                                {value.progress == "dispute" ? (
                                  <span class="badge text-bg-warning">
                                    In appeal
                                  </span>
                                ) : value.status == "deleted" ? (
                                  <span class="badge text-bg-danger">
                                    Deleted
                                  </span>
                                ) : value.progress == "pending" ? (
                                  <span class="badge text-bg-warning">
                                    Ideal
                                  </span>
                                ) : value.progress == "inprogress" ? (
                                  <span class="badge text-bg-info">Active</span>
                                ) : value.progress == "complete" ? (
                                  <span class="badge text-bg-success">
                                    Completed
                                  </span>
                                ) : (
                                  <>
                                    <span class="badge text-bg-danger">
                                      {value.progress}
                                    </span>
                                  </>
                                )}
                              </td>
                              <td className="py-3">
                                <div>
                                  {value.status != "deleted" &&
                                    value.progress != "complete" && (
                                      <>
                                        {value.progress == "inprogress" ? (
                                          <RiDeleteBin5Line
                                            className="action-icon text-secondary ms-2 me-4"
                                            style={{ fontSize: "18px" }}
                                          />
                                        ) : (
                                          <RiDeleteBin5Line
                                            className="action-icon role-btn ms-2 me-3"
                                            onClick={() =>
                                              handleDelete(value._id)
                                            }
                                            style={{ fontSize: "18px" }}
                                          />
                                        )}
                                      </>
                                    )}

                                  <FaArrowAltCircleRight
                                    className="action-icon ms-2 me-0"
                                    role="button"
                                    onClick={() =>
                                      handleRedirection("/my-orders", value._id)
                                    }
                                    style={{ fontSize: "18px" }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="7" style={{ textAlign: "center" }}>
                            <NoDataComp text={"No Data Available"} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default MyAds;
