exports.shortenEmail = (email) => {
    const [name, domain] = email.split("@");
    const shortName = name.length > 6 ? name.substring(0, 6) + "..." : name;
    return `${shortName}@${domain}`;
}

exports.maskEmail = (email) => {
    const [localPart, domain] = email?.split("@");
    if (localPart.length <= 3) {
        return email; // If the local part is too short, return the original email
    }
    const maskedLocalPart = localPart.slice(0, 3) + "***" + localPart.slice(-2);
    return `${maskedLocalPart}@${domain}`;
};

exports.priceDecimalManage = (num, usdt = true) => {
    if (typeof num !== "number" || isNaN(num)) return num;
    // if (usdt) return num.toFixed(2);

    const decimalLength = (num.toString().split(".")[1] || "").length;
    return num.toFixed(decimalLength <= 2 ? 2 : decimalLength > 4 ? 5 : 3);
};
