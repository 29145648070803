import React, { useEffect } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import { N_getUserProfile } from "./redux/helpers/api_functions_new";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import { Link } from "react-router-dom";
import { AiOutlineDownload, AiOutlineEdit, AiOutlineFileText } from "react-icons/ai";
import { GrDownload } from "react-icons/gr";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FBTCins, FaSortDown, FaSortUp } from "react-icons/fa";
import { Sparklines, SparklinesLine } from "react-sparklines";
import Footer from "./HomeComp/Footer";
import { BiDownload } from "react-icons/bi";
export default function DownloadCsv(props) {
  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        {/* <div class="s-layout__sidebar">
          <ProfileSidebar />
        </div> */}

        <main className="">
          <div className="dashboard_header">
            <h3 className="header_title">Export History</h3>
            <div>
              <a href="" className="btn login_btn ">
               <BiDownload className="text-white"/> Export
              </a>
            </div>
          </div>
          <div className="account_page">
           

            <div className="col-lg-12 col-md-12 col-sm-12 mt-3 table-responsive">
              <table className="table global_table">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>Type</th>
                    <th>Coin</th>
                    <th>Period</th>
                    <th>Status</th>
                    <th>Action</th>                   
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={6} className="text-center border-bottom-0">
                      <img src="/img/no-data.png" className="no-data-found" alt="no data found" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
