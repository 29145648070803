import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleFav } from "../redux/actions/coinDBAction";
import TVChartContainer from "./CandleChart";
import { N_getVHL, N_RemoteStatus } from "../redux/helpers/api_functions_new";
import $ from "jquery";
import { getRound } from "../redux/helpers/helper_functions";

export default function CandleGraph(props) {
  const dispatch = useDispatch();
  const { coins, currency_graph, user_fav_pairing, paired_curency_price } =
    useSelector((state) => state.coinDBReducer);
  const [isFav, setIsFav] = React.useState(false);
  const [current_price, currentPrice] = React.useState(0);
  const [volume, setVolume] = useState(0);
  const [high, setHigh] = useState(0);
  const [low, setLow] = useState(0);
  const [close, setClose] = useState(0);
  const [per, setPercet] = useState(0);
  const [newgetchart, NewGetChart] = React.useState(false);
  const { user } = useSelector((state) => state.AuthReducer);
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const filter = "1h";
  const [prev_symbol, prevSymbol] = React.useState("");

  const coin = props.match.params.id.split("-");
  const SelCurency = coin && coin[1] ? coin[1].toUpperCase() : "";
  const data = Object.values(coins).find((d) => {
    if (d.symbol === coin[0].toUpperCase()) {
      return d;
    }
  });
  useEffect(() => {
    let match = user_fav_pairing.find((d) => d === data.symbol.toUpperCase());
    setIsFav(match ? true : false);
  }, [...user_fav_pairing, ...coin]);

  useEffect(() => {
    N_getVHL(props.match.params.id).then((data) => {
      if (data.status == 200) {
        setVolume(data.vhl.todays_v);
        setHigh(data.vhl.todays_h);
        setLow(data.vhl.todays_l);
        setClose(data.vhl.todays_c);
        setPercet(data.vhl.todays_pc);
      }
    });
  }, [props.match.params.id]);
  function getChart(symbol, symbol2) {
    prevSymbol(symbol);
    // console.log("getChart1: ", symbol, symbol2);
    return (
      <>
        <TVChartContainer symbols={symbol} pre_symbols={symbol2} />
      </>
    );
  }

  useEffect(() => {
    let coinsym = coin[0] + "-" + coin[1];
    if (paired_curency_price && data && SelCurency) {
      N_RemoteStatus(coin[0], coin[1]).then((dt) => {
        let inrPrice = data.current_price ? data.current_price : 1;
        let selPrice =
          SelCurency == "INR"
            ? 1
            : paired_curency_price[SelCurency]
            ? paired_curency_price[SelCurency]
            : 1;
        let fPrice = inrPrice * selPrice ? inrPrice / selPrice : 1;
        if (dt.status == 200) {
          const coinStatus = dt?.remoteTradingData?.update_price;
          if (SelCurency == "USDT" && coinStatus) {
            currentPrice(data.usdt_price);
          } else if (SelCurency == "BTC" && coinStatus) {
            currentPrice(data.btc_price);
          } else if (SelCurency == "INR" && coinStatus) {
            currentPrice(data.inr_price);
          } else if (SelCurency == "bitcash" && coinStatus) {
            currentPrice(data.vrx_price);
          } else {
            currentPrice(fPrice);
          }
        } else {
          currentPrice(fPrice);
        }
      });
    }
    NewGetChart(getChart(coinsym, prev_symbol));
  }, [...coin, filter, currency_graph]);
  return (
    <>
      
      <div class="" style={{height:'100%'}}>
        <div class="card-body p-0">
         
          {/* <div
            className="sc-bdVaJa sc-kUaPvJ kZeBBS row"
            
          >
            <div className="sc-bdVaJa sc-giadOv iIMfMq col-12 col-md-8 col-lg-8"></div>
          </div> */}
          <div id="candleCart1">{newgetchart}</div>
        </div>
      </div>
    </>
  );
}



