import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoginORSignup from "./LoginORSignup";
import { NotificationManager } from "react-notifications";
import "./order.css";
import Loader from "./Loader";
import { getUserBalance, getUserOrder } from "../redux/actions/coinDBAction";
import {
  N_cancleOrderById,
  N_pendingOrder,
  N_completeOrder,
  N_UpdateOrderById,
} from "../redux/helpers/api_functions_new";
import { round } from "../redux/helpers/Math";
import {
  SET_USER_ORDER_CLOSE,
  SET_USER_ORDER_PENDING,
} from "../redux/constant";
import formatDate from "../../hooks/formatDate";
import toast from "react-hot-toast";
import { BiEdit, BiEditAlt, BiWindowClose } from "react-icons/bi";
export default function OrdersTab(props) {
  const dispatch = useDispatch();
  function formatNumberWithCommas(number, locales = "en-US") {
    if (isNaN(number)) return number; // Return as is if not a valid number

    const [integerPart, decimalPart] = number.toString().split("."); // Split integer & decimal
    const formattedInteger = new Intl.NumberFormat(locales, {
      useGrouping: true,
    }).format(integerPart);

    return decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  }
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { user } = useSelector((state) => state.AuthReducer);
  const { user_order_pending, user_order_close } = useSelector(
    (state) => state.coinDBReducer
  );

  const [pendingOrder, setpendingOrder] = useState([]);
  const [closeOrder, setcloseOrder] = useState([]);
  useEffect(() => {
    if (activeTab == 0) {
      N_pendingOrder(user?.params ? user.params.user_id : user.user_id)
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: SET_USER_ORDER_PENDING,
              data: res.params.trade_history.pending,
              pending_order_loading: false,
            });
          } else {
            console.log("error to fetch open and close orders: ", res);
          }
        })
        .catch((e) => console.log(e));
    }
    if (activeTab == 1) {
      N_completeOrder(user?.params ? user.params.user_id : user.user_id)
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: SET_USER_ORDER_CLOSE,
              data: res.params.trade_history.compleated,
              close_order_loading: false,
            });
          } else {
            console.log("error to fetch open and close orders: ", res);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [activeTab]);


  useEffect(() => {
    setpendingOrder(
      user_order_pending.sort(
        (a, b) => Number(b.timestamp) - Number(a.timestamp)
      )
    );
    setcloseOrder(
      user_order_close.sort((a, b) => Number(b.timestamp) - Number(a.timestamp))
    );
  }, [user_order_pending, user_order_close]);

  return (
    <>
      <div className="card mt-2 mb-2">
        <div className="card-header bg-light p-0">
          <div className="nav nav-pills" id="nav-tab" role="tablist">
            <div
              className={`nav-item nav-link  ${activeTab === 0 ? "active" : ""
                }`}
              id="nav-home-tab"
              data-toggle="tab"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
              onClick={() => setActiveTab(0)}
            >
              <span className="text-white"> Open Orders</span>
            </div>
            <div
              className={`text-white nav-item nav-link ${activeTab === 1 ? "active" : ""
                }`}
              id="nav-profile-tab"
              data-toggle="tab"
              onClick={() => setActiveTab(1)}
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              <span className="text-white">Completed Orders</span>
            </div>
            {/* <div
              className={`nav-item nav-link ${activeTab === 2 ? "active" : ""}`}
              id="nav-profile-tab"
              data-toggle="tab"
              onClick={() => setActiveTab(2)}
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              Open Orders
            </div> */}
          </div>
        </div>

        <div className="order_height custom_scrollbar">
          {!isLoggedIn ? (
            <div className="tab-content orders">
              <LoginORSignup />
            </div>
          ) : null}
          {activeTab === 0 && isLoggedIn ? (
            <div className="">
              {/* <div
              className="offset-8 col-4 text-center text-danger cursor"
              style={{ fontSize: "12px" }}
              onClick={() =>
                cancleOrder(token, props.type)
                  .then((d) => {
                    if (d.status === 1) {
                      toast.success(d.msg);
                      dispatch(getUserOrder(token, props.type));
                      dispatch(getUserBalance(token));
                      dispatch(
                        getOrderBook(coin[0], coin[1], () => {}, props.type)
                      );
                      dispatch(
                        getTradeHist(coin[0], coin[1], () => {}, props.type)
                      );
                    } else {
                      toast.error(d.msg);
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                  })
              }
            >
              Cancel All
            </div> */}
            </div>
          ) : null}
          {isLoggedIn ? (
            <>
              <div className="tab-content orders">
                <div
                  className={`tab-pane fade ${activeTab === 0 ? "show active" : ""
                    }`}
                  id="open-order"
                >
                  <table className="exchange_tbl order-book-table text-white">
                    <thead className="sticky_thead">
                      <tr>
                        <th>
                          <h6>Order Time</h6>
                        </th>
                        <th>
                          <h6>Pair</h6>
                        </th>
                        <th>
                          <h6>Side</h6>
                        </th>
                        <th>
                          <h6>Type</h6>
                        </th>
                        <th>
                          <h6>Price</h6>
                        </th>
                        <th>
                          <h6>Total</h6>
                        </th>
                        <th>
                          <h6>Quantity</h6>
                        </th>

                        <th>
                          <h6>Delete</h6>
                        </th>
                        <th>
                          <h6>Action</h6>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {pendingOrder && pendingOrder?.length > 0
                        ? pendingOrder.map((d, index) => {
                          return (
                            <OrderRowPending
                              {...d}
                              key={index}
                              // deleteOrder={(order_id, order_type) =>
                              //   deleteOrder(order_id, order_type)
                              // }
                              user_id={
                                user?.params
                                  ? user.params.user_id
                                  : user.user_id
                              }
                            />
                          );
                        })
                        : null}
                    </tbody>
                  </table>

                  {pendingOrder?.length === 0 ? (
                    <div className="text-center py-5 text-muted" colspan="3">
                      <div>
                        <i class="fa fa-folder-open-o fs-2 text-muted"></i>
                      </div>
                      <div className="fs-12">No Open Orders !</div>
                    </div>
                  ) : null}
                  {loading ? <Loader /> : null}
                </div>

                <div
                  id="order-history"
                  className={`tab-pane fade ${activeTab === 1 ? "show active" : ""
                    }`}
                >
                  <table className="order-book-table exchange_tbl text-white">
                    <thead className="sticky_thead">
                      <tr>
                        {/* <th>
                          <h6>Sr.No.</h6>
                        </th> */}
                        <th>
                          <h6>Order Time</h6>
                        </th>
                        <th>
                          <h6>Pair</h6>
                        </th>
                        <th>
                          <h6>Type</h6>
                        </th>
                        <th>
                          <h6>Side</h6>
                        </th>
                        {/* <th>
                          <h6>Amount</h6>
                        </th> */}
                        <th>
                          <h6>Price</h6>
                        </th>
                        <th>
                          <h6>Total</h6>
                        </th>
                        <th>
                          <h6>Filled</h6>
                        </th>
                        <th>
                          <h6>Status</h6>
                        </th>
                        <th>
                          <h6>Action</h6>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {closeOrder && closeOrder?.length > 0
                        ? closeOrder.map((d, index) => {
                          return (
                            <OrderRow
                              {...d}
                              key={index}
                              index={index}
                              order_type={1}
                            />
                          );
                        })
                        : null}
                    </tbody>
                  </table>
                  {closeOrder?.length === 0 ? (
                    <div className="text-center py-5 text-muted" colspan="3">
                      <div>
                        <i class="fa fa-folder-open-o fs-2 text-muted"></i>
                      </div>
                      <div className="fs-12">No Completed Orders !</div>
                    </div>
                  ) : null}
                  {loading ? <Loader /> : null}
                </div>
                <div
                  id="order-history"
                  className={`tab-pane fade ${activeTab === 2 ? "show active" : ""
                    }`}
                >
                  <table className="order-book-table exchange_tbl">
                    <thead className="sticky_thead">
                      <tr>
                        {/* <th>
                          <h6>Sr.No.</h6>
                        </th> */}
                        <th>
                          <h6>Order Time</h6>
                        </th>
                        <th>
                          <h6>Pair</h6>
                        </th>
                        <th>
                          <h6>Side</h6>
                        </th>
                        {/* <th>
                          <h6>Amount</h6>
                        </th> */}
                        <th>
                          <h6>Price</h6>
                        </th>
                        <th>
                          <h6>Total</h6>
                        </th>
                        <th>
                          <h6>Filled</h6>
                        </th>
                        <th>
                          <h6>Status</h6>
                        </th>
                        <th>
                          <h6>Action</h6>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {closeOrder && closeOrder?.length > 0
                        ? closeOrder.map((d, index) => {
                          return (
                            <OrderRow
                              {...d}
                              key={index}
                              index={index}
                              order_type={1}
                            />
                          );
                        })
                        : null}
                    </tbody>
                  </table>
                  {closeOrder?.length === 0 ? (
                    <div className="text-center py-5 text-muted" colspan="3">
                      <div>
                        <i class="fa fa-folder-open-o fs-2 text-muted"></i>
                      </div>
                      <div className="fs-12">No Completed Orders !</div>
                    </div>
                  ) : null}
                  {loading ? <Loader /> : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

function OrderRow(props) {
  function formatNumberWithCommas(number, locales = "en-US") {
    if (isNaN(number)) return number; // Return as is if not a valid number

    const [integerPart, decimalPart] = number.toString().split("."); // Split integer & decimal
    const formattedInteger = new Intl.NumberFormat(locales, {
      useGrouping: true,
    }).format(integerPart);

    return decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  }
  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied...");
  };
  const [popup, setpopup] = useState(false);
  const [spin, setspin] = useState("");
  const dispatch = useDispatch();
  let progress_width = 0;
  let back = "rgb(16 129 53 / 10%)";
  const [dis, setdis] = useState(false);
  if (props["total_buy"] === undefined) {
    progress_width = (props.total_executed * 100) / props.volume;
    back = props.type === "buy" ? "rgba(35, 172, 80, 0.4)" : "#81101026";
  } else {
    progress_width = (props.total_executed * 100) / props.volume;
    back = "rgb(16 129 53 / 10%)";
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const deleteOrder = (order_id, user_id) => {
    setspin("spinner-border spinner-border-sm");
    N_cancleOrderById(user_id, order_id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getUserOrder(user_id, props.type));
          dispatch(getUserBalance(user_id));
          setTimeout(() => {
            setspin("");
            setpopup(false);
            setdis(false);
          }, 1000);
          // setLoading(false);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };

  // function getDateTime(props) {
  //   let ddate = props.order_date;
  //   if (ddate === 0) {
  //     ddate = props.execution_date;
  //   }
  //   return ddate;
  // }
  return (
    <>
      {popup ? (
        <>
          <div
            style={{
              position: "absolute",
              height: "43%",
              width: "99%",
              display: "flex",
              flexDirection: "column",
              zIndex: 200,
              top: "45px",
              left: "2px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "100%",
                zIndex: 1000,
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <div className="row" style={{ width: "220px" }}>
                <div className="shadow-sm p-0">
                  <div className=" card-body bg-white text-center">
                    <div>
                      <h6 className="text-red fs-12">Want to Delete Order ?</h6>
                      <div className="mt-3">
                        <button
                          type="button"
                          className="btn  btn-sm me-2"
                          onClick={() => {
                            if (!dis) {
                              setpopup(false);
                            }
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            if (!dis) {
                              setdis(true);
                              deleteOrder(
                                props.order_id,
                                props.user_id,
                                props["total_buy"] === undefined
                                  ? "sell"
                                  : "buy"
                              );
                            }
                          }}
                        >
                          {dis ? (
                            <span
                              className={`${spin} mx-2`}
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : null}
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <tr>
        {/* <td>{Number(props?.index) + 1} .</td> */}
        <td>
          {/* Date(getDateTime(props?.timestamp)).toLocaleDateString() */}
          {/* new Date(props?.timestamp).toLocaleDateString() */}
          {props?.order_type !== 1 ? (
            <span className="">
              {formatDate(Number(props.timestamp)).toLocaleString()}
            </span>
          ) : (
            <span className="">
              {formatDate(Number(props.timestamp)).toLocaleString()}
            </span>
          )}
        </td>
        <td>
          {props?.currency_type?.toUpperCase()}/
          {props?.compare_currency?.toUpperCase()}
        </td>
        {/* <td>Limit</td> */}
        <td>{props?.market_type == 1 ? "Limit" : "Spot"}</td>
        <td style={{ color: props?.type == "sell" ? "#e2464a" : "#20ac95" }}>
          {props?.type?.toUpperCase()}
        </td>

        {/* <td>
          {props.type === "buy" ? props.total_executed : props.total_executed}
        </td> */}
        <td>{formatNumberWithCommas(props?.raw_price)} USDT</td>

        <td>
          {props?.type === "sell"
            ? `${round(props?.volume)} ${props?.currency_type?.toUpperCase()}`
            : `${props?.default_price} USDT`}
        </td>
        <td>
          {props?.type === "sell"
            ? `${round(props?.volume * props?.raw_price)} USDT`
            : `${round(props?.volume)} ${props?.currency_type?.toUpperCase()}`}
        </td>

        <td>
          {props?.order_type !== 1 ? (
            <div
              title="Cancel Order"
              className=""
              onClick={() => {
                setpopup(true);
              }}
            >
              <i className="fa fa-trash text-danger"></i>
            </div>
          ) : props?.order_status == "2" ? (
            <span className="text-red">Cancelled</span>
          ) : (
            <span className="text-green">Completed</span>
          )}
        </td>
        <td>
          <button
            className="detail_btn"
            onClick={() => {
              openModal();
              setModalData(props);
            }}
          >
            Details
          </button>
        </td>
      </tr>
      {/* modal */}
      <div
        className={`modal fade no-scroll bg-blur ${isModalOpen ? "show d-block" : ""
          }`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="paymentmodal2"
        aria-hidden={!isModalOpen}
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content first_registration_section comming-soon-bg p-2">
            <div className="modal-body text-white">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Details</h5>

                <div
                  style={{ textAlign: "end", fontSize: "22px" }}
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsModalOpen(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </div>
              </div>

              <div className="my-2 d-flex gap-3 align-items-center">
                <div className=" ">
                  {modalData?.currency_type?.toUpperCase()}/
                  {modalData?.compare_currency?.toUpperCase()}
                </div>
                <div
                  style={{
                    color: props?.type == "sell" ? "red" : "green",
                    border: `0.5px solid ${props?.type == "sell" ? "red" : "green"
                      }`,
                    borderRadius: "4px",
                    padding: "2px 6px",
                  }}
                >
                  {props?.type?.toUpperCase()}
                </div>
              </div>
              <div className="row ">
                <div className="col-4 mb-2">
                  <div className="text-lgray">Order ID</div>
                  <div>
                    {modalData?._id?.slice(0, 5)}...{modalData?._id?.slice(-5)}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => copyText(modalData?._id)}
                    >
                      <i className="fa fa-copy ms-2"></i>
                    </span>
                  </div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Status</div>
                  <div>Success (100%)</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Update Time</div>
                  <div>
                    {new Date(Number(modalData.timestamp)).toLocaleString()}
                  </div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Avg. Price</div>
                  <div>{round(modalData?.raw_price)} USDT</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Filled</div>
                  <div>
                    {props?.type === "sell"
                      ? `${round(props?.volume * props?.raw_price)} USDT`
                      : `${round(
                        props?.volume
                      )} ${props?.currency_type?.toUpperCase()}`}
                  </div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Total</div>
                  <div>
                    {" "}
                    {props?.type === "sell"
                      ? `${round(
                        props?.volume
                      )} ${props?.currency_type?.toUpperCase()}`
                      : `${props?.default_price} USDT`}
                  </div>
                </div>
                <div className="col-4 mb-2">
                  <div className="text-lgray">Fees</div>
                  <div>
                    {props?.trades[0]?.transaction_fee}{" "}
                    {props?.currency_type?.toUpperCase()}
                  </div>
                </div>
                <div className="col-4 mb-2">
                  <div className="text-lgray">Type</div>
                  <div>{props?.status !== "c" ? "Spot" : "Limit"}</div>
                </div>
              </div>
              <div className="border-bottom"></div>
              <div className="mt-4">
                <h5>Trade Details</h5>
              </div>
              <div className="row ">
                <div className="col-3 mb-2">
                  <div className="text-lgray"> Time</div>
                  <div>
                    {new Date(Number(modalData.timestamp)).toLocaleString()}
                  </div>
                </div>

                <div className="col-3 mb-2">
                  <div className="text-lgray"> Price</div>
                  <div>{round(modalData?.raw_price)} USDT</div>
                </div>

                <div className="col-3 mb-2">
                  <div className="text-lgray">Filled</div>
                  <div>
                    {props?.type === "sell"
                      ? `${round(props?.volume * props?.raw_price)} USDT`
                      : `${round(
                        props?.volume
                      )} ${props?.currency_type?.toUpperCase()}`}
                  </div>
                </div>

                <div className="col-3 mb-2">
                  <div className="text-lgray">Total</div>
                  <div>
                    {" "}
                    {props?.type === "sell"
                      ? `${round(
                        props?.volume
                      )} ${props?.currency_type?.toUpperCase()}`
                      : `${round(props?.volume * props?.raw_price)} USDT`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end modal  */}
    </>
  );
}

function OrderRowPending(props) {
  function formatNumberWithCommas(number, locales = "en-US") {
    if (isNaN(number)) return number; // Return as is if not a valid number

    const [integerPart, decimalPart] = number.toString().split("."); // Split integer & decimal
    const formattedInteger = new Intl.NumberFormat(locales, {
      useGrouping: true,
    }).format(integerPart);

    return decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  }
  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied...");
  };
  const [popup, setpopup] = useState(false);
  const [spin, setspin] = useState("");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.AuthReducer);
  let progress_width = 0;
  let back = "rgb(16 129 53 / 10%)";
  const [dis, setdis] = useState(false);
  if (props["total_buy"] === undefined) {
    progress_width = (props.total_executed * 100) / props.volume;
    back = props.type === "buy" ? "rgba(35, 172, 80, 0.4)" : "#81101026";
  } else {
    progress_width = (props.total_executed * 100) / props.volume;
    back = "rgb(16 129 53 / 10%)";
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const deleteOrder = (order_id, user_id) => {
    setspin("spinner-border spinner-border-sm");
    N_cancleOrderById(user_id, order_id)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getUserOrder(user_id, props.type));
          dispatch(getUserBalance(user_id));
          setTimeout(() => {
            setspin("");
            setpopup(false);
            setdis(false);
          }, 1000);
          // setLoading(false);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  };
  const [editData, setEditData] = useState({
    total: "",
    price: "",
    quantity: "",
    compare_currency: "",
    currency_type: ""

  })
  const handleEdit = (props) => {
    let val = parseFloat(props?.volume) + parseFloat(props?.trades[0]?.transaction_fee);
    // console.log(props?.raw_price * parseFloat(props?.volume) , "val----")
    setEditData({
      id: props?.order_id,
      total: props?.default_price,
      price: props?.raw_price,
      quantity: props?.type === "sell" ? props?.raw_price * parseFloat(props?.volume) : val,
      compare_currency: props?.compare_currency,
      currency_type: props?.currency_type,
      volume: props?.volume,
      type: props?.type
    })
    setEditModalOpen(true)
  }

  const handleUpdate = () => {
    // console.log(editData?.price, editData?.quantity, editData?.id, editData?.total, user?.user_id, "api data")
    let data = {
      "user_id": user?.user_id,
      "volume": editData?.quantity,
      "raw_price": editData?.price,
      "type": editData?.type,
      "cprice": editData?.price,
      "default_price": editData?.total,
      "marketType": 1,
      "order_id": editData?.id,
      "currency_type": editData?.currency_type,
      "compare_currency": editData?.compare_currency,
    }
    N_UpdateOrderById(data)
      .then((res) => {
        if (res) {
          dispatch(getUserOrder(user?.user_id));
          setEditModalOpen(false)
          // setLoading(false);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((e) => {
        console.log("error: ", e);
      });
  }


  return (
    <>
      {popup ? (
        <>
          <div
            style={{
              position: "absolute",
              height: "43%",
              width: "99%",
              display: "flex",
              flexDirection: "column",
              zIndex: 200,
              top: "45px",
              left: "2px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "100%",
                zIndex: 1000,
                display: "flex",
                flexDirection: "column",
                alignSelf: "center",
              }}
            >
              <div className="row" style={{ width: "220px" }}>
                <div className="shadow-sm p-0">
                  <div className=" card-body bg-white text-center">
                    <div>
                      <h6 className="text-red fs-12">Want to Delete Order ?</h6>
                      <div className="mt-3">
                        <button
                          style={{
                            background: "transparent",
                            border: "1px solid #fff",
                            color: "#fff",
                          }}
                          type="button"
                          className="btn  btn-sm me-2"
                          onClick={() => {
                            if (!dis) {
                              setpopup(false);
                            }
                          }}
                        >
                          Cancel
                        </button>

                        <button
                          type="button"
                          className="btn btn-success btn-sm"
                          onClick={() => {
                            if (!dis) {
                              setdis(true);
                              deleteOrder(
                                props.order_id,
                                props.user_id,
                                props["total_buy"] === undefined
                                  ? "sell"
                                  : "buy"
                              );
                            }
                          }}
                        >
                          {dis ? (
                            <span
                              className={`${spin} mx-2`}
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : null}
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      <tr>
        {/* <td>{Number(props?.index) + 1} .</td> */}
        <td>
          {/* Date(getDateTime(props?.timestamp)).toLocaleDateString() */}
          {/* new Date(props?.timestamp).toLocaleDateString() */}
          {props?.order_type !== 1 ? (
            <span className="">
              {formatDate(Number(props.timestamp)).toLocaleString()}
            </span>
          ) : (
            <span className="">
              {formatDate(Number(props.timestamp)).toLocaleString()}
            </span>
          )}
        </td>
        <td>
          {props?.currency_type?.toUpperCase()}/
          {props?.compare_currency?.toUpperCase()}
        </td>
        {/* <td>Limit</td> */}
        <td style={{ color: props?.type == "sell" ? "#e2464a" : "#20ac95" }}>
          {props?.type?.toUpperCase()}
        </td>
        <td>Limit</td>
        {/* <td>
          {props.type === "buy" ? props.total_executed : props.total_executed}
        </td> */}
        <td>{formatNumberWithCommas(props?.raw_price)} USDT
          {/* <BiEditAlt onClick={() => handleEdit(props)} style={{ fontSize: '16px' }} />  */}
        </td>

        <td>
          {props?.type === "sell"
            ? `${round(props?.volume)} ${props?.currency_type?.toUpperCase()}`
            : `${props?.default_price} USDT`}
        </td>
        <td>
          {props?.type === "sell"
            ? `
            ${round(props?.volume * props?.raw_price)} USDT`
            : `${round(props?.volume)} ${props?.currency_type?.toUpperCase()}`}
          {/* <BiEditAlt onClick={() => handleEdit(props)} style={{ fontSize: '16px' }} /> */}
        </td>

        <td>
          {props?.order_type !== 1 ? (
            <div
              title="Cancel Order"
              className=""
              onClick={() => {
                setpopup(true);
              }}
            >
              <i className="fa fa-trash text-danger"></i>
            </div>
          ) : props?.status !== "c" ? (
            <span className="text-green">Completed</span>
          ) : (
            <span className="">Executed</span>
          )}
        </td>
        <td>
          <button
            className="detail_btn"
            onClick={() => {
              openModal();
              setModalData(props);
            }}
          >
            Details
          </button>
        </td>
      </tr>
      {/* modal */}
      <div
        className={`modal fade no-scroll bg-blur ${isModalOpen ? "show d-block" : ""
          }`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="paymentmodal2"
        aria-hidden={!isModalOpen}
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content first_registration_section comming-soon-bg p-2">
            <div className="modal-body text-white">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Details</h5>

                <div
                  style={{ textAlign: "end", fontSize: "22px" }}
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsModalOpen(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </div>
              </div>

              <div className="my-2 d-flex gap-3 align-items-center">
                <div className=" ">
                  {modalData?.currency_type?.toUpperCase()}/
                  {modalData?.compare_currency?.toUpperCase()}
                </div>
                <div
                  style={{
                    color: props?.type == "sell" ? "red" : "green",
                    border: `0.5px solid ${props?.type == "sell" ? "red" : "green"
                      }`,
                    borderRadius: "4px",
                    padding: "2px 6px",
                  }}
                >
                  {props?.type?.toUpperCase()}
                </div>
              </div>
              <div className="row ">
                <div className="col-4 mb-2">
                  <div className="text-lgray">Order ID</div>
                  <div>
                    {modalData?._id?.slice(0, 5)}...{modalData?._id?.slice(-5)}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => copyText(modalData?._id)}
                    >
                      <i className="fa fa-copy ms-2"></i>
                    </span>
                  </div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Status</div>
                  <div>Success (100%)</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Update Time</div>
                  <div>
                    {new Date(Number(modalData.timestamp)).toLocaleString()}
                  </div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Avg. Price</div>
                  <div>{round(modalData?.raw_price)} USDT</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Filled</div>
                  <div>
                    {props?.type === "sell"
                      ? `${round(0)} USDT`
                      : `${round(0)} ${props?.currency_type?.toUpperCase()}`}
                  </div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Total</div>
                  <div>
                    {" "}
                    {props?.type === "sell"
                      ? `${round(
                        props?.volume
                      )} ${props?.currency_type?.toUpperCase()}`
                      : `${round(props?.volume * props?.raw_price)} USDT`}
                  </div>
                </div>
                <div className="col-4 mb-2">
                  <div className="text-lgray">Fees</div>
                  <div>
                    {props?.trades[0]?.transaction_fee}{" "}
                    {props?.currency_type?.toUpperCase()}
                  </div>
                </div>
                <div className="col-4 mb-2">
                  <div className="text-lgray">Type</div>
                  <div>Limit</div>
                </div>
              </div>
              <div className="border-bottom"></div>
              <div className="mt-4">
                <h5>Trade Details</h5>
              </div>
              <div className="row ">
                <div className="col-3 mb-2">
                  <div className="text-lgray"> Time</div>
                  <div>
                    {new Date(Number(modalData.timestamp)).toLocaleString()}
                  </div>
                </div>

                <div className="col-3 mb-2">
                  <div className="text-lgray"> Price</div>
                  <div>{round(modalData?.raw_price)} USDT</div>
                </div>

                <div className="col-3 mb-2">
                  <div className="text-lgray">Filled</div>
                  <div>
                    {props?.type === "sell"
                      ? `${round(0)} USDT`
                      : `${round(0)} ${props?.currency_type?.toUpperCase()}`}
                  </div>
                </div>

                <div className="col-3 mb-2">
                  <div className="text-lgray">Total</div>
                  <div>
                    {" "}
                    {props?.type === "sell"
                      ? `${round(
                        props?.volume
                      )} ${props?.currency_type?.toUpperCase()}`
                      : `${round(props?.volume * props?.raw_price)} USDT`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end modal  */}
      {/* edit modal */}
      <div
        className={`modal fade no-scroll bg-blur ${editModalOpen ? "show d-block" : ""
          }`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editmodal"
        aria-hidden={!editModalOpen}
        style={{ display: editModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content  p-2">
            <div className="modal-body text-white">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Edit Order</h5>
                <BiWindowClose onClick={() => setEditModalOpen(false)} style={{ fontSize: '20px' }} />

              </div>
              <div className="mt-4">
                <lable>Order Price ({editData?.compare_currency?.toUpperCase()})</lable>
                <input
                  value={editData?.price || ""}
                  type="number"
                  min="1"
                  onChange={(e) => {
                    let newPrice = e.target.value === "" ? "" : parseFloat(e.target.value);
                    // Ensure newPrice is at least 1 and prevent empty or 0 values
                    // if (newPrice === "" || newPrice < 1) {
                    //   newPrice = 1;
                    // }

                    // const currentQuantity = parseFloat(editData?.quantity) || 1; // Default quantity to 1
                    // const currentPrice = parseFloat(editData?.price) || 1; // Default price to 1
                    // const staticPrice = parseFloat(editData?.price) ;
                    // const staticQuantity = parseFloat(editData?.quantity) ;
                    // console.log(newPrice, "newPrice", currentQuantity, "currentQuantity", currentPrice, "currentPrice", editData.total, "default_price");

                    setEditData((prev) => ({
                      ...prev,
                      price: newPrice,
                      quantity: editData.type == "buy" ? (editData.total / newPrice) : (newPrice * editData.volume), // Ensures correct calculation
                    }));
                  }}
                  className="form-control"
                  placeholder=""
                />

                <div className="mt-3">
                  <lable >Total Quantity ({editData?.currency_type?.toUpperCase()})</lable>
                  <input type="number"
                    onChange={(e) => {
                      const newQuantity = e.target.value;
                      // setEditData((prev) => ({
                      //   ...prev,
                      //   quantity: newQuantity,
                      // }));
                    }} value={editData?.quantity} class="form-control" placeholder="" />
                </div>
                <div className="mt-4 d-flex justify-content-end">
                  <div>
                    <button className="login_btn" onClick={() => handleUpdate()}>Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end */}

    </>
  );
}
