import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import Loader from "../components/Loader";
import AOS from "aos";
import "aos/dist/aos.css";
import { getRound } from "../redux/helpers/helper_functions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useHumanReadableNumber from "../../hooks/useHumanReadableNumber";
import { Sparklines, SparklinesCurve, SparklinesLine } from "react-sparklines";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { N_getWebsiteData } from "../redux/helpers/api_functions_new";
import { QRCode } from "react-qrcode-logo";
export default function MarketTrend() {
  const formatNumber = useHumanReadableNumber();
  const convertStringToNumber = (input) => {
    // Ensure the input is a string
    const str = String(input);

    const cleanedString = str.replace(/,/g, ""); // Remove commas
    return parseFloat(cleanedString); // Convert to number
  };
  const [loader, setLoader] = useState(true);
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  function formatNumberWithCommas(number, locales = "en-US") {
    if (isNaN(number)) return number; // Return as is if not a valid number

    const [integerPart, decimalPart] = number.toString().split("."); // Split integer & decimal
    const formattedInteger = new Intl.NumberFormat(locales, {
      useGrouping: true,
    }).format(integerPart);

    return decimalPart !== undefined
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  }
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1200,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };
  AOS.init();

  const preferredOrder = ["BTC", "ETH", "XRP", "USDT", "SOL", "BNB", "DOGE"];
  const { coins, campare_currency } = useSelector(
    (state) => state.coinDBReducer
  );

  let coins1 = Object.values(coins);
  useEffect(() => {
    if (coins1?.length == 0) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [coins]);
  // Sort coins1 based on preferredOrder
  coins1.sort((a, b) => {
    const indexA = preferredOrder.indexOf(a.symbol);
    const indexB = preferredOrder.indexOf(b.symbol);
    if (indexA === -1) return 1; // If a.symbol is not in preferredOrder, move it to the end
    if (indexB === -1) return -1; // If b.symbol is not in preferredOrder, move it to the end
    return indexA - indexB;
  });

  const market_child =
    coins1 &&
    coins1.map((item, index) => {
      if (index <= 10) {
        let usdtprize = coins1 && coins1.find((item) => item.symbol == "USDT");

        return (
          <>
            {item.symbol != "USDT" ? (
              <tr className="table_row_border">
                <td>
                  <img
                    src={item.icon}
                    alt="coin symbole"
                    className="market_coin_img"
                    style={{ borderRadius: "50%", background: "white", padding: "0px" }}
                  />
                  {item.name == "Wrapped Monero" ? "Monero" : item.name}
                  <span className="name_coin ms-2">{item.symbol}</span>
                </td>
                <td>
                  $
                  {formatNumberWithCommas(
                    getRound(item?.current_price / usdtprize?.current_price)
                  )}
                </td>
                <td>
                  <span
                    style={{
                      color: item.direction === "up" ? "#20ac95" : "#e2464a",
                    }}
                  >
                    {item.direction === "up" ? "+" : "-"}{" "}
                    {getRound(item.price_change_percentage_1h)}%{" "}
                    {item.direction === "up" ? (
                      <BsArrowUpShort />
                    ) : (
                      <BsArrowDownShort />
                    )}
                  </span>
                </td>
                <td>
                  $
                  {formatNumber(
                    convertStringToNumber(item.market_cap) /
                    usdtprize?.current_price
                  )}
                </td>
                <td class="text-end pe-5">
                  <Sparklines>
                    <SparklinesLine color="" data={item.h_trend} />
                  </Sparklines>

                  <Sparklines data={item.h_trend}>
                    <SparklinesCurve
                      color={item.direction == "up" ? "#20ac95" : "#FF4500"} // Bright green or orange for better contrast
                      style={{
                        strokeWidth: 2,
                        strokeLinejoin: "round",
                        strokeLinecap: "round",
                        filter: "drop-shadow(0 0 5px rgba(255, 255, 255, 0.7))", // Brighter shadow for better visibility
                      }}
                    />
                  </Sparklines>
                </td>
                <td>
                  <NavLink
                    to={`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`}
                  >
                    <button className="login_btn btn px-3">Trade</button>
                  </NavLink>
                </td>
              </tr>
            ) : (
              ""
            )}
          </>
        );
      }
    });

  const [androidUrl, setAndroidUrl] = useState(null);
  const [iosUrl, setIosUrl] = useState(null);
  useEffect(() => {
    N_getWebsiteData()
      .then((data) => {
        if (data.status === 200) {
          let android = data.params?.applications?.android
          let ios = data.params?.applications?.ios
          setAndroidUrl(android)
          setIosUrl(ios)
        }
      })
  }, [])
  return (
    <>
      <div className="spacer" id="trade_now">
        <div className="container">
          <div className="para_heading text-md-center text-white">
            Capture Every <span className="text-gradient">Trading</span>{" "}
            Opportunity
          </div>
          <div className="market_trend_section">
            <div className="overflow-auto">
              <div className="table_scroll_div">
                <table className="table home_table">
                  <thead>
                    <tr>
                      <th>Trading Pair</th>
                      <th>Last Price</th>
                      <th>24h Change</th>
                      <th>Market Cap</th>
                      <th>{" "}Charts{" "}</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      market_child
                    )}
                  </tbody>
                </table>

                <div className="text-center mb-4 mt-2">
                  <NavLink className="login_btn" to={"/Markets"} style={{ fontSize: "16px", padding: "8px 25px", marginBottom: "16px" }}>View All</NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="secure_assets_section">
        <div className="container">
          <div className="para_heading text-center text-white">
            Protect Your <span className="text-gradient">Assets</span>
          </div>
          <div className="about_card_div">
            <div className="row">
              <div className="col-md-4">
                <div className="about_card about_card_border_right">
                  <div className="my-2">
                    <div className="HeadingNumber">
                      <img style={{ width: "60px" }} src="/img/l4.svg" />
                    </div>
                  </div>
                  <div className="about_card_heading mt-4 mb-2">
                    Compliance Matrix
                  </div>
                  <div className="about_card_content">
                    Take control of your financial security with bitcash—ensuring
                    transparency, trust, and state-of-the-art protection for
                    your investments.{" "}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="about_card about_card_border_right">
                  <div className="my-2">
                    <div className="HeadingNumber">
                      <img style={{ width: "60px" }} src="/img/l5.svg" />
                    </div>
                  </div>
                  <div className="about_card_heading mt-4 mb-2">
                    Advanced Security Protocols
                  </div>
                  <div className="about_card_content">
                    Protect your investments with cutting-edge security! bitcash
                    ensures your assets are safe with 2FA, AI-driven risk
                    detection, and state-of-the-art protection.{" "}
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="about_card ">
                  <div className="my-2">
                    <div className="HeadingNumber">
                      <img style={{ width: "60px" }} src="/img/l6.svg" />
                    </div>
                  </div>
                  <div className="about_card_heading mt-4 mb-2">
                    Transparency You Can Trust{" "}
                  </div>
                  <div className="about_card_content">
                    bitcash guarantees 100% Proof-of-Reserves! Backed by top
                    cybersecurity firms & validated by industry-leading audits,
                    we ensure ultimate fund safety, transparency & trust in
                    every transaction.{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="app_section">
        <div className="container">
          <div className="row">
            <div className="para_heading text-center text-white">
              Accelerate your path to market and unlock{" "}
              <br className="d-none d-md-block" />
              <span className="text-gradient">your full revenue potential</span>
            </div>
            <div className="col-12 my-5">
              <div className="d-flex justify-content-center flex-column gap-3 gap-md-5">
                <img className="px-5 move_img1" src="/img/dummy_slide1.png" />
                <img className="move_img2" src="/img/dummy_slide2.png" />
                <img className="px-5 move_img3" src="/img/dummy_slide3.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" key_features ">
        <div className="container">
          <div className="d-flex flex-column flex-lg-row justify-content-between gap-5">
            <div className="text-center mb-2">
              <h1 className="para_heading text-white">
                Vision of <span className="text-gradient">Bitcash</span>
              </h1>
              <p className="para_subheading text-center">
                We envision a world where Every Trader – from beginners to
                experts – can access the same cutting-edge tools, ensuring
                fairness and equality in trading. Our Commitment to Innovation
                drives us to build a Platform that offers Complete Control,
                robust Risk Management, and Rapid Execution for traders
                worldwide.
              </p>
            </div>

            <div className="text-center">
              <h1 className="para_heading text-white">
                Mission of <span className="text-gradient">Bitcash</span>
              </h1>
              <p className="para_subheading text-center">
                {" "}
                At bitcash, we’re on a Mission to make trading Accessible,
                Efficient, and Secure for everyone. We aim to Simplify Trading
                while providing the most Advanced Tools to help you make
                informed decisions. With our unique 3-in-1 Feature and Swap Free
                policy, we’re focused on Accuracy, Speed, and Transparency at
                every step.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" key_features trade_banner mb-5" id="trade_anytime">
        <div className="container">
          <div className="row">
            <div className="col-md-6 m-auto">
              <div className="para_heading text-white">
                Trade Anytime,
                <br />
                <span className="text-gradient">Anywhere</span>
              </div>
              <div className="para_subheading mt-4">
                Take control of your trades on the go. With bitcash's intuitive
                app and web platform, you can open positions instantly and
                manage your investments with ease. Whether you're on your
                smartphone or desktop, our technology ensures you never miss a
                market opportunity.
              </div>
              <h3 className="mt-3 para_subheading">Available on:</h3>
              <div className="mt-4">
                {/* <a href={iosUrl ? iosUrl : "#"} target={iosUrl ? "_blank" : ""}>
                  <img
                    style={{ width: "160px", marginTop: "6px" }}
                    src="/img/appstore.png"
                  />
                </a> */}
                <a href={androidUrl ? androidUrl : "#"} target={androidUrl ? "_blank" : ""}>
                  <img
                    style={{ width: "160px", marginTop: "6px" }}
                    src="/img/playstore.png"
                  />
                </a>
                <div className="mt-2 ms-3">
                  <QRCode
                    value={androidUrl ? androidUrl : ""}
                    ecLevel={"H"}
                    quietZone={12}
                    size={100}
                    logoImage={"/img/fav.png"}
                    logoWidth={15}
                    logoHeight={15}
                    logoPaddingStyle={"circle"}
                    removeQrCodeBehindLogo={true}
                    logoPadding={2}
                    qrStyle={"dots"}
                    style={{
                      padding: "1px",
                      backgroundColor: "white",
                      borderRadius: "5px"
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none text-center d-md-block">
              <img src="/img/trade.png" />
            </div>
          </div>
        </div>
      </div>


      <div className="spacer key_features ways pt-5">
        <div className="container">
          <div className="para_heading text-center text-white">
            Why <span className="text-gradient">Bitcash</span> Stands Out
          </div>
          <div className="row  mt-5">
            <Slider {...settings}>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    Integrated Wallet for Crypto, Forex, and Fiat
                  </div>

                  <div className="ways_content custom_border_gradient">
                    bitcash’s all-in-one feature allows you to trade and manage
                    various currencies effortlessly and securely.
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    Swap-Free Trading Accounts
                  </div>

                  <div className="ways_content custom_border_gradient">
                    bitcash offers swap-free accounts tailored for traders who
                    prefer not to engage in interest-based transactions. No
                    swap, More fun.{" "}
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">Competitive Spreads</div>

                  <div className="ways_content custom_border_gradient">
                    Enjoy tight spreads for all your trading needs.
                    <ul>
                      <li>Forex spreads as low as 2 pips.</li>
                      <li>
                        Cryptocurrency trades with a competitive 0.02% spread,
                        ensuring cost efficiency.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">Staking Options</div>
                  <div className="ways_content custom_border_gradient">
                    Earn passive income with bitcash’s staking feature.
                    <ul>
                      <li>
                        Simply hold select cryptocurrencies on the platform to
                        generate returns.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    {" "}
                    Advanced Charting Tools with Supercharts
                  </div>
                  <div className="ways_content custom_border_gradient">
                    Analyze market trends and make informed decisions with
                    bitcash’s advanced charting tools.
                    <ul>
                      <li>
                        Access a range of technical indicators and visualization
                        options for a superior trading experience.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading "> High Leverage Options</div>
                  <div className="ways_content custom_border_gradient">
                    Expand your trading potential with leverage options tailored
                    to your needs.
                    <ul>
                      <li>
                        Experience the power of high leverage and take your
                        trading to the next level with bitcash. Your success is
                        our priority.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="ways_card">
                  <div className="ways_heading "> P2P Trading Features</div>
                  <div className="ways_content custom_border_gradient">
                    Facilitate direct, secure trades between users through
                    bitcash’s peer-to-peer system.
                    <ul>
                      <li>Designed for safe and transparent transactions.</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    {" "}
                    No Inactivity or Withdrawal Fees{" "}
                  </div>
                  <div className="ways_content custom_border_gradient">
                    Focus on trading without worrying about hidden costs.
                    <ul>
                      <li>
                        bitcash ensures a fee-free experience for inactive
                        accounts or withdrawals.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    {" "}
                    Education and Community Building
                  </div>
                  <div className="ways_content custom_border_gradient">
                    bitcash empowers traders through comprehensive learning
                    resources.
                    <ul>
                      <li>
                        Access tutorials, guides, and community features to
                        enhance your trading expertise.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="ways_card">
                  <div className="ways_heading ">
                    {" "}
                    Ethical and Transparent Operations
                  </div>
                  <div className="ways_content custom_border_gradient">
                    Licensed by FCA and FSA, bitcash prioritizes regulatory
                    compliance and ethical trading.
                    <ul>
                      <li>
                        Ensures the security of your funds and a fair trading
                        environment for all users.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}
