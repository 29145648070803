import React,{useEffect, useState} from "react";
import Header from "./HomeComp/Header";
import { isOtp } from "./redux/helpers/form-validator.functions";
import { useDispatch, useSelector } from "react-redux";
import { opt_forget_verify } from "./redux/actions/authActions";
import { Link } from "react-router-dom";
import { N_resendOTP } from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import useToastPromise from "../hooks/toastHook";
import toast from "react-hot-toast";

export default function FOTP(props) {
  const showToast = useToastPromise();
  const { otp_send, user } = useSelector((state) => state.AuthReducer);
  const dispatch = useDispatch();
  if (!otp_send) props.history.push("/login");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState();
  const [timer, setTimer] = useState(60);
  const handleProceedSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    dispatch(
        opt_forget_verify(
        otp,
        user.email,
        () => props.history.push("/update-password"),
        () => setLoading(false)
      )
    );
  };
  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(countdown); // Cleanup on unmount
    }
  }, [timer]);
  return (
    <div className="register_bg">
      <Header {...props} />
      <div className="container ">
        <div className="row align-items-center">
        <div class="col-md-6  ">
        <div className="LoginImg">
                  <img  src="/img/rewards-1.svg" className="login-img0" />
              </div>
          </div>
          <div className="col-md-6">

        {/* <div className="security_header ">
          <div className="security_head">
            <div className="sec_circle bg_circle shead-circle">
              <i className="fas fa-dot-circle"></i>
              <span color="#ffffff" className="email_span">
                Email
              </span>
            </div>
          </div>
        </div> */}
        <div className="container ">
          <div className="row">
            <div className="col-12 col-md-12 col-sm-12 m-auto login_window signupform cstm_form">
              <form
                className="signupform mdfthemetxt"
                onSubmit={handleProceedSubmit}
                id="otp_form"
              >
                <div>
                <div className="d-flex justify-content-center mb-5">
                  <Link to="/">
              <img src="/img/logo.svg" style={{width:'80px'}}  />
              </Link>
            </div>
                  <h4>Check your inbox for verification mail</h4>
                </div>
                <div className="form-group">
                  <small id="msg" style={{ fontSize: "15px" }}>
                    Error message
                  </small>
                </div>
                <div className="form-group">
                  <div>
                    An OTP has sent your email
                    <br />
                    <strong>{user.email}</strong>. Don't see it? check your spam
                    folder.
                    <br /> <br />
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      name="user_otp"
                      className="form-control"
                      value={otp}
                      id="user_otp"
                      maxLength={6}
                      onChange={(e) => {
                        setOtp(e.target.value);
                        // isOtp(e.target.value);
                      }}
                      placeholder="Enter Your OTP"
                    />
                    {/* <i
                      className="fas fa-check-circle"
                      style={{ top: "16px" }}
                    ></i>
                    <i
                      className="fas fa-exclamation-circle"
                      style={{ top: "16px" }}
                    ></i> */}
                     <span className="input-group-text">
                            <div className="">
                              <div className="">
                              {timer > 0 ? <button
                                  className="btn border-0 text-primary  rounded-0"
                                >
                                 {timer}
                                </button>
                                : <button
                                  className="btn border-0 text-primary  rounded-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    N_resendOTP(user.email).then(
                                      (res) => {
                                        if (res.status == 200) {
                                    setTimer(60);
                                          toast.success(
                                            res.message
                                          );
                                        } else {
                                          toast.error(res.message);
                                        }
                                      }
                                    );
                                  }}
                                >
                                  Resend
                                </button>}
                              </div>
                            </div>
                          </span>
                    {/* <small>Error message</small> */}
                  </div>
                  <button style={{width:'100%'}} type="submit" id="proceed_btn" className="login_btn px-5 mt-4">
                    Verify
                  </button>
                </div>
                {loading ? (
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
  );
}
