import React, { useState } from "react";
import "../modals/p2p.css";
import SwapAssetsDropdown from "./TermsTagsDropDown";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Select, { components } from "react-select";
import { BiMinus, BiPlus } from "react-icons/bi";

const P2PPostAdd3 = ({ activeStep, setActiveStep, data, setData }) => {
  const Schema = Yup.object().shape({
    // remark: Yup.string().required("Please Enter Remarks"),
    // auto_reply: Yup.string().required("Please Enter Auto Reply Message"),
    registered_days: Yup.number().required("Please Enter Registered Days"),
    holding_assets: Yup.number().required("Please Enter Holding Assets"),
    // regions: Yup.string().required("Please Select Regions"),
    status: Yup.string().required("Please Select Status"),
  });
  const [step3Data, setStep3Data] = useState({
    remark: data?.remark,
    auto_reply: data?.auto_reply,
    registered_days: data?.registered_days,
    holding_assets: data?.holding_assets,
    regions: data?.regions,
    status: 'online',// data?.status,
  });

  const onSubmit = async (values, onSubmitProps) => {
    setData((prev) => ({
      ...prev,
      ...values,
      isSubmit: true,
    }));
  };

  const handleBack = (values) => {
    setActiveStep(1);
    setData((prev) => ({
      ...prev,
      ...values,
    }));
  };

  const status_options = [
    {
      value: "online",
      label: "Online",
    },
    {
      value: "offline",
      label: "Offline",
    },
    {
      value: "private",
      label: "Private",
    },
  ];

  const CustomOption = (props) => {
    return <components.Option {...props}>{props.data.label}</components.Option>;
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid #ced4da",
      border: "0.5px solid #ffffff7e",
      borderRadius: "12px",
      paddingLeft: "20px",
      height: "46px",
      boxShadow: "none",
      // '&:hover': {
      //   border: '1px solid #ffb703',
      // },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: "#1f1f1f",
      border: "0.5px solid #ffffff7e",
      overflow: "hidden",
      borderRadius: "12px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#5253547a"
        : state.isFocused
        ? "#5253547a"
        : "#1f1f1f",
      color: state.isSelected ? "#fff" : "#fff",
      "&:active": {
        backgroundColor: "#007bff42",
        color: "black",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "#ccc" : "#fff",
      // textAlign:'center'
    }),
  };
  return (
    <div className="add_post_step_1">
      {/* <p className="text-white p-3 rounded" style={{ background: "#2E2A1E" }}>
            Please ensure that you comply with P2P rules to avoid account
            suspension or expulsion from the bitcash Merchant Program.
            Especially: 1. If you require taker's document for verification,
            it's necessary to indicate the requirement in the 'Remarks' section
            of your advertisement. 2. Imposing extra fees on takers is not
            allowed in all scenarios.
          </p> */}
      <Formik
        initialValues={{ ...step3Data }}
        enableReinitialize
        validationSchema={Schema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form className="mt-4">
            <div className="row mb-4">
              <div className="col-12">
                <label htmlFor="">Remarks (Optional)</label>
                <Field
                  as="textarea"
                  rows="3"
                  name="remark"
                  id="remark"
                  placeholder="Write Remarks"
                  className="form-control"
                  autoComplete="off"
                  onChange={(e) => {
                    setFieldValue("remark", e?.target?.value);
                  }}
                />
              </div>
            </div>
            <div className="row mb-4 d-none">
              <div className="col-12">
                <label htmlFor="">Auto Reply (Optional)</label>
                <Field
                  as="textarea"
                  rows="3"
                  name="auto_reply"
                  id="auto_reply"
                  placeholder="Write Auto Reply"
                  className="form-control"
                  autoComplete="off"
                  onChange={(e) => {
                    setFieldValue("auto_reply", e?.target?.value);
                  }}
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-6">
                <div className="input-group">
                  <span className="input-group-text"> Registered</span>
                  <Field
                    type="number"
                    name="registered_days"
                    id="registered_days"
                    placeholder="1"
                    className="form-control text-center"
                    autoComplete="off"
                    onChange={(e) => {
                      setFieldValue("registered_days", e.target.value);
                    }}
                  />
                  <span className="input-group-text"> Days Ago</span>
                </div>
                {errors.registered_days && touched.registered_days ? (
                  <small className="text-danger">
                    {errors.registered_days}
                  </small>
                ) : null}
              </div>
              <div className="col-6">
                <div className="input-group">
                  <span className="input-group-text"> Holding</span>
                  <Field
                    type="number"
                    name="holding_assets"
                    id="holding_assets"
                    placeholder="1"
                    className="form-control text-center"
                    autoComplete="off"
                    onChange={(e) => {
                      setFieldValue("holding_assets", e.target.value);
                    }}
                  />
                  <span className="input-group-text"> Assets</span>
                </div>
                {errors.holding_assets && touched.holding_assets ? (
                  <small className="text-danger">{errors.holding_assets}</small>
                ) : null}
              </div>
            </div>
            <div className="row mb-4  d-none">
              <div className="col-6">
                <label>Status</label>
                <Field name="status">
                  {({ field }) => (
                    <Select
                      {...field}
                      placeholder="Select..."
                      options={status_options}
                      onChange={(e) => setFieldValue("status", e.value)}
                      value={status_options.filter((option) =>
                        values?.status?.includes(option.value)
                      )}
                      components={{ Option: CustomOption }}
                      styles={customStyles}
                    />
                  )}
                </Field>
                {errors.status && touched.status ? (
                  <small className="text-danger">{errors.status}</small>
                ) : null}
              </div>
            </div>

            <div className="mt-4 text-center">
              <button
                type="submit"
                className="btn common_stepper_button"
              >
                {"Submit"}
              </button>   <br />
              <button
                className="btn mt-3 text-white common_add_post_stepper_disabled_button"
                onClick={() => handleBack(values)}
              >
                Back
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default P2PPostAdd3;
