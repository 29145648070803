import React, { useState } from "react";
import Header from "../HomeComp/Header";
import Footer from "../HomeComp/Footer";
import toast from "react-hot-toast";
import useToastPromise from "../../hooks/toastHook";
const Commingsoon = () => {
  const showToast = useToastPromise();
  const saveSettings = async () => {
    // Simulating an API call
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return "Settings saved!";
  };
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();

    showToast(saveSettings, {
      loading: "Processing...",
      success: "successfully Submit!",
      error: "Failed.....",
    });
    setTimeout(() => {
      setEmail("");
    }, 2000);
  };
  return (
    <>
      <Header />
      <section className="comming_soon_section comming_soon_bg">
        <div className="container">
          <div className="row">
            <div className="col-md-5 d-flex align-item-center justify-content-center flex-column gap-3">
              <div className="  comming_soon_heading text-white">
                Launching <br className="d-none d-md-block" />{" "}
                <span className="text-gradient ms-2">Soon..... </span>🚀
              </div>
              <div className="para_subheading mt-md-3">
                Get ready to revolutionize your trading journey. Something big
                is coming—stay tuned!
              </div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="comming_soon_input">
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    value={email}
                    placeholder="Enter your email address"
                    required
                  />
                  <button className="login_btn" type="submit">
                    Notify Me
                  </button>
                </div>
              </form>
            </div>
            <div className="col-md-7 d-flex justify-content-center align-items-center order-first order-md-0">
              <img src="/img/comming_soon.png" style={{marginLeft: "20rem"}} />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Commingsoon;
