import React from "react";
import Header from "./HomeComp/Header";
import Footer from "./HomeComp/Footer";

const CustomerSuppport = () => {
    return (
        <>
            <Header />
            <div className="wrap-privacy ">
                <div className="terms_border container d-flex flex-column gap-5 py-5 text-white">
                    <section>
                        <h1 class="text-gradient">24/7 Customer Support -- Always Here for You</h1>
                        <p>
                            At bitcash, we understand that trading and account management are not confined to standard business hours. The global financial markets operate around the clock, and so does our commitment to providing you with uninterrupted assistance. Our dedicated support team is available 24/7, ensuring that no matter where you are or when you need help, you will always have access to reliable and professional assistance. Whether you are facing technical issues, need guidance on using our platform, or have questions about your account, we are here to support you every step of the way.
                        </p>
                    </section>

                    <section>
                        <h2>Multilingual Support -- Breaking Language Barriers</h2>
                        <p>
                            Clear communication is essential for effective support, which is why we offer assistance in multiple languages. Our multilingual team is equipped to assist traders in English, Arabic, and various other languages, allowing you to communicate comfortably and receive precise guidance. We are committed to making sure that language is never an obstacle when it comes to getting the help you need. No matter your location or preferred language, our support team is ready to provide clear and effective solutions to your inquiries.
                        </p>
                    </section>

                    <section>
                        <h2>Multiple Support Channels -- Fast and Convenient Assistance</h2>
                        <p>
                            We recognize that different issues require different approaches to resolution. To ensure that you receive the most effective support, we provide multiple contact channels, allowing you to choose the method that best suits your needs.
                        </p>
                        <ul>
                            <li><strong>Live Chat</strong> -- For instant assistance, our live chat feature provides real-time support from our experienced agents. This is the fastest way to resolve urgent matters, such as platform-related concerns, trading inquiries, or account issues.</li>
                            <li><strong>Email Support</strong> -- If your inquiry requires detailed explanations, documentation, or step-by-step guidance, our email support team is available around the clock to provide timely and thorough responses.</li>
                            <li><strong>Phone Support</strong> -- Sometimes, a direct conversation with a support agent is the most effective way to resolve complex issues. Our phone support service connects you with knowledgeable professionals who can offer personalized guidance and immediate resolutions.</li>
                            <li><strong>Help Center</strong> -- In addition to direct support, our extensive Help Center serves as a comprehensive resource for traders. With detailed FAQs, guides, and tutorials, you can find answers to common questions, explore platform functionalities, and learn about best trading practices at your own convenience.</li>
                        </ul>
                    </section>

                    <section>
                        <h2>How Our Customer Support Benefits You</h2>
                        <p>
                            Our commitment to customer support goes beyond just answering questions---it is about ensuring that your trading experience remains seamless, secure, and efficient. Here's how our support services add value to your experience on the bitcash platform:
                        </p>
                        <ul>
                            <li><strong>Immediate Issue Resolution</strong> -- Whether you need assistance with deposits, withdrawals, trade execution, or platform navigation, our team is equipped to address and resolve issues swiftly, minimizing disruptions to your trading activities.</li>
                            <li><strong>Peace of Mind</strong> -- The financial markets can be unpredictable, but your access to support should never be uncertain. Knowing that expert help is available 24/7 allows you to trade with confidence, knowing that assistance is always within reach.</li>
                            <li><strong>Global Accessibility</strong> -- As a platform that serves traders from different regions, we ensure that our support services are designed to accommodate diverse needs. No matter where you are in the world, you can rely on bitcash's support team for timely and effective assistance.</li>
                            <li><strong>Tailored Guidance</strong> -- Every trader has unique needs and challenges. Whether you are a beginner looking for platform walkthroughs or an experienced trader needing technical support, we personalize our assistance to match your specific requirements.</li>
                        </ul>
                    </section>

                    <section>
                        <p>
                            At bitcash, we believe that exceptional customer support is an integral part of a great trading experience. Our goal is to provide you with the tools, knowledge, and assistance needed to trade with confidence and efficiency. If you ever need help, our team is always ready to assist you, ensuring that you have the best possible experience on our platform.
                        </p>
                    </section>


                </div>
            </div>
            <Footer />
        </>
    );
};

export default CustomerSuppport;
