import React from "react";
import Header from "../HomeComp/Header";
import Commingsoon from "../components/commingsoon";
import Footer from "../HomeComp/Footer";

const Web3 = () => {
  return (
    <div>
      <Commingsoon />
    </div>
  );
};

export default Web3;
