import React, { useState, useEffect, useRef } from "react";
function SelectWithImages({
  walletsdata = [],
  coindata = {},
  onSelect = () => {},
  check,
}) {
  const [searchAssets, setSearchAssets] = useState(walletsdata);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Handle clicking outside of dropdown to close it
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSearch = (value) => {
    setSearchAssets(
      walletsdata.filter((item) =>
        item.symbol?.toLowerCase().includes(value.toLowerCase())
      )
    );
  };
  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleHide = () => {};
  return (
    <div className="dropdown" ref={dropdownRef}>
      <div
        className="select_box"
        onClick={check == 0 ? toggleHide : toggleDropdown}
      >
        <div className="select_box_inner">
          <div className="d-flex align-items-center text-white">
            <div>
              {coindata?.icon && (
                <img
                  src={coindata?.icon}
                  alt="coin symbole"
                  className="coin_img me-2"
                />
              )}
            </div>
            {coindata?.symbol ? (
              <div className="product_name">
                <span className="unit text-muted text-capitalize ">
                  {coindata?.name || coindata?.blockchain}
                </span>{" "}
                {coindata?.symbol}
              </div>
            ) : (
              <div className="product_name">Select option </div>
            )}
          </div>
        </div>
        <div>
          {" "}
          <img
            src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
            className="arrow_img"
            alt="arrow-icon"
          />
        </div>
      </div>
      {/* <button
        className="form-control bg-transparent mt-2 d-flex justify-content-between align-items-center text-dark dropdown-toggle"
        onClick={toggleDropdown}
        type="button"
        aria-expanded={isOpen}
      >
        <div className="bg-transparent">
          <span className="btn btn-modal bg-transparent text-dark">
            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className="token-image">
                <img
                  style={{
                    maxHeight: "25px",
                  }}
                  className="rounded-circle"
                  src={coindata?.icon}
                  alt=""
                />
              </div>
              <div className="token-name">
                <h6 className="mb-1">{coindata?.symbol}</h6>
              </div>
            </div>
          </span>
        </div>
      </button> */}

      <div
        className={`custom_dropdown dropdown-menu w-100 ${
          isOpen ? "show" : ""
        }`}
      >
        <div className="m-1">
          <div className="input-group ">
            <span className="input-group-text bg-transparent">
              <div>
                <i className="fa fa-search "></i>
              </div>
            </span>
            <input
              type="text"
              className="form-control text-start text-lgray "
              aria-label="Text input with checkbox"
              placeholder="Search "
              value={search}
              onChange={(e) => {
                handleSearch(e.target.value);
                setSearch(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="popular-tokens">
          <div className="token-list  px-2">
            {search && searchAssets.length === 0 ? (
              <h6 className="text-danger text-center">No Asset Found!</h6>
            ) : (
              <div className="custom_scrollbar height250">
                {(search ? searchAssets : walletsdata).map((item, i) => {
                  if (item.symbol !== "INR") {
                    return (
                      <div
                        className="select_options"
                        key={i}
                        onClick={() => {
                          onSelect(item);
                          setSearch("");
                          setIsOpen(false);
                        }}
                      >
                        <div>
                          {item?.icon && (
                            <img
                              src={item?.icon}
                              alt="coin icon"
                              className="market_coin_img"
                            />
                          )}
                        </div>
                        <div className="product_name">
                          <div
                            className="mb-0 fw-semibold text-white text-capitalize"
                            style={{ letterSpacing: "1px" }}
                          >
                            {!item.name && item?.blockchain}{" "}
                            <span className="text-lgray">
                              ({item?.symbol}){" "}
                            </span>
                          </div>
                          <div className="text-muted fs-12">{item.name}</div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectWithImages;
