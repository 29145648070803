import React, { useEffect, useState } from "react";
import "./AddPostStepper.css";
import { Stepper, Step } from "react-form-stepper";
import Header from "../HomeComp/Header";
import P2PPostAd from "./P2PAddPost";
import P2PPostAd2 from "./P2PAddPost2";
import P2PPostAdd3 from "./P2PPostAdd3";
import { NotificationManager } from "react-notifications";
import { useHistory } from "react-router-dom";
import Footer from "../HomeComp/Footer";
import { useSelector, useDispatch } from "react-redux";
import {
  N_addPostAd,
  N_get_p2p_assets,
  N_get_wallet,
  N_getPaymentMethods,
} from "../redux/helpers/api_functions_new";
import BackComp from "../../helper/components/BackComp";
import * as Yup from "yup";
import Select, { components } from "react-select";
import { getP2PCurrencyLimitation } from "../redux/helpers/api_functions_new";
import { Field, Form, Formik } from "formik";

import { NumberToFixed } from "../../utils/common";
import toast from "react-hot-toast";

const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <img
        src={props.data.image}
        alt={props.data.label}
        style={{ width: 20, height: 20, marginRight: 10, borderRadius: "50%" }}
      />
      {props.data.label}
    </components.Option>
  );
};

const defaultToken = {
  value: "USDT",
  label: "USDT",
  image: "https://s2.coinmarketcap.com/static/img/coins/200x200/825.png",
}


const StepperP2P = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [currentTab, setCurrentTab] = useState("sell");
  const history = useHistory();
  const [pageLoader, setPageLoader] = useState(false);

  const [currencyType, setCurrencyType] = useState("USDT");
  const [fiatType, setFiatType] = useState("TRY");
  const [currencyLimit, setCurrencyLimit] = useState({});
  const [fiat_options, setFiatOptions] = useState([{
    value: "TRY",
    label: "TRY",
    image:
      "https://cdn2.iconfinder.com/data/icons/world-currency/512/07-512.png",
    min: 32,
    max: 38,
  }]);
  const [minPrice, setMinPrice] = useState(32);
  const [maxPrice, setMaxPrice] = useState(38);

  const [assets_options, setAssetOption] = useState([defaultToken]);
  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);

  const [coinBal, setCoinBal] = useState({});
  function getCurrentBuySellTokenBalance() {
    N_get_wallet(user?.params ? user.params.user_id : user.user_id)
      .then((d) => {
        if (d.status === 200) {
          let final_data = Object.values(d.params.wallets);
          setCoinBal(d.params.wallets)
        } else {
          // console.log(d);
        }
      })
      .catch((e) => {
        console.log("er", e);
      });
  }
  function getP2Passets() {
    N_get_p2p_assets()
      .then((d) => {
        // console.log(d, "<<--------------------- data");
        if (d.status === 200) {
          let arr = d.data;
          let assets = [];
          for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            assets.push({
              value: element.symbol,
              label: element.symbol,
              image: element.icon,
            });
          }
          if (assets.length == 0) {
            setAssetOption([defaultToken]);
          } else {
            setAssetOption(assets);
          }
          // setCoinBal()
        } else {
          // console.log(d);
        }
      })
      .catch((e) => {
        console.log("er", e);
      });
  }

  useEffect(() => {
    getP2Passets();
    getCurrentBuySellTokenBalance();
  }, []);

  useEffect(() => {
    N_getPaymentMethods().then((res) => {
      if (res.status == 200) {
        setPaymentMethods(res.data);
      }
    });
  }, []);
  const [data, setData] = React.useState({
    currentTab, currentTab,
    isSubmit: false,
    asset_name: "USDT",
    fiat_name: "TRY",
    selling_price: "",
    total_assets: "",
    min_order_limit: "",
    max_order_limit: "",
    payment_time_limit: "",
    payment_ids: [],
    remark: "",
    auto_reply: "",
    registered_days: "0",
    holding_assets: "0",
    regions: ["india"],
    type: currentTab,
    status: "",
  });
  const props = {
    activeStep,
    setActiveStep,
    data,
    setData,
  };
  const handleFunction = async () => {
    setPageLoader(true);
    // console.log(data, "datainfo");

    N_addPostAd({
      asset_name: data?.asset_name,
      auto_reply: data?.auto_reply,
      fiat_name: data?.fiat_name,
      holding_assets: 0,// Number(data?.holding_assets),
      max_order_limit: Number(data?.max_order_limit),
      min_order_limit: Number(data?.min_order_limit),
      payment_ids: data?.payment_ids,
      payment_time_limit: Number(data?.payment_time_limit),
      regions: data?.regions,
      registered_days: 0,// Number(data?.registered_days),
      remark: data?.remark,
      selling_price: Number(data?.selling_price),
      status: data?.status,
      total_assets: Number(data?.total_assets),
      type: currentTab,
    }).then((res) => {
      if (res.status == 200) {
        toast.success(res.message);
        history.push("/p2pOrder");
      } else {
        toast.error(res.message);
      }
    });
  };

  const Schema = Yup.object().shape({
    currentTab: Yup.string().required("Please Select Asset"),
    asset_name: Yup.string().required("Please Select Asset"),
    fiat_name: Yup.string().required("Please Select Fiat"),
    selling_price: Yup.number()
      .required("Please Enter Selling Price")
      .min(minPrice, `Selling price must be at least ${minPrice}`)
      .max(maxPrice, `Selling price must not exceed ${maxPrice}`),
    // total_assets: Yup.number()
    //   .required("Please Enter Total Assets")
    //   .min(0.1, `You should have at least 0.1 Asset`)
    //   .when("currentTab", {
    //     is: "sell",
    //     then: (schema) =>
    //       schema.max(
    //         coinBal?.["USDT"]?.balance,
    //         `Selling price must not exceed ${coinBal?.["USDT"]?.balance}`
    //       ),
    //     otherwise: (schema) => schema, // No max validation when not selling
    //   }),
    total_assets: Yup.number()
      .required("Please Enter Total Assets")
      .min(0.1, `You should have at least 0.1 Asset`)
      .test(
        "maxValidation",
        `Selling price must not exceed ${coinBal?.["USDT"]?.balance}`,
        (value) => currentTab !== "sell" || value <= coinBal?.["USDT"]?.balance
      ),
    // .max(coinBal?.['USDT']?.balance, `Selling price must not exceed ${coinBal?.['USDT']?.balance}`),
    min_order_limit: Yup.number()
      .required("Please Enter Min. Order Limit")
      // .min(1, `Minimum limit must be at least 1`)
      .when("total_assets", (total_assets, schema) => {
        return schema.max(
          total_assets,
          `Minimum limit must not exceed ${total_assets} ${data?.asset_name}`
        );
      }),
    max_order_limit: Yup.number()
      .required("Please Enter Max. Order Limit")
      .when("min_order_limit", (min_order_limit, schema) => {
        return schema.min(
          min_order_limit,
          `Maximum limit must be equal or greater ${min_order_limit} ${data?.asset_name}`
        );
      })
      .when("total_assets", (total_assets, schema) => {
        return schema.max(
          total_assets,
          `Maximum limit must not exceed ${total_assets} ${data?.asset_name}`
        );
      }),
    payment_time_limit: Yup.string().required("Select Payment Time Limit"),
    payment_ids: Yup.array()
      .min(1, "Select at least 1 payment method")
      .max(5, "You can select up to 5 payment methods")
      .required("This field is required"),
    // registered_days: Yup.number().required("Please Enter Registered Days"),
    // holding_assets: Yup.number().required("Please Enter Holding Assets"),
    status: Yup.string().required("Please Select Status"),
  });
  const [step1Data, setStep1Data] = useState({
    currentTab: currentTab,
    asset_name: data?.asset_name,
    fiat_name: data?.fiat_name,
    selling_price: data?.selling_price,
    total_assets: data?.total_assets,
    min_order_limit: data?.min_order_limit,
    max_order_limit: data?.max_order_limit,
    payment_time_limit: data?.payment_time_limit,
    payment_ids: data?.payment_ids,
    remark: data?.remark,
    auto_reply: data?.auto_reply,
    registered_days: 0,// data?.registered_days,
    holding_assets: 0,//data?.holding_assets,
    regions: data?.regions,
    status: 'online',
  });

  useEffect(() => {
    getP2PCurrencyLimitation().then((res) => {
      // setCurrencyLimit(res.data);
      if (res.data.fiat && res.data.fiat.length > 0) {
        setFiatOptions(res.data.fiat);
        setFiatType(res.data.fiat[0].value);
        setMinPrice(res.data.fiat[0].min);
        setMaxPrice(res.data.fiat[0].max);
      }
    }).catch((error) => console.log(error, "<<---------------- error"))
  }, [])

  // useEffect(() => {
  //   currencyLimitChange();
  // }, [currencyType, fiatType, currencyLimit])

  // const currencyLimitChange = () => {
  //   if (currencyType && fiatType) {
  //     // setMinPrice(currencyLimit?.[currencyType]?.[fiatType]?.min ?? 80)
  //     // setMaxPrice(currencyLimit?.[currencyType]?.[fiatType]?.max ?? 90)
  //   }
  // }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: '1px solid #ced4da',
      border: '0.5px solid #ffffff7e',
      borderRadius: '4px',
      paddingLeft: '20px',
      height: '46px',
      boxShadow: 'none',

    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: '#1f1f1f',
      border: '0.5px solid #ffffff7e',
      overflow: 'hidden',
      borderRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#5253547a' : state.isFocused ? '#5253547a' : '#1f1f1f',
      color: state.isSelected ? '#fff' : '#fff',
      '&:active': {
        backgroundColor: '#007bff42',
        color: 'black',
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? '#ccc' : '#fff',
      // textAlign:'center'
    }),
  };

  const customStylesOption = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      height: '46px',
      borderRadius: '4px',

    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: '#1f1f1f',
      color: "white"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#5253547a' : state.isFocused ? '#5253547a' : '#1f1f1f',
      color: state.isSelected ? '#fff' : '#fff',
      '&:active': {
        backgroundColor: '#007bff42',
        color: 'black',
      },
    }),
  };
  // Step - 1 - End


  // Step - 2 - Start
  const options = [{
    value: "bank",
    label: "Bank"
  }];
  // paymentMethods.map((item) => ({
  //   value: item._id,
  //   label: `${item.name} - ${item.account_number}`,
  // }));

  const time_limit_options = [
    {
      value: "15",
      label: "15 Minutes",
    },
    {
      value: "30",
      label: "30 Minutes",
    },
    {
      value: "45",
      label: "45 Minutes",
    },
    {
      value: "60",
      label: "60 Minutes",
    },
  ];

  const CustomOption1 = (props) => {
    return <components.Option {...props}>{props.data.label}</components.Option>;
  };


  // Step - 2 - End

  return (
    <>
      <Header />
      <div className="mb-4">
        <main className="">
          <div className="page-content" style={{ marginTop: "100px" }}>
            <div className="add_post_stepper_page">

              <div className="add_post_stepper_steps d-none">
                <Stepper
                  styleConfig={{
                    activeBgColor: "#ffb703",
                    activeTextColor: "#ffffff",
                    inactiveBgColor: "#808080",
                    inactiveTextColor: "#000000",
                    completedBgColor: "#ffb703",
                    completedTextColor: "#ffffff",
                    size: "2em",
                  }}
                  activeStep={activeStep}
                  className="w-100"
                >
                  <Step
                    onClick={() => {
                      setActiveStep(0);
                    }}
                    label="Set Type & Price"
                  />
                  <Step
                    onClick={() => {
                      setActiveStep(1);
                    }}
                    label="Set Total Amount & Payment Method"
                  />
                  <Step
                    onClick={() => {
                      setActiveStep(2);
                    }}
                    label="Set Remarks And Automatic Response"
                  />
                </Stepper>
              </div>
              <div className="row mt-4 justify-content-md-center mx-auto">

                <div className="col-md-6 mt-3">

                  <div className="d-flex align-items-baseline">
                    {/* <div className="d-flex align-items-center">
                      <BackComp url={"/p2pOrder"} text={""} />
                      <h3 className="text-white mb-0">  Post Sell Request </h3>
                    </div> */}
                    <div className="d-flex align-items-center justify-content-center me-3 mb-2 rounded">
                      <div className="d-flex gap-5 align-items-center">
                        <ul class="add_post_tabs nav nav-pills" style={{ width: "300px" }}>
                          <li class="nav-item w-50 text-center">
                            <a
                              onClick={() => setCurrentTab("buy")}
                              class={`nav-link px-4 buy_current_tab ${currentTab == "buy" && "active"
                                }`}
                              aria-current="page"
                              href="#"
                            >
                              I want to buy
                            </a>
                          </li>

                          <li class="nav-item w-50 text-center">
                            <a
                              onClick={() => setCurrentTab("sell")}
                              class={`nav-link px-4 sell_current_tab ${currentTab == "sell" && "active"
                                }`}
                              href="#"
                            >
                              I want to sell
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <Formik
                    initialValues={{ ...step1Data }}
                    enableReinitialize
                    validationSchema={Schema}
                    onSubmit={handleFunction}
                  >
                    {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
                      <Form className="mt-4">
                        <div className="row mb-4 mx-auto">
                          <div className="col-md-6">
                            <label htmlFor="">Crypto</label>
                            <Field name="asset_name" >
                              {({ field }) => (
                                <Select
                                  {...field}
                                  onChange={(selectedOption) => {
                                    const selectedValue = selectedOption?.value;
                                    setFieldValue("asset_name", selectedValue);
                                    setCurrencyType(selectedValue);
                                    setData((prev) => ({ ...prev, asset_name: selectedValue }));
                                  }}
                                  value={assets_options.filter((option) =>
                                    values?.asset_name?.includes(option.value)
                                  )}
                                  placeholder="Asset"
                                  options={assets_options}
                                  components={{ Option: CustomOption }}
                                  styles={customStyles}
                                />
                              )}
                            </Field>
                            {errors.asset_name && touched.asset_name ? (
                              <small className="text-danger">{errors.asset_name}</small>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <label htmlFor="">For</label>
                            <Field name="fiat_name" >
                              {({ field }) => (
                                <Select
                                  {...field}
                                  onChange={(selectedOption) => {
                                    const selectedValue = selectedOption?.value;
                                    setFieldValue("fiat_name", selectedValue);
                                    setFiatType(selectedValue);
                                    setData((prev) => ({ ...prev, fiat_name: selectedValue }));
                                    setMinPrice(selectedOption?.min)
                                    setMaxPrice(selectedOption?.max)
                                  }}
                                  value={fiat_options.filter((option) =>
                                    values?.fiat_name?.includes(option.value)
                                  )}
                                  placeholder="With Fiat"
                                  options={fiat_options}
                                  components={{ Option: CustomOption }}
                                  styles={customStyles}
                                />
                              )}
                            </Field>
                            {errors.fiat_name && touched.fiat_name ? (
                              <small className="text-danger">{errors.fiat_name}</small>
                            ) : null}
                          </div>
                        </div>

                        <div className="row mb-4 mx-auto">
                          <div className="col-md-6">
                            <label>Unit price</label>
                            <Field
                              type="number"
                              name="selling_price"
                              id="selling_price"
                              placeholder="Enter Price"
                              className="form-control"
                              autoComplete="off"
                              onChange={(e) => {
                                setFieldTouched("selling_price", true);
                                setFieldValue("selling_price", e?.target?.value);
                                setData((prev) => ({ ...prev, selling_price: e?.target?.value }));
                              }}
                            />
                            {errors.selling_price && touched.selling_price ? (
                              <small className="text-danger">{errors.selling_price}</small>
                            ) : null}

                            {
                              values?.fiat_name && <p className="mb-0">{`${minPrice} ${values?.fiat_name} - ${maxPrice} ${values?.fiat_name}`}</p>
                            }
                          </div>

                          <div className="col-md-6">
                            <div className="d-flex justify-content-between align-items-center">
                              <label>Quantity ({data?.asset_name})</label>
                              {
                                currentTab == "sell" && <label>Max: {coinBal?.['USDT']?.balance} {data?.asset_name}</label>
                              }
                            </div>
                            <div className="input-group">
                              <Field
                                type="number"
                                name="total_assets"
                                id="total_assets"
                                placeholder="Enter Total Assets"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldTouched("total_assets", true);
                                  setFieldValue("total_assets", e?.target?.value);
                                  setData((prev) => ({ ...prev, total_assets: e?.target?.value }));
                                }}
                              />

                              {
                                currentTab == "sell" && <span className="input-group-text" style={{ cursor: "pointer" }} onClick={() => {
                                  setFieldTouched("total_assets", true);
                                  setFieldValue("total_assets", coinBal?.['USDT']?.balance);
                                  setData((prev) => ({ ...prev, total_assets: coinBal?.['USDT']?.balance }));
                                }}>
                                  Max
                                </span>
                              }

                            </div>
                            {errors.total_assets && touched.total_assets ? (
                              <small className="text-danger">{errors.total_assets}</small>
                            ) : null}
                            <p className="mb-0">
                              {" "}
                              {NumberToFixed(
                                values?.selling_price * values?.total_assets
                              )}{" "}
                              {values?.fiat_name}
                            </p>
                          </div>
                        </div>

                        <div className="row mb-4 mx-auto">
                          <label>Single Transaction Limit</label>
                          <div className="col-md-6 mb-2">

                            <div className="input-group">
                              <Field
                                type="number"
                                name="min_order_limit"
                                id="min_order_limit"
                                placeholder="Enter Minimum Assets"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldTouched("min_order_limit", true);
                                  setFieldValue("min_order_limit", e?.target?.value);
                                  setData((prev) => ({ ...prev, min_order_limit: e?.target?.value }));
                                }}
                              />
                              <span className="input-group-text">
                                {" "}
                                {values?.asset_name}
                              </span>
                            </div>
                            {errors.min_order_limit && touched.min_order_limit ? (
                              <small className="text-danger">
                                {errors.min_order_limit}
                              </small>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <div className="input-group">
                              <Field
                                type="number"
                                name="max_order_limit"
                                id="max_order_limit"
                                placeholder="Enter Maximum Assets"
                                className="form-control"
                                autoComplete="off"
                                onChange={(e) => {
                                  setFieldTouched("max_order_limit", true);
                                  setFieldValue("max_order_limit", e?.target?.value);
                                  setData((prev) => ({ ...prev, max_order_limit: e?.target?.value }));
                                }}
                              />
                              <span className="input-group-text">
                                {" "}
                                {values?.asset_name}
                              </span>
                            </div>
                            {errors.max_order_limit && touched.max_order_limit ? (
                              <small className="text-danger">
                                {errors.max_order_limit}
                              </small>
                            ) : null}
                          </div>
                          <p className="mb-0">
                            {" "}
                            {NumberToFixed(
                              values?.min_order_limit * values?.selling_price
                            )}{" "}
                            {values?.fiat_name} -{" "}
                            {NumberToFixed(values?.max_order_limit * values?.selling_price)}{" "}
                            {values?.fiat_name}
                          </p>
                        </div>

                        <div className="row mb-4 mx-auto">
                          <div className="col-md-6">
                            <label>Payment Methods</label>

                            <Field name="payment_ids">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  onChange={(selectedOptions) => {
                                    const values = selectedOptions.map(
                                      (option) => option.value
                                    );
                                    setFieldValue("payment_ids", values);
                                    setData((prev) => ({ ...prev, payment_ids: values }));
                                  }}
                                  isMulti
                                  placeholder="Select payment method"
                                  options={options}
                                  value={options.filter((option) =>
                                    values?.payment_ids?.includes(option.value)
                                  )}
                                  components={{ Option: CustomOption1 }}
                                  styles={customStylesOption}
                                />
                              )}
                            </Field>
                            {errors.payment_ids && touched.payment_ids ? (
                              <small className="text-danger">{errors.payment_ids}</small>
                            ) : null}
                            {/* <p className="mb-0">Select Upto 5 Methods</p> */}
                          </div>
                          <div className="col-md-6">
                            <label>Payment Time Limit</label>
                            <Field name="payment_time_limit">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  placeholder="Select..."
                                  options={time_limit_options}
                                  onChange={(e) => {
                                    setFieldValue("payment_time_limit", e.value);
                                    setData((prev) => ({ ...prev, payment_time_limit: e.value }));
                                  }
                                  }
                                  value={time_limit_options.filter((option) =>
                                    values?.payment_time_limit?.includes(option.value)
                                  )}
                                  components={{ Option: CustomOption1 }}
                                  styles={customStyles}
                                />
                              )}
                            </Field>
                            {errors.payment_time_limit && touched.payment_time_limit ? (
                              <small className="text-danger">
                                {errors.payment_time_limit}
                              </small>
                            ) : null}
                          </div>
                        </div>


                        <div className="row mb-4 mx-auto">
                          <div className="col-12">
                            <label htmlFor="">Remarks (Optional)</label>
                            <Field
                              as="textarea"
                              rows="3"
                              name="remark"
                              id="remark"
                              placeholder="Write Remarks"
                              className="form-control"
                              autoComplete="off"
                              onChange={(e) => {
                                setFieldValue("remark", e?.target?.value);
                                setData((prev) => ({ ...prev, remark: e?.target?.value }));
                              }}
                            />
                          </div>
                        </div>

                        <div className="row mb-6 d-none">
                          <div className="col-6">
                            <label> Days Since Registration</label>
                            <Field
                              type="number"
                              name="registered_days"
                              id="registered_days"
                              placeholder="Enter Days"
                              className="form-control"
                              autoComplete="off"
                              onChange={(e) => {
                                setFieldValue("registered_days", e.target.value);
                                setData((prev) => ({ ...prev, registered_days: e.value }));
                              }}
                            />
                            {/* <span className="input-group-text"> Days Ago</span> */}

                            {errors.registered_days && touched.registered_days ? (
                              <small className="text-danger">
                                {errors.registered_days}
                              </small>
                            ) : null}
                          </div>
                          <div className="col-6">
                            <label> Total Asset Holdings ({data?.asset_name})</label>
                            <Field
                              type="number"
                              name="holding_assets"
                              id="holding_assets"
                              placeholder="Enter Amount"
                              className="form-control"
                              autoComplete="off"
                              onChange={(e) => {
                                setFieldValue("holding_assets", e.target.value);
                                setData((prev) => ({ ...prev, holding_assets: e.value }));
                              }}
                            />
                            {/* <span className="input-group-text"> Assets</span> */}

                            {errors.holding_assets && touched.holding_assets ? (
                              <small className="text-danger">{errors.holding_assets}</small>
                            ) : null}
                          </div>
                        </div>


                        <div className="my-5 text-center">
                          <button
                            type="button"
                            className="login_btn_outline"
                            onClick={() => history.push("/p2pOrder")}
                            style={{ width: "20%", marginRight: "10px",padding:"2px 12px", color: "white", border: "none" }}

                          >
                            {"Cancel"}
                          </button>
                          <button
                            type="submit"
                            className="login_btn"
                            style={{ width: "20%", marginRight: "10px", padding:"2px 12px", }}

                          >
                            {"Submit"}
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div className="col-6 d-none">
                  {activeStep === 0 && (
                    <div>
                      <P2PPostAd {...props} />
                    </div>
                  )}
                  {activeStep === 1 && (
                    <div>
                      <P2PPostAd2 {...props} paymentMethods={paymentMethods} />
                    </div>
                  )}
                  {activeStep === 2 && (
                    <div>
                      <P2PPostAdd3 {...props} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default StepperP2P;
