import React from "react";

export default function PreLoader(props) {
  return (
    <div>
      <div id="preloader">
        <div className="preload-content">
         
          {/* <div id="dream-load"></div> */}
          <img src="/img/logo.svg" style={{width:'80px', marginBottom:'10px'}}/>
          <span class="loader"></span>
        </div>
        
      </div>
    </div>
  );
}
