import React, { useState, useRef, useEffect } from 'react'
import { Button } from "@mui/material";
import './OrderPaymentTrans.css'
import { Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { NotificationManager } from "react-notifications";
import Dropzone from 'react-dropzone'
import { IoMdCheckmarkCircle } from 'react-icons/io';
import toast from 'react-hot-toast';

const OrderPaymentTrans = ({ formSubmitting, isOpen, onClose, image, setImage, handleImageChange, handleSubmit, paySend }) => {

  const Schema = Yup.object().shape({
    comment: Yup.string().required("Please Enter Comment"),
  });

  const [data, setData] = useState({
    comment: ''
  });

  const onSubmit = async (values, onSubmitProps) => {
    handleSubmit(values?.comment)
  };

  const uploadRef = useRef(null)

  return (
    <div
      className={`modal modal-md fade ${isOpen ? "show d-block" : "d-none"} pay_trans_modal`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onClick={onClose}
    >
      <div
        className="modal-dialog"
        role="document"
        onClick={(e) => e.stopPropagation()}
        style={{ background: "#1f1f1f" }}
      >
        <div className="modal-content border" style={{ color: "#fff" }}>
          <div className="modal-header" style={{ padding: "0px 15px 16px 8px" }}>
            <h5 className="modal-title">Transferred, Notify Seller</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>

          <div className="modal-body">
            {
              <Formik
                initialValues={{ ...data }}
                enableReinitialize
                validationSchema={Schema}
                onSubmit={onSubmit}
              >
                {({ errors, touched, values, setFieldValue }) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-12 mb-3">
                        <label>
                          <strong>Image:</strong>{' '}
                        </label>
                        <div className="text-center mt-1">
                          <div className="mb-3 dragdrop-container">
                            <input
                              ref={uploadRef}
                              id="upload"
                              hidden
                              accept="image/*"
                              type="file"
                              onChange={(e) => handleImageChange(e.target.files)}
                            />
                            {image.src ? (
                              <div className="commonImgs">
                                <img
                                  className="every-img"
                                  src={image.src ? image.src : '/img/cloud-file-download.svg'}
                                  alt=""
                                  onClick={() => {
                                    uploadRef.current.click()
                                  }}
                                />
                              </div>
                            ) : (
                              <div className="drag-n-drop-container">
                                <div>
                                  <Dropzone
                                    accept="image/*"
                                    multiple={false}
                                    onDrop={(acceptedFiles) => {
                                      handleImageChange(acceptedFiles)
                                    }}
                                  >
                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                      <section>
                                        <div className="drop-area" {...getRootProps()}>
                                          <img
                                            width={50}
                                            src={image.src ? image.src : '/img/cloud-file-download.svg'}
                                            alt=""
                                            className='mb-2'
                                          />
                                          <input
                                            {...getInputProps()}
                                            accept="image/*"
                                            multiple={false}
                                          />

                                          {isDragActive ? (
                                            <div>Drop your image file here</div>
                                          ) : (
                                            <p>
                                              Drag n drop image file here, or click to select{' '}
                                              <br />
                                              <small className="text-center">
                                                <strong>Supported files:</strong> jpeg, jpg,
                                                png. | Will be resized to: 1920x1080 px.
                                              </small>
                                            </p>
                                          )}
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <p className="fw-bold">
                          Note:
                          <span className="text-danger mx-2">
                            Supported image formats are:&nbsp;jpg, png and jpeg only
                          </span>
                        </p>
                      </div>
                      <div className="col-12 mb-3">
                        <label htmlFor="host" className="mb-1">
                          Comment
                          <span className="text-danger">*</span>
                        </label>
                        <Field
                          as='textarea'
                          rows='3'
                          name="comment"
                          id="comment"
                          placeholder="Enter Comment"
                          className="form-control"
                          autoComplete="off"
                        />
                        {errors.comment && touched.comment ? (
                          <small className="text-danger">{errors.comment}</small>
                        ) : null}
                      </div>
                      <div className='col-12 text-end'>
                        <button className='btn login_btn' disabled={formSubmitting} type='submit' sx={{ py: 1 }}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            }

          </div>


        </div>
      </div>
    </div>
  )
}

export default OrderPaymentTrans