import React, { useState } from "react";
const Commingsooncomponent = () => {
  return (
    <>
      <section className="comming_soon_bg">
        <div className="container" style={{backgroundColor: "#121212", border: "none"}}>
          <div className="row my-5">
            <div className="col-md-5 d-flex align-item-center justify-content-center flex-column gap-3">
              <div className="  comming_soon_heading text-white">
                Launching <br className="d-none d-md-block" />{" "}
                <span className="text-gradient ms-2">Soon..... </span>🚀
              </div>
            </div>
            <div className="col-md-7 d-flex justify-content-center align-items-center order-first order-md-0">
              <img src="/img/comming_soon.png" className="ms-5" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Commingsooncomponent;
