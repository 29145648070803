import React from "react";
import Header from "./HomeComp/Header";
import Footer from "./HomeComp/Footer";

const Guide = () => {
    return (
        <>
            <Header />
            <div className="wrap-privacy ">
                <div className="terms_border container d-flex flex-column gap-5 py-5 text-white">
                    <section>
                        <h1 class="text-gradient">Guide For bitcash</h1>

                    </section>
                    <div class="accordion accordion-flush" id="accordionFlushExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="flush-headingOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    <h3 className="text-gradient">1. How to Create Your bitcash Account</h3>
                                </button>
                            </h2>
                            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">

                                    <section>
                                        <h4 class="text-gradient">Step-by-Step Guide on Signing Up</h4>
                                        <ol>
                                            <li>Go to the bitcash website and click “Create Account”</li>
                                            <li>Enter your email, create a password, and agree to the Terms of Service</li>
                                            <li>Click “Create Account” to proceed</li>
                                            <li>Continue to the email verification steps</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Verifying Your Email and Phone Number</h4>
                                        <ol>
                                            <li>Check your email inbox for the bitcash verification code</li>
                                            <li>Enter that code on the bitcash platform to confirm your email</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Completing Your Account Setup</h4>
                                        <ol>
                                            <li>Fill in any required personal details (name, address, etc.)</li>
                                            <li>Upload valid ID documents if needed for KYC verification</li>
                                            <li>Enable extra security features (e.g., 2FA)</li>
                                            <li>Confirm all your information</li>
                                            <li>Log in to explore your new bitcash account</li>
                                        </ol>
                                    </section>

                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <h3 className="text-gradient">2.How to Deposit Funds into Your bitcash Account</h3>

                                </button>
                            </h4>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Bank Transfer</h4>
                                        <ol>
                                            <li>Log in and click “Deposit”</li>
                                            <li>Select “Bank Transfer”</li>
                                            <li>Copy the bank details (IBAN, SWIFT, reference code) shown</li>
                                            <li>Initiate a transfer from your personal bank account with the copied details</li>
                                            <li>Wait for 1–3 business days for funds to appear in your bitcash balance</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Credit/Debit Card</h4>
                                        <ol>
                                            <li>Log in and click “Deposit”</li>
                                            <li>Choose “Credit/Debit Card”</li>
                                            <li>Enter your card details and the deposit amount</li>
                                            <li>Confirm any fees displayed</li>
                                            <li>Authorize the payment (you may receive a 3D Secure prompt), then check your updated balance</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Cryptocurrency</h4>
                                        <ol>
                                            <li>Log in and click “Deposit”</li>
                                            <li>Select the crypto you want to deposit (e.g., BTC, ETH)</li>
                                            <li>Copy your unique wallet address or scan the QR code</li>
                                            <li>Send the crypto from your external wallet to the copied address</li>
                                            <li>Wait for the required network confirmations to see the deposit in your bitcash account</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Fees and Limits</h4>
                                        <ol>
                                            <li>Check the displayed deposit fee (if any) before confirming</li>
                                            <li>Confirm you meet the minimum deposit amount shown</li>
                                            <li>Note any daily/monthly limits based on your verification level</li>
                                            <li>Verify your account for higher limits if needed</li>
                                            <li>Contact support if you have any questions about fees or limits</li>
                                        </ol>
                                    </section>


                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    <h3 class="text-gradient">3. How to Change Your Email Address or Phone Number</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">How to Update Your Contact Information</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Go to “Profile” or “Settings”</li>
                                            <li>Click “Edit” next to your current email or phone number</li>
                                            <li>Enter your new email/phone number</li>
                                            <li>Click “Save” or “Update” to proceed</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Verifying the New Email/Phone Number</h4>
                                        <ol>
                                            <li>Check your new email inbox or phone for a verification code</li>
                                            <li>Enter the code on the bitcash platform</li>
                                            <li>Confirm the change when prompted</li>
                                            <li>Logout and log back in if necessary to refresh your profile details</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Security Tips When Changing Your Contact Details</h4>
                                        <ol>
                                            <li>Enable Two-Factor Authentication (2FA) if you haven’t already</li>
                                            <li>Use a strong, unique password for your bitcash account</li>
                                            <li>Avoid using temporary or public email addresses</li>
                                            <li>Verify all changes immediately to prevent unauthorized access</li>
                                            <li>Contact Support if you see any suspicious activity or did not request a change</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    <h3 class="text-gradient">4. How to Reset Your Password</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Resetting Your Password via Email</h4>
                                        <ol>
                                            <li>Go to the bitcash login page</li>
                                            <li>Click “Forgot Password?”</li>
                                            <li>Enter your registered email address and submit</li>
                                            <li>Check your inbox for a password reset link or code</li>
                                            <li>Follow the link or enter the code to create a new password</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Troubleshooting Common Password Reset Issues</h4>
                                        <ol>
                                            <li>No email received? Check your spam folder or wait a few minutes</li>
                                            <li>Incorrect email? Make sure you’re using the correct address linked to your account</li>
                                            <li>Link expired? Request another reset link if the current one has expired</li>
                                            <li>System error? Try clearing your browser cache or use a different browser</li>
                                            <li>Still stuck? Contact bitcash Support for further assistance</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Setting Up a Secure Password</h4>
                                        <ol>
                                            <li>Use a mix of uppercase, lowercase, numbers, and symbols</li>
                                            <li>Avoid personal info like birthdays or common words</li>
                                            <li>Create a unique password you don’t use on other platforms</li>
                                            <li>Enable Two-Factor Authentication (2FA) for extra security</li>
                                            <li>Update your password regularly to keep your account safe</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                    <h3 class="text-gradient">5. How to Enable Two-Factor Authentication (2FA)</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Why 2FA Is Important</h4>
                                        <ol>
                                            <li>Protect your account from unauthorized access</li>
                                            <li>Add an extra security layer beyond just a password</li>
                                            <li>Reduce the risk of phishing or brute-force attacks</li>
                                            <li>Safeguard sensitive data and financial transactions</li>
                                            <li>Strengthen overall trust and safety in your bitcash account</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Enabling 2FA Using an Authenticator App</h4>
                                        <ol>
                                            <li>Log in and go to “Security Settings”</li>
                                            <li>Select “Enable 2FA” (you may need to re-enter your current password)</li>
                                            <li>Download an authenticator app (e.g., Google Authenticator) if you haven’t already</li>
                                            <li>Scan the QR code shown on the bitcash platform with the authenticator app</li>
                                            <li>Enter the 6-digit code generated by your authenticator to confirm</li>
                                            <li>Save any backup/recovery codes provided for future use</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Recovering Your Account If You Lose 2FA Access</h4>
                                        <ol>
                                            <li>Keep a backup code or recovery key in a secure place</li>
                                            <li>Use that backup code if you lose access to your phone/authenticator app</li>
                                            <li>Contact bitcash Support with valid ID if you have neither 2FA nor backup codes</li>
                                            <li>Re-enable 2FA on your new device after regaining access</li>
                                            <li>Secure your backup codes again to prevent future lockouts</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingSix">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                                    <h3 class="text-gradient">6. How to Verify Your Identity (KYC Process)</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Understanding the KYC Process</h4>
                                        <ol>
                                            <li>Comply with legal requirements to prevent fraud</li>
                                            <li>Ensure a safer trading environment for all users</li>
                                            <li>Unlock higher transaction limits and more features</li>
                                            <li>Protect your account from suspicious activity</li>
                                            <li>Establish trust with bitcash’s financial ecosystem</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Required Documents for Verification</h4>
                                        <ol>
                                            <li>Government-Issued ID (e.g., passport, driver’s license)</li>
                                            <li>Proof of Address (e.g., utility bill, bank statement)</li>
                                            <li>Selfie or live photo for identity confirmation (if requested)</li>
                                            <li>Additional documents may be required based on region</li>
                                            <li>Check the bitcash platform’s guidelines for accepted file formats</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">How to Submit Your Documents and Track Status</h4>
                                        <ol>
                                            <li>Log in and go to “Profile” or “Verification”</li>
                                            <li>Upload your ID and address proof in the designated fields</li>
                                            <li>Double-check clarity and correctness before submitting</li>
                                            <li>Wait for bitcash to review (usually within a few business days)</li>
                                            <li>Track your verification status in your account or via email updates</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingSeven">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                                    <h3 class="text-gradient">7. How to Link and Use Your Digital Wallet</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Setting Up a Digital Wallet for Trading</h4>
                                        <ol>
                                            <li>Choose a reputable digital wallet provider (e.g., MetaMask, Trust Wallet)</li>
                                            <li>Download and install the wallet app or extension</li>
                                            <li>Create a wallet by following the provider’s setup instructions</li>
                                            <li>Secure your private keys/seed phrase in a safe place</li>
                                            <li>Confirm your wallet is ready for crypto transactions</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Linking Your Wallet to Your bitcash Account</h4>
                                        <ol>
                                            <li>Log in to bitcash and go to “Settings” or “Wallets”</li>
                                            <li>Select “Link Wallet” or “Connect External Wallet”</li>
                                            <li>Authorize the connection via your wallet provider (e.g., approve in MetaMask)</li>
                                            <li>Confirm the link when prompted by bitcash</li>
                                            <li>Verify that your bitcash account now recognizes the linked wallet address</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Adding and Managing Funds in Your Wallet</h4>
                                        <ol>
                                            <li>Deposit funds directly into your wallet from external sources (bank, exchange)</li>
                                            <li>Transfer crypto between your bitcash account and your linked wallet</li>
                                            <li>Check your wallet balance regularly via the wallet interface</li>
                                            <li>Manage tokens/coins by adding them within the wallet’s settings</li>
                                            <li>Keep your wallet updated and monitor transaction history for security</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingEight">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                                    <h3 class="text-gradient">8. How to Withdraw Funds from Your Account</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Step-by-Step Guide on How to Request a Withdrawal</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Go to “Withdraw”</li>
                                            <li>Select your desired withdrawal method (e.g., bank transfer, crypto)</li>
                                            <li>Enter the amount and confirm the withdrawal details</li>
                                            <li>Submit the request and wait for confirmation</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Withdrawal Methods and Processing Times</h4>
                                        <ol>
                                            <li>Bank Transfer – usually takes 1–3 business days</li>
                                            <li>Credit/Debit Card – typically instant or up to 24 hours</li>
                                            <li>Cryptocurrency – depends on network congestion (minutes to an hour)</li>
                                            <li>Check your inbox or account notifications for status updates</li>
                                            <li>Contact Support if the withdrawal takes longer than the stated timeframe</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">Understanding Withdrawal Fees and Limits</h4>
                                        <ol>
                                            <li> Review any withdrawal fee displayed before confirming</li>
                                            <li>Note the minimum withdrawal amount for each method</li>
                                            <li>Check daily/monthly limits based on your account verification level
                                            </li>
                                            <li>Upgrade your KYC level if you need higher withdrawal limits
                                            </li>
                                            <li> Contact Support if you have questions about fees or limits</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingNine">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                    <h3 class="text-gradient">9. How to Change Your Language Preferences</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">How to Switch Between Available Languages</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Go to “Settings”</li>
                                            <li>Locate the “Language” or “Locale” option</li>
                                            <li>Choose from the list of available languages</li>
                                            <li>Save your changes and refresh the page if needed</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Language-Related Tips for Beginners</h4>
                                        <ol>
                                            <li>Start with a language you’re most comfortable reading</li>
                                            <li>Practice key terms in new languages to navigate the platform</li>
                                            <li>Use in-app translations or built-in glossaries if available</li>
                                            <li>Check for localized help guides or FAQs in your chosen language</li>
                                            <li>Contact Support if you face any issues with language settings</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingTen">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                    <h3 class="text-gradient">10. How to Set Up and Use the bitcash Mobile App</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">How to Download and Install the Mobile App</h4>
                                        <ol>
                                            <li>Open your device’s app store (Google Play or Apple App Store)</li>
                                            <li>Search for “bitcash”</li>
                                            <li>Tap “Download” or “Get”</li>
                                            <li>Install the app and allow necessary permissions if prompted</li>
                                            <li>Launch the app once installation is complete</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Features Available on the App vs. Desktop Platform</h4>
                                        <ol>
                                            <li>Trade on-the-go with mobile-optimized charts</li>
                                            <li>Manage deposits, withdrawals, and transfers from your phone</li>
                                            <li>Receive push notifications for account alerts</li>
                                            <li>Access key settings (profile, security) in a simplified menu</li>
                                            <li>Note that some advanced features may be exclusive to the desktop interface</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Logging In and Navigating the Mobile Interface</h4>
                                        <ol>
                                            <li>Open the app and enter your bitcash credentials</li>
                                            <li>Enable biometric or 2FA login if you prefer extra security</li>
                                            <li>Explore the main dashboard for quick access to balances and trades</li>
                                            <li>Swipe or tap menu icons to switch between different sections</li>
                                            <li>Use the settings tab for language preferences, notifications, and more</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingEleven">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                    <h3 class="text-gradient">11. How to View Your Transaction History</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Accessing Your Deposit and Withdrawal History</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Go to “History”, “Transactions”, or “Activity” tab</li>
                                            <li>Select “Deposits” or “Withdrawals” to see relevant records</li>
                                            <li>Click any transaction for more details (date, amount, method)</li>
                                            <li>Check timestamps and statuses to confirm successful operations</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Filtering Your Transaction History by Date or Type</h4>
                                        <ol>
                                            <li>Locate the filter or search bar on the Transactions page</li>
                                            <li>Choose a specific date range or preset (e.g., last 7 days)</li>
                                            <li>Select a transaction type (deposit, withdrawal, fees, etc.)</li>
                                            <li>Apply the filter to refine your transaction list</li>
                                            <li>Export or save the filtered results if needed</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Understanding Transaction Status (Pending, Completed, Failed)</h4>
                                        <ol>
                                            <li>Pending – the transaction is still processing or awaiting confirmation</li>
                                            <li>Completed – the transaction has finished successfully</li>
                                            <li>Failed – the transaction didn’t go through due to an error</li>
                                            <li>Check for any error messages if a transaction failed</li>
                                            <li>Contact Support if a pending status remains for an unusually long time</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingTwelve">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                    <h3 class="text-gradient">12. How to Enable Push Notifications</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingTwelve" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">How to Turn On Notifications for Account Updates</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Go to “Settings” then “Notifications”</li>
                                            <li>Toggle the switch for “Push Notifications”</li>
                                            <li>Grant permission if your browser or device requests it</li>
                                            <li>Confirm that notifications are now enabled</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Customizing Notifications for Trades, Deposits, and Withdrawals</h4>
                                        <ol>
                                            <li>Open the “Notification Preferences” section</li>
                                            <li>Select which events you want to be notified about (e.g., trades, deposits, withdrawals)</li>
                                            <li>Adjust frequency and channels (push, email, SMS) if applicable</li>
                                            <li>Save your preferences to confirm changes</li>
                                            <li>Test by performing a small transaction to ensure notifications work</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Managing Push Notifications on Both Desktop and Mobile</h4>
                                        <ol>
                                            <li>Enable notifications in your browser settings (desktop) or app settings (mobile)</li>
                                            <li>Check your device’s notification settings for bitcash (Android/iOS)</li>
                                            <li>Turn off or modify specific alerts if they become overwhelming</li>
                                            <li>Update your preferences regularly for the latest features</li>
                                            <li>Contact Support if you’re not receiving expected notifications</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingThirteen">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThirteen">
                                    <h3 class="text-gradient">13. How to Change Your Account Password</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseThirteen" class="accordion-collapse collapse" aria-labelledby="flush-headingThirteen" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Updating Your Account Password for Added Security</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Go to “Settings” or “Security”</li>
                                            <li>Select “Change Password”</li>
                                            <li>Enter your current password, then create a new one</li>
                                            <li>Confirm the update to finalize your new password</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Tips for Choosing a Strong Password</h4>
                                        <ol>
                                            <li>Use a combination of uppercase, lowercase, numbers, and symbols</li>
                                            <li>Avoid personal information (birthdays, names, phone numbers)</li>
                                            <li>Create a unique password not used on other sites</li>
                                            <li>Consider using a reputable password manager</li>
                                            <li>Update passwords regularly to minimize security risks</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Changing Your Password on Both Mobile and Desktop</h4>
                                        <ol>
                                            <li>Desktop: Access Settings or Profile from the top navigation bar</li>
                                            <li>Desktop: Locate “Change Password” under Security or Account</li>
                                            <li>Mobile: Open the bitcash app and tap Menu (or Profile icon)</li>
                                            <li>Mobile: Look for Security options and tap Change Password</li>
                                            <li>Confirm the new password on both platforms to ensure consistency</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingFourteen">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collapseFourteen">
                                    <h3 class="text-gradient">14. How to Verify Your Email Address</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseFourteen" class="accordion-collapse collapse" aria-labelledby="flush-headingFourteen" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Why Email Verification Is Necessary</h4>
                                        <ol>
                                            <li>Ensure your account is linked to a valid email</li>
                                            <li>Receive important notifications and updates</li>
                                            <li>Protect against unauthorized access or fraudulent activity</li>
                                            <li>Reset your password securely when needed</li>
                                            <li>Enhance overall account security and trustworthiness</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Step-by-Step Guide to Verify Your Email</h4>
                                        <ol>
                                            <li>Log in to your bitcash account after signing up</li>
                                            <li>Check your email inbox for the verification code from bitcash</li>
                                            <li>Copy or note down the code (it may be a 6-digit number)</li>
                                            <li>Enter the code into the designated field on bitcash’s verification page</li>
                                            <li>Confirm or submit to finalize your email verification</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">What to Do If You Didn’t Receive the Verification Code</h4>
                                        <ol>
                                            <li>Check your spam or junk folder</li>
                                            <li>Resend the verification code from your bitcash account settings
                                            </li>
                                            <li> Ensure you entered the correct email address during registration</li>
                                            <li>Whitelist bitcash’s email domain in your mail client settings
                                            </li>
                                            <li>Contact Support if the issue persists after multiple attempts</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingFifteen">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collapseFifteen">
                                    <h3 class="text-gradient">15. How to Update Your Payment Method</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseFifteen" class="accordion-collapse collapse" aria-labelledby="flush-headingFifteen" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Changing Your Credit Card, Bank Account, or Crypto Wallet</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Go to “Settings” then “Payment Methods”</li>
                                            <li>Locate your current payment method (credit card, bank account, or crypto wallet)</li>
                                            <li>Select “Edit” or “Replace” to update the existing details</li>
                                            <li>Remove any outdated methods that are no longer in use</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Step-by-Step Process for Updating Payment Methods</h4>
                                        <ol>
                                            <li>Choose the new payment method you want to add (card, bank, or crypto)</li>
                                            <li>Enter the required details (card number, bank info, wallet address)</li>
                                            <li>Confirm any additional verification steps (e.g., code sent to email or phone)</li>
                                            <li>Review the details for accuracy before submitting</li>
                                            <li>Save or Update to finalize the changes</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Ensuring Your Payment Method Is Secure</h4>
                                        <ol>
                                            <li>Enable two-factor authentication (2FA) on your bitcash account</li>
                                            <li>Verify the authenticity of any new wallet addresses or bank details</li>
                                            <li>Store payment information in a secure location or password manager</li>
                                            <li>Monitor transaction history regularly for suspicious activity</li>
                                            <li>Contact Support immediately if you notice unauthorized changes</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingSixteen">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSixteen" aria-expanded="false" aria-controls="flush-collapseSixteen">
                                    <h3 class="text-gradient">16. How to Set Up Your Preferred Trading Settings</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseSixteen" class="accordion-collapse collapse" aria-labelledby="flush-headingSixteen" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">How to Choose Your Default Trading Pairs</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Navigate to the “Trading” or “Markets” section</li>
                                            <li>Search for the pairs you trade most often (e.g., BTC/USD)</li>
                                            <li>Mark or star them as “Favorites” or “Default”</li>
                                            <li>Save your changes so they appear at the top of your list</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Adjusting Your Chart Settings (Timeframe, Indicators)</h4>
                                        <ol>
                                            <li>Open the chart for the pair you want to customize</li>
                                            <li>Select your preferred timeframe (e.g., 1H, 4H, 1D)</li>
                                            <li>Add technical indicators (e.g., Moving Averages, RSI) from the chart menu</li>
                                            <li>Customize indicator parameters (length, style) if needed</li>
                                            <li>Save your chart layout to keep these settings for next time</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">Customizing Your Trading Interface for a Better User Experience
                                        </h4>
                                        <ol>
                                            <li>Check the “Layout” or “Settings” icon on your trading dashboard</li>
                                            <li>Rearrange panels (order book, trades, charts) to your liking
                                            </li>
                                            <li>Enable or disable features like dark mode, grid lines, or advanced order types
                                            </li>
                                            <li>Adjust font size or zoom level for comfortable reading
                                            </li>
                                            <li>Apply and confirm your changes to finalize your custom layout
                                            </li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingSeventeen">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeventeen" aria-expanded="false" aria-controls="flush-collapseSeventeen">
                                    <h3 class="text-gradient">17. How to Set Up and Use Stop-Loss and Take-Profit Orders</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseSeventeen" class="accordion-collapse collapse" aria-labelledby="flush-headingSeventeen" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Step-by-Step Guide on Setting a Stop-Loss and Take-Profit</h4>
                                        <ol>
                                            <li>Log in to your bitcash account and open the Trading interface</li>
                                            <li>Select the trading pair you want to trade (e.g., BTC/USD)</li>
                                            <li>Choose your order type (e.g., “Stop-Loss” or “Take-Profit”) in the order form</li>
                                            <li>Enter the specific price you want the order to trigger (stop or take-profit level)</li>
                                            <li>Set the order amount (e.g., how many units of the asset to sell or buy)</li>
                                            <li>Review all details and click “Place Order” to confirm</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">The Importance of Risk Management</h4>
                                        <ol>
                                            <li> Limit potential losses by automatically exiting trades if the market moves against you</li>
                                            <li>Lock in profits by setting take-profit levels above your entry price</li>
                                            <li>Reduce emotional decision-making—orders trigger based on predefined conditions</li>
                                            <li>Secure your capital and keep your losses controlled</li>
                                            <li>Maintain a disciplined trading strategy to improve long-term success</li>

                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">How to Modify or Cancel These Orders            </h4>
                                        <ol>
                                            <li>Go to your Open Orders or Order History tab</li>
                                            <li>Locate the stop-loss or take-profit order you want to change</li>
                                            <li>Click “Edit” or “Cancel” to remove the order</li>
                                            <li>Update the trigger price or order amount if editing</li>
                                            <li>Save changes or place a new order if you canceled the old one</li>

                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingEighteen">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEighteen" aria-expanded="false" aria-controls="flush-collapseEighteen">
                                    <h3 class="text-gradient">18. How to Fund Your Wallet Using Cryptocurrency</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseEighteen" class="accordion-collapse collapse" aria-labelledby="flush-headingEighteen" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">How to Transfer Crypto into Your bitcash Wallet</h4>
                                        <ol>
                                            <li>Log in to your bitcash account and go to “Deposit” or “Wallet”</li>
                                            <li>Select the cryptocurrency you want to deposit (e.g., BTC, ETH)</li>
                                            <li>Copy your unique bitcash wallet address or scan the QR code</li>
                                            <li>Open your external wallet/exchange and choose “Send” or “Withdraw”</li>
                                            <li>Paste the bitcash wallet address, enter the amount, and confirm the transaction</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">Supported Cryptocurrencies for Deposits
                                        </h4>
                                        <ol>
                                            <li>Check the list of cryptocurrencies available on the deposit page</li>
                                            <li> Verify network compatibility (e.g., ERC-20 for ETH, BEP-20 for BNB)</li>
                                            <li>Note any minimum deposit amounts or special instructions
                                            </li>
                                            <li>Stay updated on newly added coins or tokens</li>
                                            <li>Use only supported networks to avoid potential loss of funds</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient"> How to Check Transaction Status and Confirm Deposit
                                        </h4>
                                        <ol>
                                            <li>Track the transaction in your external wallet/exchange (TXID)</li>
                                            <li>Wait for the required blockchain confirmations</li>
                                            <li>Refresh your bitcash wallet balance to see if the deposit has arrived
                                            </li>
                                            <li>View the deposit history under “Transactions” or “Deposit History”</li>
                                            <li> Contact Support if the transaction takes longer than expected or shows errors</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingNineteen">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNineteen" aria-expanded="false" aria-controls="flush-collapseNineteen">
                                    <h3 class="text-gradient">19. How to Monitor Your Account Balance and Equity</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseNineteen" class="accordion-collapse collapse" aria-labelledby="flush-headingNineteen" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Viewing and Understanding Your Balance and Equity</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Navigate to the “Dashboard” or “Portfolio” section</li>
                                            <li>Locate your total account balance (the sum of all assets)</li>
                                            <li>Check your equity, which factors in open trades (unrealized P&L)</li>
                                            <li>Review any margin or free margin details if applicable</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">How to Track Your Profits and Losses in Real-Time</h4>
                                        <ol>
                                            <li> Open your “Open Orders” or “Positions” tab</li>
                                            <li>Monitor unrealized P&L (profit/loss) for ongoing trades</li>
                                            <li>View realized P&L in your account history or trade log
                                            </li>
                                            <li>Use charts or performance summaries for quick insights
                                            </li>
                                            <li>Refresh regularly or enable real-time updates for the latest info
                                            </li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">How to Manage Your Balance Across Multiple Assets</h4>
                                        <ol>
                                            <li>Check your “Wallet” or “Asset Overview” for a breakdown of each currency
                                            </li>
                                            <li>Transfer funds between different assets or sub-accounts if supported</li>
                                            <li> Rebalance periodically by allocating funds to preferred assets</li>
                                            <li>Set alerts for price changes to manage holdings proactively
                                            </li>
                                            <li> Ensure you keep track of any fees when shifting assets
                                            </li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingTwenty">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwenty" aria-expanded="false" aria-controls="flush-collapseTwenty">
                                    <h3 class="text-gradient">20. How to Enable Email Notifications for Account Activity</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseTwenty" class="accordion-collapse collapse" aria-labelledby="flush-headingTwenty" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Enabling Email Alerts for Deposit, Withdrawal, and Trade Actions</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Go to “Settings” then “Notifications”</li>
                                            <li>Locate the email alerts section (for deposits, withdrawals, trades)</li>
                                            <li>Toggle each alert type you wish to receive via email</li>
                                            <li>Save your changes to activate these notifications</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">Managing Email Preferences for Important Account Events</h4>
                                        <ol>
                                            <li> Open the “Notification Preferences” or “Email Preferences” tab
                                            </li>
                                            <li>Review all available event categories (e.g., login alerts, balance updates)
                                            </li>
                                            <li>Select which events you want to receive via email</li>
                                            <li>Adjust frequency if the platform offers daily or instant updates
                                            </li>
                                            <li>Confirm to finalize your customized email notification settings
                                            </li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient"> How to Ensure Email Notifications Are Not Missed
                                        </h4>
                                        <ol>
                                            <li>Check your spam or junk folder if you don’t see expected emails</li>
                                            <li>Whitelist or add bitcash’s email domain to your trusted senders list</li>
                                            <li>Keep your email address up to date in your account profile</li>
                                            <li>Verify notifications are enabled on any security or email filtering services
                                            </li>
                                            <li> Contact Support if issues persist, providing details about missed emails
                                            </li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingTwentyOne">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyOne" aria-expanded="false" aria-controls="flush-collapseTwentyOne">
                                    <h3 class="text-gradient">21. How to Customize Your Trading Dashboard</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseTwentyOne" class="accordion-collapse collapse" aria-labelledby="flush-headingTwentyOne" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Organizing Your Dashboard for Easy Access to Key Information</h4>
                                        <ol>
                                            <li>Log in to your bitcash account and open the Trading Dashboard</li>
                                            <li>Identify the sections or panels you want to rearrange (e.g., order book, watchlist)</li>
                                            <li>Drag and drop panels to your preferred positions (if supported)</li>
                                            <li>Resize panels to focus on the most important information</li>
                                            <li>Confirm the layout to ensure your changes are saved</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">How to Add or Remove Widgets</h4>
                                        <ol>
                                            <li>Locate the “Widgets” or “Customization” option in your dashboard</li>
                                            <li>Select the widgets you want to add (e.g., news feed, alerts)</li>
                                            <li>Click “Add” or “+” to place them on the dashboard</li>
                                            <li>Remove unwanted widgets by clicking “Remove” or “x”</li>
                                            <li>Save changes to update your dashboard layout</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">Saving Custom Layouts for Different Trading Strategies</h4>
                                        <ol>
                                            <li>Click the “Save Layout” or “Save Profile” button</li>
                                            <li>Name your layout (e.g., “Day Trading Setup,” “Long-Term Investing”)</li>
                                            <li>Switch between saved layouts in your dashboard settings</li>
                                            <li>Edit or update layouts as your strategy evolves</li>
                                            <li>Use multiple layouts to quickly adapt to different market conditions</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingTwentyTwo">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyTwo" aria-expanded="false" aria-controls="flush-collapseTwentyTwo">
                                    <h3 class="text-gradient">22. How to Use the bitcash Help Center and Live Chat Support</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseTwentyTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwentyTwo" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Accessing the Help Center for Common Questions</h4>
                                        <ol>
                                            <li>Visit the bitcash website or log in to your account</li>
                                            <li>Click on “Help Center” or “Support” in the main menu</li>
                                            <li>Browse categories (e.g., Deposits, Trading, Security)</li>
                                            <li>Select an article to find detailed, step-by-step solutions</li>
                                            <li>Return to the Help Center homepage to explore other topics</li>
                                        </ol>
                                    </section>

                                    <section>
                                        <h4 class="text-gradient">How to Contact Live Chat Support for Assistance</h4>
                                        <ol>
                                            <li>Look for a chat icon or “Live Chat” button on the website/app</li>
                                            <li>Click to open the live chat window</li>
                                            <li>Enter your query or describe the issue you’re facing</li>
                                            <li>Wait for a support agent to respond (usually within a few minutes)</li>
                                            <li>Follow any instructions or troubleshooting steps provided</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">Browsing FAQs and Troubleshooting Issues
                                        </h4>
                                        <ol>
                                            <li>Go to the FAQs section in the Help Center
                                            </li>
                                            <li>Search for keywords related to your issue (e.g., “withdrawal,” “verification”)
                                            </li>
                                            <li>Review the listed solutions or guides that match your query</li>
                                            <li> Attempt suggested fixes or steps from the FAQ articles</li>
                                            <li>Contact Live Chat or Support if the FAQs don’t resolve your problem</li>
                                        </ol>
                                    </section>

                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingTwentyThree">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyThree" aria-expanded="false" aria-controls="flush-collapseTwentyThree">
                                    <h3 class="text-gradient">23. How to Set Up Push Notifications for Price Alerts</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseTwentyThree" class="accordion-collapse collapse" aria-labelledby="flush-headingTwentyThree" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Setting Price Alerts for Specific Assets (Forex, Crypto, etc.)</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Navigate to “Alerts” or “Notifications” in the settings menu</li>
                                            <li>Select the asset type (e.g., Forex, Crypto) and the specific instrument (e.g., EUR/USD, BTC/USD)</li>
                                            <li>Choose the target price at which you want to be alerted</li>
                                            <li>Save or activate the price alert to confirm</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">How to Customize Alert Thresholds and Notification Channels
                                        </h4>
                                        <ol>
                                            <li>Open your “Alert Settings” under the main menu</li>
                                            <li> Enter upper and/or lower threshold values (e.g., BTC {`${">"}`} $50,000)
                                            </li>
                                            <li>Select your notification channels (push, email, SMS)</li>
                                            <li>Adjust alert frequency (one-time or recurring) if available
                                            </li>
                                            <li>Confirm your changes to enable custom alerts
                                            </li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">Managing and Disabling Price Alerts When Not Needed
                                        </h4>
                                        <ol>
                                            <li>Go to your existing alerts list under “My Alerts” or “Manage Alerts”</li>
                                            <li>Toggle each alert on or off as needed</li>
                                            <li> Edit threshold values to update or refine alert triggers</li>
                                            <li> Delete alerts if you no longer need them</li>
                                            <li> Review alert settings periodically to avoid unnecessary notifications
                                            </li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingTwentyFour">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyFour" aria-expanded="false" aria-controls="flush-collapseTwentyFour">
                                    <h3 class="text-gradient">24. How to Track Your Trade Performance</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseTwentyFour" class="accordion-collapse collapse" aria-labelledby="flush-headingTwentyFour" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Viewing Your Trading History and Performance Reports</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Go to “History” or “Trading History”</li>
                                            <li>Filter by date range or asset type to locate specific trades</li>
                                            <li>Open a detailed trade report to see entry/exit prices and P&L</li>
                                            <li>Review summaries for overall performance over selected periods</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">Using the Trade Analytics Tool to Evaluate Past Trades</h4>
                                        <ol>
                                            <li>Open the “Analytics” or “Statistics” section under your account</li>
                                            <li>Select the time frame (e.g., weekly, monthly) you want to analyze</li>
                                            <li>View charts or graphs showing win/loss ratios and average returns</li>
                                            <li>Identify patterns or areas to improve your trading strategy</li>
                                            <li>Compare different time frames or asset classes for deeper insights
                                            </li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">Exporting Performance Data to a CSV File
                                        </h4>
                                        <ol>
                                            <li>Go to your trade history or analytics page
                                            </li>
                                            <li>Find the “Export” or “Download CSV” button
                                            </li>
                                            <li> Choose the date range or specific trades you want to include
                                            </li>
                                            <li> Click “Export” to save the file locally on your device</li>
                                            <li>Open the CSV file in Excel or Google Sheets for further analysis
                                            </li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingTwentyFive">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentyFive" aria-expanded="false" aria-controls="flush-collapseTwentyFive">
                                    <h3 class="text-gradient">25. How to Use the Demo Account to Practice Trading</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseTwentyFive" class="accordion-collapse collapse" aria-labelledby="flush-headingTwentyFive" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">How to Open a Demo Account for Practicing Trades</h4>
                                        <ol>
                                            <li>Log in to your bitcash account or sign up if you’re new</li>
                                            <li>Navigate to the “Demo” or “Practice” section (often under “Account” or “Trading”)</li>
                                            <li>Select “Open Demo Account” and follow any on-screen prompts</li>
                                            <li>Confirm your demo account details (e.g., initial virtual balance)</li>
                                            <li>Access the demo trading platform loaded with virtual funds</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">Key Features of the Demo Account (Virtual Funds, Trading Pairs)
                                        </h4>
                                        <ol>
                                            <li>Virtual Funds – practice trades risk-free with simulated balances
                                            </li>
                                            <li>Real-Time Data – trade with live market prices for authentic practice
                                            </li>
                                            <li> Wide Range of Pairs – test strategies on multiple Forex, Crypto, or Stock pairs</li>
                                            <li>Order Types – place standard, stop-loss, and take-profit orders just like a live account</li>
                                            <li>Performance Tracking – review trade history and P&L without risking real funds</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">How to Transition from Demo to Live Account Trading</h4>
                                        <ol>
                                            <li>Refine your strategy on the demo until you feel confident</li>
                                            <li> Switch to the live trading tab or “Real Account” from the main menu
                                            </li>
                                            <li> Deposit real funds and complete any required KYC or verification steps</li>
                                            <li>Start Small – begin with minimal trades to get comfortable</li>
                                            <li>Scale Up your live trading as you gain more confidence and consistent results</li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h4 class="accordion-header" id="flush-headingTwentySix">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwentySix" aria-expanded="false" aria-controls="flush-collapseTwentySix">
                                    <h3 class="text-gradient">26. How to Enable Dark Mode on Your Platform</h3>
                                </button>
                            </h4>
                            <div id="flush-collapseTwentySix" class="accordion-collapse collapse" aria-labelledby="flush-headingTwentySix" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    <section>
                                        <h4 class="text-gradient">Activating Dark Mode for a More Comfortable Trading Experience</h4>
                                        <ol>
                                            <li>Log in to your bitcash account</li>
                                            <li>Go to “Settings” or “Display Preferences”</li>
                                            <li>Find the “Theme” or “Appearance” option</li>
                                            <li>Select “Dark Mode” or “Night Mode”</li>
                                            <li>Save or Apply changes to switch to the dark theme</li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">Switching Between Light and Dark Themes
                                        </h4>
                                        <ol>
                                            <li>Open the same “Settings” or “Display” menu anytime
                                            </li>
                                            <li>Toggle between “Light” or “Dark” mode based on your preference</li>
                                            <li>Confirm and apply the changes</li>
                                            <li>Observe the immediate switch in interface colors
                                            </li>
                                            <li>Revisit settings if you want to revert to Light Mode
                                            </li>
                                        </ol>
                                    </section>
                                    <section>
                                        <h4 class="text-gradient">How Dark Mode Can Reduce Eye Strain During Long Trading Sessions</h4>
                                        <ol>
                                            <li>Dimmer screens can be easier on the eyes, especially in low-light conditions</li>
                                            <li> Less harsh contrast helps prevent eye fatigue</li>
                                            <li>Improved focus on charts and data without bright glare</li>
                                            <li> Better for users who spend extended hours monitoring markets</li>
                                            <li>Combine with other settings like font size or blue-light filters for optimal comfort
                                            </li>
                                        </ol>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Guide;
