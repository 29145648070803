import React, { useState } from "react";
import Header from "./HomeComp/Header";
import "./login.css";
import { isCpass, isPass } from "./redux/helpers/form-validator.functions";
import { password_update } from "./redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
export default function Forget(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [password, setPassword] = useState();
  const [confPassword, setConfPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const { switch_theme } = useSelector((state) => state.AuthReducer);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const toggleConfPasswordVisibility = () => {
    setShowConfPassword((prev) => !prev);
  };

  const { otp_send, user } = useSelector((state) => state.AuthReducer);
  if (!otp_send) props.history.push("/login");
  const onSubmit = (e) => {
    e.preventDefault();
    if (isPass(password) && isCpass(confPassword)) {
      setLoading(true);
      dispatch(
        password_update(
          user?.params ? user.params.user_id : user.user_id,
          password,
          confPassword,
          () => {
            setLoading(false);
            props.history?.replace("/login");
          },
          () => setLoading(false)
        )
      );
    }
  };
  return (
    <>
      <Header {...props} />
      <div className="">
        <div className="container-fuild register_bg">
          <div className="row align-items-center">
            <div class="col-md-6  ">
              <div className="LoginImg">
                <img src="/img/rewards-1.svg" className="login-img0" />
              </div>
            </div>
            <div className="col-md-6 d-flex align-items-center ">
              <div className="col-12 col-md-12 col-sm-12 m-auto login_window  cstm_form">
                <form
                  className="signupform"
                  method="post"
                  id="signupform"
                  onSubmit={onSubmit}
                >
                  <div className="d-flex justify-content-center mb-5">
                    <Link to="/">
                      {switch_theme === "dark" ? (
                        <img src="/img/logo.svg" style={{ width: "80px" }} />
                      ) : (
                        <img
                          src="/img/logo_dark.png"
                          style={{ width: "80px" }}
                        />
                      )}
                    </Link>
                  </div>
                  <div>
                    <h2 className="text-white">Update Password</h2>
                  </div>
                  <div className="form-group">
                    <small id="msg" style={{ fontSize: "15px" }}>
                      Error message
                    </small>
                  </div>
                  <div className={`form-group`}>
                    <label
                      htmlFor="user email"
                      className="text-white mb-2 fw-bold"
                    >
                      PASSWORD
                    </label>
                    {/* <label htmlFor="user_password">PASSWORD</label> */}

                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="user_password"
                        className="form-control"
                        required=""
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="pass"
                        placeholder="password"
                      />
                      <span
                        className="input-group-text"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>

                    <p>
                      Password must be contain atleast 1 Capital character , 1
                      digit , 1 symbol and length should be greater than 8.
                    </p>
                    <small className="text-danger" id="passerr"></small>
                  </div>
                  <div className={`form-group`}>
                    <label
                      htmlFor="user email"
                      className="text-white mb-2 fw-bold"
                    >
                      CONFIRM PASSWORD
                    </label>
                    {/* <label htmlFor="user_cpassword">CONFIRM PASSWORD</label> */}
                    <div className="input-group">
                      <input
                        type={showConfPassword ? "text" : "password"}
                        name="user_cpassword"
                        className="form-control"
                        id="cpass"
                        required=""
                        value={confPassword}
                        onChange={(e) => setConfPassword(e.target.value)}
                        placeholder="confirm password"
                      />
                      <span
                        className="input-group-text"
                        onClick={toggleConfPasswordVisibility}
                      >
                        {showConfPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>

                    <small className="text-danger" id="cpasserr"></small>
                  </div>
                  <button
                    type="submit"
                    id="btn_submit"
                    className="login_btn px-5 w-full mt-4"
                  >
                    <i className="loading-icon fas fa-spinner fa-spin h"></i>
                    <span id="reg">Update</span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
