import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./header.css";
import { user_logout } from "../redux/actions/authActions";
import { switchTheme } from "../redux/actions/coinDBAction";
import NotificationBanner from "./BannerData";
import { adminurl } from "../redux/helpers/api_functions_new";
import {
  RiExchangeFundsFill,
  RiFundsBoxLine,
  RiHistoryFill,
} from "react-icons/ri";
import {
  FaBalanceScaleLeft,
  FaHandHoldingUsd,
  FaRegEye,
  FaRegEyeSlash,
} from "react-icons/fa";

import { BiBot } from "react-icons/bi";
import { MdOutlineAutoGraph } from "react-icons/md";
import { GoGraph } from "react-icons/go";
import { AiOutlineUser } from "react-icons/ai";
import { HiBars3CenterLeft } from "react-icons/hi2";
import { RxCross1 } from "react-icons/rx";
import { getCoinRate, getRound } from "../redux/helpers/helper_functions";

export default function Header(props) {
  const dispatch = useDispatch();
  const { isLoggedIn, switch_theme, user, profile } = useSelector(
    (state) => state.AuthReducer
  );
  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const { wallet, paired_curency_price, coins, wallet_loading } = useSelector(
    (state) => state.coinDBReducer
  );
  async function backRouter() {
    dispatch(
      user_logout(() => {
        props.history?.replace("/login");
      })
    );
    document.location.reload();
  }

  useEffect(() => {
    if (!wallet_loading) {
      getWallets();
    }
  }, [wallet, coins, paired_curency_price]);

  function getWallets() {
    let total = 0;
    wallet &&
      Object.keys(wallet).map((res, i) => {
        let wallet_type = wallet[res]?.symbol.toUpperCase();
        let rate = getCoinRate(coins, wallet_type);
        let inr_val = rate * getRound(wallet[res]?.balance);
        total = total + parseFloat(inr_val);
      });
    setTotalInr(getRound(total));
    setTotalUsdt(getRound(total / paired_curency_price.USDT));
  }

  async function swtchTheme() {
    if (switch_theme == "dark") {
      dispatch(switchTheme("light"));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme_frontend", "light");
      const body = document.getElementsByTagName("body")[0];
      body?.classList.add("is_light");
      body?.classList.remove("is_dark");

      var icon = document.querySelector("#theme_icon");
      icon?.classList.add("fa-sun");
      icon?.classList.remove("fa-moon");
    } else {
      dispatch(switchTheme("dark"));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme_frontend", "dark");
      const body = document.getElementsByTagName("body")[0];
      body?.classList.add("is_dark");
      body?.classList.remove("is_light");

      var icon = document.querySelector("#theme_icon");
      icon?.classList.add("fa-moon");
      icon?.classList.remove("fa-sun");
    }
  }
  return (
    <>
      {/* <NotificationBanner/> */}
      <div class="Header_Section">
        <nav className="navbar  navbar-expand-lg navbar-light exchange-header fixed-top">
          <Link className="navbar-brand" to="/">
            <img className="ms-2" src={`/img/logo.svg`} alt="logo" width="60" />
          </Link>
          <span className="brand-title">
            {" "}
            {webData.logo_with_title === 1 ? webData.website_short_name : ""}
          </span>
          <button
            class="navbar-toggler circle_backdrop"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#ca-navbar"
            aria-controls="ca-navbar "
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon">
              <HiBars3CenterLeft className="fs-3" />
            </span>
          </button>
          <div
            id="ca-navbar"
            className="offcanvas offcanvas-end navbar-collapse-- manu_header"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="ca-navbar">
                <Link className="navbar-brand" to="/">
                  <img
                    className="ms-2"
                    src={`/img/logo.svg`}
                    alt="logo"
                    width="60"
                  />
                </Link>
              </h5>
              <button
                type="button"
                class="circle_backdrop border-0"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <RxCross1 />
              </button>
            </div>
            <div className="offcanvas-body">
              <ul
                id="nav--"
                className="navbar-nav justify-content-end flex-grow-1 pe-3"
              >
                {/* <li className="nav-item">
                    <Link
                      className=" nav-link theme-color-text"
                      to="/exchange/btc-usdt"
                    >
                      Trade
                    </Link>
                  </li> */}

                {isLoggedIn ? (
                  <>
                    {/* <li className="nav-item">
                      <Link className=" nav-link" to="/exchange/btc-usdt">
                        Trade
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link
                        className="nav-link theme-color-text"
                        to="/p2p/aco-inrx"
                      >
                        P2P
                      </Link>
                    </li>

                    {/* <li className="nav-item">
                      <Link className="nav-link" to="/wallet">
                        Wallets
                      </Link>
                    </li> */}
                  </>
                ) : null}
                <div className="">
                  <ul className="navbar-nav">
                    {!isLoggedIn ? (
                      <li class="nav-item header-nav-item ">
                        <Link className="nav-link " to="/login">
                          {" "}
                          Login{" "}
                        </Link>
                      </li>
                    ) : null}
                    {!isLoggedIn ? (
                      <li class="nav-item header-nav-item ">
                        <Link className="nav-link px-0" to="/create">
                          <span className="signup-btn ">Register</span>
                        </Link>
                      </li>
                    ) : null}
                    {isLoggedIn ? (
                      <>
                        <li class="nav-item header-nav-item  dropdown">
                          <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Assets
                          </a>
                          <ul
                            class="dropdown-menu dropdown-menu-end- dropdown_body_custom w320px"
                            aria-labelledby="navbarDropdown"
                          >
                            <li>
                              <a class="dropdown-item pb-0">
                                <div className="dropdown-body-item pb-0 text-lgray">
                                  <div className="fs-14">
                                    {" "}
                                    Overview
                                    <FaRegEye />
                                  </div>
                                  <div>
                                    <span className="text-dark">
                                      {total_usdt}
                                    </span>{" "}
                                    <span> USDT</span>
                                  </div>
                                </div>
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/FundingAccount">
                                <div className="dropdown-body-item">
                                  <GoGraph className="fs-4 me-2" /> Funding
                                  Account
                                </div>
                              </a>
                            </li>

                            <li>
                              <a
                                class="dropdown-item"
                                href="/AssetsTradingAccount"
                              >
                                <div className="dropdown-body-item">
                                  {" "}
                                  <RiExchangeFundsFill className="fs-4 me-2" />{" "}
                                  Trading Account
                                </div>
                              </a>
                            </li>

                            <li>
                              <a class="dropdown-item" href="/AssetsMargin">
                                <div className="dropdown-body-item">
                                  {" "}
                                  <FaBalanceScaleLeft className="fs-4 me-2" />{" "}
                                  Margin Account
                                </div>
                              </a>
                            </li>
                            <li>
                              <a class="dropdown-item" href="/AssetsFuture">
                                <div className="dropdown-body-item">
                                  <MdOutlineAutoGraph className="fs-4 me-2" />
                                  Futures Account
                                </div>
                              </a>
                            </li>

                            <li>
                              <a class="dropdown-item" href="/TradingBot">
                                <div className="dropdown-body-item">
                                  <BiBot className="fs-4 me-2" />
                                  Trading Bot Account
                                </div>
                              </a>
                            </li>

                            <li>
                              <a class="dropdown-item" href="/AssetsFinance">
                                <div className="dropdown-body-item">
                                  {" "}
                                  <FaHandHoldingUsd className="fs-4 me-2" />{" "}
                                  Financial Account{" "}
                                </div>
                              </a>
                            </li>

                            <div className="mx-2">
                              <div className="dropdown-body-item pb-0 mb-0">
                                <div className="menu_jambotron">
                                  <a class="" href="/">
                                    <div className="d-flex justify-content-between">
                                      Buy Crypto
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="mx-2">
                              <div className="dropdown-body-item ">
                                <div className="menu_jambotron">
                                  <a class="" href="/">
                                    <div className="d-flex justify-content-between">
                                      Deposit
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </ul>
                        </li>

                        <li
                          class="nav-item header-nav-item dropdown"
                          id="user_link"
                        >
                          <a
                            class="nav-link p-0 dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            {/* <div class="circle_backdrop fw-normal">AM</div> */}
                            <div class="circle_backdrop_outline gray_border">
                              {profile?.name
                                ? profile?.name
                                    ?.split("")
                                    .slice(0, 2)
                                    .join("")
                                    .toUpperCase()
                                : profile?.email
                                    ?.split("")
                                    .slice(0, 2)
                                    .join("")
                                    .toUpperCase()}
                            </div>
                          </a>

                          <ul class="dropdown-menu dropdown_body_custom dropdown-menu-end w320px">
                            <li>
                              <Link to="/profile" className="dropdown-item">
                                <div className="dropdown-body-item border-bottom">
                                  <h2 className="big_heading mb-2 text-truncate">
                                    {profile?.name
                                      ? profile.name
                                      : profile?.email}
                                  </h2>
                                  <span className="custom_badge gray_badge text-lgray ">
                                    {" "}
                                    UID :{" "}
                                    {user?.params
                                      ? user.params.user_id
                                      : user.user_id}{" "}
                                    <i className="fa fa-copy ms-1"></i>
                                  </span>
                                </div>
                              </Link>
                            </li>

                            <li>
                              <Link to="/profile" className="dropdown-item">
                                <div className="dropdown-body-item pt-3 border-top">
                                  Profile
                                </div>
                              </Link>
                            </li>

                            <li>
                              <Link
                                to="/AccountSecurity"
                                className="dropdown-item"
                              >
                                <div className="dropdown-body-item">
                                  Account Security
                                </div>
                              </Link>
                            </li>
                            <li>
                              <Link
                                className="dropdown-item"
                                to="/AccountVerification"
                              >
                                <div className="dropdown-body-item">
                                  {" "}
                                  Identity Verification{" "}
                                  {/* <span className="custom_badge bg-soft-pink">
                                    LV3
                                  </span> */}
                                </div>
                              </Link>
                            </li>

                            <li>
                              <Link
                                className="dropdown-item"
                                to="/ApiManagement"
                              >
                                <div className="dropdown-body-item">
                                  API Management
                                </div>
                              </Link>
                            </li>

                            {/* <li>
                              <Link className="dropdown-item" to="/subAccount">
                                <div className="dropdown-body-item">
                                  {" "}
                                  Sub Account{" "}
                                </div>
                              </Link>
                            </li> */}

                            <li>
                              <a
                                className="dropdown-item cursor_pointer border-top"
                                onClick={() => {
                                  backRouter();
                                }}
                              >
                                <div className="dropdown-body-item text-center text-black">
                                  Log Out
                                </div>
                              </a>
                            </li>
                          </ul>
                        </li>

                        {/* <li class="nav-item dropdown ">
                          <a
                            class="nav-link dropdown-toggle"
                            href="#"
                            data-bs-toggle="dropdown"
                          >
                            {" "}
                            <i class="fa fa-circle-user fs-6"></i>{" "}
                          </a>

                          <ul class="dropdown-menu dropdown-menu-end dropdown-menu-dark">
                            <Link to="/profile" className="dropdown-item">
                              Account
                            </Link>

                            <a
                              className="dropdown-item bg-danger text-white"
                              onClick={() => {
                                backRouter();
                              }}
                            >
                              <i className="fa fa-sign-out text-white"></i>{" "}
                              Logout
                            </a>
                          </ul>
                        </li> */}
                        <li className="nav-item header-nav-item  d-none d-lg-block ">
                          <a href="/notification" className="nav-link p-0">
                            <div className="circle_backdrop">
                              <i className="fa fa-bell-o fs-6"></i>
                            </div>
                          </a>
                        </li>
                      </>
                    ) : null}

                    <li className="nav-item d-none">
                      <a
                        onClick={() => {
                          swtchTheme();
                        }}
                        className="nav-link p-0 cursor_pointer"
                      >
                        <div className="circle_backdrop">
                          <i class="fa fa-sun fs-5" id="theme_icon"></i>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>

            {/* <div className="sing-up-button d-block d-lg-none">
                {location?.pathname !== "/login" && !isLoggedIn ? (
                  <Link to="/login">Login</Link>
                ) : null}
                {location?.pathname !== "/create" && !isLoggedIn ? (
                  <Link to="/create">Register</Link>
                ) : null}
                {isLoggedIn ? (
                  <>
                    <Link to="/profile" className="nav-link">
                      Account
                    </Link>

                    <p
                      className="nav-link my-2"
                      onClick={() => {
                        backRouter();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Logout
                    </p>
                  </>
                ) : null}
              </div> */}
          </div>
        </nav>
      </div>
    </>
  );
}
