import toast from "react-hot-toast";
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  SEND_OTP,
  F_OTP,
  SEND_AUTH,
} from "../constant";
import {
  N_forgetPassward,
  N_resendOTP,
  N_setActivityReport,
  N_updatePassword,
  N_userInfoSubmit,
  N_userLogin,
  N_userRegister,
  N_verifyForgetOTP,
  N_verifyOTP,
} from "../helpers/api_functions_new";

export function user_Login(email, mobile, password, otp, resolve, reject) {
  return (dispatch) => {
    N_userLogin(email, mobile, password, otp)
      .then((res) => {
        // console.log("node user login: ", res);
        if (res.status === 200) {
            if (res?.params?.ask_login_otp <= 0) {
              dispatch({ type: AUTH_LOGIN, data: res });
              const token = res?.params?.token;
              if (token) {
                localStorage.setItem("token", token);
              }
            }
            N_setActivityReport(res.params.user_id);
            resolve(res);
        } else {
          toast.error(res.message, "Error", 3000);
          reject();
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.message);
        reject();
      });

  };
}

export function user_authentication(
  email,
  password,
  authenticator,
  authenticator_key,
  resolve
) {
  return (dispatch) => {
    dispatch({
      type: SEND_AUTH,
      data: {
        email: email,
        password: password,
        authenticator: authenticator,
        authenticator_key: authenticator_key,
      },
    });
    resolve();
  };
}

export function password_update(
  user_id,
  password,
  confirm_password,
  resolve,
  reject
) {
  return (dispatch) => {
    N_updatePassword(user_id, password, confirm_password)
      .then((res) => {
        // console.log("password update", res, user_id);
        if (res.status === 200) {
          // dispatch({ type: AUTH_LOGIN, data: res });
          resolve();
        } else {
          toast.error(res.msg, "Error", 3000);
          reject();
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.message);
        reject();
      });
  };
}

export function user_Register(
  email,
  mobile_number,
  password,
  c_password,
  referral,
  resolve,
  reject
) {
  return (dispatch) => {
    N_userRegister(email, mobile_number, password, c_password, referral)
      .then((res) => {
        // console.log("Register", res);
        if (res.status === 200) {
          toast.success(res.message);
          // dispatch({
          //   type: SEND_OTP,
          //   data: { email: email, user_id: res.params.user_id },
          // });
          resolve();
        } else {
          toast.error(res.message);
          reject();
        }
      })
      .catch((e) => {
        console.log("n-user_reg err", e);
      });

  };
}

export function user_forget(email, resolve, reject) {
  return (dispatch) => {
    N_forgetPassward(email)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.message);
          dispatch({
            type: F_OTP,
            data: { email: email },
          });
          resolve();
        } else {
          toast.error(res.message);
          reject();
        }
      })
      .catch((e) => {
        console.log(e);
        reject();
      });
  };
}

export function opt_verify(otp, user_id, resolve, reject) {
  return (dispatch) => {
    // console.log("OTP", otp, "session", session_id);
    N_verifyOTP(otp, user_id)
      .then((res) => {
        // console.log("otp verify Node: ", res);
        if (res.status === 200) {
          dispatch({ type: AUTH_LOGIN, data: res });
          toast.success(res.message);
          resolve();
        } else {
          toast.error(res.message);
          reject();
        }
      })
      .catch((err) => {
        console.log("err otp verify:", err);
      });
  };
}

export function opt_forget_verify(otp, email, resolve, reject) {
  return (dispatch) => {
    N_verifyForgetOTP(otp, email)
      .then((res) => {
        if (res.status === 200) {
          // console.log("Fotp user_id", res);
          dispatch({
            type: F_OTP,
            data: { email: email, user_id: res.params.access_token },
          });
          toast.success(res.message);
          resolve();
        } else {
          toast.error(res.message);
          reject();
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error(e.message);
        reject();
      });
  };
}

export function user_logout(cb) {
  return (dispatch) => {
    dispatch({ type: AUTH_LOGOUT });
    cb();
  };
}

export function submitKyc(body, resolve, reject) {
  // console.log("ac body: ", body);
  return (dispatch) => {
    N_userInfoSubmit(body)
      .then((res) => {
        if (res.status === 200) {
          // console.log("sres: ", res);
          toast.success(res.message);
          resolve();
        } else {
          // console.log("s err: ", res);
          toast.error(res.message);
          reject();
        }
      })
      .catch((e) => {
        console.log(e);
        reject();
      });
  };
}
