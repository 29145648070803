import React from "react";
import "./blog.css";
import { ImUser, ImClock } from "react-icons/im";

const BlogDetail = ({ data }) => {
  return (
    <div className="d-flex flex-column gap-4 text-white">
      <h1>{data?.title}</h1>
      <section>
        <p className="blog-align">
          <span>Published by</span>
          <span className="blog-align">
            <ImUser />
            bitcash Official at
          </span>
          <span className="blog-align">
            <ImClock />
            2025-02-13
          </span>
        </p>
      </section>

      <div className="d-flex justify-content-center">
        <img src={`${data?.image}`} alt="blog" className="blog-image" />
      </div>

      <section>{data?.content}</section>
    </div>
  );
};

export default BlogDetail;
