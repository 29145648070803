import moment from "moment";
import React, { useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import Footer from "../HomeComp/Footer";
import Header from "../HomeComp/Header";
import { getMyOrderInfo, N_releaseOrRejectFunds } from "../redux/helpers/api_functions_new";
import createSocketClient from "../redux/helpers/socket";
import CreateOrder1 from "./CreateOrder1";
import CreateOrder2 from "./CreateOrder2";
import CreateOrder3 from "./CreateOrder3";
import { NotificationManager } from "react-notifications";
import toast from "react-hot-toast";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CreateOrderStep = () => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(1);
  const [orderInfo, setOrderInfo] = useState(null);
  const [orderResponse, setOrderResponse] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (timeLeft === 0) return;

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  const formattedTime = new Date(timeLeft * 1000).toISOString().substr(14, 5);

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  const [orderResp, setOrderReponse] = useState(null);
  const [data, setData] = useState();


  const ReleaseOrRejectFunds = (id, status, comment) => {
    N_releaseOrRejectFunds({
      order_id: id,
      status: status,
      comment: comment,
    }).then((res) => {
      if (res.status == 200) {
        // setActiveStep(2)
      } else {
        toast.error(res.message);
      }
    });
  };

  const updateTimerOfPost = (time, limit, status, id) => {
    const time1 = moment.utc(time, "YYYY-MM-DD HH:mm:ss");
    const time2 = moment.utc();

    const duration = moment.duration(time2.diff(time1));
    const minutes = duration.asMinutes();
    const seconds = duration.asSeconds();

    const timeout = limit - minutes;
    // console.log(timeout, limit, minutes, "<<-------------------------------------------------");

    if (timeout <= 0) {
      if (!['complete', 'reject', 'cancel', 'timeout', 'admin_complete'].includes(status)) {
        ReleaseOrRejectFunds(id, 'timeout', 'The order was automatically canceled because the time limit was exceeded.');
      }
    } else {

      setTimeLeft(timeout * 60)
      // console.log(`Time difference: ${minutes} minutes (${seconds} seconds)`);
    }
  }
  const fetchData = async () => {
    try {
      // setLoading(true)
      let response = await getMyOrderInfo(id);
      if (response) {
        // console.log(response, "<<----------response1111111");
        // console.log(response?.data?.orderInfo, "<<----------response?.data?.orderInfo");

        if (['complete', 'reject', 'cancel', 'timeout', 'admin_complete'].includes(response?.data?.orderInfo.status)) {
          // setActiveStep(2)
        } else if (response?.data?.orderInfo.status == 'payment_done') {
          // setActiveStep(1)
        }

        updateTimerOfPost(response?.data?.orderInfo.createdAt, response.data.postInfo?.payment_time_limit, response?.data?.orderInfo.status, response?.data?.orderInfo._id);
        setOrderReponse(response.data);
        setOrderInfo(response.data.postInfo);
        setData(response?.data?.orderInfo);
        setOrderResponse({
          data: {
            payment_method: response.data.paymentMethod,
            response: response.data.orderInfo,
          }
        });

      }
    } catch (error) {
      console.log("error --->>", error);
      // setLoading(false)
    }
  };

  useEffect(() => {

    console.log(orderInfo, orderResponse, "<<---- orderInfo, orderResponse")
  }, [orderInfo, orderResponse])

  const handleNext = () => {
    // setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const handleBack = () => {
    // setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const steps = [
    { label: "Order Created" },
    { label: "Pay The Seller" },
    { label: "Await Confirmation " },
  ];

  useEffect(() => {

    const socket = new createSocketClient("kujgwvfq-a-ghosttown-z-1fhhup0p6");
    socket.on("post_payment_confirmed_listen", async (res) => {
      if (res.order_id == id) {
        await fetchData();
      }
    });

    socket.on("p2p_raised_listen", (data) => {
      // console.log(data, "<<<<<------------------ User2 p2p_raised_listen");
      if (data?.order_id == id) {
        fetchData();
      }
    });


  }, []);

  return (
    <>
      <Header />

      <div className="container mb-4 pt-0" style={{ marginTop: "100px" }}>
        {/* <Stepper
          styleConfig={{
            activeBgColor: "#ffb703",
            activeTextColor: "#ffffff",
            inactiveBgColor: "#808080",
            inactiveTextColor: "#000000",
            completedBgColor: "#ffb703",
            completedTextColor: "#ffffff",
            size: "2em",
          }}
          activeStep={activeStep}
          className="w-100"
        >
          {steps.map((step, index) => (
            <Step key={index} label={step.label} />
          ))}
        </Stepper> */}

        {/* Step Content */}
        <div style={{ margin: "0px 0" }}>
          <div className="col-10 m-auto">

            <section>
              <h1 className="text-white mb-3"><IoMdArrowRoundBack className="me-2 mb-2 role-btn" onClick={() => history.push('/active-requests')} />  Order Created</h1>
              <div className="d-flex justify-content-between">
                <p className="mb-0">Order number: #{orderResponse?.data?.response?.order_number}</p>
                <p className="mb-0">
                  {
                    (orderResponse?.data?.response?.status == "payment_done" || orderResponse?.data?.response?.status == "active") && <>Order proceed within {formattedTime} Min</>
                  }
                </p>
              </div>
            </section>

            {activeStep === 0 && (
              <div>
                <CreateOrder1 handleNext={handleNext} orderInfo={orderInfo} orderResponse={orderResponse?.data} orderResp={orderResp} />
              </div>
            )}
            {activeStep === 1 && (
              <div>
                <CreateOrder2 fetchData={fetchData} data={data} orderInfo={orderInfo} orderResponse={orderResponse?.data} orderResp={orderResp} />
              </div>
            )}
            {activeStep === 2 && (
              <div>
                <CreateOrder3 fetchData={fetchData} orderInfo={data} orderResp={orderResp} />
              </div>
            )}

            {activeStep == 0 &&
              <div className="mt-4 text-center">

                <button
                  type="submit"
                  className="btn common_stepper_button d-none"
                  onClick={handleNext}
                  disabled={activeStep === steps.length - 1}
                >
                  {"Next"}
                </button>  <br />
                <button
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  className="btn mt-3 text-white common_add_post_stepper_disabled_button d-none"
                >
                  Back
                </button>

              </div>
            }</div>
        </div>


      </div>
      <Footer />
    </>
  );
};

export default CreateOrderStep;
