import React, { useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { MdOutlineArrowOutward } from "react-icons/md";

export default function StartTrade() {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const [showindex, setShowindex] = useState(null);

  const liveData = [
    {
      _id: "11",
      question: "How do I create an account on Bitcash?",
      answer: (
        <>
          <p>
            To create an account on bitcash, visit our website or download the App
            in the playstore and click on the <strong>Sign Up</strong> button.
            You'll be asked to provide your email address, set a password, and
            verify your email. Once that’s done, you can proceed with completing
            your profile and identity verification to start trading.
          </p>
        </>
      ),
    },
    {
      _id: "22",
      question: "What documents do I need to verify my account?",
      answer: (
        <>
          <p>
            To verify your account, you’ll need to submit proof of identity
            (such as a passport, driver’s license, or national ID) . These
            documents are necessary to comply with regulatory requirements and
            to ensure the safety of your account.
          </p>
        </>
      ),
    },
    {
      _id: "33",
      question: "Why is my account verification taking longer than expected?",
      answer: (
        <>
          <p>
            Account verification can take up to 24–48 hours due to high demand
            or the need for additional checks. If you’ve submitted the correct
            documents and are still experiencing delays, please reach out to our
            support team for an update.
          </p>
        </>
      ),
    },
    {
      _id: "44",
      question: "How can I reset my password if I forget it?",
      answer: (
        <>
          <p>
            If you’ve forgotten your password, click on the "Forgot Password"
            link on the login page. You’ll be asked to enter your registered
            email address. We’ll send you an email with a link to reset your
            password.
          </p>
        </>
      ),
    },
    {
      _id: "55",
      question: "Can I use my account in multiple countries?",
      answer: (
        <>
          <p>
            Yes, you can access your bitcash account from most countries. However,
            certain countries may have regulatory restrictions on trading
            cryptocurrencies or forex. Please check our Terms of Service or
            reach out to customer support for more information.
          </p>
        </>
      ),
    },
  ];

  AOS.init({ duration: 1000 });
  return (
    <>
      <div className="get_started_section">
        <div className="container">
          <div className="row">
            <div className="col-md-5 text-center text-md-start">
              <div className="para_heading text-white">
                Get Started in
                <br />
                <span className="text-gradient">3 Minutes</span>
              </div>
              <div className="sub_heading my-4">
                Experience the fastest, simplest, and most seamless <br />{" "}
                onboarding with bitcash.
              </div>
              <div className="mt-4 mb-3">
                {isLoggedIn ? (
                  <Link to="/guide">
                    <button className="login_btn">Learn More</button>
                  </Link>
                ) : (
                  <Link to="/login">
                    <button className="login_btn">Learn More</button>
                  </Link>
                )}
              </div>
            </div>
            <div className="col-md-7">
              {liveData?.map((item, index) => (
                <>
                  <div
                    className="accordion"
                    id="accordionExample"
                    key={item._id}
                  >
                    <div className={`accordionitem accordion-item`}>
                      <h2 className="accordion-header" id="acc1">
                        <button
                          className={`accordionbutton accordion-button
                              ${index === showindex ? "" : "collapsed"}
                              `}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#acc2${index}`}
                          aria-expanded="true"
                          aria-controls="acc2"
                          onClick={() => setShowindex(index)}
                        >
                          {item.question}
                        </button>
                      </h2>
                      <div
                        id={`acc2${index}`}
                        className={`accordion-collapse collapse ${
                          index === showindex ? "show" : null
                        }`}
                        aria-labelledby="acc1"
                        data-bs-parent="#accordionExample"
                      >
                        <div className={`accordionbody accordion-body`}>
                          <p className="p">{item.answer}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="crypto_trading_info_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="para_heading text-center text-white">
                Your One-Stop Solution for Crypto and Forex, <br />
                <span className="text-gradient">Only with bitcash</span>
              </div>
              <div className="sub_heading mt-4 text-center">
                Discover seamless trading across crypto and forex markets with a
                platform designed for traders of all kinds. A platform for
                Traders, Cooked by Traders.
              </div>
              <div className="crypto_trading_info_div">
                <div className="para_heading text-center">
                  Ready, set, trade! Start your <br />
                  Trading journey in minutes with bitcash!
                </div>
                <div>
                  {isLoggedIn ? (
                    <div className="d-flex justify-content-center">
                      <a href="/#trade_now">
                        <button className="crypto_trading_info_btn2">
                          Trade Now
                        </button>
                      </a>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                    
                      <NavLink to="/markets">
                        <button className="crypto_trading_info_btn2">
                          Started Trading
                        </button>
                      </NavLink>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
