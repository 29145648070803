import React, { useEffect, useMemo, useState } from "react";
import Header from "./HomeComp/Header";
import {
  N_checkKYCStatus,
  N_getProfile,
  N_updateName,
} from "./redux/helpers/api_functions_new";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  AiOutlineCloseCircle,
  AiOutlineEdit,
} from "react-icons/ai";
import { BsEye, BsEyeSlash, BsShieldCheck } from "react-icons/bs";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { Sparklines, SparklinesLine } from "react-sparklines";
import {
  checkName,
  getCoinRate,
  getRound,
} from "./redux/helpers/helper_functions";
import AssetSidebar from "./components/AssetSidebar";
import useHumanReadableNumber from "../hooks/useHumanReadableNumber";
import {
  N_DepositUpdate,
  N_transferAmonut,
} from "./redux/helpers/api_functions_new";
import { getUserBalance } from "./redux/actions/coinDBAction";
import toast from "react-hot-toast";
export default function UserProfile(props) {
  const formatNumber = useHumanReadableNumber();
  function formatNumberWithCommas(number, locales = "en-US", options = {}) {
    return new Intl.NumberFormat(locales, options).format(number);
  }
  const { isLoggedIn, user, profile } = useSelector(
    (state) => state.AuthReducer
  );
  const {
    wallet,
    coins,
    wallet_loading,
  } = useSelector((state) => state.coinDBReducer);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [hide, setHide] = useState(false);
  const [hideBalance, setHideBalance] = useState(false);
  const [hideBalance1, setHideBalance1] = useState(false);

  const [nodisplay, setDisplay] = useState(false);
  const [name, setName] = useState("");
  const [total_usdt, setTotalUsdt] = useState(0);
  const [isverified, setIsverified] = useState(0);
  const [wallet_details, setwalletDetails] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    N_checkKYCStatus(user?.params ? user.params.user_id : user.user_id).then(
      (d) => {
        if (d.status === 200) {
          setIsverified(d?.params.status);
        }
        setLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateProfile = (e) => {
    e.preventDefault();
    if (checkName(name)) {
      N_updateName(
        user?.params ? user.params.user_id : user.user_id,
        name
      ).then((res) => {
        if (res.status.matchedCount == 1) {
          setName("");
          setDisplay(!nodisplay);
          N_getProfile(user?.params ? user.params.user_id : user.user_id)
            .then((d) => {
              if (d.status === 200) {
                dispatch({ type: "PROFILE", data: d?.params.profile_info });
              }
            })
            .catch((e) => {
              console.log(e);
            });
          toast.success("Updated Successfully");
        } else {
          toast.error(res.message);
        }
      });
    } else {
      toast.error("Enter valid nick Name");
    }
  };

  useEffect(() => {
    if (!wallet_loading) {
      function getWallets() {
        setLoading(true);
        let total = 0;
        let final_wallet_data = wallet && Object.keys(wallet)?.map((key, i) => {
          const item = wallet[key];
          let wallet_type = item?.symbol.toUpperCase();
          // let usdt_val = item?.usdtBalance;
          let usdt_val = item?.total_combine_balance || 0;
          let usdt_locked_val = item?.locked * item?.usdtPrice;
          total = total + parseFloat(usdt_val);
          return {
            id: item?._id,
            icon: item?.icon,
            symbol: wallet_type,
            name: item?.name,
            status: item?.status,
            locked:
              Math.round(item?.locked * 10000) / 10000 != 0
                ? Math.round(
                  Math.abs(item?.locked ? item?.locked : 0) *
                  10000
                ) / 10000
                : Math.round(item?.locked * 100000000) / 100000000,
            address: item?.wallet_address,
            balance:
              Math.round(item?.balance * 10000) / 10000 != 0
                ? Math.round(item?.balance * 10000) / 10000
                : Math.round(item?.balance * 100000000) / 100000000,
            p2pbalance: item?.p2p_balance ? item?.p2p_balance : 0,
            p2p_locked: item?.p2p_locked ? item?.p2p_locked : 0,
            avl_balance:
              Math.round(item?.balance * 10000) / 10000 != 0
                ? Math.round(item?.balance * 10000) / 10000
                : Math.round(item?.balance * 100000000) / 100000000,
            usdt_total_balance: getRound(usdt_val),
            usdt_locked_balance: getRound(usdt_locked_val),
          };
        });
        setwalletDetails(final_wallet_data);
        setTotalUsdt(getRound(total) || 0);
        setLoading(false);
      }
      getWallets();
    }
  }, [wallet, coins, wallet_loading]);

  useEffect(() => {
    dispatch(getUserBalance(user?.params ? user.params.user_id : user.user_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    N_DepositUpdate(user?.params ? user.params.user_id : user.user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const walletsdata = useMemo(() => {
    return wallet_details &&
      wallet_details.filter((products) => {
        return products.symbol.includes(search);
      });
  }, [wallet_details, search]);
  // let coins1 = Object.values(coins);

  // // console.log(coins1)
  // const market_child =
  //   coins1 &&
  //   coins1.map((item, index) => {
  //     let usdtprize = coins1 && coins1.find((item) => item.symbol == "USDT");
  //     if (item.symbol != "INRX" && item.symbol != "USDT")
  //       return (
  //         <>
  //           <tr>
  //             <td>
  //               <div className="d-flex align-items-center">
  //                 <div>
  //                   <img
  //                     src={item.icon}
  //                     alt="coin symbole"
  //                     className="market_coin_img"
  //                     //style={{ width: "50px" }}
  //                   />
  //                 </div>
  //                 <div className="product_name">
  //                   <div className="mb-0 fw-bold">{item.symbol}</div>
  //                   <div className="text-muted fs-12"> {item.name}</div>
  //                 </div>{" "}
  //               </div>
  //             </td>
  //             <td className="text-end">
  //               ${getRound(item?.current_price / usdtprize?.current_price)}
  //             </td>
  //             <td className="text-end">
  //               <span
  //                 className={
  //                   item.direction === "up" ? "text-green " : "text-red"
  //                 }
  //               >
  //                 {getRound(item.price_change_percentage_1h)}%{" "}
  //                 {item.direction === "up" ? (
  //                   <BsArrowUpShort />
  //                 ) : (
  //                   <BsArrowDownShort />
  //                 )}
  //               </span>
  //             </td>
  //             <td className="text-end">{formatNumber(item.market_cap)}</td>
  //             <td class="text-end">
  //               <Sparklines>
  //                 <SparklinesLine color="#f705d9" data={item.h_trend} />
  //               </Sparklines>

  //               <Sparklines data={item.h_trend}>
  //                 <SparklinesLine
  //                   color={item.direction_inr === "up" ? "green " : "red"}
  //                 />
  //               </Sparklines>
  //             </td>
  //             <td class="text-end">
  //               <a
  //                 href={`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`}
  //                 className="anchor_link"
  //               >
  //                 Trade
  //               </a>
  //             </td>
  //           </tr>
  //         </>
  //       );
  //   });

  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        {/* <div class="s-layout__sidebar">
          <ProfileSidebar />
        </div> */}
        <div className="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container">
            <div className="account_page">
              <div className="col-lg-12">
                <div className="jambotron">
                  <div className="horizontal_display_with_justify">
                    <div className="flex_row">
                      {" "}
                      <div className="name_circle">
                        {profile?.name
                          ? profile?.name
                            ?.split("")
                            .slice(0, 2)
                            .join("")
                            .toUpperCase()
                          : profile?.email
                            ?.split("")
                            .slice(0, 2)
                            .join("")
                            .toUpperCase()}
                      </div>{" "}
                      <div className="big_heading text-capitalize me-2 text-truncate mob_mw_150">
                        {" "}
                        Hello,{" "}
                        {hide
                          ? "**********"
                          : profile?.name
                            ? profile.name
                            : profile?.email}{" "}
                      </div>
                      <div>
                        {nodisplay ? (
                          <>
                            <div className="d-flex gap-1">
                              <div className="">
                                <input
                                  className="form-control text-capitalize"
                                  type="text"
                                  value={name}
                                  onChange={(e) => {
                                    setName(
                                      e.target.value.replace("/a-zA-z*/i", "")
                                    );
                                  }}
                                />
                              </div>

                              <button
                                className="btn login_btn btn-sm fs-14"
                                onClick={(e) => {
                                  updateProfile(e);
                                }}
                              >
                                Update
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <span
                          className="cursor_pointer"
                          onClick={() => {
                            setDisplay(!nodisplay);
                            setName(profile?.name);
                          }}
                        // data-bs-toggle="modal"
                        // data-bs-target="#user_modal"
                        >
                          {!nodisplay ? (
                            <>
                              <AiOutlineEdit className="fs-3 ms-2" />
                            </>
                          ) : (
                            <>
                              <AiOutlineCloseCircle className="fs-2 ms-2 text-danger" />
                            </>
                          )}
                        </span>
                      </div>
                      <div>
                        <span
                          className="ms-3 cursor_pointer"
                          onClick={() => {
                            setHide(!hide);
                          }}
                        >
                          {hide ? (
                            <>
                              <BsEyeSlash className="fs-5 me-2" />

                            </>
                          ) : (
                            <>
                              <BsEye className="fs-5 me-2" />

                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="horizontal_display_without_justify mt-4">
                      <div className="dashboard_info">
                        <small className="text-lgray text-nowrap">UID</small>
                        <p>
                          {hide
                            ? "****"
                            : user?.params
                              ? user.params.user_id
                              : user.user_id}{" "}
                        </p>
                      </div>
                      <Link to="/AccountVerification">
                        <div className="dashboard_info">
                          <small className="text-lgray text-nowrap">
                            Identity Verification(KYC)
                          </small>
                          <p>
                            <span className="text-black">
                              {hide ? (
                                "***"
                              ) : (
                                <>
                                  {isverified == 0 ? (
                                    <span className="text-warning">
                                      Pending
                                    </span>
                                  ) : isverified == 2 ? (
                                    <span className="text-red">
                                      Rejected{" "}
                                      <i class="fa-solid fa-xmark text-red"></i>
                                    </span>
                                  ) : isverified == 1 ? (
                                    <span className="text-green">
                                      Verified{" "}
                                      <i class="fa-solid fa-check text-green"></i>
                                    </span>
                                  ) : isverified == -1 ? (
                                    <span className="text-warning">
                                      Request Pending{" "}
                                      <i class="fa-solid fa-hourglass-half text-yellow  spinner-loader mx-2"></i>
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </span>
                          </p>
                        </div>
                      </Link>

                      <div className="dashboard_info">
                        <small className="text-lgray text-nowrap">
                          Security
                        </small>
                        <p>
                          <BsShieldCheck className="text-black me-1" />
                          {hide
                            ? "***"
                            : profile.authenticator == 2
                              ? "High"
                              : profile.authenticator == 1
                                ? "Medium"
                                : "Low"}
                        </p>
                      </div>

                      <div className="dashboard_info">
                        <small className="text-lgray  text-nowrap">
                          {" "}
                          Time Zone{" "}
                        </small>
                        <p>
                          {hide
                            ? "***"
                            : Intl.DateTimeFormat().resolvedOptions().timeZone}
                          {/* <AiOutlineEdit /> */}
                        </p>
                      </div>

                      {/* <div className="dashboard_info">
                        <small className="text-lgray text-nowrap">
                          {" "}
                          Last Login{" "}
                        </small>
                        <p className="text-end">
                          {hide
                            ? "***"
                            : new Date(profile.last_login).toString()}
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-8 mt-4">
                  <div className="jambotron-outline">
                    <div className="horizontal_display_with_justify">
                      <div className="small_heading">
                        Total Assets{" "}
                        <span
                          onClick={() => {
                            setHideBalance1(!hideBalance1);
                          }}
                        >
                          {hideBalance1 ? (
                            <BsEyeSlash className="fs-5 ms-2" />
                          ) : (
                            <BsEye className="fs-5 ms-2" />
                          )}
                        </span>
                      </div>
                      <div>
                        <a href="" className="anchor_gray">
                          Assets Overview
                        </a>
                        <div className="verticle_line"></div>
                        <a href="" className="anchor_gray">
                          Assets Overview
                        </a>
                      </div>
                    </div>

                    <div className="horizontal_display_with_justify mt-4">
                      <div>
                        {" "}
                        <p className="assetsUi" style={{ fontSize: "32px" }}>
                          {!hideBalance1 ? formatNumberWithCommas(total_usdt) : "*****"}{" "}
                          USDT{" "}
                        </p>
                        {/* <p style={{ marginTop: "-16px" }}>
                          ≈
                          {!hideBalance1
                            ? `₹${formatNumberWithCommas(4586945)}`
                            : "*****"}{" "}
                        </p> */}
                      </div>
                      <div>
                        <Link
                          to="/AssetsDeposit"
                          className="btn login_btn  me-2"
                        >
                          Deposit
                        </Link>
                        <Link
                          to="/exchange/btc-usdt"
                          className="btn login_btn  me-2"
                        >
                          Buy Crypto
                        </Link>
                        <Link to="/AssetsWithdraw" className="btn login_btn ">
                          Withdraw
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* new code  */}
                  <div className="col-lg-12">
                    {/* <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                       
                        <span class="fs-16 text-muted">
                          <span> {total_usdt}</span>
                          <span> USDT</span>
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <button
                      className="btn btn-success"
                      onClick={(e)=>{
                        N_DepositUpdate(user?.params ? user.params.user_id : user.user_id)
                        .then(()=>{
                          setTimeout(()=>{
                            document.location.reload();
                          }, 100)
                        })
                      }}
                      >
                        Refresh Fund
                      </button>
                    </div>
                  </div> */}
                  </div>

                  <div className="">
                    <div className="row">
                      <div className="activity_panel mb-4">
                        <div className="search_input_grp">
                          <div class="input-group">
                            <span class="input-group-text">
                              <i class="fa-solid fa-magnifying-glass"></i>
                            </span>
                            <input
                              type="text"
                              placeholder="Search"
                              class="form-control text-start"
                              onChange={(e) => {
                                setSearch(e.target.value.toUpperCase());
                              }}
                            />
                          </div>
                        </div>

                        <div className="">
                          <div class="form-check bal_theme d-flex justify-content-center gap-2 align-items-center">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="inlineCheckbox1"
                              checked={hideBalance}
                              onClick={(e) => {
                                setHideBalance(!hideBalance);
                              }}
                            />
                            <label
                              class="form-check-label mt-1"
                              for="inlineCheckbox1"
                            >
                              Hide 0 balances
                            </label>
                          </div>
                          {/* <div class="form-check form-check-inline">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox2"
                            value="option2"
                          />
                          <label class="form-check-label" for="inlineCheckbox2">
                            KuCoin Earn assets
                          </label>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="row">
                    <div className="col-lg-12">
                      <div className="alert alert-success">
                        <div className="d-flex">
                          <div>
                            <i class="fa-regular fa-circle-check text-green me-2"></i>
                          </div>
                          <div className="text-lighter">
                            {" "}
                            To trade tokens, click Transfer to move the assets
                            from your Funding Account to your Trading Account.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="overflow-auto mt-3 history_table">
                    <div className="">
                      <table className="table ">
                        <thead >
                          <tr>
                            <th>Coin</th>
                            {/* <th>Total</th> */}
                            <th>Amount | Value</th>
                            {/* <th>In Orders</th> */}
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {walletsdata ? (
                            walletsdata.map((item) => {
                              if (!hideBalance || item?.balance > 0)
                                return (
                                  <tr>
                                    <td>
                                      <div class="d-flex align-items-center">
                                        <div>
                                          <img
                                            src={item.icon}
                                            alt="coin symbole"
                                            class="thumb_img"
                                          />
                                        </div>
                                        <div class="product_name">
                                          <div class="mb-0 ">
                                            {item.symbol}
                                          </div>
                                          <div class="text-muted fs-12">
                                            {" "}
                                            {item.name}
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </td>
                                    <td>
                                      <div class="mb-0 ">
                                        <p style={{ fontSize: "14px", marginBottom: '0px' }}>
                                          {(item.balance)}{" "}
                                          {/* {item.symbol} */}
                                        </p>
                                        <p style={{ fontSize: "12px", marginBottom: '0px' }}>
                                          {formatNumberWithCommas(item.usdt_total_balance)} USD
                                        </p>
                                      </div>
                                    </td>
                                    <td>
                                      <Link
                                        to="/AssetsDeposit"
                                        class="anchor_link text-green"
                                      >
                                        Deposit
                                      </Link>
                                      <div class="verticle_line"></div>
                                      <Link
                                        to="/AssetsWithdraw"
                                        class="anchor_link text-green"
                                      >
                                        Withdraw
                                      </Link>
                                      {/* <div class="verticle_line"></div>
                                      <a
                                        class="anchor_link text-green"
                                        data-bs-toggle="modal"
                                        data-bs-target="#transfer_modal"
                                      >
                                        Transfer
                                      </a> */}
                                      {/* <div class="verticle_line"></div>
                                      <Link
                                        to={`/exchange/${item.symbol}-USDT`}
                                        class="anchor_link text-green"
                                      >
                                        Trade
                                      </Link> */}
                                    </td>
                                  </tr>
                                );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={9}
                                className="text-center border-bottom-0 text-muted"
                              >
                                <img
                                  alt="no data found"
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                                <p>No records</p>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* end */}

                  {/* <div class="">
                    <div className="account_page_subheading mb-3">Market</div>

                    <div class="custom_pills">
                      <div class="nav nav-pills" id="nav-tab" role="tablist">
                        <div
                          class="nav-item nav-link active me-4"
                          href="#favourites"
                          data-toggle="tab"
                        >
                          <i className="fa-regular fa-star me-1"></i>
                          Favourites
                        </div>
                        <div
                          class="nav-item nav-link me-4"
                          href="#favourites"
                          data-toggle="tab"
                        ></div>
                        <div
                          class="nav-item nav-link "
                          href="#hot"
                          data-toggle="tab"
                        >
                          🔥 Hot
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-content">
                    <div className="tab-pane fade active show" id="favourites">
                      <div className="spacer">
                        <div className="overflow-auto">
                          <div className="table_scroll_div">
                            <table className="table global_table">
                              <thead>
                                <tr>
                                  <th>
                                    <div className="d-flex align-items-center">
                                      <span className="">Product</span>
                                      <div className="mx-1">
                                        <div className="position-relative">
                                          <FaSortUp className="sorting_btn" />
                                        </div>

                                        <div className="position-relative">
                                          <FaSortDown className="sorting_btn" />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="text-end">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <div className="">Price</div>
                                      <div className="mx-1">
                                        <div className="position-relative">
                                          <FaSortUp className="sorting_btn" />
                                        </div>

                                        <div className="position-relative">
                                          <FaSortDown className="sorting_btn" />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="text-end">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <span className="">24H Change</span>
                                      <div className="mx-1">
                                        <div className="position-relative">
                                          <FaSortUp className="sorting_btn" />
                                        </div>

                                        <div className="position-relative">
                                          <FaSortDown className="sorting_btn" />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="text-end">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <span className="">Market Cap</span>
                                      <div className="mx-1">
                                        <div className="position-relative">
                                          <FaSortUp className="sorting_btn" />
                                        </div>

                                        <div className="position-relative">
                                          <FaSortDown className="sorting_btn" />
                                        </div>
                                      </div>
                                    </div>
                                  </th>

                                  <th className="text-end">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <span className="">4H Trend</span>
                                    </div>
                                  </th>

                                  <th className="text-end">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <span className="">Action</span>
                                      <div className="mx-1">
                                        <div className="position-relative">
                                          <FaSortUp className="sorting_btn" />
                                        </div>

                                        <div className="position-relative">
                                          <FaSortDown className="sorting_btn" />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{market_child}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane fade" id="hot">
                      <div className="spacer">
                        <div className="overflow-auto">
                          <div className="table_scroll_div">
                            <table className="table global_table">
                              <thead>
                                <tr>
                                  <th>
                                    <div className="d-flex align-items-center">
                                      <span className="">Product</span>
                                      <div className="mx-1">
                                        <div className="position-relative">
                                          <FaSortUp className="sorting_btn" />
                                        </div>

                                        <div className="position-relative">
                                          <FaSortDown className="sorting_btn" />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="text-end">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <div className="">Price</div>
                                      <div className="mx-1">
                                        <div className="position-relative">
                                          <FaSortUp className="sorting_btn" />
                                        </div>

                                        <div className="position-relative">
                                          <FaSortDown className="sorting_btn" />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="text-end">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <span className="">24H Change</span>
                                      <div className="mx-1">
                                        <div className="position-relative">
                                          <FaSortUp className="sorting_btn" />
                                        </div>

                                        <div className="position-relative">
                                          <FaSortDown className="sorting_btn" />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                  <th className="text-end">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <span className="">Market Cap</span>
                                      <div className="mx-1">
                                        <div className="position-relative">
                                          <FaSortUp className="sorting_btn" />
                                        </div>

                                        <div className="position-relative">
                                          <FaSortDown className="sorting_btn" />
                                        </div>
                                      </div>
                                    </div>
                                  </th>

                                  <th className="text-end">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <span className="">4H Trend</span>
                                    </div>
                                  </th>

                                  <th className="text-end">
                                    <div className="d-flex align-items-center justify-content-end">
                                      <span className="">Action</span>
                                      <div className="mx-1">
                                        <div className="position-relative">
                                          <FaSortUp className="sorting_btn" />
                                        </div>

                                        <div className="position-relative">
                                          <FaSortDown className="sorting_btn" />
                                        </div>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{market_child}</tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

                <div className="col-lg-4 mt-4">
                  {/* <div className="jambotron-outline">
                    <div className="d-flex justify-content-between mb-4">
                      <h5>
                        <img
                          src="https://assets.staticimg.com/ucenter-web/1.0.12/media/8705ed66120a3f345ebd.png"
                          width="30px"
                          alt=""
                        />
                        VIP 0
                      </h5>
                      <div>
                        {" "}
                        <a href="#" className="anchor_gray">
                          View More{" "}
                        </a>{" "}
                      </div>
                    </div>

                    <div className="horizontal_display_with_justify">

                      <div>
                        <span className="small_heading">Future Fee Rate</span>
                        <div>
                          <span>0.08%</span>{" "}
                          <div className="verticle_line"></div>
                          <span>0.08%</span>
                        </div>
                      </div>
                    </div>

                    <div class="progress mb-2 mt-2" style={{ height: "6px" }}>
                      <div
                        class="progress-bar"
                        role="progressbar"
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <span class="sr-only">70% Complete</span>
                      </div>
                    </div>

                  </div> */}

                  {/* <div className="jambotron mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h5>Invite Friends for Rewards</h5>
                        <div className="text-lgray">
                          Invite friends and get 20% commissions.
                        </div>

                        <div className="mt-3">
                          <a href="#" className="anchor_link">
                            Apply Now
                            <i className="fa-solid fa-arrow-right-long ms-1"></i>{" "}
                          </a>{" "}
                        </div>
                      </div>
                      <div>
                        <img src="/img/steps.png" width="100px" alt="rewards" />
                      </div>
                    </div>
                  </div> */}

                  <div className="jambotron-outline">
                    <div className="d-flex justify-content-between mb-4">
                      <h5>Information</h5>
                      <div>
                        {" "}
                        <a href="#" className="anchor_gray">
                          View More{" "}
                        </a>{" "}
                      </div>
                    </div>

                    <div className="row">
                      <div className="rounded_box">
                        <h6 className="mb-0">
                          Bitcoin P2P: Get 10% Cashback on Your First USDT
                          Purchase in RUB！
                        </h6>
                        <small className="text-lgray">
                          06/21/2023 07:26:19
                        </small>
                      </div>

                      <div className="rounded_box">
                        <h6 className="mb-0">
                          Announcement of IMVU (VCORE) Token Sale on bitcash
                          Spotlight
                        </h6>
                        <small className="text-lgray">
                          06/21/2023 07:26:19
                        </small>
                      </div>

                      <div className="rounded_box">
                        <h6 className="mb-0">
                          Join the Spotlight Fiesta: Grab Your Extra VCORE
                          Tickets Now!
                        </h6>
                        <small className="text-lgray">
                          06/15/2023 15:30:01
                        </small>
                      </div>

                      <div className="rounded_box">
                        <h6 className="mb-0">
                          Floki Promotion Campaigns, $14,000 in FLOKI To Share!
                        </h6>
                        <small className="text-lgray">
                          06/14/2023 16:46:13
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="jambotron-outline">
                    <h5 className="mb-4">Start your crypto journey today!</h5>
                    <div className="d-flex gap-1">
                      {" "}
                      {!isLoggedIn ? (
                        <>
                          <a className=" login_btn px-3 " to="/create">
                            Sign Up Now
                          </a>
                        </>
                      ) : (
                        <a
                          className=" d-flex align-items-center login_btn px-3 "
                          href="/exchange/btc-usdt"
                        >
                          Trade Now
                        </a>
                      )}
                      <a href="" className="btn btn-outline-secondary  px-4 d-flex justify-content-center align-items-center ms-2">
                        <span>Scan to Download</span> <img src="/img/qrcode.svg" alt="" className="ms-2" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
