import {
  SET_USER_BALANCE,
  SET_USER_ORDER_PENDING,
  SET_USER_ORDER_CLOSE,
  SET_USER_FAV_PAIRING,
  SET_USER_FAV_CURRENCY_RATE,
  SET_USER_FAV_CURRENCY,
  SWITCH_THEME,
  GET_COIN_DATA,
  GET_CURRENCY_DATA
} from "../constant";
import { N_completeOrder, N_get_wallet, N_OrdersHistory, N_pendingOrder } from "../helpers/api_functions_new";



export function getUserBalance(user_id) {
  // console.log("start wallet fetching..........")
  return (dispatch) => {
    N_get_wallet(user_id).then((d) => {
      // console.log("response wallet fetching..........")
      dispatch({
        type: SET_USER_BALANCE,
        data: d?.params?.wallets && Object?.values(d?.params?.wallets),
        wallet_loading: false,
      });
    });
  };
}

export function getUserOrder(user_id) {
  return (dispatch) => {
    N_pendingOrder(user_id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: SET_USER_ORDER_PENDING,
            data: res.params.trade_history.pending,
            pending_order_loading: false,
          });
        } else {
          console.log("error to fetch open and close orders: ", res);
        }
      })
      .catch((e) => console.log(e));
      N_completeOrder(user_id)
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: SET_USER_ORDER_CLOSE,
            data: res.params.trade_history.compleated,
            close_order_loading: false,
          });
        } else {
          console.log("error to fetch open and close orders: ", res);
        }
      })
      .catch((e) => console.log(e));
  };
}


export function toggleFav(token, pair) {
  return (dispatch) => {
    
  };
}

export function viewFav(token) {
  return (dispatch) => {
   
  };
}

export function setFavCurrency(currency, cb) {
  return (dispatch) => {
    dispatch({ type: SET_USER_FAV_CURRENCY, data: currency });
    cb();
  };
}

export function setFavCurrencyRate(rate, cb) {
  return (dispatch) => {
    dispatch({ type: SET_USER_FAV_CURRENCY_RATE, data: rate });
    cb();
  };
}
export function switchTheme(theme_name) {
  return (dispatch) => {
    dispatch({ type: SWITCH_THEME, data: theme_name });
  };
}
