import { initializeApp } from "firebase/app";

import { getMessaging } from "firebase/messaging";


//Firebase Config values imported from .env file
const firebaseConfig = {
    apiKey: "AIzaSyCbBU2rN7-wxmj4uAJ2s8DNU-oYgrXGVAs",
    authDomain: "bitcash-1584b.firebaseapp.com",
    projectId: "bitcash-1584b",
    storageBucket: "bitcash-1584b.firebasestorage.app",
    messagingSenderId: "333206197271",
    appId: "1:333206197271:web:b850dbea6bf3445db57852",
    measurementId: "G-N44ZGTH69S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
export const VAPID_KEY = 'BP7H1dj5IPxNhHtbeOQk3FF_z0HKbU3eEYfpYrgbvFrtaI49QlM5cJ6WqlRPhCX9eGAJVPxXfz66jWpTL45hve0';