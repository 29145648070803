import React from "react";
import "./shimmer.css";

const BalanceShimmer = () => {
  return (
    <div id="container">
      {/* <div id="square" class="shimmer"></div> */}
      <div id="content">
        <div id="content-title" class="shimmer"></div>
        {/* <div id="content-desc">
          <div class="line shimmer"></div>
          <div class="line shimmer"></div>
          <div class="line shimmer"></div>
          <div class="line shimmer"></div>
        </div> */}
      </div>
    </div>
  );
};

export default BalanceShimmer;
