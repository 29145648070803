import React from "react";
import "./Modal.css"; // For optional styling
import { BiSearch } from "react-icons/bi";

const SelectPayment = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null; 

  return (
    <div
      className={`modal fade ${isOpen ? "show d-block" : "d-none"}`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }} // Dim overlay
      onClick={onClose} // Close modal when clicking outside
    >
      <div
        className="modal-dialog"
        role="document"
        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside modal
      >
        <div className="modal-content">
          {/* Modal Header */}
          <div className="modal-header">
            <h5 className="modal-title">{title}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>

          {/* Modal Body */}
          <div className="modal-body">
            <section className="d-flex justify-content-between align-items-center border rounded py-1 px-2">
              <BiSearch />
              <input
                type="text"
                placeholder="Enter Payment Method"
                className="form-control1"
              />
            </section>
            <p>Select Payment Method (Up to 5 methods)</p>
            <div></div>
            <div className="d-flex align-items-center justify-content-between border rounded-3 p-2">
              <div className="d-flex gap-2 align-items-center ">
                <div
                  className="rounded-1"
                  style={{ width: "4px", height: "14px", background: "white" }}
                ></div>
                <p className="text-sm">UPI-PAN</p>
              </div>

              <input type="checkbox" />
            </div>
          </div>

          {/* Modal Footer */}
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectPayment;
