import React, { useState } from "react";
import "../modals/p2p.css";
import { BiAddToQueue, BiBook } from "react-icons/bi";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import SelectPayment from "../modals/SelectPayment";
import Select, { components } from "react-select";
import { NumberToFixed } from "../../utils/common";

const P2PPostAd2 = ({
  activeStep,
  setActiveStep,
  data,
  setData,
  paymentMethods,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const options = paymentMethods.map((item) => ({
    value: item._id,
    label: `${item.name} - ${item.account_number}`,
  }));

  const time_limit_options = [
    {
      value: "15",
      label: "15 Minutes",
    },
    {
      value: "30",
      label: "30 Minutes",
    },
    {
      value: "45",
      label: "45 Minutes",
    },
    {
      value: "60",
      label: "60 Minutes",
    },
  ];

  const CustomOption = (props) => {
    return <components.Option {...props}>{props.data.label}</components.Option>;
  };

  const Schema = Yup.object().shape({
    total_assets: Yup.number()
      .required("Please Enter Total Assets")
      .min(0.1, `You should have at least 0.1 Asset`),
    min_order_limit: Yup.number()
      .required("Please Enter Min. Order Limit")
      // .min(1, `Minimum limit must be at least 1`)
      .when("total_assets", (total_assets, schema) => {
        return schema.max(
          total_assets,
          `Minimum limit must not exceed ${total_assets} ${data?.asset_name}`
        );
      }),
    max_order_limit: Yup.number()
      .required("Please Enter Max. Order Limit")
      .when("min_order_limit", (min_order_limit, schema) => {
        return schema.min(
          min_order_limit,
          `Maximum limit must be equal or greater ${min_order_limit} ${data?.asset_name}`
        );
      })
      .when("total_assets", (total_assets, schema) => {
        return schema.max(
          total_assets,
          `Maximum limit must not exceed ${total_assets} ${data?.asset_name}`
        );
      }),
    payment_time_limit: Yup.string().required("Select Payment Time Limit"),
    payment_ids: Yup.array()
      .min(1, "Select at least 1 payment method")
      .max(5, "You can select up to 5 payment methods")
      .required("This field is required"),
  });
  const [step2Data, setStep2Data] = useState({
    total_assets: data?.total_assets,
    min_order_limit: data?.min_order_limit,
    max_order_limit: data?.max_order_limit,
    payment_time_limit: data?.payment_time_limit,
    payment_ids: data?.payment_ids,
  });

  const onSubmit = async (values, onSubmitProps) => {
    setData((prev) => ({
      ...prev,
      ...values,
    }));
    setActiveStep(2);
  };

  const handleBack = (values) => {
    setActiveStep(0);
    setData((prev) => ({
      ...prev,
      ...values,
    }));
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      border: "1px solid #ced4da",
      border: "0.5px solid #ffffff7e",
      borderRadius: "12px",
      paddingLeft: "20px",
      height: "46px",
      overflowY:'scroll',
      boxShadow: "none",
      // '&:hover': {
      //   border: '1px solid #ffb703',
      // },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: "#1f1f1f",
      border: "0.5px solid #ffffff7e",
      overflow: "hidden",
      borderRadius: "12px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#5253547a"
        : state.isFocused
        ? "#5253547a"
        : "#1f1f1f",
      color: state.isSelected ? "#fff" : "#fff",
      "&:active": {
        backgroundColor: "#007bff42",
        color: "black",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "#ccc" : "#fff",
      // textAlign:'center'
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#ccc", 
      borderRadius: "8px",
      // maxWidth: "100px", 
      // overflow: "hidden",
      // whiteSpace: "nowrap",
      // textOverflow: "ellipsis",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "#000", // Change text color if needed
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#ff0000", // Change remove icon color if needed
      ':hover': {
        color: '#ff0000',
      },
    }),
  };
  return (
    <>
      <div className="add_post_step_2">
        <Formik
          initialValues={{ ...step2Data }}
          enableReinitialize
          validationSchema={Schema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, values, setFieldValue, setFieldTouched }) => (
            <Form className="mt-4">
              <div className="row mb-4">
                <div className="col-12">
                  <label>Total Assets</label>
                  <div className="input-group">
                    <Field
                      type="number"
                      name="total_assets"
                      id="total_assets"
                      placeholder="Enter Total Assets"
                      className="form-control"
                      autoComplete="off"
                      onChange={(e) => {
                        setFieldTouched("total_assets", true);
                        setFieldValue("total_assets", e.target.value);
                      }}
                    />
                    <span className="input-group-text">
                      {" "}
                      {data?.asset_name}
                    </span>
                  </div>
                  {errors.total_assets && touched.total_assets ? (
                    <small className="text-danger">{errors.total_assets}</small>
                  ) : null}
                  <p className="mb-0">
                    {" "}
                    {NumberToFixed(
                      data?.selling_price * values?.total_assets
                    )}{" "}
                    {data?.fiat_name}
                  </p>
                </div>
              </div>
              <div className="row mb-4">
                <label>Order Limit</label>
                <div className="col">
                  <div className="input-group">
                    <Field
                      type="number"
                      name="min_order_limit"
                      id="min_order_limit"
                      placeholder="Enter Minimum Assets"
                      className="form-control"
                      autoComplete="off"
                      onChange={(e) => {
                        setFieldTouched("min_order_limit", true);
                        setFieldValue("min_order_limit", e?.target?.value);
                      }}
                    />
                    <span className="input-group-text">
                      {" "}
                      {data?.asset_name}
                    </span>
                  </div>
                  {errors.min_order_limit && touched.min_order_limit ? (
                    <small className="text-danger">
                      {errors.min_order_limit}
                    </small>
                  ) : null}
                </div>
                <div className="col-1">
                  <div className="text-center">~</div>
                </div>
                <div className="col">
                  <div className="input-group">
                    <Field
                      type="number"
                      name="max_order_limit"
                      id="max_order_limit"
                      placeholder="Enter Maximum Assets"
                      className="form-control"
                      autoComplete="off"
                      onChange={(e) => {
                        setFieldTouched("max_order_limit", true);
                        setFieldValue("max_order_limit", e?.target?.value);
                      }}
                    />
                    <span className="input-group-text">
                      {" "}
                      {data?.asset_name}
                    </span>
                  </div>
                  {errors.max_order_limit && touched.max_order_limit ? (
                    <small className="text-danger">
                      {errors.max_order_limit}
                    </small>
                  ) : null}
                </div>
                <p className="mb-0">
                  {" "}
                  {NumberToFixed(
                    values?.min_order_limit * data?.selling_price
                  )}{" "}
                  {data?.fiat_name} -{" "}
                  {NumberToFixed(values?.max_order_limit * data?.selling_price)}{" "}
                  {data?.fiat_name}
                </p>
              </div>

              <div className="row mb-4">
                <div className="col">
                  <label>Payment Methods</label>
                  <Field name="payment_ids">
                    {({ field }) => (
                      <Select
                        {...field}
                        onChange={(selectedOptions) => {
                          const values = selectedOptions.map(
                            (option) => option.value
                          );
                          setFieldValue("payment_ids", values);
                        }}
                        isMulti
                        placeholder="Select up to 5 methods"
                        options={options}
                        value={options.filter((option) =>
                          values?.payment_ids?.includes(option.value)
                        )}
                        components={{ Option: CustomOption }}
                        styles={customStyles}
                      />
                    )}
                  </Field>
                  {errors.payment_ids && touched.payment_ids ? (
                    <small className="text-danger">{errors.payment_ids}</small>
                  ) : null}
                  <p className="mb-0">Select Upto 5 Methods</p>
                </div>
                <div className="col-1"></div>
                <div className="col">
                  <label>Payment Time Limit</label>
                  <Field name="payment_time_limit">
                    {({ field }) => (
                      <Select
                        {...field}
                        placeholder="Select..."
                        options={time_limit_options}
                        onChange={(e) =>
                          setFieldValue("payment_time_limit", e.value)
                        }
                        value={time_limit_options.filter((option) =>
                          values?.payment_time_limit?.includes(option.value)
                        )}
                        components={{ Option: CustomOption }}
                        styles={customStyles}
                      />
                    )}
                  </Field>
                  {errors.payment_time_limit && touched.payment_time_limit ? (
                    <small className="text-danger">
                      {errors.payment_time_limit}
                    </small>
                  ) : null}
                </div>
              </div>

              <div className="mt-4 text-center">
                <button
                  type="submit"
                  className="btn common_stepper_button"
                >
                  {"Next"}
                </button>
                <br />
                <button
                  className="btn mt-3 text-white common_add_post_stepper_disabled_button"
                  onClick={() => handleBack(values)}
                >
                  Back
                </button>
              </div>
            </Form>
          )}
        </Formik>

        {/* Help and Support */}
        {/* <label className="d-flex gap-2 px-3 py-2">
          <BiBook size={25} />
          <span>Help & Support</span>
        </label> */}
      </div>
      <SelectPayment
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Select payment method"
      />
    </>
  );
};

export default P2PPostAd2;
