import React, { useState, useEffect } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import FullLoader from "./components/FullLoader";
import { useSelector } from "react-redux";
import "./login.css";
import { Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import {
  N_checkMobileStatus,
  N_sendOTPMobile,
  N_verifyOTPMobile
} from "./redux/helpers/api_functions_new";
import toast from "react-hot-toast";
export default function MobileVerification(props) {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { user } = useSelector((state) => state.AuthReducer);
  const [mobile, setMobile] = useState();
  const [old_mobile, setOldMobile] = useState();
  const [action, setAction] = useState();
  const [disable, setDisable] = useState(true);
  const [disableSend, setDisableSend] = React.useState(false);
  const [fullScreenLoader, setFullScreenLoader] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [otploader, setOtpLoader] = useState(false);
  const [isOtpSend, setOtpSend] = useState(false);
  const [otp, setOTP] = useState();
  const [ctime, setctime] = useState("01:00");

  // if (!isLoggedIn) props.history?.replace("/login");
  let newparams = new URLSearchParams(window.location.search);
  // console.log("props: ",newparams.get('action'), newparams.get('mob'), props)
  useEffect(() => {
    if (newparams.get("mob")) {
      setOldMobile(newparams.get("mob"));
    }
    if (newparams.get("action")) {
      setAction(newparams.get("action"));
    }
    if (action != "edit") {
      N_checkMobileStatus(user?.params ? user.params.user_id : user.user_id)
        .then((d) => {
          if (d.status === 200 && d.params.is_mobile === 1) {
            // props.history?.replace("/exchange/btc-usdt");
          }
          setFullScreenLoader(false);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, []);
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      setDisableSend(false);
      return <></>;
    } else {
      // Render a countdown
      setDisableSend(true);
      return (
        <span className="text-success text-right h5">
          0{minutes}:{seconds}
        </span>
      );
    }
  };

  const otpCountdown = () => {
    let duration = 60;
    // const display = document.getElementById("#timer");
    let timer = duration,
      minutes,
      seconds;
    const tint = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setctime(minutes + ":" + seconds);

      if (--timer < 0) {
        // timer = duration;
        clearInterval(tint);
        setOtpSend(false);
      }
    }, 1000);
  };

  if (fullScreenLoader) return <FullLoader />;
  else
    return (
      <>
        <Header {...props} />

        {/* <ProfileSidebar {...props} /> */}

        <div className="main-content">
          {/* <div className="mt-3">
                <h5 className="mb-3">Edit Personal Info</h5>
              </div> */}
          <div className="row mt-3">
            <div className="col-lg-12 m-auto">
              <div class="profile-card">
                <div class="image">
                  <img
                    src="/img/account_profile.png"
                    style={{ width: "130px", height: "130px" }}
                    class="profile-img"
                  />
                </div>

                <h5 className="">
                  {action && action == "edit" ? "Edit " : "Verify "} Your Mobile
                  Number
                </h5>

                <div className="col-lg-4 col-12 col-md-12 col-sm-12">
                  <form
                    className=""
                    method="post"
                    onSubmit={(e) => e.preventDefault()}
                    id="loginForm"
                    autoComplete="off"
                  >
                    <div></div>
                    <div className={`form-group`}>
                      <small id="msg" style={{ fontSize: "15px" }}>
                        Error Message
                      </small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="user_mobile" className="text-red">
                        {action && action == "edit" ? "Old" : ""} Mobile Number:{" "}
                        {action && action == "edit" && old_mobile
                          ? old_mobile
                          : ""}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          id="user_mobile"
                          value={mobile}
                          maxLength={10}
                          onChange={(e) =>
                            setMobile(
                              e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1")
                            )
                          }
                          placeholder="Enter your 10 digit mobile number"
                        />
                        <span className="input-group-text bg-secondary text-white">
                          {!isOtpSend ? (
                            <button
                              className="border-0 bg-transparent text-white"
                              type="button"
                              disabled={disableSend}
                              onClick={() => {
                                if (mobile?.length === 10) {
                                  setOtpLoader(true);
                                  N_sendOTPMobile(
                                    user?.params
                                      ? user.params.user_id
                                      : user.user_id,
                                    mobile
                                  ).then((d) => {
                                    if (d.status == 200) {
                                      toast.success(d.message);
                                      setDisable(false);
                                      setOtpSend(true);
                                      otpCountdown();
                                    } else {
                                      toast.error(d.message);
                                    }
                                    setOtpLoader(false);
                                  });
                                } else {
                                  toast.error(
                                    "Please Enter valid Mobile number"
                                  );
                                }
                              }}
                              id="button-addon2"
                            >
                              {otploader ? (
                                <span className="loading-icon fas fa-spinner fa-spin me-2"></span>
                              ) : (
                                "Send"
                              )}
                            </button>
                          ) : (
                            <button
                              type="button"
                              id="button-addon2"
                              className="btn btn-outline-info btn-outline-light text-info"
                            >
                              {isOtpSend ? ctime : null}
                            </button>
                          )}
                        </span>
                      </div>
                    </div>

                    <div className={`form-group g_color`}>
                      <label htmlFor="user_otp">OTP</label>
                      <input
                        type="text"
                        name="user_otp"
                        id="otp"
                        className="form-control"
                        maxLength={6}
                        disabled={disable}
                        value={otp}
                        onChange={(e) =>
                          setOTP(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          )
                        }
                        required=""
                        placeholder="Enter 6 digit otp"
                      />
                      <i className="fas fa-check-circle"></i>
                      <i className="fas fa-exclamation-circle"></i>
                      <small></small>
                    </div>
                    <div className="d-grid">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() => {
                          if (!isNaN(otp) && otp?.length === 6) {
                            setLoading(true);
                            N_verifyOTPMobile(
                              user?.params ? user.params.user_id : user.user_id,
                              otp
                            ).then((d) => {
                              setLoading(false);
                              if (d?.status == 200) {
                                props.history.push("/welcome");
                              } else {
                                toast.error(d.message);
                              }
                            });
                          } else {
                            toast.error("Invalid OTP !");
                          }
                        }}
                      >
                        {loading ? (
                          <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                        ) : null}
                        <span id="reg">Verify OTP</span>
                      </button>
                    </div>
                  </form>

                  <div className="text-center py-2">
                    <Link to="/profile" className="text-muted">
                      {" "}
                      Back to Profile
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
