import React, { useEffect, useState } from "react";
import "./refer.css";
import Header from "../HomeComp/Header";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Footer from "../HomeComp/Footer";
import { BiCopy } from "react-icons/bi";
import ReferrelData from "./ReferrelData";
import AllReferrals from "./AllReferrals";
import ReferralCodes from "./ReferralCodes";
import ReferCommission from "./ReferCommission";
import swal from "sweetalert";
import { Referral_List } from "../redux/helpers/api_functions_new";
import toast from "react-hot-toast";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { FaFacebook, FaTwitter } from "react-icons/fa";
import ReferralModal from "./ShareModal";

const ReferPage = () => {
  const [activeEye, setActiveEye] = useState(false);
  const [referData, setReferData] = useState([]);
  const history = useHistory();
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const updateState = (key, value) => {
    setPagination((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handlePageChange = (e, value) => {
    updateState("page", value);
  };
  const totalPages = referData?.pagination?.total_pages;

  // console.log(referData, "referData");

  const getReferData = async () => {
    try {
      const res = await Referral_List(pagination);
      // console.log(res?.data, "nguifduifui");
      if (res?.status === 200) {
        setReferData(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getReferData();
    }, 500);

    return () => clearTimeout(timer);
  }, [pagination.page, pagination.limit]);

  const eyeView = () => {
    setActiveEye(!activeEye);
  };

  const copyText = (text) => {
    window.navigator.clipboard.writeText(text);
    toast.success("Copied");
  };

  const shareUrl = `${window.location.host}/create?refer-by=${referData?.self_ref_code}`;

  return (
    <>
      <Header />
      <div
        className="container d-flex flex-column gap-5 refer-page "
        style={{ marginTop: "100px", marginBottom: "20px" }}
      >
        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center col-12">
          <section className="col-lg-6">
            <h1 className="heading-txt">Refer Friends, Earn Crypto Together</h1>
            <p className="heading-txt1">
              Share commissions in spot and futures trading
            </p>
            <button
              onClick={() => history.push("/bitcash-referral-program")}
              className="btn login_btn"
            >
              Know More
            </button>
          </section>
          <div className="refer-box col-lg-5 d-flex flex-column gap-3">
            <section className="d-flex justify-content-between align-items-center">
              <p className="fs-3 text-white">
                Default Referral{" "}
                <span onClick={eyeView}>
                  {activeEye ? (
                    <BsEye className="ms-2" />
                  ) : (
                    <BsEyeSlash className="ms-2" />
                  )}
                </span>
              </p>
            </section>
            <section className="d-flex flex-column">
              <p className=" text-white">Commission per Refer</p>
              <h1>
                {activeEye ? `${referData?.commissionRatio} Pts` : "****"}
              </h1>
            </section>
            <section className="d-flex justify-content-between align-items-center border rounded-2 p-3 text-white">
              <p className="text-white">Referral Code</p>
              <p className="text-white">
                {(referData && referData?.self_ref_code) || "- - - -"}{" "}
                <span>
                  <BiCopy
                    onClick={() => {
                      copyText(referData?.self_ref_code);
                    }}
                    className="ms-2 role-btn"
                  />
                </span>
              </p>
            </section>
            <section className="d-flex justify-content-between align-items-center border rounded-2 p-3 ">
              <p className="text-white">Referral Link</p>
              <section className="text-end">
                <p className="text-white">
                  {`https://${window.location.host}/create?refer-by=${referData?.self_ref_code}`}
                  <span>
                    <BiCopy
                      onClick={() =>
                        copyText(
                          `https://${window.location.host}/create?refer-by=${referData?.self_ref_code}`
                        )
                      }
                      className="ms-2 role-btn"
                    />
                  </span>
                </p>
              </section>
            </section>
            <div className="d-flex justify-content-center">
              <button
                className="referBtn btn col-12"
                onClick={() => setIsModalOpen(true)}
              >
                Refer Now
              </button>
            </div>
          </div>
        </div>
        <div className="">
          {/* <div
            className="d-flex gap-2 nav refer-page nav-tabs mb-3"
            id="nav-tab"
            role="tablist"
          >
            <button
              className="nav-link active"
              id="data-tab"
              data-bs-toggle="tab"
              data-bs-target="#data"
              type="button"
              role="tab"
            >
              Data
            </button>
            <button
              className="nav-link"
              id="referals-tab"
              data-bs-toggle="tab"
              data-bs-target="#referals"
              type="button"
              role="tab"
            >
              Referrals
            </button> */}
          {/* <button
              className="nav-link"
              id="commissions-tab"
              data-bs-toggle="tab"
              data-bs-target="#commissions"
              type="button"
              role="tab"
            >
              Commissions
            </button>
            <button
              className="nav-link"
              id="referral-codes-tab"
              data-bs-toggle="tab"
              data-bs-target="#referral-codes"
              type="button"
              role="tab"
            >
              Referral Codes
            </button> */}
          {/* </div> */}

          {/* tab content */}
          <div className="tab-content">
            {/* <div
              className="tab-pane fade show active "
              id="data"
              role="tabpanel"
              aria-labelledby="data-tab"
            >
              <ReferrelData referData={referData} />
            </div> */}
            <div
            // className="tab-pane fade"
            // id="referals"
            // role="tabpanel"
            // aria-labelledby="referals-tab"
            >
              <AllReferrals
                referData={referData}
                handlePageChange={handlePageChange}
                totalPages={totalPages}
              />
            </div>
            {/* <div
              className="tab-pane fade text-white"
              id="commissions"
              role="tabpanel"
              aria-labelledby="commissions-tab"
            >
              <ReferCommission referData={referData} />
            </div>
            <div
              className="tab-pane fade text-white"
              id="referral-codes"
              role="tabpanel"
              aria-labelledby="referral-codes-tab"
            >
              <ReferralCodes referData={referData} />
            </div> */}
          </div>
        </div>
        <ReferralModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          shareUrl={shareUrl}
          copyText={copyText}
        />
      </div>
      <Footer />
    </>
  );
};

export default ReferPage;
