import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Button,
    Box,
    Typography
} from '@mui/material';
import { IoCloseSharp as CloseIcon } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { BiEdit } from 'react-icons/bi';
// import ImageIcon from '@mui/icons-material/Image';

const AvatarChangeModal = ({ open, onClose, onSave }) => {
    const { profile, switch_theme } = useSelector((state) => state.AuthReducer);
    const [selectedAvatar, setSelectedAvatar] = useState({
        src: profile?.profile_image || "/img/account_profile.png",
        image: null
    });
    const [loading, setLoading] = useState(false);
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const base64String = e.target.result;
                setSelectedAvatar({
                    src: base64String,
                    image: file
                })
            };
            reader.readAsDataURL(file);
        }
    };
    return (
        <Dialog
            open={open}
            onClose={() => {
                setSelectedAvatar({
                    src: profile?.profile_image || "/img/account_profile.png",
                    image: null
                })
                onClose();
            }}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 3,
                    p: 2,
                    bgcolor: switch_theme === "dark" ? "#0000007d" : "",
                    border: '0.5px solid gray',
                    maxWidth: 500
                }
            }}
        >
            <DialogTitle sx={{ p: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" component="div" className='text-white'>
                    Change Avatar
                </Typography>
                <IconButton onClick={() => {
                    setSelectedAvatar({
                        src: profile?.profile_image || "/img/account_profile.png",
                        image: null
                    })
                    onClose();
                }} size="medium">
                    <CloseIcon color={switch_theme === 'dark' ? '#fff' : "#000"} />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{ p: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
                    {/* Current avatar preview */}
                    <Box
                        sx={{
                            width: 200,
                            height: 200,
                            borderRadius: '50%',
                            overflow: 'hidden',
                            bgcolor: '#fae7da',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative', // Required for absolute positioning of overlay
                            cursor: 'pointer',
                            '&:hover .edit-overlay': {
                                opacity: 1, // Show overlay on hover
                            }
                        }}
                    >
                        <label htmlFor='pic1' style={{ position: 'relative' }}>
                            <img
                                style={{
                                    width: 190,
                                    height: 190,
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: '2px 4px 8px rgba(0,0,0,0.2)',
                                   
                                }}
                                className="hover-image"
                                src={selectedAvatar?.src}
                                alt="account_profile"
                            />
                            {/* Edit Text Overlay */}
                            <Box
                                className="edit-overlay"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: '-50px',
                                    width: '100%',
                                    textAlign: 'center',
                                    color: 'black',
                                    padding: '8px 0',
                                    fontSize: '26px',
                                    borderRadius: '0 0 50% 50%',
                                    opacity: 0,
                                    transition: 'opacity 0.3s ease-in-out',
                                    cursor: 'pointer'
                                }}
                            >
                                <BiEdit />
                            </Box>
                        </label>
                        <input name='pic1' id='pic1' type="file" hidden accept="image/*" onChange={handleFileChange} />
                    </Box>

                </Box>
            </DialogContent>

            <Box sx={{ p: 2 }}>
                <Button
                    fullWidth
                    disabled={loading}
                    // variant="contained"
                    className='login_btn'
                    // sx={{
                    //     borderRadius: 2,
                    //     py: 1.5,
                    //     textTransform: 'none',
                    //     bgcolor: loading ? '#8eaee6' : '#2a27da',
                    //     color: 'white',
                    //     '&:hover': {
                    //         bgcolor: '#8eaee6'
                    //     }
                    // }}
                    onClick={() => {
                        setLoading(true)
                        onSave(selectedAvatar?.image, setLoading);
                    }}
                >
                    {loading ? "Saving..." : "Save"}
                </Button>
            </Box>
        </Dialog>
    );
};

export default AvatarChangeModal;