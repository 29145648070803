import React, { useEffect, useState } from "react";
import { AiFillBank } from "react-icons/ai";
import { FcDisclaimer } from "react-icons/fc";
import { useSelector, useDispatch } from "react-redux";
import {
  N_p2pInitiateOrder,
  N_Screenp2pShot,
} from "../redux/helpers/api_functions_new";
import Header from "./Header";
import "./p2p.css";
import PreLoader from "./PreLoader";
import { getRound } from "../redux/helpers/helper_functions";
export default function P2Ppage(props) {
  const [bankData, setBankData] = useState();
  const [orderData, setorderData] = useState();
  const [loading, setLoading] = useState(true);
  const [filedata, setFileData] = useState();
  const [transection_id, setTransectionId] = useState();
  const [ctime, setctime] = useState("00:00:00");
  const [timer, settimer] = useState(true);

  useEffect(() => {
    setBankData(props.history.location.bank_details);
    setorderData(props.history.location.p2plist);
    if (props.history.location.bank_details && props.history.location.p2plist) {
      setLoading(false);
    } else {
      setTimeout(() => {
        props.history.push("/exchange/btc-usdt");
      }, 2000);
    }
  }, [props.history.location.bank_details, props.history.location.p2plist]);
  const { coins } = useSelector((state) => state.coinDBReducer);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const { user } = useSelector((state) => state.AuthReducer);
  const uploadIMG = (input) => {
    if (input.target.files && input.target.files[0]) {
      // console.log("fileset****");
      setFileData(input.target.files[0]);
    }
  };

  const otpCountdown = () => {
    let duration = 60 * 30;
    // const display = document.getElementById("#timer");
    let timer = duration,
      minutes,
      seconds;
    const tint = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setctime("00:" + minutes + ":" + seconds);

      if (--timer < 0) {
        // timer = duration;
        clearInterval(tint);
        settimer(false);
      }
    }, 1000);
  };

  useEffect(() => {
    otpCountdown();
    if (orderData) {
      N_p2pInitiateOrder(
        user?.params ? user.params.user_id : user.user_id,
        orderData.order_token
      );
    }
  }, [bankData, orderData]);

  return (
    <>
      <Header {...props} />
      {loading ? (
        <PreLoader />
      ) : (
        <div
          className={`${webData.bg_color}`}
          style={{
            backgroundColor: webData.bg_color_code,
            boxShadow: "0px 0px 2px rgba(0,0,0,0.2)",
          }}
        >
          <div
            className="tab-content theme-color"
            style={{ minHeight: "900px", marginTop: "80px" }}
          >
            <div className="container p-3">
              <div className="row">
                <div className="col-12">
                  <div className="box-header with-border">
                    <h4 className="box-title theme-color-text">
                      BUY {orderData?.currency_type}
                    </h4>
                    <br />
                    <h6 className="box-title theme-color-text">
                      Created Date:{" "}
                      {new Date(Number(orderData?.order_date)).toLocaleString()}
                    </h6>{" "}
                    <br />
                  </div>
                  <div
                    className="box-header justify-content-between"
                    style={{ display: "flex" }}
                  >
                    <div className="box-title">
                      Price :
                      <br />
                      <strong> {orderData?.price}</strong>
                    </div>
                    <div className="box-title">
                      Volume :
                      <br />
                      <strong>{orderData?.volume-orderData?.p2p_fee}</strong>
                    </div>
                    <div className="box-title">
                      Total Pay Amount :
                      <br />
                      <strong className="text-success fw-bolder fs-1">
                        {getRound((orderData?.volume-orderData?.p2p_fee) * orderData?.price)} INR
                      </strong>
                    </div>
                  </div>
                  <div >
                  <div className="box-body card detail-box buy-sell-form-bg buy-sell-theme ">
                    <strong className="p2p-account-detail-para pt-3">
                      Please make sure You have successfully transfer the money
                      to the Seller to this acoount Details
                    </strong>
                    <strong className="p2p-account-detail-para pt-2 pl-3">
                      <span className="pt-4 font-weight-bold">
                        <AiFillBank size={18} />
                      </span>{" "}
                      Bank Transfer :
                    </strong>

                    <div className="card-body p2p-card-body ">
                      <table class="table-striped w-100">
                        <thead>
                          <tr>
                            <th scope="col">Email:</th>
                            <td>{bankData?.email}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="col">Name:</th>
                            <td>{bankData?.name}</td>
                          </tr>
                          <tr>
                            <th scope="col">Bank Account Number:</th>
                            <td>{bankData?.account_number}</td>
                          </tr>
                          <tr>
                            <th scope="col">Bank IFSC Code:</th>
                            <td> {bankData?.ifsc}</td>
                          </tr>
                          <tr>
                            <th scope="col">Bank Name:</th>
                            <td>{bankData?.bank_name}</td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <div>
                        <span className="bold">Email:</span> {bankData.email}
                      </div>
                      <div>
                        <span className="bold">Name:</span> {bankData.name}
                      </div>
                      <div>
                        <span className="bold">Bank Account Number:</span>{" "}
                        {bankData.account_number}
                      </div>
                      <div>
                        <span className="bold">Bank IFSC Code:</span>{" "}
                        {bankData.ifsc}
                      </div>
                      <div>
                        <span className="bold">Bank Name:</span>{" "}
                        {bankData.bank_name}
                      </div> */}
                    </div>
                  </div>
                  </div>
                  <div className="card pl-4 pt-4 detail-box buy-sell-form-bg buy-sell-theme">
                    <strong>
                      Payment to be made:
                      {timer ? (
                        <div className="resend_btn text-danger pr-2" id="timer">
                          {ctime}
                        </div>
                      ) : null}
                    </strong>
                    <div className="mb-3 rounded">
                      Please make a payment within 30:00 mins. otherwise order
                      will be cancelled
                    </div>
                  </div>
                  <div className="form-group card  detail-box buy-sell-form-bg buy-sell-theme">
                    <div className="text-warning font-weight-bold pl-2 pt-2">
                      <i className="fas fa-warning" aria-hidden="true"></i>{" "}
                      <FcDisclaimer size={17} /> Disclaimer :
                    </div>
                    {/* <hr className="h_r" /> */}
                    <label
                      htmlFor="disclaimer"
                      className=" mt-1 pl-3 p-2 badge-warning"
                    >
                      If money Transfer Successfully then upload the Screenshot
                      and transaction Id
                    </label>
                  </div>

                  <h5 className="mt-2">Transaction Id</h5>
                  <input
                    className="mb-4"
                    style={{
                      width: "60% !importent",
                      display: "block",
                      padding: "0.375rem 0.75rem",
                      fontSize: "1rem",
                      lineHeight: "1.5",
                      color: "#495057",
                      backgroundColor: "#fff",
                      backgroundClip: "padding-box",
                      border: "1px solid #ced4da",
                    }}
                    type="text"
                    required
                    id="transection"
                    value={transection_id}
                    onChange={(e) => {
                      setTransectionId(e.target.value);
                    }}
                  />
                  <label for="screenshot">Upload Screenshot File: </label>
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadIMG(e);
                    }}
                  />
                  <a
                    className="btn bg-warning mt-3"
                    onClick={(e) => {
                      N_Screenp2pShot(
                        e,
                        filedata,
                        transection_id,
                        orderData.order_token,
                        user?.params ? user.params.user_id : user.user_id
                      );
                    }}
                  >
                    UpLoad
                  </a>
                  <div
                    className="spinner-border text-warning"
                    style={{ display: "none" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="mt-4">
                    <a
                      className="btn btn-lg bg-primary"
                      onClick={() => {
                        props.history.push({
                          pathname: "/exchange/btc-usdt",
                        });
                      }}
                    >
                      DONE
                    </a>
                    <button
                      className="btn btn-lg ml-4 bg-danger"
                      onClick={() => {
                        props.history.push({
                          pathname: "/exchange/btc-usdt",
                        });
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
