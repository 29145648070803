import moment from "moment";
import swal from "sweetalert2";

export const convertLongStringToShort = (string) => {
  return string
    ? string.slice(0, 5) + "..." + string.slice(string.length - 5)
    : "";
};

export const firstLetterUpper = (string) => {
  return string?.split("").slice(0, 2).join("").toUpperCase();
};
export const minuteFormat = (min) => {
  return `${String(min).padStart(2, "0")}:00`;
};

export default function appendData(values) {
  const formValues = { ...values };
  const formData = new FormData();
  for (const value in formValues) {
    formData.append(value, formValues[value]);
  }
  return formData;
}
export const chatDateStringToDate = (timestamp) => {
  const date = moment.unix(timestamp).format("DD MMM, hh:mm A");
  return date;
};

export const fullDateFormat = (date) => {
  return (
    <>
      <p style={{ margin: "0px", fontSize: "13px", lineHeight: "15px" }}>{moment(date).format("MMM DD, YYYY")}</p>
      <p style={{ margin: "0px", fontSize: "12px" }}>{moment(date).format("h:mm A")}</p>
    </>
  );
};

export const NumberToFixed = (num) => {
  return num > 1 ? num?.toFixed(4) : num?.toFixed(6)
}

export const commonSwal = (text, subtext, confirmBtnText, cancelBtnText) => {
  return swal.fire({
    title: text,
    text: subtext,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#4c05ad",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmBtnText,
    cancelButtonText: cancelBtnText,
  });
};

export const commonSwalWithInput = (
  text,
  subtext,
  confirmBtnText,
  cancelBtnText
) => {
  return swal.fire({
    input: "textarea",
    // inputLabel: "Message",
    inputPlaceholder: "Type your comment here...",
    inputAttributes: {
      "aria-label": "Type your comment here",
    },
    title: text,
    text: subtext,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#4c05ad",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmBtnText,
    cancelButtonText: cancelBtnText,
    preConfirm: (value) => {
      if (!value) {
        swal.showValidationMessage("This field is required");
        return false; // Prevent closing the modal
      }
      return value; // Allow the modal to close with valid input
    },
  });
};

export function toFixed(number, decimals) {
  number = number.toString();
  let dotIndex = number.lastIndexOf('.');
  let lastIndex = number.length - 1;
  return lastIndex - dotIndex > decimals ? Number(number).toFixed(decimals) : Number(number)
}