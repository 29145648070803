import React from "react";
import NoData from "../../helper/components/NoData";

const NoDataComp = ({ text }) => {
  return (
    <div className="my-4 py-4">
      <NoData text={text} />
    </div>
  );
};

export default NoDataComp;
