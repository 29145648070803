import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { user_logout } from "../redux/actions/authActions";
import { switchTheme } from "../redux/actions/coinDBAction";
import "./Header.css";
// import NotificationBanner from "./BannerData";
import i18n from "i18next";
import { AiOutlineUser } from "react-icons/ai";
import { GoGlobe, GoGraph } from "react-icons/go";
import { HiBars3CenterLeft } from "react-icons/hi2";
import { MdOutlineAutoGraph } from "react-icons/md";
import {
  RiExchangeFundsFill,
  RiFundsBoxLine
} from "react-icons/ri";
import { RxCross1 } from "react-icons/rx";
import { NavLink, useHistory } from "react-router-dom";
import useHumanReadableNumber from "../../hooks/useHumanReadableNumber";
import useOutsideClick from "../components/CloseDropDown";
import BalanceShimmer from "../components/shimmer/BalanceShimmer";
import { getCoinRate, getRound } from "../redux/helpers/helper_functions";
// import TranslateComponent from "../components/TranslateComponent";
import { maskEmail } from "../../helper/common";
import NoData from "../../helper/components/NoData";
import GoogleTranslate from "../../translate";
import { getMyNotifications, readMyNotifications } from "../redux/helpers/api_functions_new";
import { FaRegBell } from "react-icons/fa6";
import { FaBell } from "react-icons/fa";
import { PiCirclesThreePlusBold } from "react-icons/pi";
import { IoCard } from "react-icons/io5";
import { IoMdChatboxes } from "react-icons/io";

import { AiFillMessage } from "react-icons/ai";
import { BsFillGridFill } from "react-icons/bs";
import { BsInfoCircleFill } from "react-icons/bs";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../firebase/firebaseConfig";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom/cjs/react-router-dom";


export default function Header(props) {
  
  const location = useLocation();
  const humanReadable = useHumanReadableNumber();
  function formatNumberWithCommas(number, locales = "en-US", options = {}) {
    return new Intl.NumberFormat(locales, options).format(number);
  }
  const changeLanguage = (lang) => {
    if (["en", "ar"].includes(lang)) {
      // Change the language dynamically
      i18n.changeLanguage(lang);

      // Save the selected language to localStorage
      localStorage.setItem("language", lang);

      // Update the URL to reflect the new language without reloading
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set("lng", lang); // Set the 'lng' query parameter
      window.history.pushState(null, "", currentUrl.toString()); // Update the URL
    }
  };

  const [total_inr, setTotalInr] = useState(0);
  const [total_usdt, setTotalUsdt] = useState(0);
  const [hideBal, setHideBal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  useOutsideClick(dropdownRef, () => setShowMenu(false));

  const hideBalance = () => {
    setHideBal(!hideBal);
  };
  const { isLoggedIn, switch_theme, user, profile } = useSelector(
    (state) => state.AuthReducer
  );

  const {
    wallet,
    paired_curency_price,
    coins,
    wallet_loading,
    currency_type,
    campare_currency,
  } = useSelector((state) => state.coinDBReducer);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  async function backRouter(isExpire = false) {

    localStorage.removeItem("exchange-inrx-userid");
    localStorage.removeItem("token");
    dispatch(
      user_logout(() => {
        history.push("/login");
      })
    );
    if (isExpire) {
      toast.error("Session expired. Please log in again.")
    }
  }
  async function swtchTheme() {
    if (switch_theme == "dark") {
      dispatch(switchTheme("light"));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme_frontend", "light");
      const body = document.getElementsByTagName("body")[0];
      body?.classList.add("is_light");
      body?.classList.remove("is_dark");

      var icon = document.querySelector("#theme_icon");
      icon?.classList.add("fa-sun");
      icon?.classList.remove("fa-moon");
    } else {
      dispatch(switchTheme("dark"));
      localStorage.removeItem("tradingview.current_theme.name");
      localStorage.setItem("exchange_inrx_theme_frontend", "dark");
      const body = document.getElementsByTagName("body")[0];
      body?.classList.add("is_dark");
      body?.classList.remove("is_light");

      var icon = document.querySelector("#theme_icon");

      icon?.classList.add("fa-moon");
      icon?.classList.remove("fa-sun");
    }
  }

  // useEffect(() => {
  //   if (!wallet_loading) {
  //     getWallets();
  //   }
  // }, [wallet, coins, paired_curency_price]);



  // function getWallets() {
  //   setLoading(true);
  //   let total = 0;
  //   wallet &&
  //     Object.keys(wallet).map((res, i) => {
  //       let wallet_type = wallet[res]?.symbol.toUpperCase();
  //       let rate = getCoinRate(coins, wallet_type);
  //       let inr_val = rate * getRound(wallet[res]?.balance);
  //       total = total + parseFloat(inr_val);
  //     });
  //   setTotalInr(getRound(total));
  //   setTotalUsdt(getRound(total / paired_curency_price.USDT));

  //   setLoading(false);
  // }

  useEffect(() => {
    if (!wallet_loading) {
      function getWallets() {
        setLoading(true);
        let total = 0;
        let final_wallet_data =
          wallet &&
          Object.keys(wallet)?.map((key, i) => {
            const item = wallet[key];
            let wallet_type = item?.symbol.toUpperCase();
            // let usdt_val = item?.usdtBalance;
            let usdt_val = item?.total_combine_balance || 0;
            let usdt_locked_val = item?.locked * item?.usdtPrice;
            total = total + parseFloat(usdt_val);
            return {
              id: item?._id,
              icon: item?.icon,
              symbol: wallet_type,
              name: item?.name,
              status: item?.status,
              locked:
                Math.round(item?.locked * 10000) / 10000 != 0
                  ? Math.round(
                    Math.abs(item?.locked ? item?.locked : 0) * 10000
                  ) / 10000
                  : Math.round(item?.locked * 100000000) / 100000000,
              address: item?.wallet_address,
              balance:
                Math.round(item?.balance * 10000) / 10000 != 0
                  ? Math.round(item?.balance * 10000) / 10000
                  : Math.round(item?.balance * 100000000) / 100000000,
              p2pbalance: item?.p2p_balance ? item?.p2p_balance : 0,
              p2p_locked: item?.p2p_locked ? item?.p2p_locked : 0,
              avl_balance:
                Math.round(item?.balance * 10000) / 10000 != 0
                  ? Math.round(item?.balance * 10000) / 10000
                  : Math.round(item?.balance * 100000000) / 100000000,
              usdt_total_balance: getRound(usdt_val),
              usdt_locked_balance: getRound(usdt_locked_val),
            };
          });
        setTotalUsdt(getRound(total) || 0);
        setLoading(false);
      }
      getWallets();
    }
  }, [wallet, coins, wallet_loading]);

  const [notificationList, setNotificationList] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    if (localStorage.getItem("token")) {
      try {
        let res = await getMyNotifications();
        if (res?.status == 200) {
          setNotificationCount(res?.data?.unreadCount ?? 0);
          setNotificationList(res?.data?.notifications ?? []);
        } else if (res?.status == 401) {
          backRouter(true);
        }
      } catch (error) {
        console.log(error, "<<-------- notification error");
      }
    }
  }
  const handleReadNotification = async () => {
    readMyNotifications()
      .then((res) => {
        getNotifications();
      }).catch((error) => {
        console.log(error.message, "<<------------- notification error");
      })
  }

  const notificationSound = new Audio('https://assets.mixkit.co/active_storage/sfx/933/933-preview.mp3');
  useEffect(() => {
    onMessage(messaging, (payload) => {
      // console.log(payload, "<---------------------------- payload notification");
      if (location.pathname != "/order-chat" && location.pathname != "/create-order") {
        getNotifications();
        notificationSound.play();
        toast.success((r) => (
          <span>
            <b>{payload?.data?.title}</b> : {payload?.data?.body}
          </span>
        ), {
          duration: 2000,
        });
      }
    });
  }, []);

  return (
    <>
      {/* <NotificationBanner /> */}

      <div>
        <nav class="navbar navbar-expand-lg navbar-light fixed-top py-3 home_nav">
          <Link className="navbar-brand" to="/">
            {/* {webData.logo_img_name ? ( */}
            {switch_theme === "dark" ? (
              <img
                className="ms-2"
                src={`/img/logo.svg`}
                alt="logo"
                width="100"
              />
            ) : (
              <img
                className="ms-2"
                src={`/img/logo.svg`}
                alt="logo"
                width="100"
              />
            )}
            {/* ) : (
              <span>
                <div className="skeleton logo ms-2"></div>
              </span>
            )} */}
          </Link>{" "}
          {/*============= mobile Menu (Account | Notification| Toggle bar) Start ============= */}
          <div className="d-lg-none">
            <div className="d-flex align-items-center gap-1">
              {/* {!isLoggedIn ? (
                  <>
                    <li>
                      <Link to="/exchange/btc-usdt" className="dropdown-item">
                        <div className="dropdown-body-item">
                          <div className="d-flex justify-content-between fs-12 text-lgray">
                            <span>Spot</span>
                            <span>
                              Maker/Taker:{" "}
                              <span className="text-dark">0.08% / 0.08%</span>
                            </span>
                          </div>
                          <div className="d-flex justify-content-between fs-12 text-lgray">
                            <span>Futures</span>
                            <span>
                              Maker/Taker:{" "}
                              <span className="text-dark">0.02% / 0.06%</span>
                            </span>
                          </div>

                          <div className="fs-12 mt-3 pt-2">
                            Pay Fee with KCS (20% off):{" "}
                            <span className="text-green">Enabled</span>
                          </div>
                        </div>
                      </Link>{" "}
                    </li>

                    <li>
                      <Link to="/profile" className="dropdown-item">
                        <div className="dropdown-body-item pt-3 border-top">
                          Trading Fees
                        </div>
                      </Link>
                    </li>

                    <li>
                      <Link to="/AccountSecurity" className="dropdown-item">
                        <div className="dropdown-body-item">
                          Account Security
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/AccountVerification">
                        <div className="dropdown-body-item">
                          {" "}
                          UID :{" "}
                          {user?.params
                            ? user.params.user_id
                            : user.user_id}{" "}
                          <i className="fa fa-copy ms-1"></i>
                        </div>
                      </Link>
                    </li>

                    <li>
                      {" "}
                      <Link to="/exchange/btc-usdt" className="dropdown-item">
                        <div className="dropdown-body-item">
                          <div className="d-flex justify-content-between fs-12 text-lgray">
                            <span>Spot</span>
                            <span>
                              Maker/Taker:{" "}
                              <span className="text-dark">0.08% / 0.08%</span>
                            </span>
                          </div>
                          <div className="d-flex justify-content-between fs-12 text-lgray">
                            <span>Futures</span>
                            <span>
                              Maker/Taker:{" "}
                              <span className="text-dark">0.02% / 0.06%</span>
                            </span>
                          </div>
                        </div>
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="/ApiManagement">
                        <div className="dropdown-body-item">API Management</div>
                      </Link>
                    </li>

                    <li>
                      <Link className="dropdown-item" to="/subAccount">
                        <div className="dropdown-body-item"> Sub Account </div>
                      </Link>
                    </li>

                    <li>
                      <a
                        className="dropdown-item cursor_pointer border-top"
                        onClick={() => {
                          backRouter();
                        }}
                      >
                        Sign Up
                      </a>
                    </li>
                  </>
                ) : null} */}

              {isLoggedIn ? (
                <>
                  <li class="nav-item header-nav-item dropdown" id="user_link">
                    <a
                      class="nav-link py-0 "
                      data-bs-toggle="offcanvas"
                      href="#offcanvasExample"
                      role="button"
                      aria-controls="offcanvasExample"
                    >
                      {/* <a
                        class="nav-link py-0 dropdown-toggle"
                        href="#"
                        data-bs-toggle="dropdown"
                        
                      > */}
                      <div class="circle_backdrop_outline gray_border">
                        {profile?.name
                          ? profile?.name
                            ?.split("")
                            .slice(0, 2)
                            .join("")
                            .toUpperCase()
                          : profile?.email
                            ?.split("")
                            .slice(0, 2)
                            .join("")
                            .toUpperCase()}
                      </div>
                    </a>

                    <div
                      class="offcanvas offcanvas-end"
                      tabindex="-1"
                      id="offcanvasExample"
                      aria-labelledby="offcanvasExampleLabel"
                    >
                      <div class="offcanvas-header">
                        <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                          Your Accout
                        </h4>
                        <button
                          type="button"
                          class="btn-close text-reset"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="offcanvas-body">
                        <div className="px-3">
                          <Link to="/profile" className="text-dark">
                            <h5 className="h5 text-truncate ps-1 d-flex gap-2">
                              {" "}
                              <AiOutlineUser />
                              {profile?.name
                                ? profile.name
                                : profile?.email}{" "}
                            </h5>
                            <span className="custom_badge gray_badge text-lgray ">
                              {" "}
                              UID :{" "}
                              {user?.params
                                ? user.params.user_id
                                : user.user_id}{" "}
                              <i className="fa fa-copy ms-1"></i>
                            </span>
                          </Link>
                        </div>
                        <ul class="mt-3 navbar-nav">
                          <li className="nav-item">
                            <Link
                              to="/profile"
                              className="nav-item header-nav-item"
                            >
                              <a className="nav-link">Profile</a>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link to="/AccountSecurity" className="nav-link">
                              <div className="dropdown-body-item">
                                Account Security
                              </div>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              to="/AccountVerification"
                            >
                              <div className="dropdown-body-item">
                                {" "}
                                Identity Verification{" "}
                                {/* <span className="custom_badge bg-soft-pink">
                                    LV3
                                  </span> */}
                              </div>
                            </Link>
                          </li>

                          <li className="nav-item">
                            <Link className="nav-link" to="/referal">
                              <div className="dropdown-body-item">
                                Refer & Earn
                              </div>
                            </Link>
                          </li>

                          {/* <li className="nav-item">
                              <Link className="nav-link" to="/subAccount">
                                <div className="dropdown-body-item">
                                  {" "}
                                  Sub Account{" "}
                                </div>
                              </Link>
                            </li> */}

                          <li className="nav-item">
                            <a
                              className="nav-link cursor_pointer"
                              onClick={() => {
                                backRouter();
                              }}
                            >
                              <div className="dropdown-body-item text-center">
                                <div className="login_btn"> Log Out</div>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* <ul class="dropdown-menu  dropdown-menu-end  pb-0">
                        <li>
                          <Link to="/profile" className="dropdown-item">
                            <div className="dropdown-body-item border-bottom">
                              <h2 className="big_heading mb-2 text-truncate">
                                {profile?.name ? profile.name : profile?.email}
                              </h2>
                              <span className="custom_badge gray_badge text-lgray ">
                                {" "}
                                UID :{" "}
                                {user?.params
                                  ? user.params.user_id
                                  : user.user_id}{" "}
                                <i className="fa fa-copy ms-1"></i>
                              </span>
                            </div>
                          </Link>
                        </li>

                        <li>
                          {" "}
                          <Link
                            to="/exchange/btc-inr"
                            className="dropdown-item"
                          >
                            <div className="dropdown-body-item">
                              <div className="d-flex justify-content-between fs-12 text-lgray">
                                <span>Spot</span>
                                <span>
                                  Maker/Taker:{" "}
                                  <span className="text-dark">
                                    0.08% / 0.08%
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex justify-content-between fs-12 text-lgray">
                                <span>Futures</span>
                                <span>
                                  Maker/Taker:{" "}
                                  <span className="text-dark">
                                    0.02% / 0.06%
                                  </span>
                                </span>
                              </div>

                              <div className="fs-12 mt-3 pt-2">
                                Pay Fee with KCS (20% off):{" "}
                                <span className="text-green">Enabled</span>
                              </div>
                            </div>
                          </Link>{" "}
                        </li>

                        <li>
                          <Link to="/profile" className="dropdown-item">
                            <div className="dropdown-body-item pt-3 border-top">
                              Trading Fees
                            </div>
                          </Link>
                        </li>

                        <li>
                          <Link to="/AccountSecurity" className="dropdown-item">
                            <div className="dropdown-body-item">
                              Account Security
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/AccountVerification"
                          >
                            <div className="dropdown-body-item">
                              {" "}
                              Identity Verification{" "}
                              <span className="custom_badge bg-soft-pink">
                                LV3
                              </span>
                            </div>
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="/ApiManagement">
                            <div className="dropdown-body-item">
                              API Management
                            </div>
                          </Link>
                        </li>

                        <li>
                          <Link className="dropdown-item" to="/subAccount">
                            <div className="dropdown-body-item">
                              {" "}
                              Sub Account{" "}
                            </div>
                          </Link>
                        </li>

                        <li>
                          <a
                            className="dropdown-item cursor_pointer border-top"
                            onClick={() => {
                              backRouter();
                            }}
                          >
                            <div className="dropdown-body-item text-center text-black">
                              Log Out
                            </div>
                          </a>
                        </li>
                      </ul> */}
                  </li>

                  <li className="nav-item header-nav-item ">
                    <a
                      data-bs-toggle="offcanvas"
                      href="#notification_toggle"
                      role="button"
                      aria-controls="notification_toggle"
                      className="nav-link p-0"
                    >

                      {
                        notificationCount > 0 ? <>
                          <div className="circle_backdrop" style={{ backgroundColor: "white" }}>
                            <FaBell className="shake" style={{ color: "#eca901" }} />
                          </div>
                          <span className="notification-count yes">{notificationCount}</span>
                        </> : <>
                          <div className="circle_backdrop">
                            <FaRegBell />
                          </div>
                        </>
                      }
                    </a>
                  </li>
                </>
              ) : null}

              {/* <li className="nav-item header-nav-item  header-nav-item ">
                    <a
                      onClick={() => {
                        swtchTheme();
                      }}
                      className="nav-link p-0"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="circle_backdrop">
                        <i class="fa fa-sun fs-5" id="theme_icon"></i>
                      </div>
                    </a>
                  </li> */}

              <span className="brand-title">
                {" "}
                {webData.logo_with_title === 1
                  ? webData.website_short_name
                  : ""}
              </span>
              <button
                class="navbar-toggler circle_backdrop"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#ca-navbar"
                aria-controls="ca-navbar "
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon">
                  <HiBars3CenterLeft className="fs-3" />
                </span>
              </button>
            </div>
          </div>
          {/*============= mobile Menu (Account | Notification| Toggle bar) End ============= */}
          <div
            id="ca-navbar"
            className="offcanvas offcanvas-end navbar-collapse-- manu_header"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title" id="ca-navbar">
                <Link className="navbar-brand" to="/">
                  <img
                    className="ms-2"
                    src={`/img/logo.svg`}
                    alt="logo"
                    width="70"
                  />
                </Link>
              </h5>
              <button
                type="button"
                class="circle_backdrop border-0"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <RxCross1 />
              </button>
            </div>
            <div className="offcanvas-body">
              <ul id="nav--" className="navbar-nav me-auto">
                <li className="nav-item header-nav-item ">
                  <Link className=" nav-link" to="/Markets">
                    Crypto Trading
                  </Link>
                </li>

                <li className="nav-item header-nav-item ">
                  <Link className="nav-link" to="/p2pOrder">
                    P2P
                  </Link>
                </li>


                <li className="nav-item header-nav-item ">
                  <Link className="nav-link" to="/earn">
                    Earn
                  </Link>
                </li>
                <li className="nav-item header-nav-item ">
                  <Link className="nav-link" to="/web3">
                    Web3
                  </Link>
                </li>
                <li className="nav-item header-nav-item ">
                  <Link className="nav-link" to="/education">
                    Learn
                  </Link>
                </li>
              </ul>

              {/*============= Right Side header Manu============= */}
              <div className="">
                <ul className="navbar-nav custom_dropdown_menu">
                  {!isLoggedIn ? (
                    <li class="nav-item header-nav-item mb-4 mb-md-0 me-3">
                      <Link className="nav-link login_btn px-4" to="/create" style={{ background: "#ffb703" }}>
                        Create Account
                      </Link>
                    </li>
                  ) : null}

                  {!isLoggedIn ? (
                    <li class="nav-item header-nav-item mb-4 mb-md-0 me-2">
                      <Link className="nav-link border_btn px-4" to="/login">
                        Log In
                      </Link>
                    </li>
                  ) : null}
                  {isLoggedIn ? (
                    <>
                      <li class="nav-item header-nav-item dropdown">
                        <a
                          class="nav-link dropdown-toggle"
                          href="#"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        // onClick={toggleMenu}
                        >
                          Assets
                        </a>
                        <ul
                          // ref={dropdownRef}
                          class={`dropdown-menu dropdown-menu-start dropdown_body_custom `}
                          aria-labelledby="navbarDropdown"
                          style={{ minWidth: "320px", left: "-180px" }}
                        >
                          <li>
                            <div className="dropdown-body-item pb-0 text-lgray px-2 py-3 rounded-3 mx-4 d-flex flex-column gap-3 light_bg">
                              <div className="fs-14">
                                {" "}
                                Total Assets{" "}
                                {/* <span>
                                  <FaEye
                                    onClick={hideBalance}
                                    className="ms-2"
                                  />
                                </span> */}
                              </div>
                              <div>
                                <span className="text-white fs-3">
                                  {isNaN(total_usdt) ||
                                    total_usdt == "NaN" ||
                                    loading ? (
                                    <BalanceShimmer />
                                  ) : (
                                    <>
                                      <span className="fs-4 fw-normal">
                                        {`${formatNumberWithCommas(total_usdt)} `}
                                      </span>{" "}<span className="fs-3">USDT</span>
                                    </>
                                  )}
                                </span>
                              </div>
                              {/* <div>
                                <span> </span>
                                <span className="text-dark">
                                  {hideBal ? "****" : formatNumber(458962657)}
                                </span>{" "}
                              </div> */}
                            </div>
                          </li>
                          <li className="p-2">
                            <p className="px-3">*Data updates may be delayed</p>
                            <section className="dropdown-body-item pb-0 text-lgray p-2 rounded-3 d-flex justify-content-between">
                              <button className="btn login_btn me-2 w-50 rounded-5">
                                <Link
                                  to="/AssetsDeposit"
                                  className="text-white"
                                >
                                  Deposit
                                </Link>
                              </button>
                              <button className="btn login_btn me-2 w-50">
                                <Link
                                  to="/AssetsWithdraw"
                                  className="text-white"
                                >
                                  Withdraw
                                </Link>
                              </button>
                            </section>
                          </li>
                          <li>
                            <NavLink class="dropdown-item" to="/FundingAccount">
                              <div className="dropdown-body-item">
                                <GoGraph className="fs-4 me-2" /> Funding
                                Account
                              </div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink class="dropdown-item" to="/SpotOrders">
                              <div className="dropdown-body-item">
                                <RiFundsBoxLine className="fs-4 me-2" />
                                <span className="">Spot Order</span>
                              </div>
                            </NavLink>
                          </li>

                          {/* <li>
                              <a class="dropdown-item" href="/AssetsMargin">
                                <div className="dropdown-body-item">
                                  {" "}
                                  <FaBalanceScaleLeft className="fs-4 me-2" />{" "}
                                  Margin Account
                                </div>
                              </a>
                            </li> */}
                          <li>
                            <NavLink class="dropdown-item" to="/AssetsFuture">
                              <div className="dropdown-body-item">
                                <MdOutlineAutoGraph className="fs-4 me-2" />
                                Futures Account
                              </div>
                            </NavLink>
                          </li>
                          <li>
                            <NavLink class="dropdown-item" to="/copy-trading">
                              <div className="dropdown-body-item">
                                <RiExchangeFundsFill className="fs-4 me-2" />
                                Copy Trading Account
                              </div>
                            </NavLink>
                          </li>

                          {/* 
                            <div className="mx-2">
                              <div className="dropdown-body-item pb-0 mb-0">
                                <div className="menu_jambotron">
                                  <Link class="" to="/exchange/trx-inr">
                                    <div className="d-flex justify-content-between">
                                      Buy Crypto
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>

                            <div className="mx-2">
                              <div className="dropdown-body-item ">
                                <div className="menu_jambotron">
                                  <Link class="" to="/AssetsDeposit">
                                    <div className="d-flex justify-content-between">
                                      Deposit
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div> */}
                        </ul>
                      </li>

                      <li
                        class="nav-item header-nav-item dropdown d-none d-lg-block"
                        id="user_link"
                      >
                        <a
                          class="nav-link py-0 dropdown-toggle"
                          href="#"
                          data-bs-toggle="dropdown"
                        >
                          <div class="circle_backdrop_outline gray_border">
                            {profile?.name
                              ? profile?.name
                                ?.split("")
                                .slice(0, 2)
                                .join("")
                                .toUpperCase()
                              : profile?.email
                                ?.split("")
                                .slice(0, 2)
                                .join("")
                                .toUpperCase()}
                          </div>
                        </a>

                        <ul
                          class="dropdown-menu dropdown_body_custom dropdown-menu-end pb-0"
                          style={{ width: "320px" }}
                        >
                          <li>
                            <Link to="/profile" className="dropdown-item">
                              <div className="dropdown-body-item d-flex justify-content-start align-items-center overflow-hidden gap-2">
                                <div
                                  class="border"
                                  style={{
                                    padding: "15px",
                                    borderRadius: "50%",
                                    fontSize: "larger",
                                    fontWeight: "bolder",
                                  }}
                                >
                                  {profile?.name
                                    ? profile?.name
                                      ?.split("")
                                      .slice(0, 2)
                                      .join("")
                                      .toUpperCase()
                                    : profile?.email
                                      ?.split("")
                                      .slice(0, 2)
                                      .join("")
                                      .toUpperCase()}
                                </div>
                                <div className="ms-1 d-flex flex-column gap-1">
                                  <span
                                    className=" text-truncate text-black"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {profile?.name || profile?.email
                                      ? profile.name ||
                                      maskEmail(profile?.email)
                                      : " N/A"}
                                  </span>
                                  <span
                                    className="text-lgray "
                                    style={{ fontSize: "13px" }}
                                  >
                                    {" "}
                                    UID :{" "}
                                    {user?.params
                                      ? user.params.user_id
                                      : user.user_id}{" "}
                                    {/* <i className="fa fa-copy ms-1"></i> */}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </li>

                          <li>
                            <Link to="/profile" className="dropdown-item">
                              <div className="dropdown-body-item pt-3">
                                Profile
                              </div>
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/AccountSecurity"
                              className="dropdown-item"
                            >
                              <div className="dropdown-body-item">
                                Account Security
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="/AccountVerification"
                            >
                              <div className="dropdown-body-item">
                                {" "}
                                Identity Verification{" "}
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link className="dropdown-item" to="/referal">
                              <div className="dropdown-body-item">
                                {" "}
                                Refer & Earn{" "}
                              </div>
                            </Link>
                          </li>

                          <li>
                            <a
                              className="dropdown-item cursor_pointer border-top"
                              onClick={() => {
                                backRouter();
                              }}
                            >
                              <div className="dropdown-body-item text-center text-black">
                                Log Out
                              </div>
                            </a>
                          </li>
                        </ul>
                      </li>

                      <li className="nav-item header-nav-item d-none d-lg-block">
                        <a
                          data-bs-toggle="offcanvas"
                          href="#notification_toggle"
                          role="button"
                          aria-controls="notification_toggle"
                          className="nav-link p-0"
                          onClick={() => handleReadNotification()}
                        >
                          {
                            notificationCount > 0 ? <>
                              <div className="circle_backdrop" style={{ backgroundColor: "white" }}>
                                <FaBell className="shake" style={{ color: "#eca901" }} />
                              </div>
                              <span className="notification-count yes">{notificationCount}</span>
                            </> : <>
                              <div className="circle_backdrop">
                                <FaRegBell />
                              </div>
                            </>
                          }
                        </a>
                      </li>
                    </>
                  ) : null}
                  <li className="nav-item header-nav-item header-nav-item m-md-auto ms-4 ms-md-1 d-none">
                    <div className="dropdown me-3 language_dropdown">
                      <button
                        className="btn  dropdown-toggle "
                        type="button"
                        id="languageDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          border: "none",
                          background: "transparent",
                          padding: 0,
                          cursor: "pointer",
                        }}
                      >
                        <GoGlobe className="text-white" size={30} />
                      </button>

                      <ul
                        className="dropdown-menu mr-4"
                        aria-labelledby="languageDropdown"
                        style={{ minWidth: "100px", left: "-50px" }}
                      >
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => changeLanguage("en")}
                          >
                            English (EN)
                          </button>
                        </li>
                        <li>
                          <button
                            className="dropdown-item"
                            onClick={() => changeLanguage("ar")}
                          >
                            Arabic (AR)
                          </button>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    {/* <GoogleTranslate /> */}
                    {/* <TranslateComponent /> */}
                  </li>

                  <li className="nav-item header-nav-item header-nav-item d-none">
                    <a
                      onClick={() => {
                        swtchTheme();
                      }}
                      className="nav-link p-0"
                      style={{ cursor: "pointer" }}
                    >
                      <div className="circle_backdrop">
                        <i class="fa fa-sun fs-5" id="theme_icon"></i>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="notification_toggle"
        aria-labelledby="notification_toggleLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="notification_toggleLabel">
            Notifications
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          {
            notificationList.length > 0 ?
              notificationList.map((val, ind) => {
                return <div key={ind} className="notification-box">
                  <div className="icon">
                    {
                      val.type == "1" ?
                        <PiCirclesThreePlusBold />
                        : val.type == "2" ?
                          <IoCard />
                          : val.type == "3" ?
                            <BsInfoCircleFill />
                            : val.type == "5" ?
                              <IoMdChatboxes />
                              : <BsFillGridFill />
                    }
                  </div>
                  <div className="message">
                    <h6>{val.title}</h6>
                    <p>{val.body}</p>
                  </div>
                </div>
              })
              : <>
                <div className=" d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                  <NoData text={"No Notification Found!"} url={"/img/no-noti.png"} />
                </div>
              </>
          }
        </div>
      </div>
    </>
  );
}
