import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaRegStar } from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
import Footer from "./Footer";
import { getRound } from "../redux/helpers/helper_functions";
import useHumanReadableNumber from "../../hooks/useHumanReadableNumber";
import { Sparklines, SparklinesCurve, SparklinesLine } from "react-sparklines";
import { NavLink } from "react-router-dom";
import { Rating } from "@mui/material";
import "./market.css";
import AllCoins from "./markets/AllCoins";
import {
  Addfavoritetoken,
  getFavoritepairedCurrency,
  N_getNewSupportedCurrency,
  Removefavoritetoken,
} from "../redux/helpers/api_functions_new";
import Commingsooncomponent from "../components/commingsooncomponet";
import toast from "react-hot-toast";
import NoData from "../../helper/components/NoData";
import { CiSearch } from "react-icons/ci";

export default function Markets() {
  const formatNumber = useHumanReadableNumber();
  const [loader, setLoader] = useState(true);
  const [favoriteAssets, setFavoriteAssets] = useState([]);
  const [allAssets, setAllAssets] = useState([]);
  const humanReadableNumber = useHumanReadableNumber();
  const [tabSelect, setTabSelect] = useState("spot");
  const [favourites_panel, setFavourites_panel] = useState("spot");
  const [searchCoin, setSearchCoin] = useState(null);
  const [shortCoin, setShortCoin] = useState(null);

  const convertStringToNumber = (input) => {
    const str = String(input);
    const cleanedString = str.replace(/,/g, "");
    return parseFloat(cleanedString);
  };
  function formatNumberWithCommas(number, decimalReq = true, locales = "en-US") {
    if (isNaN(number)) return number; // Return as is if not a valid number

    const [integerPart, decimalPart] = number.toString().split("."); // Split integer & decimal
    const formattedInteger = new Intl.NumberFormat(locales, {
      useGrouping: true,
    }).format(integerPart);

    if (decimalReq) {
      return decimalPart !== undefined
        ? `${formattedInteger}.${decimalPart}`
        : formattedInteger;
    } else {
      return formattedInteger;
    }
  }

  const { coins, campare_currency } = useSelector(
    (state) => state.coinDBReducer
  );
  const { user, profile } = useSelector((state) => state.AuthReducer);
  const userId = user?.user_id;

  let coins1 = Object.values(coins);

  const preferredOrder = ["BTC", "ETH", "XRP", "USDT", "SOL", "BNB", "DOGE"];

  const [favoriteCurrencies, setFavCurrencies] = useState([]);
  useEffect(() => {
    if (tabSelect == "favourite" || tabSelect == "spot") {
      updateFavTokenListing();
    }
  }, [tabSelect]);

  const updateFavTokenListing = () => {
    if (userId && userId != "" && userId != undefined && userId != "undefined") {
      setLoader(true);
      getFavoritepairedCurrency(userId).then((data) => {
        let favoriteTokens = data?.data;
        setFavCurrencies(favoriteTokens);
        fetchFavouriteCurrencies(favoriteTokens);
        setTimeout(() => {
          setLoader(false);
        }, 500);
      }).catch((error) => {
        console.log(error, "<<--------------- error in fav");
        setLoader(false);
      });
    }
  }

  useEffect(() => {
    fetchFavouriteCurrencies();
    if (!userId || userId == "" && userId == undefined && userId == "undefined") {
      if (coins.length > 0) {
        setLoader(false);
      }
    }
  }, [coins]);


  const fetchFavouriteCurrencies = async (favTok = favoriteCurrencies) => {
    try {

      const allCoins = coins1.map((item) => ({
        ...item,
        favorite: favTok.find(
          (favItem) => favItem.currency_coin === item.symbol
        )?.favorite,
        favoriteTokenId: favTok.find(
          (favItem) => favItem.currency_coin === item.symbol
        )?._id,
      }));
      const filteredCoins = allCoins.filter((item) => item.favorite);
      setAllAssets(allCoins);


      if (allCoins.length == 0) {
        console.log("allCoins length ---->>", allCoins.length);
      }
      setFavoriteAssets(filteredCoins);
    } catch (error) {
      console.log(error)
    }
  };
  const handleFavouriteClicked = async (isChecked, id) => {

    // if (!userId || userId == "" && userId == undefined && userId == "undefined") {
    //   toast.error("Please login first!");
    // } else {
    if (!isChecked) {
      const res = await Addfavoritetoken(id);
    } else {
      await Removefavoritetoken(id);
    }
    if (tabSelect == "favourite") {
      updateFavTokenListing();
    }
    toast.success(isChecked ? "Removed from Favourite" : "Added to Favourite", { id });
    // }
  };



  const [newListing, setNewListing] = useState([]);
  useEffect(() => {
    N_getNewSupportedCurrency()
      .then((res) => {
        if (res.status === 200) {
          setNewListing(res.data);
        }
      })
      .catch((error) => {
        console.log(error.message, "<<----------- error message");
      });
  }, []);

  useEffect(() => {
    AOS.init();
    return () => {
      AOS.refresh(); // Cleanup AOS when component unmounts
    };
  }, []);

  const [market_child, setMarketChild] = useState(null);
  useEffect(() => {
    updateTokenLatestUpdate();
  }, [allAssets, favoriteAssets, tabSelect]);



  // Sort coins1 based on preferredOrder
  coins1.sort((a, b) => {
    const indexA = preferredOrder.indexOf(a.symbol);
    const indexB = preferredOrder.indexOf(b.symbol);
    if (indexA === -1) return 1; // If a.symbol is not in preferredOrder, move it to the end
    if (indexB === -1) return -1; // If b.symbol is not in preferredOrder, move it to the end
    return indexA - indexB;
  });

  const updateTokenLatestUpdate = async () => {
    try {
      let result = coins1 &&
        (tabSelect === "all" || tabSelect === "spot" || tabSelect === "new"
          ? allAssets
          : favoriteAssets
        ).length > 0 ? (
        (tabSelect === "all" || tabSelect === "spot" || tabSelect === "new"
          ? allAssets
          : favoriteAssets
        ).filter((item) => {
          // Check if searchCoin has a value and filter based on symbol
          if (searchCoin) {
            return (item.symbol.toLowerCase().includes(searchCoin.toLowerCase()) || item.name.toLowerCase().includes(searchCoin.toLowerCase()));
          }
          return true; // If no searchCoin, include all items

        }).sort((a, b) => {
          if (shortCoin?.key === "24h") {
            if (shortCoin?.value === "asc") {
              // Sort by direction first
              if (a.direction === "up" && b.direction === "down") return -1; // a comes before b
              if (a.direction === "down" && b.direction === "up") return 1; // b comes before a
              // If both have the same direction, sort by price change percentage
              if (a.direction === "up" && b.direction === "up") {
                return b.price_change_percentage_1h - a.price_change_percentage_1h;
              } else {
                return a.price_change_percentage_1h - b.price_change_percentage_1h;
              }
            } else if (shortCoin?.value === "desc") {
              // Sort by direction first
              if (a.direction === "down" && b.direction === "up") return -1; // a comes before b
              if (a.direction === "up" && b.direction === "down") return 1; // b comes before a
              // If both have the same direction, sort by price change percentage

              if (a.direction === "up" && b.direction === "up") {
                return a.price_change_percentage_1h - b.price_change_percentage_1h;
              } else {
                return b.price_change_percentage_1h - a.price_change_percentage_1h;
              }
            }
          } else if (shortCoin?.key === "last-price") {
            if (shortCoin?.value === "asc") {
              return a.current_price - b.current_price;
            } else if (shortCoin?.value === "desc") {
              return b.current_price - a.current_price;
            }
          } else if (shortCoin?.key === "market-cap") {
            if (shortCoin?.value === "asc") {
              return a.market_cap - b.market_cap;
            } else if (shortCoin?.value === "desc") {
              return b.market_cap - a.market_cap;
            }
          } else if (shortCoin?.key === "names") {
            if (shortCoin?.value === "asc") {
              return a.name.localeCompare(b.name);
            } else if (shortCoin?.value === "desc") {
              return b.name.localeCompare(a.name);
            }
          }
        }).map((item, index) => {

          let usdtprize = coins1 && coins1.find((item) => item.symbol === "USDT");
          if (item.symbol === "USDT") return null;
          if (tabSelect === "new" && !newListing.includes(item.symbol))
            return null;

          return (
            <tr style={{ height: "60px" }} className="table_row_border ">
              <td>
                <div className="d-flex align-items-center">
                  <Rating
                    emptyIcon={
                      <FaRegStar
                        style={{ fontSize: "21px" }}
                        className="text-white"
                      />
                    }
                    onChange={() => handleFavouriteClicked(item.favorite, item.favoriteTokenId)}
                    defaultValue={item.favorite ? 1 : 0}
                    className="me-4"
                    max={1}
                    highlightSelectedOnly={true}
                  // size="large"
                  />
                  <img
                    src={item.icon}
                    alt="coin symbol"
                    className="market_coin_img"
                    style={{
                      borderRadius: "50%",
                      background: "white",
                      padding: "0px",
                    }}
                  />
                  {item.name == "Wrapped Monero" ? "Monero" : item.name}
                  <span className="name_coin ms-2">{item.symbol}</span>
                </div>
              </td>
              <td>
                $
                {/* {formatNumberWithCommas(item?.current_price.toFixed(5))} */}
                {formatNumberWithCommas(
                  getRound(item?.current_price), (item?.symbol != "BTC" && item?.symbol != "ETH")
                )}
              </td>
              <td>
                <span
                  style={{
                    color: item.direction === "up" ? "#20ac95" : "#e2464a",
                  }}
                >
                  {item.direction === "up" ? "+" : "-"}{" "}
                  {getRound(item.price_change_percentage_1h)}%{" "}
                  {item.direction === "up" ? (
                    <BsArrowUpShort />
                  ) : (
                    <BsArrowDownShort />
                  )}
                </span>
              </td>
              <td>
                $
                {formatNumber(
                  convertStringToNumber(item.market_cap) /
                  usdtprize?.current_price
                )}
              </td>
              <td className="text-end pe-5">
                <Sparklines>
                  <SparklinesLine color="" data={item.h_trend} />
                </Sparklines>

                <Sparklines data={item.h_trend}>
                  <SparklinesCurve
                    color={item.direction === "up" ? "#20ac95" : "#FF4500"}
                    style={{
                      strokeWidth: 2,
                      strokeLinejoin: "round",
                      strokeLinecap: "round",
                      filter: "drop-shadow(0 0 5px rgba(255, 255, 255, 0.7))",
                    }}
                  />
                </Sparklines>
              </td>
              <td>
                <NavLink
                  to={`/exchange/${item.symbol.toLowerCase()}-${campare_currency}`}
                >
                  <button className="login_btn btn px-3">Trade</button>
                </NavLink>
              </td>
            </tr>
          );
        })
      ) : (
        <>
          <tr>
            <td colSpan={6} style={{ border: "none" }}>
              <div className="text-center py-5 mt-5">
                <NoData />
                {tabSelect === "favourite" && !profile?._id && (
                  <NavLink to={`/login`}>
                    <button className="btn-pink btn px-4 mt-3">Login</button>
                  </NavLink>
                )}
              </div>
            </td>
          </tr>
        </>
      );

      setMarketChild(result);

      // if ((tabSelect === "all" || tabSelect === "spot" || tabSelect === "new"
      //   ? allAssets
      //   : favoriteAssets
      // ).length > 0) {
      //   setLoader(false);
      // }
    } catch (error) {
      console.log(error, "<<------------- token error");
    }
  }


  // Debounce timer
  useEffect(() => {
    const handler = setTimeout(() => {
      updateTokenLatestUpdate();
    }, 500); // 1 second debounce

    return () => {
      clearTimeout(handler); // Cleanup the timer on unmount or when searchCoin changes
    };
  }, [searchCoin, shortCoin]); // Run effect when searchCoin changes

  const handleCoinSearch = (value) => {
    setSearchCoin(value); // Update the search term
  };

  return (
    <>
      <Header />
      <div className="">
        <main className="">
          <div className="page-content" style={{ marginTop: "100px" }}>
            <div className="tab-content">
              <div className="tab-pane fade active show" id="all">
                <div className="spacer">
                  <div className="container">
                    <div className="para_heading text-white">Market Trend</div>
                    {/* new code */}
                    <div class="tab_area market_table pt-3">
                      <div class="container custom_pills">
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="nav nav-pills" id="nav-tab" role="tablist">
                            <div
                              class={`nav-item nav-link   ${tabSelect == "favourite" ? "active" : ""
                                }`}
                              onClick={() => {
                                setTabSelect("favourite");
                                setFavourites_panel("spot");
                              }}
                              data-toggle="tab"
                            >
                              <i className="fa-regular fa-star me-1"></i>
                              Favourites
                            </div>

                            <div
                              class={`nav-item nav-link   ${tabSelect == "spot" ? "active" : ""
                                }`}
                              href="#spot"
                              data-toggle="tab"
                              onClick={() => setTabSelect("spot")}
                            >
                              Spot
                            </div>

                            <div
                              class={`nav-item nav-link   ${tabSelect == "new" ? "active" : ""
                                }`}
                              onClick={() => setTabSelect("new")}
                              data-toggle="tab"
                            >
                              New Listing
                            </div>
                            <div
                              class={`nav-item nav-link   ${tabSelect == "future" ? "active" : ""
                                }`}
                              onClick={() => setTabSelect("future")}
                              data-toggle="tab"
                            >
                              Futures
                            </div>
                          </div>

                          <div className="search-container" style={{ position: 'relative', marginLeft: '20px' }}>
                            <input
                              type="text"
                              placeholder="Search..."
                              className="search-input"
                              onChange={(e) => handleCoinSearch(e.target.value)}
                            />
                            <CiSearch className="search-icon" />
                          </div>

                        </div>
                      </div>
                    </div>
                    {/* end */}
                    <div className="mt-3">
                      {tabSelect == "future" ? (
                        <Commingsooncomponent />
                      ) : (
                        <>
                          {tabSelect === "favourite" && (
                            <>
                              <div className="container d-flex flex-column gap-5  market-page ">
                                <div className="">
                                  <div
                                    className="d-flex gap-2 nav refer-page nav-tabs mb-3 market-nav"
                                    id="nav-tab"
                                    role="tablist"
                                  >
                                    <button
                                      onClick={() =>
                                        setFavourites_panel("spot")
                                      }
                                      className="nav-link active"
                                      id="data-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#data"
                                      type="button"
                                      role="tab"
                                    >
                                      Spot
                                    </button>
                                    <button
                                      onClick={() =>
                                        setFavourites_panel("futures")
                                      }
                                      className="nav-link"
                                      id="referals-tab"
                                      data-bs-toggle="tab"
                                      data-bs-target="#referals"
                                      type="button"
                                      role="tab"
                                    >
                                      Futures
                                    </button>
                                  </div>

                                  {/* tab content */}
                                  {/* <div className="tab-content">
            <div
              className="tab-pane fade show active "
              id="data"
              role="tabpanel"
              aria-labelledby="data-tab"
            >
              <ReferrelData referData={referData} />
            </div>
            <div
              className="tab-pane fade"
              id="referals"
              role="tabpanel"
              aria-labelledby="referals-tab"
            >
              <AllReferrals
                referData={referData}
                handlePageChange={handlePageChange}
                totalPages={totalPages}
              />
            </div>
            <div
              className="tab-pane fade text-white"
              id="commissions"
              role="tabpanel"
              aria-labelledby="commissions-tab"
            >
              <ReferCommission referData={referData} />
            </div>
            <div
              className="tab-pane fade text-white"
              id="referral-codes"
              role="tabpanel"
              aria-labelledby="referral-codes-tab"
            >
              <ReferralCodes referData={referData} />
            </div>
          </div> */}
                                </div>
                              </div>
                            </>
                          )}

                          {tabSelect === "favourite" &&
                            favourites_panel === "spot" && (
                              <AllCoins
                                market_child={market_child}
                                loader={loader}
                                tabSelect={tabSelect}
                                setShortCoin={setShortCoin}
                                shortCoin={shortCoin}
                              />
                            )}
                          {tabSelect !== "favourite" && (
                            <AllCoins
                              market_child={market_child}
                              loader={loader}
                              tabSelect={tabSelect}
                              setShortCoin={setShortCoin}
                              shortCoin={shortCoin}
                            />
                          )}
                          {tabSelect === "favourite" &&
                            favourites_panel == "futures" && (
                              <Commingsooncomponent />
                            )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="favourites">
                favourites
              </div>
              <div className="tab-pane fade" id="spot">
                spot
              </div>
              <div className="tab-pane fade" id="futures">
                Futures
              </div>
              <div className="tab-pane fade" id="listing">
                New Listings
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}
