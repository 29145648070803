import React from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

export default function Footer() {
  AOS.init({ duration: 1000 });
  return (
    <div className="footer_div">
      <div className="container-fluid py-3 footer_bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h5>About Us</h5>
              <ul className="footer_list">
                <li>
                  <Link to="/about">About</Link>
                </li>

                <li>
                  <Link to="/#trade_anytime">Downloads</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy policy</Link>
                </li>
                <li>
                  <Link to="/terms_&_conditions">Terms & Conditions</Link>
                </li>
                <li>
                  <Link to="/bitcash-community">Community</Link>
                </li>
               
              </ul>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h5>Products</h5>
              <ul className="footer_list">
                <li>
                  <Link to="/Markets">Crypto Trading</Link>
                </li>

                <li>
                  <Link to="/p2pOrder">P2P Trading</Link>
                </li>
     
                <li>
                  <Link to="/copytrading">Copy Trading </Link>
                </li>
              
              </ul>
            </div>
 
            <div className="col-lg-3 col-md-6 col-sm-12  ">
              <h5>Support</h5>
              <div>
                <ul className="footer_list">
                  <li>
                    <Link to="/customer-support">
                      24/7 Customer Support System
                    </Link>
                  </li>
          
                  <li>
                    <Link to="/education">Learn with bitcash</Link>
                  </li>
                
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 my-2 mmysocial">
              <h5>Social Links </h5>
              <div className="mt-3">
                <ul className="social-grid">
                  <li>
                    <a
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-3"
                    >
                      <img
                        className="register_social_icon"
                        src="/img/facebook.png"
                      />
                    </a>
                  </li>
                  <li className="">
                    <a
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-3"
                    >
                      <img
                        className="register_social_icon"
                        src="/img/insta.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-3"
                    >
                      <img
                        className="register_social_icon"
                        src="/img/twitter.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-3"
                    >
                      <img
                        className="register_social_icon"
                        src="/img/telegram.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-3"
                    >
                      <img
                        className="register_social_icon"
                        src="/img/linkedin.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                    href=""
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-3"
                    >
                      <img
                        className="register_social_icon"
                        src="/img/tictoc.png"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row mt-5 mb-5">
            <h5>Disclaimer</h5>
            <p className="pt-2 Disclaimer">
              Crypto products & NFTs are unregulated and can be highly risky.
              There may be no regulatory recourse for any loss from such
              transactions. The information and material contained herein are
              subject to change without prior notice including prices which may
              fluctuate based on market demand and supply. The material
              available on the site is proprietary to bitcash, its parent,
              Licensor and/or its affiliates and is for informational purposes
              and informed investors only. This material is not: (i) an offer,
              or solicitation of an offer, to invest in, or to buy or sell, any
              interests or shares, or to participate in any investment or
              trading strategy, or (ii) intended to provide accounting, legal,
              or tax advice, or investment recommendations.
            </p>
            <div className="disclaimer_footer">
              <ul>
                <li style={{ listStyle: "circle" }}>
                  Data sourced as on 01st Feb,2025
                </li>
                <li style={{ listStyle: "circle" }}>
                  Data as on 25th Feb,2025
                </li>
                <li style={{ listStyle: "circle" }}>
                  Quarterly trading volume for Q2 FY'23. Currency conversion
                  rate applied as in data capturing period
                </li>
                <li style={{ listStyle: "circle" }}>
                  Certified in Bangladesh for May 2024-25
                </li>
                <li style={{ listStyle: "circle" }}>
                  Leverage differs as per token
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="divider" />
        <div className="d-flex justify-content-center footer-bottom">
          <span className="disclaimer_footer" style={{ padding: "10px" }}>
            © 2025 bitcash. All Rights Reserved.
          </span>
        </div>
      </div>
    </div>
  );
}
