import React from 'react';
import Header from "./HomeComp/Header";
import { useSelector } from "react-redux";

export default function Security(props) {
  const { isLoggedIn} = useSelector((state) => state.AuthReducer);
  if (!isLoggedIn) props.history?.replace("/login");
  const securityChange = (event) => {
    const id = event.target.id;
    if(id === 'mobile_sms') {
      props.history.push("/mobile-verify");
    }
    if(id === 'none_option') {
      props.history.push("/kyc-verify");
    }
};


  return (
    <>
      <Header {...props}/>
      <div className="signupContainer mdfthemetxt">
        <div className="security_header shead-bg">
          <div className="security_head">
            <div className="sec_circle bg_check_circle shead-circle">
              <i className="fas fa-check-circle"></i>
              <span color="#ffffff" className="email_span">
                Email
              </span>
            </div>
            <div className="sec_circle bg_circle shead-circle">
              <i className="fas fa-dot-circle"></i>
              <span color="#ffffff" className="email_span">
                Security
              </span>
            </div>
            <div className="sec_circle bg_dot_circle shead-circle">
              <i className="fas fa-dot-circle"></i>
              <span color="#ffffff" className="email_span">
                Welcome
              </span>
            </div>
          </div>
        </div>
        <div className="container shead-bg">
          <div className="row">
            <div className="col-12 col-md-12 col-sm-12">
              <form className="signupform" method="post" id="security_form">
                <div>
                  <h3>Security</h3>
                </div>
                <div className="form-group">
                  <small id="msg" style={{ fontSize: "15px" }}>
                    Error message
                  </small>
                </div>
                <div className="form-group">
                  <div className="custom-control custom-radio">
                    <input
                      name="security_option"
                      id="mobile_sms"
                      onClick={(e) => {securityChange(e)}}
                      type="radio"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="mobile_sms">
                      Mobile SMS
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      name="security_option"
                      id="none_option"
                      onClick={(e) => {securityChange(e)}}
                      type="radio"
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" for="none_option">
                      NONE
                    </label>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
