import React, { useState } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { useSelector } from "react-redux";

export default function StartTrade() {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  AOS.init({ duration: 1000 });
  const [showindex, setShowindex] = useState(null);

  // Dummy data for liveData
  const liveData = [
    {
      _id: "1",
      question: "What currency pairs can I trade on Bitcash?",
      answer:
        "On Bitcash, you can trade a wide range of forex currency pairs, including major pairs (e.g., EUR/USD, GBP/USD), minor pairs (e.g., EUR/GBP, AUD/JPY), and exotic pairs. The platform offers both popular and more common pairs for trading flexibility.",
    },
    {
      _id: "2",
      question: "Does Bitcash offer leverage for trading?",
      answer:
        "Yes, Bitcash offers leverage for trading, allowing you to trade with larger positions than your actual account balance. Leverage ratios may vary based on the currency pair and market conditions, and it’s important to use leverage responsibly to manage risk.",
    },
    {
      _id: "3",
      question: "How do I transfer crypto between wallets on Bitcash?",
      answer:
        "To transfer crypto between wallets on Bitcash, go to your wallet section, select the cryptocurrency, and choose the withdraw or transfer option. Enter the recipient’s wallet address or select an internal wallet for transfers within the platform. Confirm the transaction and any network fees that may apply.",
    },
    {
      _id: "4",
      question: "Is there a way to track my crypto portfolio’s performance?",
      answer:
        "Yes, Bitcash provides a portfolio overview where you can track the performance of your crypto holdings. You can see real-time value, price changes, and monitor profits/losses from the platform dashboard.",
    },
  ];
  return (
    <>
      <div className="faq_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="para_heading  d-flex justify-content-between align-items-center">
                <div className="text-white">
                  Frequently{" "}
                  <span className="text-gradient">Asked Questions</span>
                </div>
                <div className="text-end d-none d-md-block">
                </div>
              </div>
              <div className="mt-5">
                {liveData?.map((item, i) => (
                  <>
                    <div
                      className="accordion"
                      id="accordionExample"
                      key={item._id}
                    >
                      <div className={`accordionitem accordion-item`}>
                        <h2 className="accordion-header" id="headingOne2">
                          <button
                            className={`accordionbutton accordion-button
                              ${i === showindex ? "" : "collapsed"}
                              `}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapseOne2${i}`}
                            aria-expanded="true"
                            aria-controls="collapseOne2"
                            onClick={() => setShowindex(i)}
                          >
                            {item.question}
                          </button>
                        </h2>
                        <div
                          id={`collapseOne2${i}`}
                          className={`accordion-collapse collapse ${
                            i === showindex ? "show" : null
                          }`}
                          aria-labelledby="headingOne2"
                          data-bs-parent="#accordionExample"
                        >
                          <div className={`accordionbody accordion-body`}>
                            <p className="p">{item.answer}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
