import React from "react";
import Commingsoon from "../components/commingsoon";
import Header from "../HomeComp/Header";
import Footer from "../HomeComp/Footer";

const EarnPage = () => {
  return (
    <div>
      <Commingsoon />
    </div>
  );
};

export default EarnPage;
