import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import "./sidebar.css";
import {
  N_depositHistory,
  N_p2pHistory,
} from "./redux/helpers/api_functions_new";
import AssetSidebar from "./components/AssetSidebar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Pagination, PaginationItem } from "@mui/material";
import moment from "moment";
import toast from "react-hot-toast";
import formatDate from "../hooks/formatDate";
import { useHistory } from "react-router-dom";
import { BiInfoCircle, BiRefresh } from "react-icons/bi";
import { FaPlus } from "react-icons/fa6";

import { TiMinus } from "react-icons/ti";



export default function P2phistory(props) {
  const router = useHistory();
  const { profile, switch_theme } = useSelector((state) => state.AuthReducer);
  const { coins } = useSelector((state) => state.coinDBReducer);
  const [statusFilter, setStatusFilter] = useState("all");
  const [assets, setAssets] = useState();
  const [refreshFund, setRefreshFund] = useState(false);
  const [action, setAction] = useState("order");
  const [calendarIsShowing, setCalendarIsShowing] = useState(false);
  const [pageData, setPageData] = useState({
    pagination: {
      page: 1,
      limit: 10,
      total: 0,
    },
    filters: {
      status: "all",
      type: "", // New filter state
      startDate: "",
      endDate: "",
    },
    data: [],
    walletInfo: {},
    loading: true
  });

  const updateState = (key, value) => {
    setPageData((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [key]: value
      }
    }));
  };

  useEffect(() => {
    if (coins) {
      updatePair();
    }
  }, [coins]);
  function updatePair() {
    setAssets(Object.values(coins).map((data) => data.symbol));
  }

  const handleRefresh = () => {
    setRefreshFund(true)
    setTimeout(() => {
      setRefreshFund(false)
    }, [1000])
    getHistory()
  }

  const getHistory = useCallback(async () => {
    try {
      const res = await N_p2pHistory({
        ...pageData.filters,
        ...pageData.pagination
      });
      console.log(res, "response");

      const totalPages = Math.ceil(res?.data?.totalItems / pageData.pagination.limit);
      setPageData((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: totalPages,
        },
        data: res?.data?.items,
        walletInfo: res?.data?.walletInfo
      }))
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageData.pagination.page,
    pageData.filters
  ]);

  useEffect(() => {
    setPageData((prev) => ({
      ...prev,
      loading: true
    }));
    getHistory().finally(() => {
      setPageData((prev) => ({
        ...prev,
        loading: false
      }))
    });
  }, [
    getHistory, statusFilter
  ]);
  const handleRedirection = async (url, id) => {
    router.push(url, {
      state: id
    })
  }


  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied...");
  };
  const handlePageChange = (e, value) => {
    setPageData((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page: value
      }
    }));
  };

  const renderHistory = useMemo(() => {
    return pageData?.data?.map((item, index) => (
      <tr key={item.tx_id || "----"}>
        <td className="border-bottom-0 text-muted">
          <p> {formatDate(new Date(
            item.createdAt
          ))}</p>

        </td>
        <td className="border-bottom-0 text-muted">
          <p>{item.order_number || "----"}</p>
        </td>
        <td className="border-bottom-0 text-muted text-capitalize">
          <p>{item.fiat_amount_sent || "----"}{" "}{item.fiat_currency || "----"}</p>
        </td>
        <td className="border-bottom-0 text-muted text-capitalize">
          <p
            style={{ width: "100px" }}
          >
            {
              (item?.status == "complete" || item?.status == "admin_complete") ?
                <> {
                  item?.post_info?.type == "sell" ?
                    <strong className={`${(profile?._id == item?.user_id) ? "text-success" : "text-danger"} d-flex align-items-center`}> {(profile?._id == item?.user_id) ? <FaPlus className="me-1" /> : <TiMinus className="me-1" />}  {" "} {item?.crypto_amount_receive || "0.00"}{" "}  {item?.crypto_currency} </strong>
                    : <strong className={`${(profile?._id != item?.user_id) ? "text-success" : "text-danger"} d-flex align-items-center`}>{(profile?._id != item?.user_id) ? <FaPlus className="me-1" /> : <TiMinus className="me-1" />} {" "}  {item?.crypto_amount_receive || "0.00"}{" "}  {item?.crypto_currency} </strong>
                }</>
                :
                <>{item?.crypto_amount_receive || "0.00"}{" "}  {item?.crypto_currency}</>
            }
          </p>
        </td>
        <td className="border-bottom-0 text-muted">
          <p>

            {
              item.ticket_status == "active" ?
                <span class="badge text-bg-warning">In appeal</span>
                : (item.status == "active" || item.status == "payment_done") ?
                  <span class="badge text-bg-info">Active</span>
                  : (item.status == "complete" || item.status == "admin_complete") ?
                    <span class="badge text-bg-success">Completed</span>
                    : item.status == "cancel" ?
                      <span class="badge text-bg-danger">Cancel</span>
                      : item.status == "reject" ?
                        <span class="badge text-bg-danger">Rejected</span>
                        : item.status == "timeout" ?
                          <span class="badge text-bg-danger">Timeout</span>
                          : <><span class="badge text-bg-danger">{item.status}</span></>
            }
          </p>
        </td>
        <td className="border-bottom-0 text-muted text-center">
          <p style={{ fontSize: '22px' }} className="role-btn" onClick={() => handleRedirection('/order-chat', item._id)}> <BiInfoCircle /></p>
        </td>
      </tr>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData.data]);

  return (
    <>
      <Header />
      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="marginTop">
            <div className="account_page">
              <div className="container">
                <div className="row">
                  <div className="activity_panel mb-4">
                    <div className="w-100">
                      {/*  */}
                      <div className="jambotron-outline mb-0">
                        <div className="page-content-title">
                          <h3 className="">P2P History</h3>
                        </div>
                        <div className="col-lg-12">
                          <div className="flex_row mb-4">
                            <div className="">
                              <span className="assetsUi">
                                Locked Balance :  {pageData?.walletInfo?.p2p_locked ? pageData?.walletInfo?.p2p_locked?.toFixed(2) : "0.00"} USDT
                              </span>
                            </div>

                            <div
                              className="d-flex gap-2 align-items-center cursor_pointer"
                              onClick={() => handleRefresh()}
                            >
                              <BiRefresh
                                className={refreshFund ? "refresh-spin" : ""}
                                size={30}
                              />
                              <span>Refresh</span>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className="tab-pane fade active show" id="open_order">
                    <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            updateState("status", e.target.value);
                          }}
                        >
                          <option value="all" selected>
                            All Status
                          </option>
                          <option value="unpaid">Unpaid</option>
                          <option value="paid">Paid</option>
                          <option value="appeal">Appeal</option>
                          <option value="completed">Completed</option>
                          <option value="failed">Failed</option>
                        </select>
                      </div>
                      <div className="col-lg-3 mb-2 datefiltergroup">
                        <DateRangePicker
                          onApply={(e, picker) => {
                            const startDate =
                              picker.startDate.format("YYYY-MM-DD");
                            const endDate = picker.endDate.format("YYYY-MM-DD");
                            updateState("startDate", startDate);
                            updateState("endDate", endDate);
                          }}
                          onShow={() => setCalendarIsShowing(true)}
                          onHide={() => setCalendarIsShowing(false)}
                        >
                          <input
                            // ref={inputRef}
                            style={{ height: "41px", cursor: "pointer" }}
                            placeholder="Filter by Date"
                            className="form-control date_picker"
                            value={
                              pageData?.filters?.startDate && pageData?.filters?.endDate
                                ? `${pageData.filters.startDate} - ${pageData.filters.endDate}`
                                : ""
                            }
                          />
                        </DateRangePicker>
                        <img
                          onClick={() => {
                            if (!calendarIsShowing) {
                              updateState("startDate", "");
                              updateState("endDate", "");
                            }
                          }}
                          src={
                            calendarIsShowing
                              ? "/img/calendar-remove-lines.svg"
                              : pageData?.filters?.startDate && pageData?.filters?.endDate
                                ? "/img/calendar-remove-lines.svg"
                                : "/img/schedule.svg"
                          }
                          style={{ filter: switch_theme === "dark" ? "invert(1)" : "" }}
                          className="filterimg"
                          alt=""
                          width={20}
                        />
                      </div>
                    </div>

                    {/* <div className="overflow-auto mt-3 history_table"> */}
                      {/* <div className="table_scroll_div"> */}
                        <table className="table global_table history-p2p-tbl">
                          <thead>
                            <tr>
                              <th>Type/Date</th>
                              <th>Order Number</th>
                              <th>Price</th>
                              <th>Amount</th>
                              <th>Status</th>
                              <th className="text-center">Details</th>
                            </tr>
                          </thead>

                          <tbody className="">
                            {/*============= Use this div when no data is found =============== */}
                            {
                              pageData.loading ?

                                <tr>
                                  <td
                                    colSpan={9}
                                    className="text-center border-bottom-0 text-muted"
                                  >
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                                      <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                : pageData?.data?.length > 0 ? (
                                  renderHistory
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={9}
                                      className="text-center border-bottom-0 text-muted"
                                    >
                                      <img
                                        alt=""
                                        src="/img/no-data.png"
                                        className="no-data-found"
                                      />
                                      <p>No records</p>
                                    </td>
                                  </tr>
                                )
                            }

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>

                        <Pagination
                          hidden={pageData.loading || pageData.pagination.total < 2 || pageData?.data?.length <= 0}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          count={pageData.pagination.total}
                          onChange={handlePageChange}
                          renderItem={(item) => (
                            <PaginationItem
                              style={{
                                backgroundColor: !item.selected ? "" : "#552dba",
                                color: "white",
                              }}
                              {...item}
                            />
                          )}
                        />
                      {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}