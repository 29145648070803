

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const TradeTab = (props) => {
  const [trades, setTrades] = useState([]);
  let symbol = "btcusdt";

  const { coins, currency_graph, user_fav_pairing, paired_curency_price } =
    useSelector((state) => state.coinDBReducer);
  const coin = props.match.params.id.split("-");
  const SelCurency = coin && coin[1] ? coin[1].toUpperCase() : "";
  const data = Object.values(coins).find((d) => {
    if (d.symbol === coin[0].toUpperCase()) {
      return d;
    }
  });

  useEffect(() => {
    let match = user_fav_pairing.find((d) => d === data.symbol.toUpperCase());
    // setIsFav(match ? true : false);
  }, [...user_fav_pairing, ...coin]);

  useEffect(() => {
    const symbol = data?.symbol ? data.symbol.toLowerCase() : "btc";
    const ws = new WebSocket(
      `wss://stream.binance.com:9443/ws/${symbol}usdt@trade`
    );

    ws.onmessage = (event) => {
      const trade = JSON.parse(event.data);

      // Update the trade list with the latest trade
      setTrades((prevTrades) => {
        const newTrades = [
          {
            price: trade.p, // Trade price
            quantity: trade.q, // Trade quantity
            time: new Date(trade.T).toLocaleTimeString(), // Trade time
            isBuyerMaker: trade.m, // Buyer is the market maker
          },
          ...prevTrades,
        ];
        return newTrades.slice(0, 16); // Limit the history to the last 20 trades
      });
    };

    ws.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    return () => {
      ws.close(); // Clean up WebSocket connection on component unmount
    };
  }, [symbol]);

  return (
    <div className="trades-container">
    <div className="trades-header">
      <span>Price (USDT)</span>
      <span>Quantity</span>
      <span>Time</span>
    </div>
    <div className="trades-body">
      {trades.map((trade, index) => (
        <div key={index} className="trades-row">
          <span className={`trades-price ${trade.isBuyerMaker ? "sell" : ""}`}>
            {Number(trade.price)?.toFixed(5)}
          </span>
          <span>{Number(trade.quantity)?.toFixed(5)}</span>
          <span>{trade.time}</span>
        </div>
      ))}
    </div>
  </div>
  
  );
};

export default TradeTab;
