import React, { useState, useEffect } from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./HomeComp/Header";
import { useSelector } from "react-redux";
import $ from "jquery";
import { isNum } from "./redux/helpers/form-validator.functions";
import {
  N_checkKYCStatus,
  N_ischeckKycSubmited,
  N_setKyc,
  N_test
} from "./redux/helpers/api_functions_new";
import toast from "react-hot-toast";

export default function AuthKyc(props) {
  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);
  const [visibl, setVisiblity] = useState("h");
  // const [visib, setVisiblty] = useState("h");
  const [doc_title, setTitle] = useState();
  // const [v, setv] = useState();
  const [filedata, setFileData] = useState();
  const [camera_type, setcameratype] = useState("user");

  // const constraints = {
  //   audio: false,
  //   video: {
  //     width: { min: 239, max: 239 },
  //     height: { min: 239, max: 239 },
  //     ideal: 1280,
  //     facingMode: camera_type,
  //   },
  // };
  if (!isLoggedIn) props.history?.replace("/login");
  else if (user.kyc === -1) props.history?.replace("/");

  useEffect(async () => {
    N_checkKYCStatus(user?.params ? user.params.user_id : user.user_id)
      .then((d) => {
        if (d.status === 200) {
          if (d.params.kyc_status === 1) {
            toast.success(d.message);
            props.history?.replace("/login");
          } else if (d.params.kyc_status === -1) {
            toast.success(d.message);
            props.history?.replace("/");
          }
        } else {
          toast.error(d.message);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  // function showCambtn(event) {
  //   const sel_btn = event.target.parentElement;
  //   sel_btn.className = "signupform-control h";
  //   setVisiblty("vis");
  // }

  // const play = async () => {
  //   if (
  //     "mediaDevices" in navigator &&
  //     "getUserMedia" in navigator.mediaDevices
  //   ) {
  //     // ok, browser supports it
  //     const video = document.querySelector("#video");
  //     const videoStream = await navigator.mediaDevices.getUserMedia(
  //       constraints
  //     );
  //     video.srcObject = videoStream;
  //     setv(video);
  //   }
  // };

  // const takepicture = async () => {
  //   const canvas = document.querySelector("#canvas");
  //   let mime_type = "image/jpeg";
  //   let quality = 30;
  //   canvas.width = v.videoWidth;
  //   canvas.height = v.videoHeight;
  //   canvas.getContext("2d").drawImage(v, 0, 0);
  //   document.getElementById("uploaded_img").src = canvas.toDataURL("image/png");
  //   var file = dataURLtoFile(canvas.toDataURL("image/png"), "selfie.png");
  //   setFileData(file);
  //   document.getElementById("cam").className = "h";
  //   document.getElementById("pic_img").className = "vis";
  //   $("#uploaded_img_btn").show();
  // };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // function resumeCam() {
  //   document.getElementById("pic_img").className = "h";
  //   document.getElementById("cam").className = "vis";
  //   play();
  // }
  const comp_btn = (event) => {
    const kyc_btn = event.target.parentElement;
    kyc_btn.className = "signupform-control h";
    setVisiblity("vis");
  };

  function getCard(event) {
    if (document.getElementById("doc_titile").className === "h") {
      document.getElementById("next_doc_btn").style.display = "block";
    }
    // console.log("id: ", event.target.id);
    if (event.target.id === "adhar_card") {
      document.getElementById("adhar").className = "vis";
      document.getElementById("dl").className = "h";
      document.getElementById("passport").className = "h";
      setTitle("Adhar");
    }
    if (event.target.id === "dl_card") {
      document.getElementById("dl").className = "vis";
      document.getElementById("adhar").className = "h";
      document.getElementById("passport").className = "h";
      setTitle("Driver’s License");
    }
    if (event.target.id === "passport_card") {
      document.getElementById("adhar").className = "h";
      document.getElementById("dl").className = "h";
      document.getElementById("passport").className = "vis";
      setTitle("Passport");
    }
  }

  function readURL(input) {
    const current_img = input.target.parentElement;
    const img = "#" + current_img.querySelector("img").id;
    const btn = "#" + current_img.querySelector("Button").id;
    const file = input.target.files[0];
    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      setFileData(file);
      reader.onload = function (e) {
        $(img).attr("src", e.target.result);
        $(btn).removeClass("h").addClass("vis");
      };
    }
  }
  function nextCard(event) {
    if (event.target.id === "next_doc_btn") {
      document.getElementById("next_doc_btn").style.display = "none";
      $("#doc_titile").removeClass("h").addClass("vis");
      document.getElementById("next_selfie_btn").style.display = "block";
    }
    if (event.target.id === "next_selfie_btn") {
      document.getElementById("next_selfie_btn").style.display = "none";
      $("#pan_detail").removeClass("h").addClass("vis");
    }
  }

  return (
    <>
      <Header {...props} />

      {/* <ProfileSidebar {...props} /> */}

      <div className="main-content">
        <div className="mt-3">
          <h4 className="mb-3">Verify KYC</h4>
          <div className="card">
            <div className="card-body">
              {/* <div className="security_header">
            <div className="security_head">
              <div className="sec_circle bg_check_circle">
                <i className="fas fa-check-circle"></i>
                <span color="#ffffff" className="email_span">
                  personal info
                </span>
              </div>
              <div className="sec_circle bg_circle">
                <i className="fas fa-dot-circle"></i>
                <span color="#ffffff" className="email_span">
                  uploads
                </span>
              </div>
            </div>
          </div> */}
              <div className="">
                <div className="row">
                  <div className="col-12 col-md-12 col-sm-12 col-lg-6">
                    <form
                      className=""
                      method="post"
                      id="welcome_form"
                      encType="multipart/form-data"
                    >
                      <div className="">
                        <h4>Complete your KYC</h4>
                        <p>
                          You will be redirected to a trusted third-party
                          service for instant KYC verification where you have
                          to:
                        </p>
                        <ul className="mb-4">
                          <li>
                            1. Upload Aadhar / Passport / Driver’s License
                          </li>
                          <li>2. Click and Upload Selfie</li>
                          <li>3. Upload Pan Card</li>
                        </ul>
                        <div>
                          <span className="text-red">Note:</span> We have also
                          sent you a website URL via mobile SMS. If you want to
                          continue KYC verification process via mobile, please
                          click on the link in the SMS.
                        </div>
                        <div className="my-3">
                          <button
                            type="button"
                            id="back_per_btn"
                            name="back_per_btn"
                            className="btn btn-outline-primary me-2"
                          >
                            Re-submit Kyc Form
                          </button>

                          <button
                            type="button"
                            id="proceed_btn"
                            onClick={(e) => {
                              comp_btn(e);
                            }}
                            className="btn btn-primary"
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                      <div id="Doc_page" className={visibl}>
                        <div className="signupform-control mt-2">
                          <h5 className="mt-3">
                            Select one Aadhar / Passport / Driver’s License{" "}
                            <span className="text-red">*</span>
                          </h5>
                          <div className="form-check pt-2">
                            <input
                              name="card"
                              id="adhar_card"
                              onClick={(e) => {
                                getCard(e);
                              }}
                              type="radio"
                              className="form-check-input"
                            />
                            <label
                              htmlFor="adhar_card"
                              className="form-check-label"
                            >
                              Adhar card
                            </label>
                          </div>
                          <div className="form-check pt-2">
                            <input
                              name="card"
                              id="dl_card"
                              onClick={(e) => {
                                getCard(e);
                              }}
                              type="radio"
                              className="form-check-input"
                            />
                            <label
                              htmlFor="dl_card"
                              className="form-check-label"
                            >
                              Driver’s License
                            </label>
                          </div>
                          <div className="form-check pt-2">
                            <input
                              name="card"
                              id="passport_card"
                              onClick={(e) => {
                                getCard(e);
                              }}
                              type="radio"
                              className="form-check-input"
                            />
                            <label htmlFor="passport_card">Passport</label>
                          </div>
                        </div>
                        <div className="h" id="adhar">
                          <div className="col-lg-8 my-4">
                            <label htmlFor="user_adhar">
                              Adhar Card Number{" "}
                              <span className="text-red">*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                name="user_adhar"
                                required="required"
                                maxLength={12}
                                minLength={12}
                                className="form-control"
                                id="user_adhar"
                                onChange={(e) => {
                                  isNum(e.target.value) &&
                                  e.target.value.length == 12
                                    ? (document.getElementById(
                                        "user_adhar_btn"
                                      ).style.display = "block")
                                    : (document.getElementById(
                                        "user_adhar_btn"
                                      ).style.display = "none");
                                }}
                                placeholder="XXXXXXXXXXXX"
                              />
                              <span className="input-group-text">
                                <button
                                  type="button"
                                  id="user_adhar_btn"
                                  onClick={(e) => {
                                    isNum(
                                      document.getElementById("user_adhar")
                                        .value
                                    )
                                      ? N_setKyc(
                                          e,
                                          user?.params
                                            ? user.params.user_id
                                            : user.user_id
                                        )
                                      : toast.error(
                                          "Not a valid adhaar"
                                        );
                                  }}
                                  className="uploadbtn mt-0 h"
                                >
                                  Send
                                </button>
                                <div
                                  className="spinner-border text-primary"
                                  style={{ display: "none" }}
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <h6 className="text-dark">
                                Upload front image Aadhar{" "}
                              </h6>
                              <div className="mt-2">
                                <div className="mb-2">
                                  <img
                                    src="/img/adhar_front.png"
                                    height="150px"
                                    width="250px"
                                    id="front_adhar"
                                    alt="adhar pic"
                                    className=""
                                  />
                                </div>
                                <input
                                  id="upload_front_adhar"
                                  type="file"
                                  onChange={(e) => {
                                    readURL(e);
                                  }}
                                  className="form-control kyc_image--"
                                />
                                <button
                                  type="button"
                                  id="adhar_front_btn"
                                  onClick={(e) => {
                                    N_test(
                                      e,
                                      filedata,
                                      "docf",
                                      user?.params
                                        ? user.params.user_id
                                        : user.user_id
                                    );
                                  }}
                                  className="uploadbtn h"
                                >
                                  Send
                                </button>
                                <div
                                  className="spinner-border text-primary"
                                  style={{ display: "none" }}
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <h6>
                                Upload back image Aadhar{" "}
                                <span className="text-red">*</span>
                              </h6>
                              <div className="signupform-control mt-2">
                                <div className="mb-2">
                                  <img
                                    src="/img/adhar_back.png"
                                    height="150px"
                                    width="250px"
                                    id="back_adhar"
                                    alt="adhar pic"
                                  />
                                </div>
                                <input
                                  id="upload_back_adhar"
                                  type="file"
                                  onChange={(e) => {
                                    readURL(e);
                                  }}
                                  className="form-control"
                                />
                                <button
                                  type="button"
                                  id="adhar_back_btn"
                                  onClick={(e) => {
                                    N_test(
                                      e,
                                      filedata,
                                      "docb",
                                      user?.params
                                        ? user.params.user_id
                                        : user.user_id
                                    );
                                  }}
                                  className="uploadbtn h"
                                >
                                  Send
                                </button>
                                <div
                                  className="spinner-border text-primary"
                                  style={{ display: "none" }}
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="h" id="dl">
                          <div className="col-lg-8 my-4">
                            <label htmlFor="user_dl">
                              Driver's License Card Number{" "}
                              <span className="text-red">*</span>
                            </label>
                            <div className="input-group mb-3">
                              <input
                                type="text"
                                name="user_dl"
                                required="required"
                                className="form-control"
                                id="user_dl"
                                placeholder="XXXXXXXXXXXX"
                              />
                              <span className="input-group-text">
                                <button
                                  type="button"
                                  id="user_dl_btn"
                                  onClick={(e) => {
                                    N_setKyc(
                                      e,
                                      user?.params
                                        ? user.params.user_id
                                        : user.user_id
                                    );
                                  }}
                                  className="uploadbtn-- border-0 bg-transparent mt-0"
                                >
                                  Send
                                </button>
                              </span>
                              <div
                                className="spinner-border text-primary"
                                style={{ display: "none" }}
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <h6>Upload front image Driver’s License *</h6>

                              <div className="mb-2">
                                <img
                                  src="/img/selfie.png"
                                  height="150px"
                                  width="250px"
                                  id="front_dl"
                                  alt="License pic"
                                />
                              </div>
                              <input
                                id="upload_front_dl"
                                type="file"
                                onChange={(e) => {
                                  readURL(e);
                                }}
                                className="form-control"
                              />
                              <button
                                type="button"
                                id="dl_front_btn"
                                onClick={(e) => {
                                  N_test(
                                    e,
                                    filedata,
                                    "docf",
                                    user?.params
                                      ? user.params.user_id
                                      : user.user_id
                                  );
                                }}
                                className="uploadbtn h"
                              >
                                Send
                              </button>
                              <div
                                className="spinner-border text-primary"
                                style={{ display: "none" }}
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              {" "}
                              <h6>
                                Upload back image Driver’s License{" "}
                                <span className="text-red">*</span>
                              </h6>
                              <div className="mb-2">
                                <img
                                  src="/img/selfie.png"
                                  height="150px"
                                  width="250px"
                                  id="back_dl"
                                  alt="License pic"
                                />
                              </div>
                              <input
                                id="upload_back_dl"
                                type="file"
                                onChange={(e) => {
                                  readURL(e);
                                }}
                                className="form-control"
                              />
                              <button
                                type="button"
                                id="dl_back_btn"
                                onClick={(e) => {
                                  N_test(
                                    e,
                                    filedata,
                                    "docb",
                                    user?.params
                                      ? user.params.user_id
                                      : user.user_id
                                  );
                                }}
                                className="uploadbtn h"
                              >
                                Send
                              </button>
                              <div
                                className="spinner-border text-primary"
                                style={{ display: "none" }}
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="h" id="passport">
                          <div className="col-lg-8 my-4">
                            <label htmlFor="user_passport">
                              Passport Card Number *
                            </label>
                            <div className="input-group-mb-3">
                              <input
                                type="text"
                                name="user_passport"
                                required="required"
                                className="form-control"
                                id="user_passport"
                                placeholder="XXXXXXXXXXXX"
                              />
                              <span className="input-group-text">
                                <button
                                  type="button"
                                  id="user_passport_btn"
                                  onClick={(e) => {
                                    N_setKyc(
                                      e,
                                      user?.params
                                        ? user.params.user_id
                                        : user.user_id
                                    );
                                  }}
                                  className=" border-0 bg-transparent"
                                >
                                  Send
                                </button>
                                <div
                                  className="spinner-border text-primary"
                                  style={{ display: "none" }}
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>{" "}
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <h6>
                                Upload front image Passport{" "}
                                <span className="text-red">*</span>
                              </h6>
                              <div className="signupform-control mt-2">
                                <div className="mb-2">
                                  <img
                                    src="/img/selfie.png"
                                    height="150px"
                                    width="250px"
                                    id="front_passport"
                                    alt="Passport pic"
                                  />
                                </div>
                                <input
                                  id="upload_front_passport"
                                  type="file"
                                  onChange={(e) => {
                                    readURL(e);
                                  }}
                                  className="form-control"
                                />
                                <button
                                  type="button"
                                  id="passport_front_btn"
                                  onClick={(e) => {
                                    N_test(
                                      e,
                                      filedata,
                                      "docf",
                                      user?.params
                                        ? user.params.user_id
                                        : user.user_id
                                    );
                                  }}
                                  className="uploadbtn h"
                                >
                                  Send
                                </button>
                                <div
                                  className="spinner-border text-primary"
                                  style={{ display: "none" }}
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              {" "}
                              <h6>
                                Upload back image Passport{" "}
                                <span className="text-red">*</span>
                              </h6>
                              <div className="signupform-control mt-2">
                                <div className="mb-2">
                                  <img
                                    src="/img/selfie.png"
                                    height="150px"
                                    width="250px"
                                    id="back_passport"
                                    alt="Passport pic"
                                  />
                                </div>
                                <input
                                  id="upload_back_passport"
                                  type="file"
                                  onChange={(e) => {
                                    readURL(e);
                                  }}
                                  className="form-control"
                                />
                                <button
                                  type="button"
                                  id="passport_back_btn"
                                  onClick={(e) => {
                                    N_test(
                                      e,
                                      filedata,
                                      "docb",
                                      user?.params
                                        ? user.params.user_id
                                        : user.user_id
                                    );
                                  }}
                                  className="uploadbtn h"
                                >
                                  Send
                                </button>
                                <div
                                  className="spinner-border text-primary"
                                  style={{ display: "none" }}
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          id="next_doc_btn"
                          onClick={(e) => {
                            nextCard(e);
                          }}
                          className="reg_btn btn btn-primary mt-3"
                          style={{ display: "none" }}
                        >
                          Next &raquo;
                        </button>
                        <div className="h" id="doc_titile">
                          <div className="col-lg-4 mt-4">
                            <h6>Upload a photo with holding {doc_title} *</h6>
                            <div className="signupform-control mt-2">
                              <div className="mb-2">
                                <img
                                  src="/img/selfie.png"
                                  height="150px"
                                  width="250px"
                                  id="uploaded_img"
                                  alt="uploaded img pic"
                                />
                              </div>
                              <input
                                id="uploaded_img"
                                type="file"
                                onChange={(e) => {
                                  readURL(e);
                                }}
                                className="form-control"
                              />
                              <button
                                type="button"
                                id="uploaded_img_btn"
                                onClick={(e) => {
                                  N_test(
                                    e,
                                    filedata,
                                    "docs",
                                    user?.params
                                      ? user.params.user_id
                                      : user.user_id
                                  );
                                }}
                                className="uploadbtn h"
                              >
                                Send
                              </button>
                              <div
                                className="spinner-border text-primary"
                                style={{ display: "none" }}
                                role="status"
                              >
                                <span className="sr-only">Loading...</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div id="doc_titile" className="h">
                      <div>Upload a photo with holding {doc_title} *</div>
                      <div className="signupform-control mt-2">
                        <img
                          src="/img/selfie.png"
                          height="150px"
                          width="200px"
                          alt="selfie pic"
                        />
                        <button
                          type="button"
                          id="cam_btn"
                          name="cam_btn"
                          onClick={(e) => {
                            showCambtn(e);
                            play();
                          }}
                          className="backbtn ml-2"
                        >
                          Click and Upload Selfie
                        </button>
                      </div>
                      <div className={visib} id="cam">
                        <div className="display-cover">
                          <video autoPlay id="video"></video>
                          <canvas
                            className="d-none position-absolute"
                            id="canvas"
                          ></canvas>

                          <div id="img-cont"></div>

                          <div className="control">
                            <button
                              className="btn mr-4"
                              onClick={() => {
                                camera_type === "user"
                                  ? setcameratype("environment")
                                  : setcameratype("user");
                                play();
                              }}
                            >
                              <MdCameraswitch
                                size={30}
                                style={{ color: "black" }}
                              />
                            </button>
                            <button
                              className="btn text-dark"
                              style={{ background: "rgba(0,0,0,0.2)" }}
                              onClick={() => takepicture()}
                            >
                              Capture
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 h" id="pic_img">
                        <img
                          id="uploaded_img"
                          height="220px"
                          width="300px"
                          alt="uploaded pic"
                          style={{ margin: "0 auto 10px" }}
                        />
                        <br />
                        <button
                          type="button"
                          className="cancelbtn"
                          onClick={(e) => {
                            resumeCam();
                          }}
                          style={{ margin: "0 30px" }}
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          id="uploaded_img_btn"
                          onClick={(e) => {
                            N_test(e, filedata, "docs", user?.params ? user.params.user_id : user.user_id);
                          }}
                          className="uploadbtn"
                        >
                          Send
                        </button>
                        <div
                          className="spinner-border text-primary"
                          style={{ display: "none" }}
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div> */}
                        <button
                          type="button"
                          id="next_selfie_btn"
                          onClick={(e) => {
                            nextCard(e);
                          }}
                          className="btn btn-primary mt-2"
                          style={{ display: "none" }}
                        >
                          Next &raquo;
                        </button>
                        <div className="h" id="pan_detail">
                          <div className="col-lg-6 col-12 my-4">
                            <label htmlFor="user_pancard">
                              Pan Card Number{" "}
                              <span className="text-red">*</span>
                            </label>
                            <input
                              type="text"
                              name="user_pancard"
                              required="required"
                              className="form-control"
                              maxLength={10}
                              minLength={10}
                              onChange={(e) => {
                                e.target.value.length == 10
                                  ? (document.getElementById(
                                      "user_pancard_btn"
                                    ).style.display = "block")
                                  : (document.getElementById(
                                      "user_pancard_btn"
                                    ).style.display = "none");
                              }}
                              id="user_pancard"
                              placeholder="XXXXXXXXXXXX"
                            />
                            <button
                              type="button"
                              id="user_pancard_btn"
                              onClick={(e) => {
                                N_setKyc(
                                  e,
                                  user?.params
                                    ? user.params.user_id
                                    : user.user_id
                                );
                              }}
                              className="uploadbtn mt-0 h"
                            >
                              Send
                            </button>
                            <div
                              className="spinner-border text-primary"
                              style={{ display: "none" }}
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                          <h6>
                            Upload front image Pan Card{" "}
                            <span className="text-red">*</span>
                          </h6>
                          <div className="col-lg-4 mt-2">
                            <div className="mb-2">
                              <img
                                src="/img/pan_front.png"
                                height="150px"
                                width="250px"
                                id="front_pan"
                                alt="pancard pic"
                              />
                            </div>
                            <input
                              id="upload_front_pan"
                              type="file"
                              onChange={(e) => {
                                readURL(e);
                              }}
                              className="form-control"
                            />
                            <button
                              type="button"
                              id="front_pan_btn"
                              onClick={(e) => {
                                N_test(
                                  e,
                                  filedata,
                                  "panf",
                                  user?.params
                                    ? user.params.user_id
                                    : user.user_id
                                );
                              }}
                              className="uploadbtn h"
                            >
                              Send
                            </button>
                            <div
                              className="spinner-border text-primary"
                              style={{ display: "none" }}
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>

                          <button
                            type="button"
                            id="Done_btn"
                            onClick={() => {
                              N_ischeckKycSubmited(
                                user?.params
                                  ? user.params.user_id
                                  : user.user_id,
                                document.getElementById("user_pancard").value
                              ).then((d) => {
                                if (d.status === 200) {
                                  toast.info(d.message);
                                  document.location.reload();
                                } else {
                                  // console.log(d);
                                  toast.error(d.message);
                                }
                              });
                            }}
                            className="btn btn-primary mt-3"
                          >
                            <i className="loading-icon fas fa-spinner fa-spin h"></i>
                            <span id="reg">Done</span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
