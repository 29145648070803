import React, { useEffect, useMemo, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    IconButton,
    Box,
    FormControl,
    Select,
    MenuItem,
    ListItem,
    List,
    ListItemIcon,
    ListItemText,
    Radio,
    CircularProgress
} from '@mui/material';
import { IoCloseSharp as CloseIcon } from "react-icons/io5";
import { useSelector } from 'react-redux';

const DataDialog = ({ open, onClose, onSave }) => {
    const { profile, switch_theme } = useSelector((state) => state.AuthReducer);
    const [countries, setCountries] = useState([]);
    const [payload, setPayload] = useState({
        user_name: profile?.user_name,
        country: profile?.country,
        gender: profile?.gender,
    });
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const handleSave = () => {
        setLoading(true)
        onSave(payload, setLoading);
    };

    // Effect to fetch countries when dialog opens
    useEffect(() => {
        if (open?.location) {
            const fetchCountries = async () => {
                try {
                    const response = await fetch('https://restcountries.com/v3.1/all?fields=name,flags,cca2');

                    if (!response.ok) {
                        throw new Error('Failed to fetch countries');
                    }

                    const data = await response.json();
                    setCountries(data); // Store raw data
                } catch (err) {
                    console.error('Error fetching countries:', err);
                }
            };

            fetchCountries();
        }
    }, [open]);

    // Use useMemo to process countries data
    const formattedCountries = useMemo(() => {
        if (!countries.length) return [];

        return countries
            .map(country => ({
                name: country.name.common,
                flag: country.flags.svg,
                code: country.cca2
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [countries]);

    // Add filtered countries memo
    const filteredCountries = useMemo(() => {
        return formattedCountries.filter(country =>
            country.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [formattedCountries, searchQuery]);

    return (
        <Dialog
            open={Object.keys(open).filter((key) => key !== 'avatar').some((key) => open[key])}
            onClose={() => {
                onClose();
                setPayload({
                    user_name: "",
                    location: "",
                    gender: "",
                })
            }}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 3,
                    p: 2,
                    bgcolor: switch_theme === "dark" ? "#0000007d" : "",
                    border: '0.5px solid gray',
                    maxWidth: 500
                }
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <DialogTitle
                    sx={{
                        fontSize: '28px',
                        fontWeight: 'bold',
                        p: 1,
                        color: switch_theme === 'dark' ? '#fff' : "#000"
                    }}
                >
                    {open?.name ? "Username" : open?.gender ? "Gender" : open?.location && "Location"}
                </DialogTitle>
                <IconButton
                    onClick={() => {
                        onClose();
                        setPayload({
                            user_name: "",
                            location: "",
                            gender: "",
                        })
                    }}

                    sx={{ mr: 1 }}
                >
                    <CloseIcon color={switch_theme === 'dark' ? '#fff' : "#000"} />        </IconButton>
            </Box>

            <DialogContent sx={{ pt: 1, pb: 3, p: 1 }}>
                {
                    open?.name
                        ?
                        <TextField
                            autoFocus
                            fullWidth
                            value={payload?.user_name}
                            onChange={(e) => setPayload(prev => ({ ...prev, user_name: e.target.value }))}
                            variant="outlined"
                            sx={{
                                borderRadius: '8px',
                                bgcolor: switch_theme === 'dark' ? '#f5f5f5' : "",
                                '& .MuiOutlinedInput-root': {
                                    borderRadius: '8px',
                                    fontSize: '18px'
                                }
                            }}
                        />
                        : open?.gender ?
                            <>
                                <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
                                    <Select
                                        sx={{
                                            borderRadius: '8px',
                                            bgcolor: switch_theme === 'dark' ? '#f5f5f5' : "",
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '8px',
                                                fontSize: '18px'
                                            }
                                        }}
                                        value={payload.gender}
                                        onChange={(e) => setPayload(prev => ({ ...prev, gender: e.target.value }))}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value="Female">Female</MenuItem>
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                            :
                            open?.location &&
                            <>
                                <TextField
                                    fullWidth
                                    placeholder="Search country..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    sx={{
                                        mb: 2,
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '8px',
                                            bgcolor: switch_theme === 'dark' ? '#f5f5f5' : "",
                                        },
                                        '& .MuiOutlinedInput-input': {
                                            fontSize: '16px',
                                            padding: '12px 14px',
                                        }
                                    }}
                                />
                                <Box sx={{ 
                                    maxHeight: '400px', 
                                    overflow: 'auto',
                                    '&::-webkit-scrollbar': {
                                        width: '8px',
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: '#f1f1f1',
                                        borderRadius: '4px',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: '#888',
                                        borderRadius: '4px',
                                    },
                                    '&::-webkit-scrollbar-thumb:hover': {
                                        background: '#555',
                                    },
                                }}>
                                    <List sx={{ width: '100%' }}>
                                        {!formattedCountries?.length > 0 ? (
                                            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                                                <CircularProgress />
                                            </Box>
                                        ) : filteredCountries.length === 0 ? (
                                            <Box sx={{ 
                                                display: 'flex', 
                                                justifyContent: 'center', 
                                                py: 2,
                                                color: switch_theme === 'dark' ? '#f5f5f5' : '#666'
                                            }}>
                                                No countries found
                                            </Box>
                                        ) : (
                                            filteredCountries?.map((country) => (
                                                <ListItem
                                                    key={country.code}
                                                    onClick={() => setPayload(prev => ({ ...prev, country: country?.name }))}
                                                    sx={{
                                                        borderRadius: 1,
                                                        cursor: 'pointer'
                                                    }}
                                                    role="button"
                                                    tabIndex={0}
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter' || e.key === ' ') {
                                                            setPayload(prev => ({ ...prev, country: country?.name }))
                                                        }
                                                    }}
                                                >
                                                    <ListItemIcon sx={{ minWidth: 44 }}>
                                                        {typeof country.flag === 'string' && country.flag.endsWith('.svg') ? (
                                                            <Box
                                                                component="img"
                                                                src={country.flag}
                                                                alt={`${country.name} flag`}
                                                                sx={{
                                                                    width: 24,
                                                                    height: 18,
                                                                    objectFit: 'cover',
                                                                    border: '1px solid #eaeaea'
                                                                }}
                                                            />
                                                        ) : (
                                                            <Box
                                                                component="span"
                                                                sx={{ fontSize: '1.5rem' }}
                                                            >
                                                                {country.flag}
                                                            </Box>
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText 
                                                     sx={{
                                                        color: switch_theme === 'dark' ? '#f5f5f5' : "",
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: '8px',
                                                            fontSize: '18px'
                                                        },
                                                     }}
                                                     primary={country.name} 
                                                    />
                                                    <Radio
                                                        checked={payload?.country === country?.name}
                                                        onChange={() => setPayload(prev => ({ ...prev, country: country?.name }))}
                                                        sx={{
                                                            color: switch_theme === 'dark' ? '#f5f5f5' : "",
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 24,
                                                            },
                                                            '&.Mui-checked': {
                                                                color: '#0052ff',
                                                            },
                                                        }}
                                                    />
                                                </ListItem>
                                            ))
                                        )}
                                    </List>
                                </Box>
                            </>
                }

            </DialogContent>

            <DialogActions sx={{ p: 1, pb: 2 }}>
                <Button
                    onClick={() => {
                        onClose();
                        setPayload({
                            user_name: "",
                            location: "",
                            gender: "",
                        })
                    }}
                    className='btn-outline-pink text-white'
                    // variant="outlined"
                    // sx={{
                    //     borderRadius: '4px',
                    //     width: '100%',
                    //     py: 1.5,
                    //     textTransform: 'none',
                    //     fontSize: '16px',
                    //     fontWeight: 'medium',
                    //     color: switch_theme === 'dark' ? '#fff' : "#000",
                    //     borderColor: '#ddd'
                    // }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSave}
                   className='login_btn'
                    // variant="contained"
                    // disabled={loading}
                    // sx={{
                    //     borderRadius: '4px',
                    //     width: '100%',
                    //     py: 1.5,
                    //     textTransform: 'none',
                    //     fontSize: '16px',
                    //     fontWeight: 'medium',
                    //     bgcolor: loading ? '#8eaee6 !important' : '#2a27da !important',
                    //     color: 'white',
                    //     '&:hover': {
                    //         bgcolor: '#8eaee6'
                    //     }
                    // }}
                >
                    {loading ? "Saving..." : "Save"}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DataDialog;