import React, { useEffect, useState } from "react";
import Header from "../HomeComp/Header";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../HomeComp/Footer";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FaTrash } from "react-icons/fa";
import {
  N_addPostTerms,
  N_deletePaymentMethods,
  N_getPaymentMethods,
  N_getPostTerms,
} from "../redux/helpers/api_functions_new";
import NoDataComp from "../components/NoDataComp";
import Loader from "../components/Loader";
import JoditEditor from "jodit-react";
import { NotificationManager } from "react-notifications";
import { IoIosSave } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import BackComp from "../../helper/components/BackComp";
import toast from "react-hot-toast";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin5Line } from "react-icons/ri";
import Swal from "sweetalert2";


const PaymentMethod = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [hide, setHide] = useState(true);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const { isLoggedIn, user, profile } = useSelector(
    (state) => state.AuthReducer
  );

  useEffect(() => {
    setLoading(true);
    N_getPaymentMethods().then((res) => {
      if (res.status == 200) {
        setData(res.data);
        setLoading(false);
      }
    });
    N_getPostTerms().then((res) => {
      if (res.status == 200) {
        setContent(res.data?.content);
        setLoading(false);
      }
    });
  }, []);

  const handleDeleteMethod = async (id) => {

    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then(async (result) => {
        if (result.isConfirmed) {


          N_deletePaymentMethods({
            payment_method_id: id
          }).then((res) => {
            if (res.status == 200) {
              setContent(res.data?.content);
              setLoading(false);
            }
          });

        }
      });

    } catch (error) {
      console.log(error, "<<---------------- delete post error")
    }
  }

  const handleRedirect = (id) => {
    history.push(`/bank-transfer?id=${id}`);
  }

  return (
    <>
      <Header />
      <div className="container" style={{ marginTop: "100px" }}>
        <div className="mb-3 d-none">
          <div className="horizontal_display_with_justify">
            <div className="flex_row">
              {" "}
              <div className="name_circle">
                {profile?.name
                  ? profile?.name?.split("").slice(0, 2).join("").toUpperCase()
                  : profile?.email
                    ?.split("")
                    .slice(0, 2)
                    .join("")
                    .toUpperCase()}
              </div>
              <div className="big_heading text-capitalize me-2 text-truncate mob_mw_150">
                {" "}
                {profile?.name ? profile.name : profile?.email}
                <div className="horizontal_display_without_justify mt-2">
                  <div className="text-muted fs-12">
                    Email <i class="fa-solid fa-check-circle text-green"></i>
                  </div>
                  <div className="text-muted fs-12">
                    SMS <i class="fa-solid fa-check-circle text-green"></i>
                  </div>
                  <div className="text-muted fs-12">
                    KYC <i class="fa-solid fa-check-circle text-green"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="horizontal_display_with_justify">
              <div className="big_heading text-capitalize me-2 text-truncate mob_mw_150">
                {" "}
                <h6 className="mb-0">
                  P2P Estimated Value (BTC)
                  <span>
                    <a
                      className="btn"
                      onClick={() => {
                        setHide(!hide);
                      }}
                    >
                      {hide ? (
                        <>
                          <BsEye className="fs-5 me-2" color="#fff" />
                        </>
                      ) : (
                        <>
                          <BsEyeSlash className="fs-5 me-2" color="#fff" />
                        </>
                      )}
                    </a>
                  </span>
                </h6>
                <div className="horizontal_display_without_justify">
                  <div className="text-muted fs-12">
                    {hide ? "*************" : "12216.4888"}
                  </div>
                </div>
              </div>
              <a className="btn login_btn px-3 ">Become Merchant</a>
            </div>
          </div>
        </div>
        <div className="row mb-4  d-none">
          <div className="col">
            <div className="jambotron text-center">
              <p>30d Trades</p>
              <h6 className="fs-18">0 Time(s)</h6>
            </div>
          </div>
          <div className="col">
            <div className="jambotron text-center">
              <p>30d Completion Rate</p>
              <h6 className="fs-18">0 %</h6>
            </div>
          </div>
          <div className="col">
            <div className="jambotron text-center">
              <p>Avg. Release Time</p>
              <h6 className="fs-18">0 Minute(s)</h6>
            </div>
          </div>
          <div className="col">
            <div className="jambotron text-center">
              <p>Avg. Pay Time</p>
              <h6 className="fs-18">0 Minute(s)</h6>
            </div>
          </div>
          <div className="col">
            <div className="jambotron text-center">
              <p>Positive Feedback</p>
              <h6 className="fs-18">100 %(3)</h6>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <section className="col-md-6">

                <div className="d-flex align-items-center">
                  <BackComp url={"/p2pOrder"} text={""} />
                  <h6 className="text-white mb-0"> Payment Methods</h6>
                </div>
                {/* <h4 className="text-white">Payment Methods</h4> */}
              </section>
              <section className="col-md-6 pay_meth_dropdown  text-end">
                <Link
                  className="login_btn fs-12 py-1 px-md-4 px-1"
                  id="payments"
                  type="button"
                  to={"/bank-transfer"}
                >
                  <IoMdAdd /> Add <span className="d-none d-md-inline">Payment</span> Method
                </Link>
                {/* <button
                  className="dropdown-toggle btn login_btn"
                  id="payments"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Add Payment Method
                </button>
                <ul className="dropdown-menu p-2" aria-labelledby="payments">
                  <li className="dropdown-item ">
                    <Link to={"/payment-method/imps"}>IMPS</Link>
                  </li>
                  <li className="dropdown-item ">
                    <Link to={"/bank-transfer"}>Bank Transfer (Turkey)</Link>
                  </li>
                </ul> */}
              </section>
            </div>
            <section className="pay_meth_listing">
              <div className="row justify-content-center">
                <div className="col-12">
                  {loading ? (
                    <Loader />
                  ) : data?.length > 0 ? (
                    data?.map((item, i) => (
                      <div
                        key={i}
                        className="card pay_meth_listing_box mb-4"
                        style={{ backgroundColor: "#1f1f1f" }}
                      >
                        <div
                          className="card-header d-flex justify-content-between align-items-center py-3 text-white"
                          style={{
                            backgroundColor: "#1f1f1f",
                            borderBottom: "0.5px solid #ffffff78",
                          }}
                        >
                          <h6 className="mb-0 text-uppercase">
                            {item?.payment_method_type == "bank"
                              ? "Bank"
                              : item?.payment_method_type}
                          </h6>
                          <div className="d-flex gap-2">
                            {/* <div className="" style={{ cursor: "pointer" }}>
                          <FaTrash />
                        </div> */}
                          </div>
                        </div>
                        <div
                          className="card-body"
                          style={{ backgroundColor: "#1f1f1f" }}
                        >
                          <table className="table">
                            <tr>
                              <th><h6 className="box-h6  mb-0 text-light">Account Holder</h6></th>
                              <th><h6 className="box-h6  mb-0 text-light">Bank</h6></th>
                              <th><h6 className="box-h6  mb-0 text-light">Branch</h6></th>
                              <th><h6 className="box-h6  mb-0 text-light">IBAN Number</h6></th>
                              <th><h6 className="box-h6  mb-0 text-light">Action</h6></th>
                            </tr>
                            <tr>
                              <td>
                                <h6 className="box-h6 mb-0">{item?.name}</h6>
                                <h6 className="box-h6">{item?.email}</h6>
                              </td>
                              <td>
                                <h6 className="box-h6 mb-0">{item?.bank_name}</h6>
                                <h6 className="box-h6">{item?.account_number}</h6>
                              </td>
                              <td>
                                <h6 className="box-h6 mb-0">{item?.branch_name || "-"}</h6>
                                <h6 className="box-h6">{item?.branch_code || "-"}</h6>
                              </td>
                              <td>
                                <h6 className="box-h6 mb-0">{item?.iban || "-"}</h6>
                              </td>
                              <td>
                                <div className="text-light">
                                  <span> <CiEdit style={{ fontSize: "18px", marginRight: "15px" }} className="role-btn" onClick={() => handleRedirect(item?._id)} /> </span>
                                  <span> <RiDeleteBin5Line className="role-btn" onClick={() => handleDeleteMethod(item?._id)} /> </span>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="mt-4">
                      <NoDataComp text="No Payment Method Added" />
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>

        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentMethod;
