import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { Formik, Form as FormikForm } from "formik";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import "./p2p.css";
import { FaStopwatch, FaThumbsUp } from "react-icons/fa";
import { convertLongStringToShort } from "../../utils/common";
import { N_createBuyReq } from "../redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import toast from "react-hot-toast";
import { maskEmail } from "../../helper/common";

const styleInput = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      outline: "none",
      borderColor: "transparent",
    },
  },
};

const P2pDropDown = ({ activeButton, data, setSelectedIndex }) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const { isLoggedIn, user } = useSelector((state) => state.AuthReducer);
  const router = useHistory();
  const [selectedMethod, setSelectedMethod] = useState(""); // For selected value
  const [open, setOpen] = useState(false); // For pop-up visibility

  const initialValues = {
    amountPay: "",
    amountReceive: "",
    paymentMethod: selectedMethod,
  };

  const validationSchema = Yup.object({
    amountPay: Yup.number().required("This field is required"),
    amountReceive: Yup.number()
      .required("This field is required")
      .min(
        data?.min_order_limit,
        `Amount must be at least ${data?.min_order_limit} ${data?.asset_name}`
      )
      .max(
        data?.max_order_limit,
        `Amount must not exceed ${data?.max_order_limit} ${data?.asset_name}`
      ),
    paymentMethod: Yup.string().required("Please select a payment method"),
  });
  const handleSubmit = (values, { setSubmitting }) => {
    // console.log("Form submitted with values:", values);
    if (!isLoggedIn) {
      router.push("/login");
    } else {
      setSubmitLoading(true);
      N_createBuyReq({
        post_id: data?._id,
        fiat_amount_sent: values?.amountPay * 1,
        fiat_currency: data?.fiat_name,
        crypto_amount_receive: values?.amountReceive * 1,
        crypto_currency: data?.asset_name,
        user_payment_method_id: values?.paymentMethod,
      }).then((res) => {
        if (res.status == 200) {
          toast.success(res.message);
          setSubmitLoading(false);

          router.push(`/create-order?id=${res.data.response._id}`, {
            state: {
              order_response: res,
              order_info: data,
            },
          });
        } else {
          toast.error(res.message);
          setSubmitLoading(false);
        }
      });
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSelect = (method, setFieldValue) => {
    setFieldValue("paymentMethod", method);
    setSelectedMethod(method);
    handleClose();
  };

  return (
    <Box
      sx={{
        width: "full",
        margin: "auto",
        bgcolor: "#333a41",
        p: 4,
        borderRadius: "8px",
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          values,
        }) => (
          <FormikForm>
            <Grid container spacing={2}>
              {/* Left Panel */}
              <Grid item xs={12} sm={6}>
                <Grid className="d-flex gap-2 mb-2">
                  {/* <img
                    src="https://thispersondoesnotexist.com/"
                    alt=""
                    style={{ width: "45px", borderRadius: "4px" }}
                  /> */}
                  <div class="name_p2p_list gray_border mt-1">

                    {(data?.user?.name ||
                      data?.user?.email).split("")
                      .slice(0, 2)
                      .join("")
                      .toUpperCase()}
                  </div>

                  <Grid className="d-flex flex-column gap-0">
                    <Typography
                      variant="h6"
                      color="white"
                    >
                      {data?.user?.name ? data?.user?.name.toUpperCase() : maskEmail(data?.user?.email).toLowerCase()}
                    </Typography>

                    <Typography variant="body1" color="gray">
                      Time Period | {data?.payment_time_limit} min
                    </Typography>
                  </Grid>
                </Grid>

                <Grid className=" p-2 ">
                  {
                    data?.remark && <div className="d-flex mb-3">
                      <div>
                        <i class="fa-regular fa-circle-check text-green me-2"></i>
                      </div>
                      <div className="text-lighter">
                        <p className="mb-0"> Remark</p>
                        {data?.remark}
                      </div>
                    </div>
                  }

                  <div className="d-flex mb-3">
                    <div>
                      <i class="fa-regular fa-circle-check text-green me-2"></i>
                    </div>
                    <div className="text-lighter">
                      <p className="mb-0"> Advertisers' Terms ( Please read carefully ) </p>
                      {
                        data?.user?.terms?.content ?
                          <>
                            {data?.user?.terms?.content}
                          </>
                          :
                          <> Make your payments securely and safely on our platform, ensuring complete protection for every transaction. All discussions, agreements, and transactions happen within the platform, keeping your data private and secure. We prioritize safety, so you can trust every interaction without worries.</>
                      }
                    </div>
                  </div>

                </Grid>
              </Grid>

              {/* Right Panel */}
              <Grid item xs={12} sm={6} sx={{ pl: 2 }}>
                {/* Dynamic Fields */}
                <Box sx={{ mb: 2 }}>
                  <Typography
                    sx={{ mb: 1, fontSize: "16px", color: "#fff" }}
                    className="text-white"
                  >
                    Price : {data?.selling_price} {data?.fiat_name} /{" "}
                    {data?.asset_name}
                  </Typography>
                </Box>

                {/* Input Field: You Pay */}
                <Box
                  sx={{
                    mt: 3,
                    border: "0.5px solid #fff",
                    borderRadius: "12px",
                    px: 2,
                    py: 2,
                  }}
                  className="p2pInputOuterDiv"
                >
                  <Typography
                    variant="body2"
                    className="text-white"
                    sx={{ mb: 1, fontSize: "14px" }}
                  >
                    You {activeButton === "buy" ? "Pay" : "Receive"} :
                  </Typography>
                  <section className="d-flex justify-content-between align-items-center p2p_field_set">
                    <TextField
                      type="number"
                      name="amountPay"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      placeholder="Enter Amount"
                      // variant="outlined"
                      value={values.amountPay}
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setFieldTouched("amountPay", true);
                          setFieldTouched("amountReceive", true);
                          setFieldValue("amountPay", e.target.value);
                          setTimeout(() => {
                            setFieldValue(
                              "amountReceive",
                              e.target.value / data?.selling_price
                            );
                          }, 200);
                        }
                      }}
                      // onBlur={handleBlur}
                      // error={touched.amountPay && Boolean(errors.amountPay)}
                      // helperText={touched.amountPay && errors.amountPay}
                      InputProps={{
                        style: { color: "white", border: "none" },
                      }}
                      sx={styleInput}
                    />
                    <Typography className="text-white">{data?.fiat_name}</Typography>
                  </section>
                  {errors.amountPay && touched.amountPay ? (
                    <small className="text-danger">{errors.amountPay}</small>
                  ) : null}
                </Box>
                <Box
                  sx={{
                    mt: 3,
                    border: "0.5px solid #fff",
                    borderRadius: "12px",
                    px: 2,
                    py: 2,
                  }}
                  className="p2pInputOuterDiv"
                >
                  <Typography
                    variant="body2"
                    sx={{ mb: 1, fontSize: "14px" }}
                    className="text-white"
                  >
                    You {activeButton === "buy" ? "Receive" : "Pay"} :
                  </Typography>
                  <section className="d-flex justify-content-between align-items-center p2p_field_set">
                    <TextField
                      name="amountReceive"
                      type="number"
                      fullWidth
                      size="small"
                      autoComplete="off"
                      placeholder="Receive Amount"
                      variant="outlined"
                      value={values?.amountReceive}
                      onChange={(e) => {
                        if (e.target.value >= 0) {
                          setFieldTouched("amountPay", true);
                          setFieldTouched("amountReceive", true);
                          setFieldValue("amountReceive", e.target.value > data?.max_order_limit ? data?.max_order_limit : e.target.value);
                          setTimeout(() => {
                            setFieldValue(
                              "amountPay",
                              (e.target.value > data?.max_order_limit ? data?.max_order_limit : e.target.value) * data?.selling_price
                            );
                          }, 500);
                        }
                      }}
                      // onBlur={handleBlur}
                      // error={
                      //   touched.amountReceive && Boolean(errors.amountReceive)
                      // }
                      // helperText={touched.amountReceive && errors.amountReceive}
                      InputProps={{
                        style: { color: "white" },
                      }}
                      sx={styleInput}
                    />
                    <Typography className="text-white">{data?.asset_name}</Typography>
                  </section>
                  {errors.amountReceive && touched.amountReceive ? (
                    <small className="text-danger">
                      {errors.amountReceive}
                    </small>
                  ) : null}
                </Box>

                {/* Payment Method Dropdown */}

                <div className="mb-4">
                  <Button
                    fullWidth
                    onClick={handleOpen}
                    className=""
                    sx={{
                      fontSize: '13px',
                      fontWeight: '400',
                      mt: 3,
                      textTransform: selectedMethod ? 'uppercase !important' : 'inherit',
                      textAlign: "left",
                      justifyContent: "space-between",
                      color: selectedMethod ? "white" : "gray",
                      border: "0.5px solid #fff",
                      borderRadius: "12px",
                      "&:hover": { borderColor: "white" },
                      padding: "10px 25px",
                    }}
                  >
                    {selectedMethod ? selectedMethod : "Select Payment Method"}
                  </Button>
                  {errors.paymentMethod && touched.paymentMethod ? (
                    <small className="text-danger">
                      {errors.paymentMethod}
                    </small>
                  ) : null}
                </div>

                <Dialog
                  open={open}
                  onClose={handleClose}
                  sx={{
                    "& .MuiDialog-paper": {
                      backgroundColor: "#212529",
                      color: "white",
                      width: "450px",
                      // height: "500px",
                      borderRadius: "12px",
                    },
                  }}
                >
                  <Grid className="d-flex justify-content-between align-items-center p-4">
                    <Typography size={10}>Payment Method(s)</Typography>
                    <Box onClick={handleClose} sx={{ cursor: "pointer" }}>
                      ✕
                    </Box>
                  </Grid>

                  <DialogContent
                    sx={{
                      position: "relative",
                      color: "white",
                      pt: 0,
                    }}
                  >
                    <List>
                      {data?.payment_method.map((method, index) => (
                        <ListItem
                          disablePadding
                          key={index}
                          sx={{
                            border: "1px solid gray",
                            p: 1,
                            borderRadius: "6px",
                            marginBottom: "8px",
                          }}
                        >
                          <ListItemButton
                            onClick={() => handleSelect(method, setFieldValue)}
                            sx={{
                              color: "white",
                              textTransform: "capitalize",
                            }}
                          >
                            {method}
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </DialogContent>
                </Dialog>

                <div
                  className=""
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    type="button"
                    variant="contained"
                    className="btn btn-secondary bg-secondary text-white border-0 fs-12"
                    style={{ width: "20%", height: "38px" }}
                    onClick={() => setSelectedIndex(null)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    variant="contained"
                    color={activeButton === "buy" ? "success" : "danger"}
                    className={
                      activeButton === "buy"
                        ? "login_btn fs-12"
                        : "login_btn_red fs-12"
                    }
                    disabled={submitLoading}
                    style={{ width: "78%", height: "38px" }}
                  >
                    {submitLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : activeButton === "buy" ? (
                      `BUY ${data?.asset_name}`
                    ) : (
                      `SELL ${data?.asset_name}`
                    )}
                  </button>
                </div>
              </Grid>
            </Grid>

            {/* <Divider sx={{ my: 2, borderColor: "gray" }} /> */}

            {/* Buttons */}
          </FormikForm>
        )}
      </Formik>
    </Box>
  );
};

export default P2pDropDown;
