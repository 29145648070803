import React, { useEffect, useState } from "react";
import { Step, Stepper } from "react-form-stepper";
import CustomStepper from "./p2pComponents/CustomStepper";
import { Button } from "@mui/material";
import OrderPaymentTrans from "../modals/OrderPaymentTransModal/OrderPaymentTrans";
import { N_PaymentRaise, N_releaseOrRejectFunds, RaiseDisputeP2p } from "../redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import createSocketClient from "../redux/helpers/socket";
import ChatComp from "./payment-methods/ChatComponent";
import { commonSwalWithInput, NumberToFixed } from "../../utils/common";
import WaitingLoader from '../components/waitingLoader/index'
import toast from "react-hot-toast";
import CopyBtn from "../../helper/components/Copy";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { RxCrossCircled } from "react-icons/rx";
import { MdVerified } from "react-icons/md";
import { IoWarning } from "react-icons/io5";

const CreateOrder2 = ({
  fetchData,
  data,
  orderInfo,
  orderResponse,
  orderResp,
}) => {

  // console.log(orderInfo, "<<------ orderInfo");
  // console.log(orderResponse, "<<------ orderResponse");
  // console.log(orderResp, "<<------ orderResp");

  const history = useHistory();
  const [supportBtn, setSupportBtn] = useState("admin");
  const [show, setShow] = useState(false);
  const [paySend, setPaySend] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [image, setImage] = useState({
    blob: null,
    src: "",
  });
  const handleImageChange = (files) => {
    const extn = ["image/jpg", "image/png", "image/jpeg"];
    const [file] = files;
    if (file && extn.includes(file.type)) {
      if (file.size <= 5242880) {
        setImage({ blob: file, src: window.URL.createObjectURL(file) });
      } else {
        toast.error("File too large!!");
      }
    } else {
      toast.error(
        "Please select a valid image file(only jpg, png and jpeg images are allowed)!!"
      );
    }
  };

  const handleShow = () => {
    setImage({
      blob: null,
      src: "",
    });
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setPaySend(false);
  };

  const handleSubmit = (comment) => {
    setFormSubmitting(true);
    N_PaymentRaise({
      comment: comment,
      file: image.blob,
      order_id: orderResponse.response?._id,
    }).then((res) => {
      if (res.status == 200) {
        // toast.success(res.message);
        toast.success('Notified Seller Successfully!');

        setPaySend(true);
        setShow(false);
        setFormSubmitting(false);
        fetchData();
      } else {
        toast.error(res.message);
        setFormSubmitting(false);
      }
    });
  };


  const ConfirmReject = (id) => {
    commonSwalWithInput(
      "Are you sure?",
      "You want be able to revert this!",
      "Yes, Reject Payment!",
      "Cancel"
    ).then((result) => {
      if (result.isConfirmed) {
        ReleaseOrRejectFunds(id, "reject", result?.value);
      }
    });
  };

  const ConfirmRelease = (id) => {
    commonSwalWithInput(
      "Are you sure?",
      "You won't be able to revert this!",
      "Yes, Release Funds!",
      "Cancel"
    ).then((result) => {
      if (result.isConfirmed) {
        ReleaseOrRejectFunds(id, "complete", result?.value);
      }
    });
  };

  const ReleaseOrRejectFunds = (id, status, comment) => {
    N_releaseOrRejectFunds({
      order_id: id,
      status: status,
      comment: comment,
    }).then((res) => {
      if (res.status == 200) {
        toast.success(res.message);
        fetchData();
      } else {
        toast.error(res.message);
      }
    });
  };


  const raiseDispute = () => {
    RaiseDisputeP2p(orderResponse.response?._id, "buyer").then((res) => {
      if (res.status == 200) {
        toast.success(res.message);
        setSupportBtn("admin");
        fetchData();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <div className="">
        {/* <section>
          <h1>Pay the Seller</h1>
          <p>Order Created, Pay the Seller within</p>
          <p>Order number  22714969631719280640</p>
        </section> */}
        <div className="d-flex gap-4 mt-3">
          <div
            className="d-flex flex-column gap-3 gray-borders rounded-3 p-4 col-md-6"
            style={{ height: "600px", overflow: "auto", position: "relative" }}
          >
            <div className="p-3 bank-info" style={{ borderBottom: "1px solid white" }}>
              <h5>
                <span className={orderInfo?.type == "sell" ? "text-green" : "text-red"}>{orderInfo?.type == "sell" ? "Buy" : "Sell"}</span>{" "}
                {orderResponse?.response?.crypto_currency}
              </h5>
              <p className="d-flex justify-content-between my-1 line-bnk">
                <span>Fiat Amount</span>
                <span>
                  {orderResponse?.response?.fiat_amount_sent}{" "}
                  {orderResponse?.response?.fiat_currency}
                </span>
              </p>
              <p className="d-flex justify-content-between my-1 line-bnk">
                <span>Price / {orderInfo?.asset_name} </span>
                <span>
                  {orderInfo?.selling_price} {orderInfo?.fiat_name}{" "}
                </span>
              </p>
              <p className="d-flex justify-content-between my-1 line-bnk">
                <span>Receive Quantity</span>
                <span>
                  {NumberToFixed(
                    orderResponse?.response?.crypto_amount_receive
                  )}{" "}
                  {orderResponse?.response?.crypto_currency}
                </span>
              </p>
              {/* <p className="d-flex justify-content-between">
                <span>Total Quantity</span>
                <span>100 USDT</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Fee</span>
                <span>0.05000000 USDT</span>
              </p> */}
            </div>


            {
              orderResponse?.payment_method.length > 0 ?
                (
                  <div className="px-3" style={{ maxHeight: "200px", overflowY: "scroll" }}>
                    {
                      orderResponse?.payment_method.map((value, index) => {
                        return <div key={index} className="bank-info my-3">
                          <h6 className="bank-detail">
                            <span className="text-green">#{index + 1}</span> Bank Detail
                          </h6>
                          <div className=" d-flex  aligh-items-center justify-content-between my-0">
                            <p>Account Holder</p>
                            <p>{value?.name} <CopyBtn text={value?.name} textEnable={false} /></p>
                          </div>
                          <div className=" d-flex  aligh-items-center justify-content-between my-0">
                            <p>Bank Name</p>
                            <p>{value?.bank_name} <CopyBtn text={value?.bank_name} textEnable={false} /></p>
                          </div>
                          <div className=" d-flex  aligh-items-center justify-content-between my-0">
                            <p>Account Number</p>
                            <p>{value?.account_number} <CopyBtn text={value?.account_number} textEnable={false} />  </p>
                          </div>
                          <div className=" d-flex  aligh-items-center justify-content-between my-0">
                            <p>IBAN Number</p>
                            <p>{value?.iban} <CopyBtn text={value?.iban} textEnable={false} />  </p>
                          </div>
                          <div className=" d-flex  aligh-items-center justify-content-between mb-2">
                            <p>Branch Code</p>
                            <p>{value?.branch_code} <CopyBtn text={value?.branch_code} textEnable={false} />  </p>
                          </div>
                        </div>
                      })
                    }
                  </div>
                )
                : <>
                  <div className="px-3 text-center my-1">
                    <img src="/img/nowallet.png" style={{ height: "120px" }} />
                    <p>Oops! Payment method not available.</p>
                  </div>
                </>
            }

            <div className="status-manage border-top p-3">

              {
                orderInfo?.type == "buy" ? <>
                  {/* Type -- Buy */}
                  {
                    data?.status == "payment_done" ? (
                      <div className="text-center d-flex flex-column align-items-center">
                        <div
                          style={{
                            height: "150px",
                            width: "150px",
                            overflow: "hidden",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            window.open(
                              data?.sender_payment_image,
                              "_blank"
                            );
                          }}
                        >
                          <img
                            src={data?.sender_payment_image}
                            alt="Qr"
                            className=" p-2"
                            style={{
                              background: "#ffffff",
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                        <p className="my-2">Sender Message: {data?.sender_comment}</p>
                        <div className="d-flex align-items-center justify-content-center mt-3 gap-3">
                          <Button
                            variant="contained"
                            sx={{ background: "red", py: 1 }}
                            onClick={() =>
                              ConfirmReject(data?._id)
                            }
                          >
                            Reject Payment
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ background: "green", py: 1 }}
                            onClick={() =>
                              ConfirmRelease(data?._id)
                            }
                          >
                            Release Fund
                          </Button>
                        </div>
                      </div>
                    ) : data?.status == "active" ? (
                      <>
                        <div className="text-center d-flex flex-column align-items-center">
                          <img
                            src="/img/payment-loading.gif"
                            alt="Qr"
                            style={{ height: "110px" }}
                            className="p-2 mt-5"
                          />
                          <h5>
                            Waiting for Confirmation{" "}
                          </h5>
                        </div>
                      </>

                    ) : ""}
                  {/* Type -- Buy -- End */}
                </> : <>

                  {/* Type -- Sell */}
                  {
                    data?.status == "active" ? (
                      <button
                        onClick={handleShow}
                        variant="contained"
                        className="login_btn fs-6"
                        style={{ position: "absolute", bottom: "25px", width: "93%" }}
                      >
                        Transferred, Notify Seller
                      </button>
                    ) : data?.status == "payment_done" ? (
                      <div className="text-center d-flex flex-column align-items-center">
                        <WaitingLoader />
                        <h5 className="mb-3">
                          Waiting for Confirmation{" "}
                        </h5>
                        <Button
                          onClick={handleShow}
                          variant="contained"
                          sx={{ background: "#ffb703", py: 1 }}
                        >
                          Upload Again
                        </Button>
                      </div>
                    ) : (
                      ""
                    )
                  }
                  {/* Type -- Sell -- End */}
                </>
              }

              {

                (data?.status == "timeout" || data?.status == "reject" || data?.status == "cancel") ? (
                  <div className="text-center d-flex flex-column align-items-center">
                    <div>
                      {
                        data?.status == "reject" && <>
                          <RxCrossCircled size={70} className="text-danger bounce_1 mt-4" />
                          <p className="my-2">Reason : {data?.receiver_comment}</p>
                          <p className="mb-2">Payment has been rejected!</p>
                        </>
                      }
                      {
                        data?.status == "timeout" && <>
                          <IoWarning size={70} className="text-danger bounce_1 mt-4" />
                          <p className="my-2">Order Auto-Canceled: Time Limit Exceeded!</p>
                          <p className="mb-2">{data?.receiver_comment}</p>
                        </>
                      }

                    </div>

                    {(!orderResp?.orderInfo?.buyer_ticket_number) && (
                      <button
                        className="login_btn fs-14 py-1"
                        onClick={() => raiseDispute()}
                      >
                        Raise Dispute
                      </button>
                    )}
                  </div>
                ) :
                  data?.status == "complete" ? (
                    <div className="text-center d-flex flex-column align-items-center">
                      <div className="mt-5">
                        <MdVerified size={70} className="text-success bounce_1 mt-2" />
                        <p className="my-2">Comment: {data?.receiver_comment}</p>
                        <h5>Payment Done!</h5>
                      </div>
                    </div>
                  ) :
                    data?.status == "admin_complete" ? (
                      <div className="text-center d-flex flex-column align-items-center">
                        <div className="mt-5">
                          <MdVerified size={70} className="text-success bounce_1 mt-2" />
                          <p className="my-2">The admin has successfully released the funds to{" "}
                            {
                              orderResp?.orderInfo?.release_fund_user == orderResp?.receiverInfo?._id ?
                                orderResp?.receiverInfo?.name ? orderResp?.receiverInfo?.name : orderResp?.receiverInfo?.email
                                : orderResp?.sellerInfo?.name ? orderResp?.sellerInfo?.name : orderResp?.sellerInfo?.email
                            }
                            {" "}account!</p>
                          <h5>Payment Done!</h5>
                        </div>
                      </div>
                    ) : ""
              }
            </div>
          </div>
          <div className="col-md-6" style={{ height: "600px" }}>
            {
              (orderResp?.orderInfo?.buyer_ticket_number) &&
              <div className="chat-box-heading">
                <h4 onClick={() => setSupportBtn("admin")} className={supportBtn == "admin" ? "borderBot" : ""}>Admin Support</h4>
                <h4 onClick={() => setSupportBtn("user")} className={supportBtn == "user" ? "borderBot" : ""}>{orderResp?.sellerInfo?.name ? orderResp?.sellerInfo?.name : orderResp?.sellerInfo?.email}</h4>
              </div>
            }
            <div
              className="gray-borders rounded-3 "
              style={{
                overflow: "auto",
                position: "relative",
                scrollbarWidth: 'none',
                padding: "0px"
              }}
            >
              {
                ((orderResp?.orderInfo?.buyer_ticket_number) && supportBtn == "admin") ? (
                  <>
                    <ChatComp orderResp={orderResp} userType={"support"} supportBtn={supportBtn} status={(orderResp?.orderInfo?.buyer_ticket_number) ? true : false} uuid={orderResp?.orderInfo?.buyer_ticket_number} />
                  </>
                ) : <>
                  <ChatComp orderResp={orderResp} userType={"buyer"} supportBtn={supportBtn} status={(orderResp?.orderInfo?.buyer_ticket_number) ? true : false} uuid={orderResp?.orderInfo._id} />
                </>
              }

            </div>
          </div>
        </div>
      </div>
      <OrderPaymentTrans
        formSubmitting={formSubmitting}
        isOpen={show}
        onClose={handleClose}
        image={image}
        setImage={setImage}
        handleImageChange={handleImageChange}
        handleSubmit={handleSubmit}
        paySend={paySend}
      />
    </>
  );
};

export default CreateOrder2;
