import { useCallback } from "react";

const useHumanReadableNumber = () => {
  const formatNumber = useCallback((num) => {
    if (num >= 1e12) {
      return (num / 1e12).toFixed(1) + " T"; // Trillion
    }
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + " B"; // Billion
    }
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + " M"; // Million
    }
    if (num >= 1e3) {
      return (num / 1e3).toFixed(4) + " K"; // Thousand
    }
    return num?.toString(); // Less than a thousand
  }, []);

  return formatNumber;
};

export default useHumanReadableNumber;

