import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import toast from "react-hot-toast";

export default function FeedbackForm(props) {
  const [formData, setFormData] = useState({
    overallExperience: "",
    platformUsability: "",
    usabilityIssues: "",
    usabilityImprovement: "",
    tradingTools: "",
    orderExecution: "",
    additionalFeatures: "",
    interactedSupport: "",
    supportRating: "",
    issueResolutionTime: "",
    supportSuggestions: "",
    depositSatisfaction: "",
    withdrawalSatisfaction: "",
    depositWithdrawalIssues: "",
    security: "",
    technicalIssues: "",
    securityImprovements: "",
    finalThoughts: "",
  });

  useEffect(() => {
    document.title = "bitcash Feedback Form";
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Submitted Feedback:", formData);
    toast.success("Feedback submitted successfully!");
    // Reset form data
    setFormData({
      overallExperience: "",
      platformUsability: "",
      usabilityIssues: "",
      usabilityImprovement: "",
      tradingTools: "",
      orderExecution: "",
      additionalFeatures: "",
      interactedSupport: "",
      supportRating: "",
      issueResolutionTime: "",
      supportSuggestions: "",
      depositSatisfaction: "",
      withdrawalSatisfaction: "",
      depositWithdrawalIssues: "",
      security: "",
      technicalIssues: "",
      securityImprovements: "",
      finalThoughts: "",
    });
  };

  return (
    <>
      <Header {...props} />
      <div className="wrap-privacy d-flex align-items-center ">
        <div className="container py-5">
          <div className="terms_border">
            <h1 className="text-left font-size-40 text-gradient">
              We Value Your Feedback!{" "}
            </h1>
            <p className="para">
              At bitcash, we continuously strive to enhance your trading
              experience. Your feedback plays a crucial role in helping us
              improve our platform, services, and features. Whether you’re a
              beginner or an experienced trader, your insights help us build a
              better and more user-friendly platform tailored to your needs.
            </p>
            <p className="para">
              Please take a few moments to share your thoughts. Your responses
              will remain confidential and will be used solely for improving
              your experience.
            </p>
            <h1 className="text-left font-size-40 text-gradient">
              Let’s build the future of trading together!
            </h1>
            <hr />
            <form onSubmit={handleSubmit} className="p-4">
              {/* Overall Experience */}
              <div className="mb-4">
                <h4>1. Overall Experience</h4>
                <label>How satisfied are you with our trading platform?</label>
                {[
                  "Very Satisfied",
                  "Satisfied",
                  "Neutral",
                  "Dissatisfied",
                  "Very Dissatisfied",
                ].map((option) => (
                  <div key={option} className="form-check ms-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="overallExperience"
                      value={option}
                      required
                      onChange={handleChange}
                    />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
              </div>

              {/* Platform Usability
               */}
              <div className="mb-4">
                <h4>2. Platform Usability</h4>
                <label>How easy is it to navigate the platform?</label>
                {[
                  "Very Easy",
                  "Easy",
                  "Neutral",
                  "Difficult",
                  "Very Difficult",
                ].map((option) => (
                  <div key={option} className="form-check ms-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="platformUsability"
                      value={option}
                      required
                      onChange={handleChange}
                    />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
                <label className="form-label mt-3">
                  Did you encounter any issues while using the platform?
                </label>
                <select
                  className="form-select"
                  name="usabilityIssues"
                  value={formData.usabilityIssues}
                  required
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
                {formData.usabilityIssues === "Yes" && (
                  <textarea
                    className="form-control mt-2"
                    name="usabilityImprovement"
                    value={formData.usabilityImprovement}
                    placeholder="Describe the issues..."
                    onChange={handleChange}
                  ></textarea>
                )}
                <label>How can we improve the user experience?</label>
                <textarea
                  className="form-control mt-2"
                  name="usabilityImprovement"
                  value={formData.usabilityImprovement}
                  placeholder="Describe the issues..."
                  onChange={handleChange}
                ></textarea>
              </div>

              {/*  Trading Features & Performance
               */}
              <div className="mb-4">
                <h4>3. Trading Features & Performance</h4>
                <label>
                  Are you satisfied with the available trading tools and
                  indicators?
                </label>
                {[
                  "Very Satisfied",
                  "Satisfied",
                  "Neutral",
                  "Dissatisfied",
                  "Very Dissatisfied",
                ].map((option) => (
                  <div key={option} className="form-check ms-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tradingTools"
                      value={option}
                      required
                      onChange={handleChange}
                    />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
                <label>Are the order execution speeds satisfactory?</label>
                {["Yes", "No"].map((option) => (
                  <div key={option} className="form-check ms-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tradingTools"
                      value={option}
                      required
                      onChange={handleChange}
                    />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}

                <label>Which additional features would you like to see?</label>
                <textarea
                  className="form-control mt-2"
                  name="usabilityImprovement"
                  value={formData.usabilityImprovement}
                  placeholder="Describe the issues..."
                  onChange={handleChange}
                ></textarea>
              </div>

              {/*  Customer Support */}

              <div className="mb-4">
                <h4>4. Customer Support</h4>
                <label className="form-label">
                  Have you interacted with customer support?
                </label>
                {["Yes", "No"].map((option) => (
                  <div key={option} className="form-check ms-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tradingTools"
                      value={option}
                      required
                      onChange={handleChange}
                    />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
                {/* {formData.interactedSupport === "Yes" && (
                  <textarea
                    className="form-control mt-2"
                    name="supportSuggestions"
                    placeholder="Suggestions for support..."
                    onChange={handleChange}
                  ></textarea>
                )} */}

                <label>If yes, how would you rate the support received?</label>

                {["Excellent", "Good", "Neutral", "Poor", " Very Poor"].map(
                  (option) => (
                    <div key={option} className="form-check ms-4">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="tradingTools"
                        value={option}
                        required
                        onChange={handleChange}
                      />
                      <label className="form-check-label">{option}</label>
                    </div>
                  )
                )}
                <label>How quickly was your issue resolved?</label>

                {[
                  "Immediately",
                  "Within a few hours",
                  "Within a day",
                  " More than a day",
                ].map((option) => (
                  <div key={option} className="form-check ms-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tradingTools"
                      value={option}
                      required
                      onChange={handleChange}
                    />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
                <label>Any suggestions for improving customer support?</label>
                <textarea
                  className="form-control mt-2"
                  name="usabilityImprovement"
                  value={formData.usabilityImprovement}
                  placeholder="Describe the issues..."
                  onChange={handleChange}
                ></textarea>
              </div>

              {/* Deposits & Withdrawals
               */}
              <div className="mb-4">
                <h4>5. Deposits & Withdrawals</h4>
                <label className="form-label">
                  How satisfied are you with the deposit process?
                </label>
                {[
                  "Very Satisfied",
                  "Satisfied",
                  "Neutral",
                  "Dissatisfied",
                  " Very Dissatisfied",
                ].map((option) => (
                  <div key={option} className="form-check ms-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tradingTools"
                      value={option}
                      required
                      onChange={handleChange}
                    />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
                {/* {formData.interactedSupport === "Yes" && (
                  <textarea
                    className="form-control mt-2"
                    name="supportSuggestions"
                    placeholder="Suggestions for support..."
                    onChange={handleChange}
                  ></textarea>
                )} */}
                <label>
                  How satisfied are you with the withdrawal process?
                </label>
                {[
                  "Very Satisfied",
                  "Satisfied",
                  "Neutral",
                  "Dissatisfied",
                  " Very Dissatisfied",
                ].map((option) => (
                  <div key={option} className="form-check ms-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tradingTools"
                      value={option}
                      required
                      onChange={handleChange}
                    />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
                <label>Any suggestions for improving customer support?</label>

                <label className="form-label mt-3">
                  Have you faced any issues with deposits or withdrawals?
                </label>
                <select
                  className="form-select"
                  name="usabilityIssues"
                  value={formData.usabilityIssues}
                  required
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
                {formData.usabilityIssues === "Yes" && (
                  <textarea
                    className="form-control mt-2"
                    name="usabilityImprovement"
                    value={formData.usabilityImprovement}
                    placeholder="Describe the issues..."
                    onChange={handleChange}
                  ></textarea>
                )}
              </div>

              {/*  Security & Reliability
               */}
              <div className="mb-4">
                <h4>6. Security & Reliability</h4>
                <label className="form-label">
                  How secure do you feel using our platform?
                </label>
                {[
                  " Very Secure",
                  "Secure",
                  "Neutral",
                  "Not Secure",
                  "Very Insecure",
                ].map((option) => (
                  <div key={option} className="form-check ms-4">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tradingTools"
                      value={option}
                      required
                      onChange={handleChange}
                    />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}

                <label>
                  Have you experienced any downtime or technical issues?
                </label>

                <label className="form-label mt-3">
                  Have you experienced any downtime or technical issues?
                </label>
                <select
                  className="form-select"
                  name="usabilityIssues"
                  value={formData.usabilityIssues}
                  required
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  <option>Yes</option>
                  <option>No</option>
                </select>
                {formData.usabilityIssues === "Yes" && (
                  <textarea
                    className="form-control mt-2"
                    name="usabilityImprovement"
                    value={formData.usabilityImprovement}
                    placeholder="Describe the issues..."
                    onChange={handleChange}
                  ></textarea>
                )}

                <label>How can we further improve platform security?</label>
                <textarea
                  className="form-control mt-2"
                  name="usabilityImprovement"
                  value={formData.usabilityImprovement}
                  placeholder="Describe the issues..."
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="mb-4">
                <h4>6. Final Thoughts</h4>
                <label className="form-label">
                  Is there anything else you’d like to share with us?
                </label>
                <textarea
                  className="form-control mt-2"
                  name="usabilityImprovement"
                  value={formData.usabilityImprovement}
                  placeholder="Describe the issues..."
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="text-center">
                <button type="submit" className="btn login_btn">
                  Submit Feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
