import { useEffect, useRef } from "react";

const TradingViewTicker = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbols: [
        { proName: "BINANCE:BTCUSDT", description: "" },
        { proName: "OKX:ETHUSDT", description: "Ethereum" },
        { proName: "BINANCE:TRXUSDT", description: "TRON" },
        { proName: "BINANCE:DOGEUSDT", description: "" },
        { proName: "BINANCE:XRPUSDT", description: "XRP" },
        { proName: "KUCOIN:SOLUSDT", description: "sol" },
      ],
      showSymbolLogo: true,
      isTransparent: false,
      displayMode: "adaptive",
      colorTheme: "dark",
      locale: "en",
      isTransparent: true
    });

    if (containerRef.current) {
      containerRef.current.appendChild(script);
    }
  }, []);

  return (
    <div className="tradingview-widget-container">
      <div ref={containerRef} className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
};

export default TradingViewTicker;
