import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import toast from "react-hot-toast";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins, FaSortDown, FaSortUp } from "react-icons/fa";
import {
  N_GetAllSymbol,
  N_crypto_withdraw_New,
  N_crypto_withdraw_Otp,
  N_crypto_withdraw_Otp_Email,
  N_getBlockchain,
  N_getBlockchainData,
  N_withdrawHistory,
  N_withdrawRequest,
  N_withdrawVerifyRequest,
} from "./redux/helpers/api_functions_new";
import AssetOption from "./components/AssetsDropdown";
import formatDate from "../hooks/formatDate";
import useToastPromise from "../hooks/toastHook";
import { useHistory } from "react-router-dom";

export default function AssetsWithdraw() {
  const history = useHistory();
  const showToast = useToastPromise();
  const [blockchain, setBlockchain] = useState();
  const [currency, setCurrency] = useState();
  const [contract, setContract] = useState();
  const [coindata, setCoinData] = useState("");
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState();
  const [transection_id, setTransectionId] = useState();
  const [wallettype, setwallettype] = useState();
  const [wallets, setWallets] = useState();
  const [wallet_data, setWalletData] = useState();
  const [withdrawhistory, setWithdrawHistory] = useState();
  const [symbolData, setSymbolData] = useState();
  const [isSelected, setIsSelected] = useState(0);
  const [withdraw_address, setWithdrawAddress] = useState();
  const [withdrawAmount, setWithdrawAmount] = useState();
  const [motp, setMOtp] = useState();
  const [eotp, setEOtp] = useState();
  const [search, setSearch] = useState("");
  const [networkData, setNetworkData] = useState("");
  const [refresh, setRefresh] = useState(false);
  const { user } = useSelector((state) => state.AuthReducer);
  const { wallet } = useSelector((state) => state.coinDBReducer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});


  function formatNumberWithCommas(number, locales = "en-US", options = {}) {
    return new Intl.NumberFormat(locales, options).format(number);
  }
  const openModal = (item) => {
    setIsModalOpen(true);
    setModalData(item);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    N_getBlockchain().then((res) => {
      if (res.status == 200) {
        setBlockchain(res.data);
      }
    });
    N_GetAllSymbol({ is_withdrawal: 1 }).then((res) => {
      setWallets(res);
      const coinsData = res.find((item) => item.symbol == "USDT");
      setCoinData(coinsData);
    });
  }, []);
  useEffect(() => {
    if (coindata) {
      let data = {
        user_id: user?.params ? user.params.user_id : user.user_id,
        symbol: coindata.symbol,
      };
      // N_getAllBlockchain(coindata.symbol).then((res) => {
      //   if (res.status == 200) {
      //     setBlockchain(res.data);
      //   }
      // });
      N_getBlockchain(coindata?.symbol).then((res) => {
        if (res.status == 200) {
          setBlockchain(res.data);
        }
      });
    }
  }, [coindata]);
  useEffect(() => {
    const walletd = wallet.find(
      (item) => item.symbol == coindata?.symbol?.toUpperCase()
    );
    setWalletData(walletd);
  }, [wallet, coindata]);
  useEffect(() => {
    if (coindata) {
      let data = {
        user_id: user?.params ? user.params.user_id : user.user_id,
        symbol: coindata.symbol,
      };
      N_withdrawHistory({ user_id: data?.user_id })
        .then((data) => {
          if (data.status === 200) {
            setWithdrawHistory(data?.result?.result);
          }
          // console.log(data?.result, "data test");
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [coindata, user?.user_id, refresh]);
  const walletsdata =
    wallets &&
    wallets?.filter((products) => {
      return products.symbol.includes(search);
    });
  const onSelect = (item) => {
    setCoinData(item);
    setIsSelected(false);
    setSymbolData("");
  };
  const onNetworkSelect = (item) => {
    setNetworkData(item);
    setSymbolData(item);
  };

  const saveSettings = async () => {
    // Simulating an API call
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return "Settings saved!";
  };
  const [withModal, setWithModal]=useState(false)
  const handleWithdrawal = (e) => {
    e.preventDefault();
    if (coindata != "" && networkData != "") {
      // console.log(coindata,withdraw_address,withdrawAmount,networkData,"handle withdrawal")
      N_withdrawRequest({
        user_id: user?.params ? user.params.user_id : user.user_id,
        symbol: coindata?.symbol,
        toAddress: withdraw_address,
        volume: withdrawAmount,
        network: networkData?.blockchain,
      })
        .then((res) => {
          setWithModal(!res?.error)
          // console.log(res);
          setWithdrawAmount("");
          setWithdrawAddress("");
          setRefresh(!refresh);
          setTransectionId(res?.params?.transection_id)

          res?.error
            ? toast.error(res?.message)
            : showToast(saveSettings, {
              loading: "Processing...",
              success: "Kindly verify your OTP to proceed!",
              error: "Failed.....",
            });
        })
        .catch((err) => {
          setWithModal(false)
          toast.error(err?.message);
        });
    } else {
      setWithModal(false)
      toast.error("All Fields are required.");
    }
  };

  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    // toast.success("Copied...");
    toast.success("Copied...");
  };
  // console.log(modalData,"modal data")
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container">
            <div className="marginTop">
              <div className="account_page">
                <div className="col-lg-12">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="d-flex align-items-center gap-2">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => history.goBack()}
                        >
                          <i class="fa-solid fa-arrow-left-long"></i>
                        </span>
                        <p className="text-white fs-2"> Withdraw Crypto</p>
                      </div>
                    </div>
                    {/* <div className="">
                  <a href="/AssetsFiat" className="btn btn-light">
                    Withdraw Fiat{" "}
                    <i class="fa-solid fa-arrow-right-long ms-1"></i>
                  </a>
                </div> */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-7">
                    <form
                      className="cstm_form"
                      onSubmit={(e) => handleWithdrawal(e)}
                    >
                      <div class="row_flex_div ">
                        <div class="col_left"></div>
                        <div class="col_right">
                          {/* <label for="inputPassword6" class="">
                            Coin
                          </label> */}
                        </div>
                      </div>

                      <div class="row mb-4">
                        <div class="text-lgray">
                          <label for="inputPassword6" class="mb-2">
                            Select Coin
                          </label>
                        </div>
                        <AssetOption
                          walletsdata={walletsdata}
                          coindata={coindata}
                          onSelect={onSelect}
                        />
                        <p class="fs-14 mt-3 ms-3 text-white">
                          {wallets &&
                            wallets.map((item) => {
                              if (
                                item.symbol == "USDT" ||
                                item.symbol == "ETH" ||
                                item.symbol == "BNB" ||
                                item.symbol == "TRX" ||
                                item.symbol == "BTC"
                              ) {
                                return (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    class="me-3 "
                                    onClick={() => {
                                      setCoinData(item);
                                      setIsSelected(false);
                                      setSymbolData();
                                    }}
                                  >
                                    <img
                                      src={item.icon}
                                      alt="coin symbole"
                                      class="market_coin_img "
                                      style={{ width: "24px", height: "24px" }}
                                    />{" "}
                                    <span style={{ marginTop: "4px" }}>
                                      {item.symbol}
                                    </span>
                                  </span>
                                );
                              }
                            })}
                        </p>
                      </div>
                      <div class="row mb-4">
                        <div class="text-lgray">
                          <label for="inputPassword6" class="mb-2">
                            Select Network
                          </label>
                        </div>
                        <AssetOption
                          walletsdata={blockchain}
                          coindata={symbolData}
                          onSelect={onNetworkSelect}
                        />
                      </div>

                      <div class="row align-items-start ">
                        <div class="">
                          <div class="custom_pills mb-4">
                            <div
                              class="nav nav-pills"
                              id="nav-tab"
                              role="tablist"
                            >
                              {/* <div
                                class="nav-item nav-link active"
                                href="#wallet_address"
                                data-toggle="tab"
                                style={{ height: "28px" }}
                              >
                                Wallet Address
                              </div> */}
                              {/* <div
                            class="nav-item nav-link"
                            href="#aco_user"
                            data-toggle="tab"
                            style={{ height: '28px' }}
                          >
                            GlobelX User
                          </div> */}
                            </div>
                          </div>

                          <div className="tab-content">
                            <div
                              className="tab-pane fade active show"
                              id="wallet_address"
                            >
                              <div className="cstm_form withdrawalform">
                                <div className="mb-4">
                                  <label class="small text-muted">
                                    Wallet Address
                                  </label>
                                  <div class="input-group">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="Enter Wallet Address"
                                      value={withdraw_address}
                                      required
                                      onChange={(e) => {
                                        setWithdrawAddress(e.target.value);
                                      }}
                                    />
                                    {/* <span class="input-group-text">
                                  <i class="fa-regular fa-file text-primary"></i>
                                </span> */}
                                  </div>
                                </div>

                                <div className="mb-4">
                                  <label class="small text-muted">
                                    Withdrawal Amount
                                  </label>
                                  <div class="input-group">
                                    <input
                                      required
                                      type="number"
                                      class="form-control"
                                      placeholder="Enter Withdrawal Amount"
                                      min={+symbolData?.min_withdraw_limit}
                                      step="0.000001"
                                      value={withdrawAmount}
                                      onChange={(e) => {
                                        setWithdrawAmount(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>

                                {/* <div class="mb-4">
                              <label for="Fiat" class="text-muted">
                                Network
                              </label>{" "}
                              <div className="select_box">
                                {" "}
                                <div className="select_box_inner">
                                  <div
                                    className="select_box_inner"
                                    data-bs-toggle="modal"
                                    data-bs-target="#network_modal"
                                  >
                                     <div class="product_name">
                                  {symbolData
                                    ? symbolData.symbol +
                                      " ("+symbolData.type+")" +
                                      symbolData.blockchain
                                    : "Select Network"}
                                </div>{" "}
                                  </div>
                                </div>
                                <div>
                                  {" "}
                                  <img
                                    src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                    class="arrow_img"
                                    alt="arrow-icon"
                                  />
                                </div>
                              </div>
                            </div> */}
                              </div>
                            </div>

                            <div className="tab-pane fade " id="aco_user">
                              <div class="mb-4 new_pills">
                                <div
                                  class="nav nav-pills"
                                  id="nav-tab"
                                  role="tablist"
                                >
                                  <div
                                    class="nav-item nav-link active "
                                    href="#email_div"
                                    data-toggle="tab"
                                  >
                                    Email
                                  </div>
                                  <div
                                    class="nav-item nav-link"
                                    href="#phone_div"
                                    data-toggle="tab"
                                  >
                                    Phone
                                  </div>
                                </div>
                              </div>

                              <div className="tab-content">
                                <div
                                  className="tab-pane active fade show"
                                  id="email_div"
                                >
                                  <div className="cstm_form">
                                    <div className="mb-4">
                                      {/* <label class="small text-muted">
                               Email
                              </label> */}
                                      <div class="input-group">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Enter Email"
                                        />
                                        <span class="input-group-text">
                                          <i class="fa-regular fa-file text-primary"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="tab-pane fade" id="phone_div">
                                  <div className="cstm_form">
                                    <div className="mb-4">
                                      {/* <label class="small text-muted">
                               Email
                              </label> */}
                                      <div class="input-group">
                                        <input
                                          type="text"
                                          class="form-control"
                                          placeholder="Enter Phone"
                                        />
                                        <span class="input-group-text">
                                          <i class="fa-regular fa-file text-primary"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <section>
                            <div className="all_value_grid">
                              <div class="all_value_col">
                                <h6 class="mb-0  fs-12 text-muted">
                                  Available Balance
                                </h6>
                                <span class="text-white">
                                  {formatNumberWithCommas(
                                    Number(
                                      wallet_data?.balance
                                    )?.toFixed(4)
                                  )}
                                </span>
                                <span class="text-muted ms-1">
                                  {wallet_data?.symbol}
                                </span>
                              </div>
                              {symbolData && <div class="all_value_col">
                                <h6 class="mb-0  fs-12 text-muted">
                                  Minimum Withdrawal
                                </h6>
                                <span className="">{symbolData?.min_withdraw_limit} {coindata?.symbol}</span>
                              </div>}
                              {/* <div class="all_value_col">
                            <h6 class="mb-0  fs-12 text-muted">Fee</h6>
                            <span class="">{wallet_data?.withdrawal_fee}</span>
                            <span class="ms-1 text-muted">{wallet_data?.symbol}</span>
                          </div> */}
                              {/* <div class="all_value_col">
                            <h6 class="mb-0  fs-12 text-muted">
                              24h Withdrawal Limit
                            </h6>
                            <span class="">1,000,000.00</span>
                            <span class="text-muted ms-1">USDT</span>
                          </div> */}
                            </div>
                          </section>
                          <div className="d-grid">
                            <button
                              className="btn login_btn btn-lg py-3"
                              type="submit"
                              // data-bs-toggle="modal"
                              // data-bs-target="#withdrawal_modal"
                              // onClick={() => handleWithdrawal()}
                              // disabled={coindata?.is_withdrawal == 0 || !symbolData}
                            >
                              {coindata?.is_withdrawal == 0
                                ? "Currently Withdrawal is not available on this coin"
                                : "Withdrawal"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-lg-1"></div>
                  <div class="col-lg-4">
                    <div className="jambotron-outline">
                      <div className="account_page_subheading mb-2">
                        Without KYC
                      </div>
                      <span class="badge bg-light border  me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        Deposit
                      </span>{" "}
                      <span class="badge bg-light border  me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        Trade
                      </span>{" "}
                      <span class="badge bg-light border  me-2 fs-14 mb-2">
                        <span class="fa-solid fa-circle-xmark text-secondary  me-2"></span>{" "}
                        Withdrawal
                      </span>{" "}
                      <span class="badge bg-light border  me-2 fs-14 mb-2">
                        <span class="fa-solid fa-circle-xmark text-secondary  me-2"></span>{" "}
                        P2P
                      </span>
                    </div>
                    <div className="jambotron-outline mt-3">
                      <div className="account_page_subheading mb-2">
                        With KYC
                      </div>

                      <span class="badge bg-light border  me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        Deposit
                      </span>

                      <span class="badge bg-light border  me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        Trade
                      </span>

                      <span class="badge bg-light border  me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        Withdrawal
                      </span>

                      <span class="badge bg-light border  me-2 fs-14 mb-2">
                        <span class="fa fa-circle-check text-green me-2"></span>{" "}
                        P2P
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 mt-5">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <h4 className="text-white"> Recent Withdrawals </h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto history_table mt-3">
                  <div className="">
                    <table className="table ">
                      <thead>
                        <tr>
                          <th class="">Time</th>
                          <th>Currency</th>
                          <th>Amount</th>
                          <th>Network</th>
                          <th> Withdrawal Address/Account </th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {withdrawhistory && withdrawhistory.length > 0 ? (
                          withdrawhistory?.map((item) => {
                            return (
                              <tr className="tr">
                                <td class="">
                                  {new Date(item.createdAt).toLocaleString(
                                    "en-GB",
                                    {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                      hour: "2-digit",
                                      minute: "2-digit",
                                      second: "2-digit",
                                      hour12: false,
                                    }
                                  )}
                                </td>
                                <td>{item.symbol}</td>
                                <td>{item.amount}</td>
                                <td>{item.network.toUpperCase()}</td>
                                <td>
                                  <span className="address_item">
                                    {item?.to_address?.slice(0, 6)}...
                                    {item?.to_address?.slice(-6)}{" "}
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() => copyText(item?.to_address)}
                                    >
                                      <i className="fa fa-copy ms-2"></i>
                                    </span>
                                  </span>
                                </td>
                                <td
                                  style={{
                                    color:
                                      item?.transaction_status == "PENDING"
                                        ? "orange"
                                        : "#20ac95",
                                  }}
                                >
                                  {item?.transaction_status}
                                </td>
                                <td>
                                  <button
                                    className="detail_btn py-1"
                                    onClick={() => openModal(item)}
                                  >
                                    Details
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <img
                                alt="no data found"
                                src="/img/no-data.png"
                                className="no-data-found"
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* =============Use this div When data is not configure================ */}

                {/* <div className="col-lg-12">
              <div className="aco_box">
                <div className="page-content-title text-center">
                  <h3 className="">Withdraw Crypto</h3>
                </div>
                <div className="aco_hero mt-4">
                  <div class="pb-3">
                    <h4 className="fw-bold">
                      You must configure the security settings of your account
                      before withdrawal.
                    </h4>
                  </div>

                  <div className="text-lgray">
                    <p>
                      <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                      Set trading password. <a href="#">Configure</a>
                    </p>
                    <p>
                      {" "}
                      <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                      Bind mobile phone or add Google Verification.{" "}
                      <a href="#">Enable</a>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
                {/* =============When data is not configure================ */}
              </div>
            </div>
          </div>
        </main>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="coin_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Coin</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="m-1">
                <div class="input-group ">
                  <span class="input-group-text bg-transparent">
                    <div class="">
                      <i class="fa fa-search text-lgray"></i>
                    </div>
                  </span>
                  <input
                    type="text"
                    class="form-control text-start mp-exide-form bg-white"
                    aria-label="Text input with checkbox"
                    placeholder="Search "
                    onChange={(e) => {
                      setSearch(e.target.value.toUpperCase());
                    }}
                  />
                </div>
              </div>

              <div className="mt-4">
                <h6 className="text-lgray">Popular</h6>
                <p class="fs-14">
                  {wallets &&
                    wallets.map((item) => {
                      if (
                        item.symbol == "USDT" ||
                        item.symbol == "ETH" ||
                        item.symbol == "BNB" ||
                        item.symbol == "TRX"
                      ) {
                        return (
                          <span
                            class="label_light me-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              setCoinData(item);
                              setIsSelected(false);
                              setSymbolData();
                              const wallt_data = wallet.find(
                                (dt) => dt.symbol == item.symbol
                              );
                              setWalletData(wallt_data);
                            }}
                          >
                            {item.symbol}
                          </span>
                        );
                      }
                    })}
                </p>
              </div>

              <div className="custom_scrollbar height250">
                {walletsdata &&
                  walletsdata.map((item) => {
                    if (item.symbol !== "INR") {
                      return (
                        <div
                          class="select_options"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setCoinData(item);
                            setIsSelected(false);
                            setSymbolData("");
                            const wallt_data = wallet.find(
                              (dt) => dt.symbol == item.symbol
                            );
                            setWalletData(wallt_data);
                          }}
                        >
                          <div>
                            <img
                              src={item.icon}
                              alt="coin symbole"
                              class="market_coin_img"
                            />
                          </div>
                          <div class="product_name">
                            <div class="mb-0 fw-bold">{item.symbol}</div>
                            <div class="text-muted fs-12">{item.name}</div>
                          </div>{" "}
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="network_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Select Network</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="alert alert-warning">
                <div class="d-flex">
                  <div>
                    <i className="fa fa-exclamation-circle me-2 text-warning"></i>
                  </div>
                  <div>
                    Ensure that the selected deposit network is the same as the
                    withdrawal network. Otherwise, your assets could be lost.
                  </div>
                </div>
              </div>

              <div className="custom_scrollbar height250">
                {blockchain &&
                  blockchain.map((item) => {
                    return (
                      <div
                        class="select_options"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          setSymbolData(item);
                          N_getBlockchainData(
                            item?.blockchain,
                            user?.params ? user.params.user_id : user.user_id,
                            coindata?.symbol
                          ).then((res) => {
                            if (res.status == 200) {
                              if (res.contractdata) {
                                setCurrency(res.currencydata);
                                setContract(res.contractdata);
                                setIsSelected(1);
                              } else if (
                                item.blockchain == coindata?.blockchain
                              ) {
                                setCurrency(res.currencydata);
                                setIsSelected(2);
                              } else {
                                setIsSelected(3);
                              }
                            }
                          });
                        }}
                      >
                        <div class="product_name">
                          <div class="mb-0 fw-bold">
                            {item.symbol}
                            {item.type ? `(${item.type})` : ""}
                          </div>
                          <div class="text-muted fs-12"> {item.blockchain}</div>
                        </div>{" "}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
   { withModal?  <div class="modal coin_modal  fade show" style={{display:'block'}}  tabindex="-1" id="withdrawal_modal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-bottom-0">
              <h4 class="modal-title">Withdrawal</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={()=>setWithModal(false)}
              ></button>
            </div>
            <div class="modal-body p-5">
              {/* {isOTPVerified ? ( */}
              <div class="mb-4">
                <label class="text-muted">Email Verification Code</label>
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    value={eotp}
                    onChange={(e) => {
                      setEOtp(e.target.value);
                    }}
                  />
                  <span class="input-group-text">
                    <a
                    style={{cursor:'pointer'}}
                      className="ms-1"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={(e) => {
                        N_withdrawVerifyRequest(
                          transection_id,
                          eotp,
                        ).then((res) => {
                          setWithModal(false)
                          setRefresh(!refresh);
                          setEOtp("")
                          setTransectionId("")
                          res?.error
                            ? toast.error(res?.message)
                            : showToast(saveSettings, {
                              loading: "Processing...",
                              success: res?.message,
                              error: "Failed.....",
                            });
                        }).catch((err) => {
                          toast.error(err?.message);
                        });
                      }}
                    >
                      Verify code
                    </a>
                  </span>
                </div>
              </div>
              {/* ) : ( */}
              {/* <div class="mb-4">
                  <label class="text-muted mb-1">
                    Mobile Verification Code
                  </label>
                  <div class="input-group">
                    <input
                      type="number"
                      class="form-control"
                      value={motp}
                      onChange={(e) => {
                        setMOtp(e.target.value);
                      }}
                    />
                    <span class="input-group-text bg-primary">
                      <a
                        className="text-white"
                        onClick={() => {
                          const symbol = coindata.symbol;
                          const network = symbolData.type;
                          N_crypto_withdraw_New(
                            user?.params ? user.params.user_id : user.user_id,
                            symbol,
                            withdraw_address,
                            withdrawAmount,
                            network
                          ).then((data) => {
                            if (data.status == 200) {
                              toast.success(data.message);
                              setIsOTPSent(true);
                              setTransectionId(data?.params.transection_id);
                              setwallettype(data?.params.symbol);
                            } else {
                              toast.error(data.message);
                            }
                          });
                        }}
                      >
                        {isOTPSent ? "Resend Code" : "Send Code"}
                      </a>
                      {isOTPSent ? (
                        <a
                          className="ms-1"
                          onClick={(e) => {
                            N_crypto_withdraw_Otp(
                              user?.params ? user.params.user_id : user.user_id,
                              motp,
                              transection_id,
                              wallettype
                            ).then((res) => {
                              if (res.status == 200) {
                                setEOtp("");
                                setIsOTPVerified(true);
                                toast.success(res.message);
                              } else {
                                toast.error(res.message);
                              }
                            });
                          }}
                        >
                          Verify code
                        </a>
                      ) : null}
                    </span>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div> :""}

      {/* modal */}
      <div
        className={`modal fade no-scroll bg-blur ${isModalOpen ? "show d-block" : ""
          }`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="paymentmodal2"
        aria-hidden={!isModalOpen}
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content first_registration_section comming-soon-bg p-2">
            <div className="modal-body text-white">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Details</h5>

                <div
                  style={{ textAlign: "end", fontSize: "22px" }}
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsModalOpen(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </div>
              </div>

              <div className="my-2 d-flex gap-3 align-items-center">
                <div className=" ">
                  {modalData?.symbol?.toUpperCase()}{" "}
                  <span style={{ fontSize: "12px" }}>
                    ({modalData?.network?.toUpperCase()})
                  </span>
                </div>
              </div>
              <div className="row ">
                <div className="col-4 mb-2">
                  <div className="text-lgray">Wallet Address</div>
                  <div>
                    {modalData?.to_address?.slice(0, 5)}...
                    {modalData?.to_address?.slice(-5)}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => copyText(modalData?.to_address)}
                    >
                      <i className="fa fa-copy ms-2"></i>
                    </span>
                  </div>
                </div>
                <div className="col-4 mb-2">
                  <div className="text-lgray">Amount</div>
                  <div>
                    {modalData?.amount} {modalData?.symbol?.toUpperCase()}
                  </div>
                </div>
                <div className="col-4 mb-2">
                  <div className="text-lgray">Transaction ID</div>
                  <div>
                    {modalData?.transection_id?.slice(0, 5)}...
                    {modalData?.transection_id?.slice(-5)}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => copyText(modalData?.transection_id)}
                    >
                      <i className="fa fa-copy ms-2"></i>
                    </span>
                  </div>
                </div>
                <div className="col-4 mb-2">
                  <div className="text-lgray">Status</div>
                  <div
                    style={{
                      color:
                        modalData?.transaction_status == "PENDING"
                          ? "orange"
                          : "#20ac95",
                    }}
                  >
                    {modalData?.transaction_status}
                  </div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Update Time</div>
                  <div>
                    {new Date(modalData.updatedAt).toLocaleString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hour12: false,
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end modal  */}
    </>
  );
}
