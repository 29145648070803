import React, { useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import educationCards from "./mockData";
export default function Educations(props) {
  const [showAll, setShowAll] = useState(false);

  // const educationCards = [
  //   {
  //     id: "1",
  //     title: "What is Crypto Trading? A Beginner's Guide",
  //     description:
  //       "Cryptocurrency has revolutionized the financial industry,offering a decentralized and digital alternative to traditional money.With...",
  //     image: "/img/e1.jpg",
  //     reward: "5 CRYPTO",
  //   },
  //   {
  //     id: "2",
  //     title:
  //       "Benefits of Trading in Financial Markets – A Guide for bitcash Traders",
  //     description:
  //       " Trading in financial markets offers flexibility, accessibility, and financial growth opportunities for traders at all levels.....",
  //     image: "/img/e2.webp",
  //     reward: "10 CRYPTO",
  //   },
  //   {
  //     id: "3",
  //     title: "Difference Between Assets: Crypto, Forex, and Commodities",
  //     description:
  //       " Financial markets offer variety of asset classes each with distinct characteristics, trading strategies, and riskfactors...",
  //     image: "/img/nft.jpg",
  //     reward: "8 CRYPTO",
  //   },
  //   {
  //     id: "4",
  //     title: "Fundamental Analysis of Financial Markets",
  //     description:
  //       "Fundamental analysis is a key approach used by traders andinvestors to determine the true value of a financial asset...",
  //     image: "/img/defi.jpeg",
  //     reward: "6 CRYPTO",
  //   },
  //   {
  //     id: "5",
  //     title: "Risks involved in trading in financial markets",
  //     description:
  //       "Every business has some intrinsic risk attached to it. Financial markets are notorious when it comes to risk. Frankly yet practically...",
  //     image: "/img/defi.jpeg",
  //     reward: "6 CRYPTO",
  //   },
  //   {
  //     id: "6",
  //     title: "Technical Analysis of Financial Markets",
  //     description:
  //       "In classic way, technical analysis is a method ofevaluating trends in trading activity. Technical analysis is a powerful tool that helps...",
  //     image: "/img/nft.jpg",
  //     reward: "6 CRYPTO",
  //   },
  //   {
  //     id: "7",
  //     title:
  //       "Sentiment Analysis of Financial Markets – A Guide for bitcash Traders",
  //     description:
  //       "Market sentiment is a critical factor in trading, as it reflects the overall mood and emotions of market participants,...",
  //     image: "/img/defi.jpeg",
  //     reward: "10 CRYPTO",
  //   },
  //   {
  //     id: "8",
  //     title: "Commodities Trading – A Guide for bitcash Traders",
  //     description:
  //       "Commodities trading has a long history, dating back to ancient civilizations where agricultural goods and precious ...",
  //     image: "/img/e1.jpg",
  //     reward: "5 CRYPTO",
  //   },
  // ];

  const visibleCards = showAll ? educationCards : educationCards.slice(0, 3);

  return (
    <>
      <Header {...props} />
      <div className="wrap-privacy d-flex align-items-center commom-bg">
        <div className="container py-5 ">
          <div className="row align-items-center">
            <div className="col-md-6 mb-3">
              <div className="banner_left_inner">
                <h2 className="bannertxt text-white">
                  Learn with bitcash,
                  <span className="text-gradient"> Trade with Confidence!</span>
                </h2>
                <p className="mb-2 mt-3 bannerSubHeading text-white">
                  {" "}
                  Master the markets with expert insights on crypto and forex.
                  Turn knowledge into profits with bitcash!
                </p>
                <div className="mt-5"> </div>
              </div>
            </div>
            <div className="col-md-6 d-none d-md-block">
              <div className="d-flex items-center justify-content-center">
                <img
                  style={{ width: "70%" }}
                  className=""
                  src="/img/study.png"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="study_frame">
          <div className="row">
            {visibleCards.map((card, index) => (
              <div key={index} className="col-md-4 mb-4">
                <div className="card h-100 education-card">
                  <img
                    src={card.image}
                    className="card-img-top p-3"
                    alt={card.title}
                    style={{ height: "200px", objectFit: "cover" }}
                  />
                  <div className="card-body text-white">
                    <h5 className="card-title">{card.title}</h5>
                    <p className="card-text">{card.description}</p>
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="text-gradient"></span>
                      <Link
                        // to="/education-details"
                        to={{
                          pathname: `/education-detail`,
                          state: { id: +card.id },
                        }}
                      >
                        <button className="btn login_btn btn-sm text-white">
                          Start Learning
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {educationCards.length > 3 && (
            <div className="text-center mt-4">
              <button
                className="btn login_btn"
                onClick={() => setShowAll(!showAll)}
                style={{
                  padding: "10px 30px",
                }}
              >
                {showAll ? "Show Less" : "View More"}
              </button>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}
