import React, { useEffect, useState } from "react";
import Header from "../HomeComp/Header";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../HomeComp/Footer";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaTrash } from "react-icons/fa";
import {
    N_addPostTerms,
    N_getPaymentMethods,
    N_getPostTerms,
} from "../redux/helpers/api_functions_new";
import NoDataComp from "../components/NoDataComp";
import Loader from "../components/Loader";
import JoditEditor from "jodit-react";
import { NotificationManager } from "react-notifications";
import { IoIosSave } from "react-icons/io";
import { IoMdAdd } from "react-icons/io";
import BackComp from "../../helper/components/BackComp";
import toast from "react-hot-toast";

const AdvertiserTerms = () => {
    const [data, setData] = useState([]);
    const [hide, setHide] = useState(true);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState("");
    const { isLoggedIn, user, profile } = useSelector(
        (state) => state.AuthReducer
    );

    useEffect(() => {
        setLoading(true);
        N_getPaymentMethods().then((res) => {
            if (res.status == 200) {
                setData(res.data);
                setLoading(false);
            }
        });
        N_getPostTerms().then((res) => {
            // console.log(res.data?.content, "<<--------------res.data?.content")
            if (res.status == 200) {
                setContent(res.data?.content);
                setLoading(false);
            }
        });
    }, []);

    const handleSave = async () => {
        // try {
        N_addPostTerms({ content }).then((resp) => {
            // console.log(content, "Content saved successfully!");
            toast.success("Content saved successfully!");
        });
        // const response = await axios.post("https://your-api-endpoint.com/save", { content });

        // console.log(response.data);
        // } catch (error) {
        //   console.error("Error saving content:", error);
        //   alert("Failed to save content");
        // }
    };

    const defaultText = "Make your payments securely and safely on our platform, ensuring complete protection for every transaction. All discussions, agreements, and transactions happen within the platform, keeping your data private and secure. We prioritize safety, so you can trust every interaction without worries.";

    return (
        <>
            <Header />
            <div className="container" style={{ marginTop: "100px" }}>
                <div className="mb-3 d-none">
                    <div className="horizontal_display_with_justify">
                        <div className="flex_row">
                            {" "}
                            <div className="name_circle">
                                {profile?.name
                                    ? profile?.name?.split("").slice(0, 2).join("").toUpperCase()
                                    : profile?.email
                                        ?.split("")
                                        .slice(0, 2)
                                        .join("")
                                        .toUpperCase()}
                            </div>
                            <div className="big_heading text-capitalize me-2 text-truncate mob_mw_150">
                                {" "}
                                {profile?.name ? profile.name : profile?.email}
                                <div className="horizontal_display_without_justify mt-2">
                                    <div className="text-muted fs-12">
                                        Email <i class="fa-solid fa-check-circle text-green"></i>
                                    </div>
                                    <div className="text-muted fs-12">
                                        SMS <i class="fa-solid fa-check-circle text-green"></i>
                                    </div>
                                    <div className="text-muted fs-12">
                                        KYC <i class="fa-solid fa-check-circle text-green"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="horizontal_display_with_justify">
                            <div className="big_heading text-capitalize me-2 text-truncate mob_mw_150">
                                {" "}
                                <h6 className="mb-0">
                                    P2P Estimated Value (BTC)
                                    <span>
                                        <a
                                            className="btn"
                                            onClick={() => {
                                                setHide(!hide);
                                            }}
                                        >
                                            {hide ? (
                                                <>
                                                    <BsEye className="fs-5 me-2" color="#fff" />
                                                </>
                                            ) : (
                                                <>
                                                    <BsEyeSlash className="fs-5 me-2" color="#fff" />
                                                </>
                                            )}
                                        </a>
                                    </span>
                                </h6>
                                <div className="horizontal_display_without_justify">
                                    <div className="text-muted fs-12">
                                        {hide ? "*************" : "12216.4888"}
                                    </div>
                                </div>
                            </div>
                            <a className="btn login_btn px-3 ">Become Merchant</a>
                        </div>
                    </div>
                </div>
                <div className="row mb-4 d-none">
                    <div className="col">
                        <div className="jambotron text-center">
                            <p>30d Trades</p>
                            <h6 className="fs-18">0 Time(s)</h6>
                        </div>
                    </div>
                    <div className="col">
                        <div className="jambotron text-center">
                            <p>30d Completion Rate</p>
                            <h6 className="fs-18">0 %</h6>
                        </div>
                    </div>
                    <div className="col">
                        <div className="jambotron text-center">
                            <p>Avg. Release Time</p>
                            <h6 className="fs-18">0 Minute(s)</h6>
                        </div>
                    </div>
                    <div className="col">
                        <div className="jambotron text-center">
                            <p>Avg. Pay Time</p>
                            <h6 className="fs-18">0 Minute(s)</h6>
                        </div>
                    </div>
                    <div className="col">
                        <div className="jambotron text-center">
                            <p>Positive Feedback</p>
                            <h6 className="fs-18">100 %(3)</h6>
                        </div>
                    </div>
                </div>

                <div className="row mb-5">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <section className="col-md-6">

                                <div className="d-flex align-items-center">
                                    <BackComp url={"/p2pOrder"} text={""} />
                                    <h6 className="text-white mb-0">Advertisers' Terms</h6>
                                </div>
                            </section>
                            <section className="col-md-6 pay_meth_dropdown  text-end">
                                <button
                                    className="login_btn py-1 px-4 fs-12"
                                    type="button"
                                    onClick={handleSave}
                                >
                                    <IoIosSave style={{ fontSize: "14px", marginRight: "3px" }} />{" "}
                                    Save
                                </button>
                            </section>
                        </div>
                        <div>
                            <textarea
                                className="form-control p-4"
                                rows="15"
                                tabIndex={1}
                                onChange={(content) => setContent(content.target.value)}
                                value={content ?? defaultText}
                            >
                                {content ?? defaultText}
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AdvertiserTerms;
