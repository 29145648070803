import React from "react";

const ReferrelData = ({ referData }) => {
  // console.log(referData);

  return (
    <>
      <div className="mt-5 text-white">
        <h1 className="">Data</h1>
        <div className="refer-data">
          <section>
            <p>Today</p>
            <p className="fs-4">{referData?.todayReferrals || "0"} User(s)</p>
          </section>
          <section>
            <p>Yesterday's Commission</p>
            <p className="fs-4">≈ {referData?.yesterdayIncome || "0"} USDT</p>
          </section>
          <section>
            <p>Last 30D Referral</p>
            <p className="fs-4">{referData?.last30DReferrals || "0"} User(s)</p>
          </section>
          <section>
            <p>Last 30D Commission</p>
            <p className="fs-4">≈ {referData?.last30DIncome || "0"} Pts</p>
          </section>
        </div>
      </div>
    </>
  );
};

export default ReferrelData;
