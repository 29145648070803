import React from "react";
import Loader from "../../components/Loader";
import { Pagination } from "@mui/material";
import "./market.css";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";


const AllCoins = ({ market_child, loader, setShortCoin, shortCoin }) => {
  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 table-responsive ">
        <table style={{ minWidth: '800px' }} className="table text-light mt-2 text-white border-no">
          <thead>
            <tr>
              <th style={{ width: "36%" }}>
                <div className="d-flex">
                  <span>Trading Pair</span>
                  <div style={{ lineHeight: "0px" }} className={`ms-2 role-btn`} onClick={() => shortCoin?.key == "names" ? setShortCoin({
                    key: "names",
                    value: shortCoin?.value == "asc" ? "desc" : "asc"
                  }) : setShortCoin({
                    key: "names",
                    value: "asc"
                  })}>
                    <TiArrowSortedUp style={{ display: "block" }} className={`role-btn ${(shortCoin?.key == "names" && shortCoin?.value) == "asc" ? "active-short" : ""}`} />
                    <TiArrowSortedDown className={`role-btn ${(shortCoin?.key == "names" && shortCoin?.value == "desc") ? "active-short" : ""}`} />
                  </div>
                </div>
              </th>
              <th>
                <div className="d-flex">
                  <span>Last Price</span>
                  <div style={{ lineHeight: "0px" }} className={`ms-2 role-btn`} onClick={() => shortCoin?.key == "last-price" ? setShortCoin({
                    key: "last-price",
                    value: shortCoin?.value == "asc" ? "desc" : "asc"
                  }) : setShortCoin({
                    key: "last-price",
                    value: "asc"
                  })}>
                    <TiArrowSortedUp style={{ display: "block" }} className={`role-btn ${(shortCoin?.key == "last-price" && shortCoin?.value) == "asc" ? "active-short" : ""}`} />
                    <TiArrowSortedDown className={`role-btn ${(shortCoin?.key == "last-price" && shortCoin?.value == "desc") ? "active-short" : ""}`} />
                  </div>
                </div>
              </th>
              <th>
                <div className="d-flex">
                  <span>24h Change</span>
                  <div style={{ lineHeight: "0px" }} className={`ms-2 role-btn`} onClick={() => shortCoin?.key == "24h" ? setShortCoin({
                    key: "24h",
                    value: shortCoin?.value == "asc" ? "desc" : "asc"
                  }) : setShortCoin({
                    key: "24h",
                    value: "asc"
                  })}>
                    <TiArrowSortedUp style={{ display: "block" }} className={`role-btn ${(shortCoin?.key == "24h" && shortCoin?.value) == "asc" ? "active-short" : ""}`} />
                    <TiArrowSortedDown className={`role-btn ${(shortCoin?.key == "24h" && shortCoin?.value == "desc") ? "active-short" : ""}`} />
                  </div>
                </div>
              </th>
              <th>
                <div className="d-flex">
                  <span>Market Cap</span>
                  <div style={{ lineHeight: "0px" }} className={`ms-2 role-btn`} onClick={() => shortCoin?.key == "market-cap" ? setShortCoin({
                    key: "market-cap",
                    value: shortCoin?.value == "asc" ? "desc" : "asc"
                  }) : setShortCoin({
                    key: "market-cap",
                    value: "asc"
                  })}>
                    <TiArrowSortedUp style={{ display: "block" }} className={`role-btn ${(shortCoin?.key == "market-cap" && shortCoin?.value) == "asc" ? "active-short" : ""}`} />
                    <TiArrowSortedDown className={`role-btn ${(shortCoin?.key == "market-cap" && shortCoin?.value == "desc") ? "active-short" : ""}`} />
                  </div>
                </div>
              </th>
              <th>4H Trend</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan="6" style={{ textAlign: "center" }}>
                  <Loader />
                </td>
              </tr>
            ) : (
              market_child
            )}
          </tbody>
        </table>
        {/* <Pagination count={10}  color="secondary" /> */}
      </div >
    </>
  );
};

export default AllCoins;
