import React, { useState, useEffect } from "react";
import Header from "./HomeComp/Header";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./login.css";
import {
  isEmail,
  isNum,
  isPass,
} from "./redux/helpers/form-validator.functions";
import { user_Login, user_authentication } from "./redux/actions/authActions";
import { useGoogleLogin } from "@react-oauth/google";
import {
  N_getGoogleAuth,
  N_sendOTPMobileEmail,
  N_verifyOTPNew,
  socialLogin,
} from "./redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import { AUTH_LOGIN } from "./redux/constant";
import toast from "react-hot-toast";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

// import { ConnectButton } from "@rainbow-me/rainbowkit";
export default function Login(props) {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  if (isLoggedIn) props.history?.replace("/profile");
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [phone, setphone] = useState();
  const [activetab, setActivetab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const [otp, setotp] = useState();
  const { webData } = useSelector((state) => state.websiteDBReducer);
  const { switch_theme } = useSelector((state) => state.AuthReducer);
  const [user, setUser] = useState([]);
  const [eye, seteye] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authenticator, setAuthenticator] = useState(0);

  const hsPassword = (_id) => {
    var _idv = document.querySelector("#" + _id);
    if (_idv.getAttribute("type") == "text") {
      _idv.setAttribute("type", "password");
      seteye(false);
    } else {
      _idv.setAttribute("type", "text");
      seteye(true);
    }
  };
  const setErrorFor1 = (selector, message) => {
    const element = document.querySelector(selector);
    if (element) {
      element.innerText = message;
    } else {
      console.error(`Element with selector "${selector}" not found.`);
    }
  };
  const isPass = (password) => {
    const errorSelector = "#password-error"; // Example selector
    if (!password || password.length < 8) {
      setErrorFor1(
        errorSelector,
        "Password must be at least 8 characters long"
      );
      return false;
    }
    setErrorFor1(errorSelector, ""); // Clear error if validation passes
    return true;
  };

  const submit = (e) => {
    e.preventDefault();
    // console.log(email, phone, password);
    // console.log(isEmail(email), isNum(phone), isPass(password));
    setIsAuthenticating(true);
    if ((isEmail(email) || isNum(phone)) && isPass(password)) {
      // N_getGoogleAuth(email, phone, password).then((data) => {
      // console.log("data", data);
      // if (data.status === 200 && data.authenticator_status === 0) {
      setLoading(true);
      dispatch(
        user_Login(
          email,
          phone,
          password,
          otp,
          (res) => {
            // console.log(res?.params?.token, "juguyftytyyt");
            if (res?.params?.ask_login_otp > 0) {
              setAuthenticator(1)
            }
            setLoading(false);
            // props.history?.replace("/profile");
          },
          () => setLoading(false)
        )
      );
    } else {
      // console.log("called");
      toast.error("Invalid Password/Email");
      // setAuthenticator(data.authenticator_status);
    }
    // });
    // } else {
    //   toast.error("Invalid Password/Email");
    // }

  };

  const onSubmit = (e) => {
    e.preventDefault();
    N_verifyOTPNew(email, phone, otp).then((res) => {
      if (res.status == 200) {
        toast.success(res.message);
        dispatch({ type: AUTH_LOGIN, data: res });
        // props.history?.replace("/profile");
      } else {
        toast.error(res.message);
      }
    });
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      socialLogin(codeResponse.access_token).then((res) => {
        if (res.status) {
          toast.success(res.message);
          dispatch({ type: AUTH_LOGIN, data: res });
          props.history?.replace("/");
        } else {
          toast.success(res.message);
        }
      });
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <>
      <Header {...props} />
      <div class="register_bg">
        <div className="container">
          <div class="row align-items-center">
            <div class="col-md-6 p-0 d-none d-md-block">
              <div className="LoginImg">
                <img src="/img/rewards-1.svg" className="login-img0" />
              </div>
            </div>

            <div class="col-md-6">
              <div class="m-auto login_window signupform cstm_form">
                <div className="d-flex justify-content-center mb-5">
                  <Link to="/">
                    {switch_theme === "dark" ? (
                      <img src="/img/logo.svg" style={{ width: "100px" }} />
                    ) : (
                      <img
                        src="/img/logo.svg"
                        style={{ width: "100px" }}
                      />
                    )}
                  </Link>
                </div>

                {/* <div className="page-content-title">
                  <h3 className="mb-lg-5 text-center">Log In</h3>
                </div> */}
                <p class="text-white mt-0 text-center">
                  Sign In your account to enjoy trading.
                </p>
                {authenticator === 0 ? (<>
                  <form
                    className="mt-5"
                    method="post"
                    id="loginForm"
                    onSubmit={submit}
                    autoComplete="off"
                  >
                    <div className="form-group">
                      <small
                        id="msg"
                        className="mt-2 "
                        style={{ fontSize: "15px", marginTop: "20px" }}
                      >
                        Error Message
                      </small>
                    </div>
                    {activetab == 0 ? (
                      <div className="form-group mb-4">
                        <label
                          htmlFor="user_password"
                          className="fw-bold mb-2 text-white"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="user_email"
                          className="form-control"
                          value={email}
                          autoComplete="off"
                          readOnly
                          onFocus={(e) => {
                            e.target.removeAttribute("readOnly");
                          }}
                          required
                          id="user_email"
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter Email"
                        />
                        {/* <i className="fas fa-check-circle"></i>
                      <i className="fas fa-exclamation-circle"></i>
                      <small></small> */}
                      </div>
                    ) : (
                      <div className="form-group mb-4">
                        {/* <label>Phone Number</label> */}
                        <input
                          type="phone"
                          name="user_phone"
                          className="form-control"
                          autoComplete="off"
                          readOnly
                          onFocus={(e) => {
                            e.target.removeAttribute("readOnly");
                          }}
                          value={phone}
                          id="user_phone"
                          onChange={(e) => setphone(e.target.value)}
                          placeholder="Phone Number"
                        />
                        {/* <i className="fas fa-check-circle"></i>
                      <i className="fas fa-exclamation-circle"></i>
                      <small></small> */}
                      </div>
                    )}
                    <div className="form-group">
                      <label
                        htmlFor="user_password"
                        className="fw-bold mb-2 text-white"
                      >
                        Password
                      </label>

                      <div className="input-group">
                        <input
                          type="password"
                          className="form-control"
                          name="user_password"
                          id="pass"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          placeholder="Enter Password"
                        />
                        <span className="input-group-text">
                          <a
                            href="#view_qr"
                            className=""
                            onClick={(e) => {
                              hsPassword("pass");
                            }}
                          >
                            {/* <i className="far fa-eye"></i> */}
                            {eye ? (
                              <IoIosEye size={20} />
                            ) : (
                              <IoIosEyeOff size={20} />
                            )}
                          </a>
                        </span>
                      </div>
                      {/* <i className="fas fa-check-circle"></i>
                    <i className="fas fa-exclamation-circle"></i>
                    <small id="passerr"></small> */}
                    </div>
                    <div className="text-end ">
                      <Link to="/forget" className="text-lgray fs-12 hover_text">
                        Forgot Password ?
                      </Link>
                    </div>

                    <div className="d-grid mt-3 mb-3">
                      <button
                        type="submit"
                        id="login_btn"
                        className="btn login_btn"
                      // onClick={() => submit()}
                      >
                        {loading ? (
                          <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                        ) : null}
                        <span id="reg">Login</span>
                      </button>
                    </div>
                  </form>
                </>) : (authenticator === 1 ? (<>
                  <form
                    className="mt-5"
                    method="post"
                    id="loginForm"
                    onSubmit={onSubmit}
                    autoComplete="off"
                  >
                    <div className="form-group">
                      <small
                        id="msg"
                        className="mt-2 "
                        style={{ fontSize: "15px", marginTop: "20px" }}
                      >
                        Error Message
                      </small>
                    </div>


                    <div className="form-group mb-4">
                      <input
                        type="number"
                        name="user_otp"
                        className="form-control"
                        required=""
                        value={otp}
                        id="user_otp"
                        onChange={(e) => setotp(e.target.value)}
                        placeholder="Enter OTP"
                      />
                      {/* <i className="fas fa-check-circle"></i>
                      <i className="fas fa-exclamation-circle"></i>
                      <small></small> */}
                    </div>


                    <div className="text-end ">
                      <Link to="/forget" className="text-lgray fs-12 hover_text">
                        Forgot Password ?
                      </Link>
                    </div>

                    <div className="d-grid mt-3 mb-3">
                      <button
                        type="submit"
                        id="login_btn"
                        className="btn login_btn"
                      >
                        {loading ? (
                          <i className="loading-icon fas fa-spinner fa-spin me-2"></i>
                        ) : null}
                        <span id="reg">Log In</span>
                      </button>
                    </div>
                  </form></>) : null)}

                {/* <div className="my-3 text-center">
                  Or Log In with any one of these
                </div> */}
                <div className="d-grid">
                  {/* <button
                    className="login-with-google-btn"
                    onClick={() => googleLogin()}
                  >
                    <img
                      src="./img/google.png"
                      width={20}
                      className="me-2"
                    />{" "}
                    Sign in with Google
                  </button> */}

                  {/* <ConnectButton.Custom>
                    {({
                      account,
                      chain,
                      openAccountModal,
                      openChainModal,
                      openConnectModal,
                      authenticationStatus,
                      mounted
                    }) => {
                      // Note: If your app doesn't use authentication, you
                      // can remove all 'authenticationStatus' checks
                      const ready =
                        mounted && authenticationStatus !== "loading";
                      const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus ||
                          authenticationStatus === "authenticated");

                      return (
                        <div
                          className="d-grid mt-3"
                          {...(!ready && {
                            "aria-hidden": true,
                            style: {
                              opacity: 0,
                              pointerEvents: "none",
                              userSelect: "none"
                            }
                          })}
                        >
                          {(() => {
                            if (!connected) {
                              return (
                                <button
                                  class="btn login_btn_outline"
                                  onClick={openConnectModal}
                                  type="button"
                                >
                                  <i className="fa fa-wallet me-2"></i> Sign
                                  in With Wallet
                                </button>
                              );
                            }

                            if (chain.unsupported) {
                              return (
                                <button
                                  onClick={openChainModal}
                                  type="button"
                                >
                                  Wrong network
                                </button>
                              );
                            }
                            return (
                              <div style={{ display: "flex", gap: 12 }}>
                                <button
                                  onClick={openChainModal}
                                  style={{
                                    display: "flex",
                                    alignItems: "center"
                                  }}
                                  type="button"
                                >
                                  {chain.hasIcon && (
                                    <div
                                      style={{
                                        background: chain.iconBackground,
                                        width: 12,
                                        height: 12,
                                        borderRadius: 999,
                                        overflow: "hidden",
                                        marginRight: 4
                                      }}
                                    >
                                      {chain.iconUrl && (
                                        <img
                                          alt={chain.name ?? "Chain icon"}
                                          src={chain.iconUrl}
                                          style={{ width: 12, height: 12 }}
                                        />
                                      )}
                                    </div>
                                  )}
                                  {chain.name}
                                </button>

                                <button
                                  onClick={openAccountModal}
                                  type="button"
                                >
                                  {account.displayName}
                                  {account.displayBalance
                                    ? ` (${account.displayBalance})`
                                    : ""}
                                </button>
                              </div>
                            );
                          })()}
                        </div>
                      );
                    }}
                  </ConnectButton.Custom> */}
                </div>
                <div className="text-center mt-2">
                  <span className="text-white"> Don't have account? </span>
                  <Link className="text-gradient hover_text" to="/create">
                    Sign Up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
