import React, { useEffect, useState } from "react";
import Header from "../HomeComp/Header";
import "../sidebar.css";
import { getMyOrders } from "../redux/helpers/api_functions_new";
import { IoChatbubbleEllipses } from "react-icons/io5";
import { FaDotCircle } from "react-icons/fa";
import "./myads.css"
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { fullDateFormat } from "../../utils/common";
import NoDataComp from "../components/NoDataComp";
import Loader from "../components/Loader";
import { Chip } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaEye } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";
import BackComp from "../../helper/components/BackComp";

const MyOrders = () => {
    const router = useHistory();
    const location = useLocation();
    const info = location.state;

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (info.state) {
            fetchOrders(info.state);
        }
    }, [info]);

    const fetchOrders = async ({ post_id }) => {
        try {
            setLoading(true)
            let response = await getMyOrders(post_id);
            if (response) {
                setData(response?.data);
                setLoading(false)
            }
        } catch (error) {
            console.log("error --->>", error);
            setLoading(false)
        }
    };

    const handleRedirection = async (url, id) => {
        router.push(url, {
            state: id
        })
    }

    const hasDisabledStatus = data?.orderInfo?.some(
        (item) => item.status === "complete" || item.status === "reject"
    );

    return (
        <>
            <Header />
            <div className="page-content " style={{ marginTop: "100px" }}>
                <main className="container mt-5 my-postad">
                    <div className="row">
                        <div className="mb-3 w-full">

                            <div className="">

                                <div className="d-flex align-items-center">
                                    <BackComp url={"/my-postad"} text={""} />
                                    <h3 className="text-white my-0"> My Orders</h3>
                                </div>
                                <div className="mt-4">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">User</th>
                                                <th scope="col">Unit price</th>
                                                <th scope="col">Receiving Amount</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-white">
                                            {
                                                loading ? (
                                                    <tr>
                                                        <td colSpan="7" style={{ textAlign: "center" }}>
                                                            <Loader />
                                                        </td>
                                                    </tr>
                                                ) :

                                                    data?.orderInfo.length > 0 ?
                                                        data?.orderInfo?.map((value, index) => {
                                                            return <tr key={index}>
                                                                <td scope="row" className="py-3">{index + 1}</td>
                                                                <td className="py-3">{value?.user?.name ? value?.user?.name : value?.user?.email}</td>
                                                                <td className="py-3">{value.crypto_amount_receive} {value.crypto_currency}</td>
                                                                <td className="py-3">{value.fiat_amount_sent} {value.fiat_currency} </td>
                                                                <td className="py-3">
                                                                    {
                                                                        value.ticket_status == "active" ?
                                                                            <span class="badge text-bg-warning">In appeal</span>
                                                                            : (value.status == "active" || value.status == "payment_done") ?
                                                                                <span class="badge text-bg-info">Active</span>
                                                                                : (value.status == "complete" || value.status == "admin_complete") ?
                                                                                    <span class="badge text-bg-success">Completed</span>
                                                                                    : value.status == "cancel" ?
                                                                                        <span class="badge text-bg-danger">Cancel</span>
                                                                                        : value.status == "reject" ?
                                                                                            <span class="badge text-bg-danger">Rejected</span>
                                                                                            : value.status == "timeout" ?
                                                                                                <span class="badge text-bg-danger">Timeout</span>
                                                                                                : <><span class="badge text-bg-danger">{value.status}</span></>
                                                                    }
                                                                </td>
                                                                <td className="py-3">
                                                                    {fullDateFormat(value.createdAt)}
                                                                </td>
                                                                <td className="py-3">

                                                                    <FaArrowAltCircleRight
                                                                        className="action-icon ms-3 me-0"
                                                                        role="button"
                                                                        onClick={() => handleRedirection('/order-chat', value._id)}
                                                                        style={{ fontSize: "18px" }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        })
                                                        : (
                                                            <tr>
                                                                <td colSpan="7" style={{ textAlign: "center" }}>
                                                                    <NoDataComp text={"No Data Available"} />
                                                                </td>
                                                            </tr>
                                                        )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default MyOrders;
