import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";

export default function TermsConditions(props) {
  return (
    <>
      <Header {...props} />
      <div className="wrap-privacy d-flex align-items-center">
        <div className="container py-5">
          <div className="terms_border risk_warning">
            <div className="d-block mx-auto">
              <h1 className="text-left font-size-40 text-gradient">
                Security at bitcash – Trade with Confidence
              </h1>
              <p className="para">
                At bitcash, security is not just a feature—it is a fundamental
                principle that drives every aspect of our platform. We employ
                cutting-edge security measures to safeguard your funds, personal
                data, and trading activities at all times. Our advanced security
                protocols ensure that you can trade with peace of mind, knowing
                that your assets are protected by industry-leading technology
                and rigorous compliance standards.
              </p>
              <br />

              <h1 className="text-left font-size-40 text-gradient">
                Proof-of-Reserves – 100% Fund Transparency
              </h1>
              <p className="para">
                Transparency is at the core of our financial integrity. bitcash
                guarantees 100% Proof-of-Reserves, ensuring that every deposit
                is fully backed and verifiable. With our real-time auditing
                system, you can trust that your funds are always accounted for,
                eliminating any hidden risks. Our commitment to financial
                transparency means you have full visibility into your account
                security at all times.
              </p>
              <br />

              <h1 className="text-left font-size-40 text-gradient">
                AI-Driven Risk Detection and Fraud Prevention
              </h1>
              <p className="para">
                Security threats in the financial sector are constantly
                evolving, which is why we leverage AI-powered security systems
                to monitor transactions 24/7. Our intelligent fraud detection
                algorithms analyze trading patterns, login activity, and
                withdrawal requests in real time to identify and block
                suspicious activities before they become threats. This proactive
                approach ensures a secure trading environment, protecting you
                from unauthorized access and fraudulent transactions.
              </p>
              <br />

              <h1 className="text-left font-size-40 text-gradient">
                Two-Factor Authentication (2FA) for Enhanced Account Security
              </h1>
              <p className="para">
                To add an extra layer of protection, bitcash enforces mandatory
                Two-Factor Authentication (2FA) for all logins and transactions.
                By enabling 2FA, your account remains secure even if your
                password is compromised. This security measure ensures that only
                authorized users can access your account, providing an
                additional safeguard against cyber threats.
              </p>
              <br />

              <h1 className="text-left font-size-40 text-gradient">
                End-to-End Encryption & Secure Transactions
              </h1>
              <p className="para">
                Your personal and financial data are protected by AES-256
                encryption, the same security standard used by banks and
                military institutions. Every transaction, login, and
                communication on bitcash is encrypted, ensuring that no third
                party can access or intercept your sensitive information. This
                guarantees that your trading activities remain private and
                secure at all times.
              </p>
              <br />

              <h1 className="text-left font-size-40 text-gradient">
                DDoS Protection & Real-Time Threat Monitoring
              </h1>
              <p className="para">
                bitcash is fortified with multi-layered security protocols to
                defend against Distributed Denial-of-Service (DDoS) attacks and
                unauthorized access attempts. Our real-time monitoring system
                instantly detects and mitigates cyber threats, ensuring platform
                stability and uninterrupted trading. No matter how sophisticated
                the attack, our infrastructure is designed to keep your funds
                and data safe.
              </p>
              <br />

              <h1 className="text-left font-size-40 text-gradient">
                Strict Anti-Money Laundering (AML) & KYC Compliance
              </h1>
              <p className="para">
                To maintain a secure and compliant trading environment, bitcash
                enforces rigorous AML and KYC policies. Every user undergoes
                identity verification to prevent fraudulent activities, money
                laundering, and unauthorized transactions. Our adherence to
                global financial regulations ensures a transparent and
                trustworthy trading experience for all users.
              </p>
              <br />

              <h1 className="text-left font-size-40 text-gradient">
                Device & IP Whitelisting – Control Your Account Access
              </h1>
              <p className="para">
                bitcash allows users to restrict account access to trusted devices
                and IP addresses only. By enabling device and IP whitelisting,
                you can prevent unauthorized logins from unrecognized locations.
                This additional security measure ensures that only you can
                access your account, further reducing the risk of hacking
                attempts.
              </p>
              <br />

              <h1 className="text-left font-size-40 text-gradient">
                Security Audits & Industry Partnerships
              </h1>
              <p className="para">
                Security is an ongoing commitment, which is why bitcash
                collaborates with top-tier cybersecurity firms to conduct
                regular security audits. These independent audits verify our
                compliance with the highest industry standards, ensuring that
                our security infrastructure remains robust and up to date
                against emerging threats.
              </p>
              <br />

              <h1 className="text-left font-size-40 text-gradient">
                How You Can Enhance Your Account Security
              </h1>
              <p className="para">
                While bitcash implements industry-leading security measures,
                account security is a shared responsibility. To further protect
                your account, we recommend the following best practices:
              </p>
              <ul>
                <li>
                  <span className="text-gradient">
                    Enable Two-Factor Authentication (2FA)
                  </span>{" "}
                  for all logins and withdrawals.
                </li>
                <li>
                  <span className="text-gradient">
                    {" "}
                    Use strong, unique passwords{" "}
                  </span>{" "}
                  and update them regularly.
                </li>
                <li>
                  <span className="text-gradient">
                    {" "}
                    Beware of phishing attempts{" "}
                  </span>{" "}
                  —bitcash will never ask for your private keys or passwords.
                </li>
                <li>
                  <span className="text-gradient">
                    {" "}
                    Enable withdrawal whitelisting{" "}
                  </span>{" "}
                  to restrict fund transfers to pre-approved addresses.
                </li>
                <li>
                  <span className="text-gradient">
                    {" "}
                    Monitor your account activity{" "}
                  </span>{" "}
                  and report any suspicious transactions immediately.
                </li>
              </ul>
              <br />

              <h1 className="text-left font-size-40 text-gradient">
                Trade Securely with bitcash
              </h1>
              <p className="para">
                At bitcash, we go beyond industry standards to provide a secure,
                transparent, and fraud-free trading experience. Our advanced
                security infrastructure ensures that your funds, personal
                information, and transactions remain protected at all times.
                With continuous innovation and strict regulatory compliance, we
                make security our highest priority—so you can trade with
                confidence, knowing that your assets are in safe hands.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
