import React from 'react'

function NoData({ text, url }) {

    return (
        <div className='text-center'>
            <img src={url ? url : "/img/norecord-1.png"} style={{ height: "180px", marginBottom: "5px" }} />
            <h5 className='text-light'>{text ? text : "No Record Found!"}</h5>
        </div>
    )
}

export default NoData
