import React, { useEffect, useState } from "react";
import Header from "../HomeComp/Header";
import "../sidebar.css";
import { getMyActiveRequests } from "../redux/helpers/api_functions_new";
import { IoChatbubbleEllipses } from "react-icons/io5";
import { FaDotCircle } from "react-icons/fa";
import "../myAds/myads.css";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { fullDateFormat } from "../../utils/common";
import NoDataComp from "../components/NoDataComp";
import Loader from "../components/Loader";
import { Chip } from "@mui/material";
import BackComp from "../../helper/components/BackComp";

const ActiveRequests = () => {
  const router = useHistory();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [fiatPrice, setFiatPrice] = useState('');
  const [selectedCoin, setSelectedCoin] = useState({
    name: "TRY",
    img: "https://cdn2.iconfinder.com/data/icons/world-currency/512/07-512.png",
  });
  const coinsData = [
    {
      img: "https://img.icons8.com/color/48/000000/us-dollar-circled--v1.png",
      name: "USD",
    },
    {
      img: "https://cdn2.iconfinder.com/data/icons/world-currency/512/07-512.png",
      name: "TRY",
    },
    {
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQOln-TjfUuSEKP8w4CQ5JnIAcYQoNSHvhytQ&s",
      name: "AED",
    },
  ];
  const fetchRequests = async () => {
    try {
      setLoading(true);
      let response = await getMyActiveRequests();
      if (response) {
        // console.log(response, "es>>>>>");
        setData(response?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error --->>", error);
      setLoading(false);
    }
  };

  const handleRedirection = async (url, id) => {
    router.push(`${url}?id=${id}`);
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  return (
    <>
      <Header />
      <div className="page-content" style={{ marginTop: "100px" }}>
        <main className="container mt-5 my-postad">
          <div className="row">
            <div className="mb-3 w-full">
              <div className="py-3">
                <div className="d-flex justify-content-between">
                  {/* <h2 className="text-white"> My Requests</h2> */}

                  <div className="d-flex align-items-center">
                    <BackComp url={"/p2pOrder"} text={""} />
                    <h3 className="text-white m-0 mr-1"> My Requests</h3>
                  </div>
                  <div className="d-flex align-items-center p2p-filter-border">
                    <div
                      className="dropdown me-2 pe-2"
                      style={{ borderRight: "1px solid #474d57" }}
                    >
                      <button
                        className="btn btn-secondary dropdown-toggle d-flex align-items-center"
                        type="button"
                        id="currencyDropdown"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{
                          backgroundColor: "transparent",
                          border: "none",
                          color: "white",
                          padding: "0",
                        }}
                      >
                        <img
                          src={selectedCoin?.img}
                          alt="USD"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                            borderRadius: "50%",
                          }}
                        />
                        {selectedCoin?.name}
                      </button>
                      <ul
                        className="dropdown-menu"
                        style={{
                          backgroundColor: "#333",
                          padding: "5px 0px",
                          borderRadius: "5px",
                        }}
                        aria-labelledby="currencyDropdown"
                      >
                        {coinsData?.map((item, i) => (
                          <li
                            key={i}
                            onClick={() =>
                              setSelectedCoin({ img: item?.img, name: item?.name })
                            }
                          >
                            <a
                              className="dropdown-item d-flex align-items-center hover-bg-gray"
                              href="#"
                            >
                              <img
                                src={item?.img}
                                alt="TRY"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  marginRight: "5px",
                                  borderRadius: "50%",
                                }}
                              />
                              {item?.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <input
                      type="text"
                      className="form-control text-white"
                      placeholder="Enter Amount"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        color: "white",
                        flex: 1,
                      }}
                      onChange={(e) => setFiatPrice(e.target.value)}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Order ID</th>
                        <th scope="col">User</th>
                        <th scope="col">Receiving Asset</th>
                        <th scope="col">Sending Amount</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody className="text-white">
                      {loading ? (
                        <tr>
                          <td colSpan="7" style={{ textAlign: "center" }}>
                            <Loader />
                          </td>
                        </tr>
                      ) : data?.orderInfo.length > 0 ? (
                        data?.orderInfo?.map((value, index) => {
                          return (
                            <tr key={index}>
                              <td scope="row" className="py-3">
                                {index + 1}
                              </td>
                              <td scope="row" className="py-3">
                                {value.order_number}
                              </td>
                              <td className="py-3 text-capitalize">
                                {value?.post_info?.user_info?.name
                                  ? value?.post_info?.user_info?.name
                                  : value?.post_info?.user_info?.email}
                              </td>
                              <td className="py-3">
                                {value.crypto_amount_receive}{" "}
                                {value.crypto_currency}
                              </td>
                              <td className="py-3">
                                {value.fiat_amount_sent} {value.fiat_currency}{" "}
                              </td>
                              <td className="py-3 text-capitalize">
                                {
                                  (value.status == "active" || value.status == "payment_done") ?
                                    <span class="badge text-bg-info">Active</span>
                                    : (value.status == "complete" || value.status == "admin_complete") ?
                                      <span class="badge text-bg-success">Completed</span>
                                      : value.status == "cancel" ?
                                        <span class="badge text-bg-danger">Cancel</span>
                                        : value.status == "reject" ?
                                          <span class="badge text-bg-danger">Rejected</span>
                                          : value.status == "timeout" ?
                                            <span class="badge text-bg-danger">Timeout</span>
                                            : value.ticket_status == "active" ?
                                              <span class="badge text-bg-danger">In appeal</span>
                                              : <><span class="badge text-bg-danger">{value.status}</span></>
                                }
                                {/* <Chip
                                  color={
                                    value.status == "active"
                                      ? "info"
                                      : value.status == "complete"
                                        ? "success"
                                        : "warning"
                                  }
                                  icon={<FaDotCircle />}
                                  label={value.status}
                                  size="small"
                                  variant="outlined"
                                /> */}
                              </td>
                              <td className="py-3">
                                {fullDateFormat(value.createdAt)}
                              </td>
                              <td className="py-3">
                                <button className="btn border-0">
                                  <IoChatbubbleEllipses
                                    color="#fff"
                                    onClick={() =>
                                      handleRedirection(
                                        "/create-order",
                                        value._id
                                      )
                                    }
                                    className="action-icon"
                                  />
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="7" style={{ textAlign: "center" }}>
                            <NoDataComp text={"No Data Available"} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ActiveRequests;
