
// TradingViewWidget.jsx
// import React, { useEffect, useRef, memo } from 'react';

// function TVChartContainer({ symbols = 'BTCUSD', pre_symbols = 'USDT', interval = '1' }) {
//   let pair = symbols;
// pair = pair.replace('-', '').toUpperCase();
//   console.log(symbols,'pair')
//   const container = useRef();

//   useEffect(
//     () => {
//       const script = document.createElement("script");
//       script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
//       script.type = "text/javascript";
//       script.async = true;
//       script.innerHTML = `
//         {
//          "width": "100%",
//           "height": "660",
//          "symbol": "BINANCE:${pair}",
//           "interval": "M",
//           "timezone": "Etc/UTC",
//           "theme": "dark",
//           "style": "1",
//           "locale": "en",
//           "allow_symbol_change": true,
//           "backgroundColor": "#171b26",
//           "calendar": false,
//           "support_host": "https://www.tradingview.com"
//         }`;
//       container.current.appendChild(script);

//     },
//     []
//   );
//   return (
//     <div className="tradingview-widget-container ddd" ref={container} style={{ height: "640px", width: "100%" }}>
//       <div className="tradingview-widget-container__widget eee" style={{ height: "100%", width: "100%" }}></div>
//       <div className="tradingview-widget-copyright"><a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div>
//     </div>
//   );
// }

// export default memo(TVChartContainer);



import React, { useEffect, useRef, memo } from "react";
import { useSelector } from "react-redux";

function TVChartContainer({ symbols = "BTCUSD", interval = "1" }) {
  const container = useRef();
  const { switch_theme } = useSelector((state) => state.AuthReducer);
  let pair = symbols.replace("-", "").toUpperCase();

  useEffect(() => {
    if (container.current) {
      container.current.innerHTML = "";
    }

    const script = document.createElement("script")
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js"
    script.type = "text/javascript"
    script.async = true

    const theme = switch_theme === "dark" ? "dark" : "light"; // Ensure theme switch works

    script.innerHTML = `
    {
      "autosize": true,
      "width": "100%",
      "height": "660",
      "symbol": "BINANCE:${pair}",
      "timezone": "Etc/UTC",
      "theme": "${theme}",
      "style": "1",
      "locale": "en",
      "withdateranges": true,
      "range": "YTD",
      "hide_side_toolbar": false,
      "allow_symbol_change": false,
      "calendar": false,
      "show_popup_button": true,
      "popup_width": "1000",
      "popup_height": "650",
      "support_host": "https://tradingview.com"
    }`

    container.current.appendChild(script);
  }, [pair, switch_theme]);

  return (
    <div
      className="tradingview-widget-container"
      ref={container}
      key={pair + switch_theme} // Ensure re-render when theme changes
      style={{ height: "660px", width: "100%" }}
    >
      <div
        className="tradingview-widget-container__widget"
        style={{ height: "660px", width: "100%" }}
      ></div>
      <div className="tradingview-widget-copyright">
        <a
          href="https://tradingview.com/"
          rel="noopener nofollow"
          target="_blank"
        >
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
}

export default memo(TVChartContainer);
