import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import OrderSidebar from "./components/OrderSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";

export default function MarginLending() {
  return (
    <>
      <Header />
      <div className="page-content">
        <main className="container">
          <div className="account_page">
            <div class="aco_hero mt-5 text-center">
              <img src="/img/solutions.svg" alt="margin" className="mt-5" />
              <p class="mt-5 text-muted">
                You haven't enabled margin trading yet.
              </p>
              <a href="#" class="btn login_btn mb-5">
                Enable Margin Trading
              </a>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
