import React from 'react'
import { IoMdArrowRoundBack } from "react-icons/io";
import { useHistory } from "react-router-dom";


function BackComp({ url, text }) {
    const history = useHistory();

    return (
        <div className='d-flex align-items-center' style={{ cursor: "pointer", color: "white" }} onClick={() => history.push(url)}>
            <IoMdArrowRoundBack className='ml-2' style={{ fontSize: "24px", marginRight: "10px" }} /> {text ? text : ""}
        </div>
    )
}

export default BackComp
