import { Box, Paper, Typography } from "@mui/material";
import {
  Chat,
} from "@pubnub/chat";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { IoIosSend, IoMdCheckmarkCircleOutline } from "react-icons/io";
import { MdAttachFile } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector } from "react-redux";
import { chatDateStringToDate, firstLetterUpper } from "../../../utils/common";
import Loader from "../../components/Loader";
import { sendPushNotification } from "../../redux/helpers/api_functions_new";


const ChatComp = ({ orderResp, userType, supportBtn, status, uuid }) => {
  const [activeUser, setActiveUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [channelId, setChannelId] = useState(null);
  const [productInfo, setProductInfo] = useState(null);
  const [lastTimeToken, setLastTimeToken] = useState("");
  const [channel, setChannel] = useState();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [chatLoader, setChatLoader] = useState(false);
  const messageListRef = useRef(null);
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const { user, profile } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    if (orderResp) {

      let activeUser;
      let selectedUser;

      if (userType == "seller") {
        activeUser = orderResp?.sellerInfo;
        selectedUser = orderResp?.receiverInfo;
      } else if (userType == "buyer") {
        activeUser = orderResp?.receiverInfo;
        selectedUser = orderResp?.sellerInfo;
      } else {
        activeUser = orderResp?.receiverInfo;
        selectedUser = orderResp?.support;
      }

      setActiveUser(activeUser);
      setSelectedUser(selectedUser);

      setProductInfo(orderResp?.orderInfo);
      setChannelId(uuid);
      initializeChat(
        activeUser,
        selectedUser,
        uuid
      );
    }
  }, [orderResp, supportBtn]);

  const initializeChat = async (active, selected, channelid) => {
    const PubNubPublishKey = "pub-c-3ca8b3a4-b3cb-47e3-beaf-f0af221e22ec";
    const PubNubSubscribeKey = "sub-c-b986ebfd-cd6c-4ed9-b879-b479db40043d";
    try {
      setChatLoader(true);
      const chat = await Chat.init({
        publishKey: PubNubPublishKey,
        subscribeKey: PubNubSubscribeKey,
        userId: active._id,
      });

      await chat.currentUser.update({
        name: active.name || "Buyer",
        custom: {
          initials: "MS",
          avatar: "#ffab91",
          userId: active?._id,
          email: active?.email,
          profile:
            "https://pinnacle.works/wp-content/uploads/2022/06/dummy-image.jpg",
        },
      });

      const interlocutor =
        (await chat.getUser(selected._id)) ||
        (await chat.createUser(selected._id, {
          name: selected.name || "Seller",
          custom: {
            initials: "GS",
            avatar: "#ffab91",
            userId: selected?._id,
            email: selected?.email,
            profile:
              "https://pinnacle.works/wp-content/uploads/2022/06/dummy-image.jpg",
          },
        }));

      const { channel } = await chat.createDirectConversation({
        user: interlocutor,
        channelId: channelid,
        channelData: { name: "Post_Order_Chat" },
      });

      setChannel(channel);
      setMessages([]);
      await getChatHistory(channel);
    } catch (error) {
      console.log(error, "<<<--- error");
    } finally {
      setChatLoader(false);
    }
  };

  const getChatHistory = async (connectedChannel) => {
    try {

      const message = await connectedChannel.getHistory({
        count: 100000, // Fetch up to 100 messages
      });

      if (message) {
        let list = [];
        for (let index = 0; index < message?.messages?.length; index++) {
          const element = message?.messages[index];
          setLastTimeToken(message?.messages[0].timetoken);

          list.push({
            content: element.content,
            timetoken: element.timetoken,
            userId: element.userId,
          });
        }
        setMessages((messages) => [...list, ...messages]);
      }

      setTimeout(() => {
        messageScroll();
      }, 50);
      return message;
    } catch (error) {
      console.log(error, "<<--- error message history");
    } finally {
      setChatLoader(false);
    }
  };

  const setFormFile = (file) => {
    try {
      if (file) {
        const extn = [
          "image/jpg",
          "image/png",
          "image/jpeg",
        ];
        if (!extn.includes(file[0].type)) {
          toast.error("Please select a valid file (only jpg, png, jpeg images are allowed)")

          setFile(null);
          throw new Error("");
        } else if (file?.[0].size >= 2097152) {
          setFile(null);
          throw new Error("");
        }
        setFile(file);
      }
    } catch (error) {
      setFile(null);
    }
  };

  const [sendingLoading, setSendingLoading] = useState(false);
  const handleSend = async (event) => {
    try {
      event.preventDefault();
      setSendingLoading(true);

      if (!channel) return;
      if (!newMessage.trim() && !file) return;

      let response;
      if (!file || file.length == 0) {
        response = await channel.sendText(newMessage);
        if (selectedUser.deviceToken) {
          await sendPushNotification(selectedUser._id, [selectedUser.deviceToken], activeUser?.name ? activeUser?.name : activeUser?.email, newMessage, "5", {});
        }

      } else if (file) {
        const extn = [
          "image/jpg",
          "image/png",
          "image/jpeg",
        ];
        if (!extn.includes(file[0].type)) {
          toast.error("Please select a valid file (only jpg, png, jpeg images are allowed)")
          throw new Error("");
        }

        response = await channel.sendText(newMessage, {
          files: file[0],
          message: newMessage,
          storeInHistory: true,
          meta: {
            messageImportance: "high",
          },
        });
        if (selectedUser.deviceToken) {
          await sendPushNotification(selectedUser._id, [selectedUser.deviceToken], activeUser?.name ? activeUser?.name : activeUser?.email, "Sent a file", "5", {});
        }

      }
      setNewMessage("");
      setFile(null);

    } catch (error) {
      console.error('Error in sending message:', error);
      // alert("Error sending message. Please try again.");
    } finally {
      setSendingLoading(false);
    }
  };


  const messageScroll = () => {
    if (!messageListRef.current) return;
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  };

  useEffect(() => {
    if (!channel) return;
    return channel.connect((message) => {
      read_message(message);
    });
  }, [channel]);

  const read_message = async (message) => {
    try {

      // if (message.userId == selectedUser._id) {
      setMessages((messages) => [...messages, {
        content: message.content,
        userId: message.userId,
        timetoken: message.timetoken
      },]);

      setTimeout(() => {
        messageScroll();
      }, 50);
      // }
    } catch (error) { }
  };


  return (
    <Paper
      elevation={4}
      sx={{
        width: "full",
        margin: "0 auto",
        height: status ? "565px" : "600px",
        display: "flex",
        flexDirection: "column",
        background: "#333a41",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          backgroundColor: "#1f1f1f",
          color: "white",
          p: 2,
          textAlign: "start",
          fontWeight: "bold",
          borderBottom: "2px solid #959595",
          display: "flex",
          alignItems: "center"
        }}
      >
        <span className="first-char"> {selectedUser?.name ? firstLetterUpper(selectedUser?.name) : firstLetterUpper(selectedUser?.email)} </span>
        <p className="m-0 ms-2 text-uppercase">
          <span>{selectedUser?.name || selectedUser?.email}</span>
        </p>
      </Box>

      {/* Chat Messages */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          scrollbarWidth: "none",
          backgroundColor: "#1f1f1f",
        }}
        ref={messageListRef}
      >
        {
          chatLoader ? <Loader /> : <>
            {messages.map((msg, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: msg.userId == activeUser?._id ? "flex-end" : "flex-start",
                  // mb: 2,
                  p: 1,
                  background: "#1f1f1f",
                  alignItems: msg.userId == activeUser?._id ? "flex-end" : "flex-start",
                  flexDirection: "column",
                  justifyContent: msg.userId == activeUser?._id ? "flex-end" : "flex-start",
                }}
              >
                {
                  msg.content.text && <Box
                    sx={{
                      maxWidth: "70%",
                      p: "10px",
                      borderRadius: 2,
                      backgroundColor: msg.sender === "me" ? "#6200ea" : "#818589",
                    }}
                  >
                    <Typography variant="body1" className="text-white" sx={{ fontSize: "0.8rem" }}>
                      {msg.content.text}
                    </Typography>

                  </Box>
                }
                <div>
                  {
                    msg.content.files.length > 0 && msg.content.files.map((value) => {
                      return <a href={value.url} target="_blank" >
                        <img
                          src={value.url}
                          class="show-chat-img"
                          loading="lazy"
                        />
                      </a>
                    })
                  }
                </div>
                <p className="text-white" style={{
                  fontSize: "9px",
                  margin: "6px 0px 0px 0px"
                }}>{chatDateStringToDate(msg.timetoken / 10000000)}</p>
              </Box>
            ))}
          </>
        }


      </Box>

      {
        file && file?.length > 0 && (
          <div class="preview-box">
            <div class="receiver-chat">
              <div class="text-white mx-2">
                <RxCrossCircled
                  className="cross-img"
                  onClick={() => setFile(null)}
                />
                <img
                  src={
                    file[0].type.search("image/") >= 0
                      ? URL.createObjectURL(file[0])
                      : file[0].type.search(
                        "application/pdf"
                      ) >= 0
                        ? 'https://www.gstatic.com/images/branding/product/2x/docs_512dp.png'
                        : 'https://cdn2.iconfinder.com/data/icons/adobe-acrobat-pdf/154/pdf-article-link-document-text-acrobat-512.png'
                  }
                  class="preview-img"
                />
              </div>
            </div>
          </div>
        )
      }

      {
        (orderResp?.orderInfo.ticket_status == "close") ? <>
          <div className="text-white px-3 mt-3">
            <h6 className="mb-1">Ticket Closed</h6>
            <p className="mt-0 mb-2" style={{ fontSize: "12px" }}>Support ticket has been closed by the admin!</p>
          </div>
        </> : (orderResp?.orderInfo.status == "admin_complete") ? <>
          <div className="text-white px-3 mt-3">
            <h6 className="mb-1 d-flex align-items-center"> <IoMdCheckmarkCircleOutline style={{ fontSize: "17px", marginRight: "3px" }} /> Fund Released</h6>
            <p className="mt-0 mb-2" style={{ fontSize: "12px" }}>The admin has successfully released the funds to buyer account!</p>
          </div>
        </> :
          <>

            {/* Input Section */}
            <form className="chat-form" onSubmit={handleSend}>
              <Box
                sx={{
                  background: '#1f1f1f',
                  p: 2,
                  borderTop: "1px solid #e0e0e0",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >

                <div class="file file-upload">
                  <label
                    for={'fileid'}
                    onClick={() => {
                      document.getElementById('fileid').value = null;
                    }}
                  >
                    <MdAttachFile className="attach-icon" />
                  </label>
                  <input
                    id={'fileid'}
                    type="file"
                    name="file"
                    className="form-control"
                    onChange={(e) => {
                      setFormFile(e.target.files);
                    }}
                  />
                </div>

                <input
                  className="form-control"
                  placeholder="Type a message..."
                  style={{
                    borderRadius: "20px",
                    background: "white",
                    height: "55px",
                    padding: "6px 50px 6px 21px",
                    color: "black"
                  }}
                  value={newMessage}
                  onChange={(e) => setNewMessage((orderResp?.orderInfo.ticket_status == "close" ||
                    orderResp?.orderInfo.status == "admin_complete") ? "" : e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && handleSend(e)}
                  disabled={(orderResp?.orderInfo.ticket_status == "close" || orderResp?.orderInfo.status == "admin_complete") ? true : false}
                />

                {
                  sendingLoading ?
                    <div className="spinner-border text-secondary send-msg-icon" role="status">
                      <span className="sr-only">Loading...</span>
                    </div> : <IoIosSend onClick={(e) => file ? handleSend(e) : handleSend(e)} className="send-msg-icon" />
                }

              </Box>
            </form>
          </>
      }

    </Paper >
  );
};

export default ChatComp;
