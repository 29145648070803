import React, { useEffect, useState } from "react";
import Header from "../HomeComp/Header";
import "../sidebar.css";
import { Button } from "@mui/material";
import "./myads.css";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getMyOrderInfo,
  N_PaymentRaise,
  N_releaseOrRejectFunds,
  RaiseDisputeP2p,
} from "../redux/helpers/api_functions_new";
import { commonSwalWithInput } from "../../utils/common";
import { NotificationManager } from "react-notifications";
import createSocketClient from "../redux/helpers/socket";
import ChatComp from "../p2pPages/payment-methods/ChatComponent";
import { RxCrossCircled } from "react-icons/rx";
import moment from "moment";
import { MdVerified } from "react-icons/md";
import toast from "react-hot-toast";
import CopyBtn from "../../helper/components/Copy";
import WaitingLoader from '../components/waitingLoader';
import OrderPaymentTrans from "../modals/OrderPaymentTransModal/OrderPaymentTrans";
import { IoMdArrowRoundBack } from "react-icons/io";
import { IoWarning } from "react-icons/io5";

const OrderChat = () => {
  const history = useHistory();
  const location = useLocation();
  const info = location.state;
  const id = info.state;

  const [supportBtn, setSupportBtn] = useState("admin");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (timeLeft === 0) return;

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  const updateTimerOfPost = (time, limit, status, id) => {
    const time1 = moment.utc(time, "YYYY-MM-DD HH:mm:ss");
    const time2 = moment.utc();

    const duration = moment.duration(time2.diff(time1));
    const minutes = duration.asMinutes();
    const seconds = duration.asSeconds();

    const timeout = limit - minutes;

    if (timeout <= 0) {
      if (!['complete', 'reject', 'cancel', 'timeout', 'admin_complete'].includes(status)) {
        ReleaseOrRejectFunds(id, 'timeout', 'The order was automatically canceled because the time limit was exceeded.');
      }
    } else {
      setTimeLeft(timeout * 60)
    }
  }

  const formattedTime = new Date(timeLeft * 1000).toISOString().substr(14, 5);

  useEffect(() => {
    if (info.state) {
      fetchData();
    }
  }, [info]);

  const fetchData = async () => {
    try {
      setLoading(true);
      let response = await getMyOrderInfo(id);
      if (response) {

        updateTimerOfPost(response?.data?.orderInfo.createdAt, response.data.postInfo?.payment_time_limit, response?.data?.orderInfo.status, response?.data?.orderInfo._id);
        setData(response?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error --->>", error);
      setLoading(false);
    }
  };

  const ConfirmReject = (id) => {
    commonSwalWithInput(
      "Are you sure?",
      "You want be able to revert this!",
      "Yes, Reject Payment!",
      "Cancel"
    ).then((result) => {
      if (result.isConfirmed) {
        ReleaseOrRejectFunds(id, "reject", result?.value);
      }
    });
  };

  const ConfirmRelease = (id) => {
    commonSwalWithInput(
      "Are you sure?",
      "You won't be able to revert this!",
      "Yes, Release Funds!",
      "Cancel"
    ).then((result) => {
      if (result.isConfirmed) {
        ReleaseOrRejectFunds(id, "complete", result?.value);
      }
    });
  };

  const ReleaseOrRejectFunds = (id, status, comment) => {
    N_releaseOrRejectFunds({
      order_id: id,
      status: status,
      comment: comment,
    }).then((res) => {
      if (res.status == 200) {
        toast.success(res.message);
        fetchData();
      } else {
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    const socket = new createSocketClient("kujgwvfq-a-ghosttown-z-1fhhup0p6");
    socket.on("p2p_raised_listen", (data) => {
      if (data?.order_id == id) {
        fetchData();
      }
    });
    socket.on("post_payment_confirmed_listen", async (res) => {
      if (res.order_id == id) {
        await fetchData();
      }
    });

  }, []);

  const [show, setShow] = useState(false);
  const [paySend, setPaySend] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [image, setImage] = useState({
    blob: null,
    src: "",
  });
  const handleImageChange = (files) => {
    const extn = ["image/jpg", "image/png", "image/jpeg"];
    const [file] = files;
    if (file && extn.includes(file.type)) {
      if (file.size <= 5242880) {
        setImage({ blob: file, src: window.URL.createObjectURL(file) });
      } else {
        toast.error("File too large!!");
      }
    } else {
      toast.error(
        "Please select a valid image file(only jpg, png and jpeg images are allowed)!!"
      );
    }
  };

  const handleShow = () => {
    setImage({
      blob: null,
      src: "",
    });
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setPaySend(false);
  };

  const handleSubmit = (comment) => {
    setFormSubmitting(true);
    N_PaymentRaise({
      comment: comment,
      file: image.blob,
      order_id: data?.orderInfo?._id,
    }).then((res) => {
      if (res.status == 200) {
        // toast.success(res.message);
        toast.success('Notified Seller Successfully!');

        setPaySend(true);
        setShow(false);
        setFormSubmitting(false);
        fetchData();
      } else {
        toast.error(res.message);
        setFormSubmitting(false);
      }
    });
  };

  const raiseDispute = () => {
    RaiseDisputeP2p(data?.orderInfo?._id, "seller").then((res) => {
      if (res.status == 200) {
        toast.success(res.message);
        setSupportBtn("admin");
        fetchData();
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleRedirection = async (url, id) => {

    history.push(url, {
      state: {
        post_id: id,
      },
    });
  };

  return (
    <>
      <Header />
      <div className="page-content " style={{ marginTop: "100px" }}>
        <main className="container mt-5 my-postad">
          <div className="row">
            <div className="mb-3 w-full">
              <div className="">
                <div className="mt-4">
                  <div className="">

                    <section>
                      <h1><IoMdArrowRoundBack className="me-2 mb-2 role-btn" onClick={() => history.goBack()} /> Order Information</h1>
                      <div className="d-flex justify-content-between">

                        <p>Order number: #{data?.orderInfo?.order_number}</p>
                        <p>
                          {
                            (data?.orderInfo?.status == "payment_done" || data?.orderInfo?.status == "active") && <>Order proceed within {formattedTime} Min</>
                          }
                        </p>
                      </div>
                    </section>

                    <div className="d-flex gap-4">
                      <div
                        className="d-flex flex-column gap-3 gray-borders rounded-3 p-4 col-md-6"
                        style={{ height: "600px", overflow: "auto", position: "relative" }}
                      >
                        <div className="p-3 bank-info" style={{ borderBottom: "1px solid white" }}>
                          <h5>
                            <span className="text-green">Buy</span>{" "}
                            {data?.orderInfo?.crypto_currency}
                          </h5>
                          <p className="d-flex justify-content-between my-1 line-bnk">
                            <p>Fiat Amount</p>
                            <p>
                              {data?.orderInfo?.fiat_amount_sent}{" "}
                              {data?.orderInfo?.fiat_currency}
                            </p>
                          </p>
                          <p className="d-flex justify-content-between my-1 line-bnk">
                            <p>Price</p>
                            <p>
                              {data?.postInfo?.selling_price}{" "}
                              {data?.postInfo?.fiat_name}{" "}
                            </p>
                          </p>
                          <p className="d-flex justify-content-between my-1 line-bnk">
                            <p>Receive Quantity</p>
                            <p>
                              {data?.orderInfo?.crypto_amount_receive}{" "}
                              {data?.orderInfo?.crypto_currency}
                            </p>
                          </p>

                          <p className="d-flex justify-content-between my-1 line-bnk">
                            <p>User Name</p>
                            <p className="text-capitalize">
                              {data?.receiverInfo?.name
                                ? data?.receiverInfo?.name
                                : data?.receiverInfo?.email}
                            </p>
                          </p>
                        </div>

                        {
                          data?.paymentMethod.length > 0 ?
                            (
                              <div className="px-3" style={{ maxHeight: "200px", overflowY: "scroll" }}>
                                {
                                  data?.paymentMethod.map((value, index) => {
                                    return <div key={index} className="bank-info my-3">
                                      <h6 className="bank-detail">
                                        <span className="text-green">#{index + 1}</span> Bank Detail
                                      </h6>
                                      <div className=" d-flex  aligh-items-center justify-content-between my-0">
                                        <p>Account Holder</p>
                                        <p>{value?.name} <CopyBtn text={value?.name} textEnable={false} /></p>
                                      </div>
                                      <div className=" d-flex  aligh-items-center justify-content-between my-0">
                                        <p>Bank Name</p>
                                        <p>{value?.bank_name} <CopyBtn text={value?.bank_name} textEnable={false} />  </p>
                                      </div>
                                      <div className=" d-flex  aligh-items-center justify-content-between my-0">
                                        <p>Account Number</p>
                                        <p>{value?.account_number} <CopyBtn text={value?.account_number} textEnable={false} />  </p>
                                      </div>
                                      {
                                        value?.iban && <div className=" d-flex  aligh-items-center justify-content-between my-0">
                                          <p>IBAN Number</p>
                                          <p>{value?.iban} <CopyBtn text={value?.iban} textEnable={false} />  </p>
                                        </div>
                                      }
                                      {
                                        value?.branch_name && <div className=" d-flex  aligh-items-center justify-content-between my-0">
                                          <p>Branch Name</p>
                                          <p>
                                            {value?.branch_name}{" "}
                                            <CopyBtn
                                              text={value?.branch_name}
                                              textEnable={false}
                                            />{" "}
                                          </p>
                                        </div>
                                      }

                                      <div className=" d-flex  aligh-items-center justify-content-between mb-2">
                                        <p>Branch Code</p>
                                        <p>{value?.branch_code} <CopyBtn text={value?.branch_code} textEnable={false} />  </p>
                                      </div>
                                    </div>
                                  })
                                }
                              </div>
                            )
                            : <>
                              <div className="px-3 text-center my-1">
                                <img src="/img/nowallet.png" style={{ height: "120px" }} />
                                <p>Oops! Payment method not available.</p>
                              </div>
                            </>
                        }

                        <div className="border-top p-3">
                          {
                            data?.postInfo?.type == "sell" ? <>
                              {/* Type -- Sell */}
                              {
                                data?.orderInfo?.status == "payment_done" ? (
                                  <div className="text-center d-flex flex-column align-items-center">
                                    <div
                                      style={{
                                        height: "150px",
                                        width: "150px",
                                        overflow: "hidden",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        window.open(
                                          data?.orderInfo?.sender_payment_image,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      <img
                                        src={data?.orderInfo?.sender_payment_image}
                                        alt="Qr"
                                        className=" p-2"
                                        style={{
                                          background: "#ffffff",
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                    <p className="my-2">Sender Message: {data?.orderInfo?.sender_comment}</p>
                                    <div className="d-flex align-items-center justify-content-center mt-3 gap-3">
                                      <Button
                                        variant="contained"
                                        sx={{ background: "red", py: 1 }}
                                        onClick={() =>
                                          ConfirmReject(data?.orderInfo?._id)
                                        }
                                      >
                                        Reject Payment
                                      </Button>
                                      <Button
                                        variant="contained"
                                        sx={{ background: "green", py: 1 }}
                                        onClick={() =>
                                          ConfirmRelease(data?.orderInfo?._id)
                                        }
                                      >
                                        Release Fund
                                      </Button>
                                    </div>
                                  </div>
                                ) : data?.orderInfo?.status == "active" ? (
                                  <>
                                    <div className="text-center d-flex flex-column align-items-center">
                                      <img
                                        src="/img/payment-loading.gif"
                                        alt="Qr"
                                        style={{ height: "110px" }}
                                        className="p-2 mt-5"
                                      />
                                      <h5>
                                        Waiting for Confirmation{" "}
                                      </h5>
                                    </div>
                                  </>

                                ) : ""}
                              {/* Type -- Sell -- End */}
                            </> : <>

                              {/* Type -- Buy */}
                              {
                                data?.orderInfo?.status == "active" ? (
                                  <button
                                    onClick={handleShow}
                                    variant="contained"
                                    className="login_btn fs-6"
                                    style={{ position: "absolute", bottom: "25px", width: "93%" }}
                                  >
                                    Transferred, Notify Seller
                                  </button>
                                ) : data?.orderInfo?.status == "payment_done" ? (
                                  <div className="text-center d-flex flex-column align-items-center">
                                    <WaitingLoader />
                                    <h5 className="mb-3">
                                      Waiting for Confirmation{" "}
                                      {/* <span className="typeWriter">
                                                  <span> . . . . .</span>
                                                </span> */}
                                    </h5>
                                    <Button
                                      onClick={handleShow}
                                      variant="contained"
                                      sx={{ background: "#ffb703", py: 1 }}
                                    >
                                      Upload Again
                                    </Button>
                                  </div>
                                ) : (
                                  ""
                                )
                              }


                              {/* Type -- Buy -- End */}
                            </>
                          }

                          {
                            (data?.orderInfo?.status == "timeout" || data?.orderInfo?.status == "reject" || data?.orderInfo?.status == "cancel") ? (
                              <div className="text-center d-flex flex-column align-items-center">
                                <div>
                                  {
                                    data?.orderInfo?.status == "reject" && <>
                                      <RxCrossCircled size={70} className="text-danger bounce_1 mt-4" />
                                      <p className="my-2">Reason : {data?.orderInfo?.receiver_comment}</p>
                                      <p className="mb-2">Payment has been rejected!</p>
                                    </>
                                  }
                                  {
                                    data?.orderInfo?.status == "timeout" && <>
                                      <IoWarning size={70} className="text-danger bounce_1 mt-4" />
                                      <p className="my-2">Order Auto-Canceled: Time Limit Exceeded!</p>
                                      <p className="mb-2">{data?.orderInfo?.receiver_comment}</p>
                                    </>
                                  }

                                </div>

                                {(!data?.orderInfo?.seller_ticket_number) && (
                                  <button
                                    className="login_btn fs-14 py-1"
                                    onClick={() => raiseDispute()}
                                  >
                                    Raise Dispute
                                  </button>
                                )}
                              </div>
                            ) :
                              data?.orderInfo?.status == "complete" ? (
                                <div className="text-center d-flex flex-column align-items-center">
                                  <div className="mt-5">
                                    <MdVerified size={70} className="text-success bounce_1 mt-2" />
                                    <p className="my-2">Comment: {data?.orderInfo?.receiver_comment}</p>
                                    <h5>Payment Done!</h5>
                                  </div>
                                </div>
                              ) :
                                data?.orderInfo?.status == "admin_complete" ? (
                                  <div className="text-center d-flex flex-column align-items-center">
                                    <div className="mt-5">
                                      <MdVerified size={70} className="text-success bounce_1 mt-2" />
                                      <p className="my-2">The admin has successfully released the funds to{" "}
                                        {
                                          data?.orderInfo?.release_fund_user == data?.receiverInfo?._id ?
                                            data?.receiverInfo?.name ? data?.receiverInfo?.name : data?.receiverInfo?.email
                                            : data?.sellerInfo?.name ? data?.sellerInfo?.name : data?.sellerInfo?.email
                                        }
                                        {" "}account!</p>
                                      <h5>Payment Done!</h5>
                                    </div>
                                  </div>
                                ) : ""
                          }
                        </div>
                      </div>
                      <div className="col-md-6" style={{ height: "600px" }}>
                        {
                          (data?.orderInfo?.seller_ticket_number) && <div className="chat-box-heading">
                            <h4 onClick={() => setSupportBtn("admin")} className={supportBtn == "admin" ? "borderBot" : ""}>Admin Support</h4>
                            <h4 onClick={() => setSupportBtn("user")} className={supportBtn == "user" ? "borderBot" : ""}>{data?.receiverInfo?.name ? data?.receiverInfo?.name : data?.receiverInfo?.email}</h4>
                          </div>
                        }

                        <div
                          className="gray-borders rounded-3 "
                          style={{
                            overflow: "auto",
                            position: "relative",
                            scrollbarWidth: 'none',
                            padding: "0px"
                          }}
                        >
                          {
                            ((data?.orderInfo?.seller_ticket_number) && supportBtn == "admin") ?
                              <ChatComp orderResp={data} userType={"support"} supportBtn={supportBtn} status={(data?.orderInfo?.seller_ticket_number) ? true : false} uuid={data?.orderInfo?.seller_ticket_number} />
                              :
                              <ChatComp orderResp={data} userType={"seller"} supportBtn={supportBtn} status={(data?.orderInfo?.seller_ticket_number) ? true : false} uuid={data?.orderInfo?._id} />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <OrderPaymentTrans
        formSubmitting={formSubmitting}
        isOpen={show}
        onClose={handleClose}
        image={image}
        setImage={setImage}
        handleImageChange={handleImageChange}
        handleSubmit={handleSubmit}
        paySend={paySend}
      />
    </>
  );
};

export default OrderChat;
