import { Pagination, PaginationItem } from "@mui/material";
import React, { useState } from "react";
import NoData from "../../helper/components/NoData";
import { MdPeopleAlt } from "react-icons/md";
import { FaLink, FaMoneyBill } from "react-icons/fa";

const AllReferrals = ({ referData, handlePageChange, totalPages }) => {
  return (
    <>
      <div className="pt-5 refer_class text-white">
        <h1 className="text-center">Referral Data</h1>

        <div className="stats-container mt-5">
          <div className="stat-card text-white">
            <div className="">
              <span>
                <MdPeopleAlt size={35} />
              </span>
            </div>
            <h3>Total Referrals</h3>
            <p>People who joined using your link</p>
            <h2 className="">{referData?.totalReferrals || "0"}</h2>
          </div>

          <div className="stat-card">
            <div className="">
              <span>
                <FaLink size={35} />
              </span>
            </div>
            <h3>Pending Referrals</h3>
            <p>People yet to complete KYC</p>
            <h2 className="">{referData?.pendingKYCCount || "0"}</h2>
          </div>

          <div className="stat-card">
            <div className="">
              <span>
                <FaMoneyBill size={35} />
              </span>
            </div>
            <h3>Total Points</h3>
            <p>Rewards earned from referrals</p>
            <h2 className="">{referData?.totalIncome || "0"} Pts</h2>
          </div>
        </div>
        <div className="text-white mt-5">
          <div className="refer-data">
            <section>
              <p>Today</p>
              <p className="fs-4">{referData?.todayReferrals || "0"} User(s)</p>
            </section>
            <section>
              <p>Yesterday's Commission</p>
              <p className="fs-4">≈ {referData?.yesterdayIncome || "0"} </p>
            </section>
            <section>
              <p>Last 30D Referral</p>
              <p className="fs-4">
                {referData?.last30DReferrals || "0"} User(s)
              </p>
            </section>
            <section>
              <p>Last 30D Commission</p>
              <p className="fs-4">≈ {referData?.last30DIncome || "0"} Pts</p>
            </section>
          </div>
        </div>
        {/* <h1>Referrals</h1> */}
        <div className="d-flex flex-column gap-4 mt-5">
          <h1>Referee List</h1>
          <p className="fs-5">
            Direct and indirect referees in the last 30 days.
          </p>
          <div className="refer-data">
            <table className="refer-data-table">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Invited</th>
                  <th>KYC Status</th>
                  <th>Refree Email</th>
                  <th>Updated</th>
                  <th>Points Earned</th>
                </tr>
              </thead>
              <tbody>
                {referData?.referrals?.length > 0 ? (
                  referData?.referrals?.map((referral, index) => (
                    <tr key={index}>
                      {/* <td>1</td> */}
                      <td className="fs-4">
                        {(referral.name && referral.name) || "Unknown User"}
                      </td>
                      <td
                        style={{
                          color: "white",
                        }}
                      >
                        <span
                          className="px-4 py-2 rounded-5"
                          style={{
                            backgroundColor:
                              referral?.is_kyc_verified === 1
                                ? "green"
                                : referral?.is_kyc_verified === 2
                                  ? "red"
                                  : "orange",
                          }}
                        >
                          {
                            referral?.is_kyc_verified === 1
                              ? "Verified"
                              : referral?.is_kyc_verified === 2
                                ? "Rejected"
                                : "Pending"}
                        </span>
                      </td>
                      <td className="fs-4">
                        {(referral.email && referral.email) || "No Email"}
                      </td>
                      <td className="fs-4">
                        {new Date(referral.createdAt).toLocaleDateString()}
                      </td>
                      <td className="fs-4">{referral?.is_kyc_verified == 1 ? "10" : "0"} Pts</td>
                    </tr>
                  ))
                ) : (
                  <>
                    <tr>
                      <td
                        colSpan="6"
                        style={{
                          textAlign: "center",
                          border: "none",
                        }}
                      >
                        <NoData text={"No Data Available"} />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <Pagination
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              count={totalPages}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  style={{
                    backgroundColor: "#552dba",
                    color: "white",
                  }}
                  {...item}
                />
              )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllReferrals;
