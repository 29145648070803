import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsInfoCircleFill } from "react-icons/bs";
import { MdOutlineInfo } from "react-icons/md";
import { N_depositHistory, N_withdrawHistory } from "./redux/helpers/api_functions_new";

export default function AssetsHistory(props) {
  const { coins } = useSelector(
    (state) => state.coinDBReducer
  );
  const [deposithistory, setDepositHistory] = useState();
  const [withdrawhistory, setWithdrawHistory] = useState();
  const [activeTab, setActivetab] = useState(0);
  const [symbol, setSymbol] = useState('');
  const [start_date, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const { user } = useSelector((state) => state.AuthReducer);
  useEffect(() => {
    let data = {
      user_id:user?.params ? user.params.user_id : user.user_id,
      start_date, 
      endDate,
      symbol
    }
    if(activeTab==0) {
      N_depositHistory(data)
      .then((data) => {
        if (data.status === 200) {
          setDepositHistory(data?.result);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    }
    if(activeTab == 1){
      N_withdrawHistory(data)
      .then((data) => {
        if (data.status === 200) {
          setWithdrawHistory(data?.result);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    }
   
  }, [activeTab, start_date, endDate, symbol]);

  const coinData = coins && Object.values(coins).map((item)=>{
    return (
      <option value={item.symbol}>{item.symbol}</option>
    )
  })
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="marginTop">
            <div className="account_page">
              <div className="container">
                <div className="page-content-title">
                  <h3 className="">Assets History</h3>
                </div>
                <div className="">
                  <div className="row">
                    <div className="activity_panel mb-4">
                      <div className="tab_width">
                        <div class="custom_pills">
                          <div
                            class="nav nav-pills"
                            id="nav-tab"
                            role="tablist"
                          >
                            <div
                              class="nav-item nav-link active fs-14"
                              style={{ height: "35px" }}
                              href="#deposit_history"
                              data-toggle="tab"
                              onClick={(e)=>{
                                setStartDate('')
                                setEndDate('')
                                setSymbol('')
                                setActivetab(0)
                              }}
                            >
                              Deposit History
                            </div>
                            <div
                              class="nav-item nav-link fs-14"
                              style={{ height: "35px" }}
                              href="#withdrawal_history"
                              data-toggle="tab"
                              onClick={(e)=>{
                                setStartDate('')
                                setEndDate('')
                                setSymbol('')
                                setActivetab(1)
                              }}
                            >
                              Withdrawal History
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="search_input_grp">
                    <div class="input-group">
                      <span class="input-group-text">
                        <i class="fa-solid fa-magnifying-glass"></i>
                      </span>
                      <input
                        type="text"
                        placeholder="Search"
                        class="form-control text-start"
                      />
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>

                <div className="row cstm_form">
                  <div className="col-lg-3 mb-3">
                  <input 
                   type="Date" 
                   class="form-control" 
                   onChange={(e)=>{
                    setStartDate(e.target.value)
                   }}
                   />
                  </div>
                  <div className="col-lg-3 mb-3">
                   <input 
                   type="Date" 
                   class="form-control" 
                   onChange={(e)=>{
                    setEndDate(e.target.value)
                   }}
                   />
                    
                  </div>
                  <div className="col-lg-3 mb-3">
                    {" "}
                    <select
                      class="form-select"
                      aria-label="Default select example"
                      onChange={(e)=>{
                        setSymbol(e.target.value);
                      }}
                    >
                      <option selected>All Coins</option>
                      {coinData}
                    </select>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <a href="#" className="btn login_btn  ">
                      CSV Download
                    </a>
                  </div>
                </div>

                <div className="tab-content">
                  <div
                    className="tab-pane fade active show"
                    id="deposit_history"
                  >
                    <div className="table_scroll_div">
                      <div className="overflow-auto mt-3">
                        <div className="table_scroll_div">
                          <table className="table global_table">
                            <thead>
                              <tr>
                                <th>Symbol</th>
                                <th>Amount</th>
                                <th>Address</th>
                                <th>Status</th>
                                <th>Time</th>
                              </tr>
                            </thead>

                            <tbody>
                        {deposithistory && deposithistory.length > 0 ? (
                          deposithistory.map((item) => {
                            return (
                              <tr>
                                <td>{item?.symbol}</td>
                                <td class="text-end">{item.amount}</td>
                                <td class="text-end">{item.to_address}</td>
                                <td class="text-end">{item.status}</td>

                                <td class="text-end">
                                  {new Date(item.createdAt).toLocaleString()}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <img
                                alt="no data found"
                                src="/img/no-data.png"
                                className="no-data-found"
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane fade" id="withdrawal_history">
                    <div className="overflow-auto mt-3">
                      <div className="table_scroll_div">
                      <table className="table global_table">
                        <thead>
                          <tr>
                            <th>Symbol</th>
                            <th>Amount</th>
                            <th>Network</th>
                            <th> Withdrawal Address/Account </th>
                            <th>Status</th>
                            <th class="text-end">Time</th>
                          </tr>
                        </thead>
                        <tbody>
                      {withdrawhistory && withdrawhistory.length > 0 ? (
                          withdrawhistory.map((item) => {
                            return (
                            <tr>
                              <td>{item.symbol}</td>
                              <td>{item.amount}</td>
                              <td>{item.network.toUpperCase()}</td>
                              {/* <td>
                                <a class="anchor_link">View Transaction</a>
                              </td> */}
          
                              <td>
                                <span className="address_item">
                                  {item.to_address}
                                  <span class="">
                                    <img src="/img/copy-icon.svg" alt="copy-address" />
                                  </span>
                                </span>
                              </td>
                              <td>{item.status==1?"Completed":item.status==-2?"Rejected":"Pending"}</td>
          
                              <td class="text-end">
                                  {new Date(item.updatedAt).toLocaleString()}
                                </td>
                            </tr>
                            );
                          })
                        ):(
                            <tr>
                              <td colSpan={6} className="text-center">
                                <img
                                  alt="no data found"
                                  src="/img/no-data.png"
                                  className="no-data-found"
                                />
                              </td>
                            </tr>
                          )}
              

                
                </tbody>
              </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
