import React, { useState, useEffect } from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./HomeComp/Header";
import FullLoader from "./components/FullLoader";
import { useSelector } from "react-redux";
import "./login.css";
import { NotificationManager } from "react-notifications";
import {
  N_checkKYCStatus,
  N_panVerify,
  N_sendAADHAROTPMobile,
  N_verifyAADHAROTPMobile
} from "./redux/helpers/api_functions_new";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
export default function KYCVerification(props) {
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { user } = useSelector((state) => state.AuthReducer);
  const [mobile, setMobile] = useState();
  const [aadhar_no, setAadharNo] = useState();
  const [type_of_kyc, setTypeofKyc] = useState("Individual");
  const [client_id, setClientId] = useState();
  const [disable, setDisable] = useState(true);
  const [visibl, setVisiblity] = useState("h");
  const [disableSend, setDisableSend] = useState(false);
  const [fullScreenLoader, setFullScreenLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [otploader, setOtpLoader] = useState(false);
  const [isverifypan, isVerifyPan] = useState(false);
  const [isOtpSend, setOtpSend] = useState(false);
  const [aadharVerify, setAadharVerify] = useState(false);
  const [otp, setOTP] = useState();
  const [ctime, setctime] = useState("10:00");
  const [personalData, setPersnalData] = useState();
  const [ispanverify, setPanVerify] = useState(false);
  const [pan_no, setPanNo] = useState();
  if (!isLoggedIn) props.history?.replace("/login");
  const otpCountdown = () => {
    let duration = 600;
    // const display = document.getElementById("#timer");
    let timer = duration,
      minutes,
      seconds;
    const tint = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setctime(minutes + ":" + seconds);

      if (--timer < 0) {
        // timer = duration;
        clearInterval(tint);
        setOtpSend(false);
      }
    }, 1000);
  };
  useEffect(() => {
    N_checkKYCStatus(user?.params ? user.params.user_id : user.user_id).then(
      (d) => {
        if (d.status === 200) {
          if (d.params.kyc_status === 1)
            props.history?.replace("/exchange/btc-usdt");
        }
        setFullScreenLoader(false);
      }
    );
  }, []);
  if (fullScreenLoader) return <FullLoader />;
  else
    return (
      <>
        <Header {...props} />
        <div className="container-fluid">
          <div className="col-lg-12">
            
              {/* <ProfileSidebar {...props} /> */}
           
            <div className="main-content">
              <div className="mt-3 mb-3">
                <h2 className="g_color">KYC Form</h2>
              </div>
              <div className="card">
                <div className="card-body">
                  <form
                    className="row"
                    method="post"
                    id="loginForm"
                    autoComplete="off"
                  >
                    <div className="col-lg-4 mb-3">
                      <label htmlFor="type_of_kyc" className="text-muted mb-1">
                        Type of KYC
                      </label>
                      <select
                        id="type_of_kyc"
                        name="type_of_kyc"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        data-role="select-dropdown"
                        onChange={(e) => {
                          setTypeofKyc(e.target.value);
                        }}
                      >
                        <option value="Individual">Individual</option>
                        <option value="Corporate">Corporate</option>
                      </select>
                    </div>
                    <div className="col-lg-12">
                      <button
                        type="button "
                        className="btn btn-light me-2"
                        onClick={() => {
                          props.history.push("/exchange/btc-usdt");
                        }}
                      >
                        Skip for Now
                      </button>
                      <button
                        type="button"
                        id="proceed_btn"
                        className="btn btn-primary"
                        onClick={(e) => {
                          const kyc_btn = e.target.parentElement;
                          kyc_btn.className = "form-control h";
                          document.getElementById("verify_pan").style.display =
                            "block";
                        }}
                      >
                        Complete KYC
                      </button>
                    </div>

                    <div id="verify_pan" style={{ display: "none" }}>
                      <div className="col-lg-4 mb-3">
                        <label
                          htmlFor="user_mobile"
                          className="text-muted mb-1"
                        >
                          PAN Number
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control buy-sell-form-bg buy-sell-theme"
                            id="pan_no"
                            name="pan_no"
                            value={pan_no}
                            maxLength={10}
                            minLength={10}
                            onChange={(e) => setPanNo(e.target.value)}
                            placeholder="Enter your PAN number"
                          />
                          <span className="input-group-text bg-primary text-white">
                            {!ispanverify ? (
                              <button
                                className="btn btn-sm border-0 bg-transparent text-white"
                                type="button"
                                disabled={disableSend}
                                onClick={() => {
                                  if (pan_no?.length === 10) {
                                    isVerifyPan(true);
                                    N_panVerify(
                                      pan_no,
                                      user?.params
                                        ? user.params.user_id
                                        : user.user_id
                                    ).then((d) => {
                                      if (d.status == 200) {
                                        toast.success(d.message);
                                        setPanVerify(true);
                                      } else {
                                        toast.error(d.message);
                                        setPanNo("");
                                        document.getElementById(
                                          "pan_id"
                                        ).style.display = "block";
                                      }
                                      isVerifyPan(false);
                                    });
                                  } else {
                                    toast.error(
                                      "Please Enter valid PAN number"
                                    );
                                  }
                                }}
                                id="button-addon2"
                              >
                                {isverifypan ? (
                                  <span className="loading-icon fas fa-spinner fa-spin mr-2"></span>
                                ) : (
                                  "Verify"
                                )}
                              </button>
                            ) : (
                              <button
                                type="button"
                                id="button-addon2"
                                disabled="disabled"
                                className="border-0 bg-transparent"
                              >
                                Verified
                              </button>
                            )}
                          </span>
                        </div>
                        <div
                          id="pan_id"
                          className="mt-2"
                          style={{ display: "none" }}
                        >
                          If Your Pan is Not Valid then{" "}
                          <Link className="text-primary" to="/welcome">Click here</Link> to fill Manual
                          Record
                          <p>
                            <b
                              style={{ fontSize: "15px" }}
                              className=" text-danger"
                            >
                              Note:
                            </b>
                            <strong
                              style={{
                                fontSize: "12px",
                                fontWeight: "500"
                              }}
                            >
                              &nbsp;&nbsp;&nbsp;If you fill kyc manually, then
                              you have to wait for 72 hours for KYC
                              verification.
                            </strong>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      id="verify_aadhar"
                      className={ispanverify ? "vis" : "h"}
                    >
                      <div className="form-control g_color">
                        <label htmlFor="user_aadhar">AADHAR Number:</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            id="user_aadhar"
                            name="user_aadhar"
                            value={aadhar_no}
                            maxLength={12}
                            minLength={12}
                            onChange={(e) =>
                              setAadharNo(
                                e.target.value
                                  .replace(/[^0-9.]/g, "")
                                  .replace(/(\..*?)\..*/g, "$1")
                              )
                            }
                            placeholder="Enter your AADHAR number"
                          />
                          <div className="input-group-append">
                            {!isOtpSend ? (
                              <button
                                className="btn bg-transparent btn-outline-light text-info"
                                type="button"
                                disabled={disableSend}
                                onClick={() => {
                                  if (aadhar_no?.length === 12) {
                                    setOtpLoader(true);
                                    N_sendAADHAROTPMobile(
                                      aadhar_no,
                                      user?.params
                                        ? user.params.user_id
                                        : user.user_id
                                    ).then((d) => {
                                      // console.log(d);
                                      if (d.status == 200) {
                                        toast.success(d.message);
                                        setClientId(d.result);
                                        setDisable(false);
                                        setOtpSend(true);
                                        otpCountdown();
                                      } else {
                                        toast.error(d.message);
                                        setAadharNo("");
                                        document.getElementById(
                                          "pan_id"
                                        ).style.display = "block";
                                      }
                                      setOtpLoader(false);
                                    });
                                  } else {
                                    toast.error(
                                      "Please Enter valid AADHAR number"
                                    );
                                  }
                                }}
                                id="button-addon2"
                              >
                                {otploader ? (
                                  <span className="loading-icon fas fa-spinner fa-spin mr-2"></span>
                                ) : (
                                  "Send"
                                )}
                              </button>
                            ) : (
                              <button
                                type="button"
                                id="button-addon2"
                                disabled="disabled"
                                className="btn btn-outline-info btn-outline-light text-danger"
                              >
                                {isOtpSend ? ctime : null}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className={`form-control g_color`}>
                        <label htmlFor="user_mobile">MOBILE Number</label>
                        <input
                          type="text"
                          name="user_mobile"
                          id="user_mobile"
                          className="bg-white"
                          maxLength={10}
                          disabled={disable}
                          value={mobile}
                          onChange={(e) =>
                            setMobile(
                              e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1")
                            )
                          }
                          required=""
                          placeholder="Enter your mobile number"
                        />
                        <i className="fas fa-check-circle"></i>
                        <i className="fas fa-exclamation-circle"></i>
                        <small></small>
                      </div>
                      <div
                        className={`form-control g_color ${
                          aadharVerify ? "h" : "vis"
                        }`}
                      >
                        <label htmlFor="user_otp">OTP</label>
                        <input
                          type="text"
                          name="user_otp"
                          id="user_otp"
                          className="bg-white"
                          maxLength={6}
                          disabled={disable}
                          value={otp}
                          onChange={(e) =>
                            setOTP(
                              e.target.value
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*?)\..*/g, "$1")
                            )
                          }
                          required=""
                          placeholder="Enter 6 digit otp"
                        />
                        <i className="fas fa-check-circle"></i>
                        <i className="fas fa-exclamation-circle"></i>
                        <small></small>
                      </div>
                      <button
                        type="button"
                        id="aadhar_otp"
                        className={`reg_btn btn-theme-color`}
                        onClick={() => {
                          if (
                            !isNaN(otp) &&
                            !isNaN(mobile) &&
                            otp?.length === 6 &&
                            mobile?.length === 10
                          ) {
                            setLoading(true);
                            N_verifyAADHAROTPMobile(
                              client_id,
                              mobile,
                              otp,
                              user?.params ? user.params.user_id : user.user_id
                            ).then((d) => {
                              // console.log(d);
                              setLoading(false);
                              if (d.status == 200) {
                                setVisiblity("vis");
                                setPersnalData(d.result);
                                setAadharVerify(true);
                                document.getElementById(
                                  "aadhar_otp"
                                ).style.display = "none";
                                toast.success(d.message);
                              } else {
                                toast.error(d.message);
                              }
                            });
                          } else {
                            toast.error("invalid input !");
                          }
                        }}
                      >
                        {loading ? (
                          <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                        ) : null}
                        <span id="reg">Verify OTP</span>
                      </button>
                    </div>
                    <div id="personal_info" className={visibl}>
                      <div className="row">
                        <h5 className="mb-3">Personal Info</h5>

                        <div className="col-lg-4 mb-3">
                          <label htmlFor="user_fname">
                            NAME <span className="text-red">*</span>
                          </label>
                          <input
                            type="text"
                            name="user_name"
                            readOnly
                            className="form-control"
                            id="user_name"
                            value={personalData?.full_name}
                          />
                          <img
                            src={`data:image/png;base64,${personalData?.profile_image}`}
                            height="150px"
                            width="200px"
                            id="aadhar_selfie"
                            name="aadhar_selfie"
                            alt="adhar pic"
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="gender">
                            GENDER <span className="text-red">*</span>
                          </label>
                          <input
                            type="text"
                            name="gender"
                            className="form-control"
                            id="gender"
                            readOnly
                            value={
                              personalData?.gender == "M" ? "MALE" : "FEMALE"
                            }
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="user_dob">
                            DATE OF BIRTH <span className="text-red">*</span>
                          </label>
                          <input
                            type="date"
                            name="user_dob"
                            id="user_dob"
                            readOnly
                            className="form-control"
                            value={personalData?.dob}
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="user_address">
                            ADDRESS <span className="text-red">*</span>
                          </label>
                          <textarea
                            className="form-control"
                            id="user_address"
                            name="user_address"
                            readOnly
                            rows="1"
                            value={
                              personalData?.address.house +
                              " " +
                              personalData?.address.vtc +
                              " " +
                              personalData?.address.po +
                              " " +
                              personalData?.address.landmark +
                              " " +
                              personalData?.address.loc
                            }
                          ></textarea>
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="country">COUNTRY *</label>
                          <input
                            type="text"
                            name="country"
                            className="form-control"
                            id="country"
                            readOnly
                            value={personalData?.address.country}
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="state">STATE *</label>
                          <input
                            type="text"
                            name="state"
                            className="form-control"
                            id="state"
                            readOnly
                            value={personalData?.address.state}
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="country">CITY *</label>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            id="city"
                            readOnly
                            value={personalData?.address.dist}
                          />
                        </div>
                        <div className="col-lg-4 mb-3">
                          <label htmlFor="user_pincode">PINCODE *</label>
                          <input
                            type="text"
                            name="user_pincode"
                            className="form-control"
                            id="user_pincode"
                            readOnly
                            value={personalData?.zip}
                          />
                        </div>
                        <div className="">
                          <button
                            type="button"
                            id="btn_submit"
                            className="reg_btn btn btn-primary"
                            onClick={() => {
                              props.history?.replace("/exchange/btc-usdt");
                            }}
                          >
                            {loading ? (
                              <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                            ) : null}
                            <span id="reg">SUBMIT</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mt-3" id="kyc_btn">
                      <div className="row my-2 fs-14">
                        <div className="col-lg-3">
                          <h6 className="mb-2">Without KYC</h6>
                          <ul className="">
                            <li className="badge bg-light border  me-2">
                              <i class="fa-solid fa-circle-check text-green fs-14 me-2"></i>
                              <span className="_text fs-14 fs-14">Deposit</span>
                            </li>
                            <li className="badge bg-light border  me-2">
                              <i class="fa-solid fa-circle-check text-green fs-14 me-2"></i>
                              <span className="_text fs-14">Trade</span>
                            </li>
                            <li className="badge bg-light border  me-2">
                              <i class="fa-solid fa-circle-xmark text-secondary fs-14 me-2"></i>
                              <span className="_text fs-14 disabled">
                                Withdrawal
                              </span>
                            </li>
                            <li className="badge bg-light border  me-2">
                              <i class="fa-solid fa-circle-xmark text-secondary fs-14 me-2"></i>
                              <span className="_text fs-14 disabled">P2P</span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-lg-3">
                          <h6 className="mb-2">With KYC</h6>
                          <ul className="">
                            <li className="badge bg-light border  me-2">
                              <i class="fa-solid fa-circle-check text-green fs-14 me-2"></i>
                              <span className="_text fs-14">Deposit</span>
                            </li>
                            <li className="badge bg-light border  me-2">
                              <i class="fa-solid fa-circle-check text-green fs-14 me-2"></i>
                              <span className="_text fs-14">Trade</span>
                            </li>
                            <li className="badge bg-light border  me-2">
                              <i class="fa-solid fa-circle-check text-green fs-14 me-2"></i>
                              <span className="_text fs-14">Withdrawal</span>
                            </li>
                            <li className="badge bg-light border  me-2">
                              <i class="fa-solid fa-circle-check text-green fs-14 me-2"></i>
                              <span className="_text fs-14">P2P</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
