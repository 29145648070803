import React, { useEffect } from "react";
//import Banner from "./Banner";
import Footer from "./Footer";
import Header from "./Header";
import Card from "./Card";
import AOS from "aos";
import "aos/dist/aos.css";

export default function About() {
  /*  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }); */
  AOS.init({ duration: "2000" });

  return (
    <>
      <Header />
      <div className="wrap-about common-bg py-4">
        <div className="container mx-auto mt-5 pt-5 d-flex justify-content-center flex-column">
          <div className="para_heading d-flex justify-content-center">
            <div className="text-gradient">About Us</div>
          </div>
          <div className="row">
            <div
              className="col-md-12 col-lg-6 col-12 about-img mt-4"
              data-aos="fade-right"
            >
              <img src="./img/trade.png" className="" alt="" />
            </div>
            <div className="col-md-12 col-lg-6 col-12 mt-4" data-aos="fade-left">
              <h2 className="about-head text-white">Our Mission and Vision</h2>
              <p className="about-paraOne">
                At bitcash, our mission is to revolutionize the way individuals
                engage with the world of digital finance. We strive to provide a
                seamless and secure platform that caters to all your crypto
                trading, forex, and digital wallet needs. Whether you are
                trading cryptocurrencies, managing your forex investments, or
                safeguarding your digital assets, we offer an intuitive
                experience for both beginners and experienced traders alike.
              </p>
              <p className="about-paraOne">
                Our vision is to build a global, inclusive financial ecosystem
                based on trust, transparency, and convenience. We are committed
                to providing our users with an unmatched level of control over
                their investments while prioritizing security and reliability at
                every step.
              </p>
            </div>

            {/* 2nd para */}

            <div
              className="col-md-12 col-lg-6 col-12 mt-4"
              data-aos="fade-left"
            >
              <h2 className="about-head text-white">Transparency, Security, and Trust</h2>
              <p className="about-paraOne">
                We understand that trust is crucial in the world of digital
                finance, and therefore, Security and transparency are core to
                the bitcash foundation. Our platform operates with full
                accountability, ensuring that every transaction is traceable and
                verifiable. We leverage cutting-edge security measures to
                safeguard your assets, keeping your personal information and
                funds protected from potential threats.
              </p>
              <p className="about-paraOne">
                At bitcash, we prioritize user convenience without compromising on
                the quality of service. Our easy-to-use interface allows you to
                access all your trading and financial tools in one place,
                enabling you to manage your crypto, forex, and digital wallet
                effortlessly. Whether you're making deposits, withdrawals, or
                executing trades, we ensure that every action is smooth, fast,
                and secure.
              </p>
            </div>
            <div
              className="col-md-12 col-lg-6 col-12 about-img mt-4"
              data-aos="fade-right"
            >
              <img src="./img/security.webp" className="" alt="" />
            </div>

            {/* 3rd para */}
            <div
              className="col-md-12 col-lg-6 col-12 about-img mt-4"
              data-aos="fade-right"
            >
              <img src="./img/finance.png" className="" alt="" />
            </div>
            <div
              className="col-md-12 col-lg-6 col-12 mt-4"
              data-aos="fade-left"
            >
              <h2 className="about-head text-white">
                A Complete Digital Finance Solution
              </h2>
              <p className="about-paraOne">
                bitcash is more than just a crypto exchange; it’s a comprehensive
                platform designed to cater to all aspects of your digital
                finance journey. From buying and selling cryptocurrencies to
                wallet management, our platform offers
                everything you need to navigate the complex world of finance. We
                provide powerful tools to help you track your portfolio, analyze
                market trends, and optimize your investments.
              </p>
            </div>

            {/* 4th para */}

            <div
              className="col-md-12 col-lg-6 col-12 mt-4"
              data-aos="fade-left"
            >
              <h2 className="about-head text-white">Why Choose bitcash?</h2>

              <p className="about-paraOne">
                <strong>Security First:</strong> Industry-leading encryption
                and security protocols to protect your data and assets.
              </p>
              <p className="about-paraOne">
                <strong>Transparent Operations:</strong> Clear, verifiable
                transactions that promote accountability and trust.
              </p>
              <p className="about-paraOne">
                <strong>Seamless User Experience:</strong> Fast, simple, and
                accessible services designed for users at all experience
                levels.
              </p>
              <p className="about-paraOne">
                <strong>Global Reach:</strong> A platform that serves users
                worldwide with a wide range of supported assets and
                currencies.
              </p>
              <p className="about-paraOne">
                <strong>24/7 Customer Support:</strong> Our dedicated support
                team is always ready to assist you, no matter where you are.
              </p>

            </div>
            <div
              className="col-md-12 col-lg-6 col-12 about-img mt-4"
              data-aos="fade-right"
            >
              <img src="./img/choose.png" className="" alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
