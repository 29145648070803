import React, { useState, useEffect } from "react";
import { isNum, isOtp } from "./redux/helpers/form-validator.functions";
import QRCode from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import FullLoader from "./components/FullLoader";
import { NotificationManager } from "react-notifications";
import $, { data } from "jquery";
import "./sidebar.css";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";
import { FaCoins, FaSortDown, FaSortUp } from "react-icons/fa";

export default function AssetsFiat() {
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="marginTop">
            <div className="account_page">
              <div className="container">
                <div className="page-content-title"></div>
                <div className="col-lg-12">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <h3 className=""> Deposit Fiat </h3>
                      </div>
                    </div>
                    <div className="">
                      <a href="AssetsDeposit" className="btn btn-light">
                        Deposit Crypto{" "}
                        <i class="fa-solid fa-arrow-right-long"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <div className="cstm_form">
                      <div class="mb-4">
                        <label for="Fiat" class="text-muted">
                          Fiat
                        </label>
                        <div className="select_box">
                          {" "}
                          <div
                            className="select_box_inner"
                            data-bs-toggle="modal"
                            data-bs-target="#coin_modal"
                          >
                            <div class="d-flex align-items-center">
                              <div>
                                <img
                                  src="https://assets-currency.kucoin.com/618b89853b4d840006da19dd_EUR.png"
                                  alt="coin symbole"
                                  class="coin_img me-2"
                                />
                              </div>
                              <div class="product_name">
                                EUR{" "}
                                <span className="unit text-muted">EURO</span>
                              </div>{" "}
                            </div>
                          </div>
                          <div>
                            {" "}
                            <img
                              src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                              class="arrow_img"
                              alt="arrow-icon"
                            />
                          </div>
                        </div>
                      </div>

                      <label for="Fiat" class="text-muted">
                        Payement
                      </label>
                      <div className="mb-4 hero_div border-0 p-4">
                        <p className="mb-0 text-lgray ">
                          <i class="fa-solid fa-location-dot me-2"></i>
                          Payment methods will be available in your region soon.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="aco_hero mt-3 text-center text-muted mb-4">
                      <img
                        src="/img/Modes-of-payment.png"
                        className="img-fluid mb-4"
                        width="30%"
                      />
                      <p>
                        Payment methods will be available in your region soon.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <h3 className=""> Deposit History </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto mt-3">
                  <div className="table_scroll_div">
                  <table className="table global_table">
                    <thead>
                      <tr>
                        <th>
                          <div className="d-flex align-items-center">
                            <span className="">Amount</span>
                            <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                          </div>
                        </th>
                        <th className="text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="">Network</div>
                            <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                          </div>
                        </th>
                        <th className="text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <span className=""> Blockchain Record</span>
                            <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                          </div>
                        </th>
                        <th className="text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <span className=""> Status</span>
                            <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                          </div>
                        </th>

                        <th className="text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <span className="">Remarks</span>
                            <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                          </div>
                        </th>

                        <th className="text-end">
                          <div className="d-flex align-items-center justify-content-end">
                            <span className="">Time</span>
                            <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>

                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={6} className="text-center">
                          <img
                              alt="no data found"
                              src="/img/no-data.png"
                              className="no-data-found"
                            />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="coin_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Coin</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="m-1">
                <div class="input-group ">
                  <span class="input-group-text bg-transparent">
                    <div class="">
                      <i class="fa fa-search text-lgray"></i>
                    </div>
                  </span>
                  <input
                    type="text"
                    class="form-control text-start mp-exide-form bg-white"
                    aria-label="Text input with checkbox"
                    placeholder="Search "
                  />
                </div>
              </div>

              <div className="mt-4">
                <h6 className="text-lgray">Popular</h6>
                <p class="fs-14">
                  <span class="label_light me-2">USDT</span>
                  <span class="label_light me-2">BTC</span>
                  <span class="label_light me-2">IRON</span>
                  <span class="label_light me-2">TRX</span>
                  <span class="label_light me-2">ETH</span>
                  <span class="label_light me-2">LTC</span>
                </p>
              </div>

              <div className="custom_scrollbar height250">
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="network_modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Network</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="m-1">
                <div class="input-group ">
                  <span class="input-group-text bg-transparent">
                    <div class="">
                      <i class="fa fa-search text-lgray"></i>
                    </div>
                  </span>
                  <input
                    type="text"
                    class="form-control text-start mp-exide-form bg-white"
                    aria-label="Text input with checkbox"
                    placeholder="Search "
                  />
                </div>
              </div>

              <div className="mt-4">
                <h6 className="text-lgray">Popular</h6>
                <p class="fs-14">
                  <span class="label_light me-2">USDT</span>
                  <span class="label_light me-2">BTC</span>
                  <span class="label_light me-2">IRON</span>
                  <span class="label_light me-2">TRX</span>
                  <span class="label_light me-2">ETH</span>
                  <span class="label_light me-2">LTC</span>
                </p>
              </div>

              <div className="custom_scrollbar height250">
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf91f78afb0a00068efef7_ETH.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">ETH</div>
                    <div class="text-muted fs-12"> Ethereum</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60c34ba0db892b0006d7b0ec_USDT.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">USDT</div>
                    <div class="text-muted fs-12"> Tether</div>
                  </div>{" "}
                </div>

                <div class="select_options">
                  <div>
                    <img
                      src="https://assets-currency.kucoin.com/60bf8a90db892b0006d73786_BTC.png"
                      alt="coin symbole"
                      class="market_coin_img"
                    />
                  </div>
                  <div class="product_name">
                    <div class="mb-0 fw-bold">BTC</div>
                    <div class="text-muted fs-12"> Bitcoin</div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
