import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TradeTab from "../Trade.Tab";

const OrderBook = (props) => {
  const { coins, user_fav_pairing } = useSelector(
    (state) => state.coinDBReducer
  );
  const coin = props.match.params.id.split("-");
  const coinSymbol = props.match.params.id.split("-")[0]?.toUpperCase();
  const SelCurency = coin && coin[1] ? coin[1].toUpperCase() : "";
  const data = Object.values(coins).find(
    (d) => d.symbol === coin[0].toUpperCase()
  );

  const [bids, setBids] = useState([]);
  const [asks, setAsks] = useState([]);
  const [activeTab, setActiveTab] = useState("orderbook"); // Tab state
  const levels = 10;

  useEffect(() => {
    const symbol = data?.symbol ? data.symbol.toLowerCase() : "btc";
    const ws = new WebSocket(
      `wss://stream.binance.com:9443/ws/${symbol}usdt@depth${levels}`
    );

    ws.onmessage = (event) => {
      const parsedData = JSON.parse(event.data);
      setBids(parsedData.bids.slice(0, levels));
      setAsks(parsedData.asks.slice(0, levels));
    };

    ws.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    return () => {
      ws.close();
    };
  }, [data]);

  // Utility function to calculate percentage
  const calculatePercentage = (quantity, maxQuantity) =>
    (quantity / maxQuantity) * 100;

  return (
    <div className="order_book ">
      {/* Tabs */}
      <div className="tabs card-header">
        <button
          className={activeTab === "orderbook" ? "active" : ""}
          onClick={() => setActiveTab("orderbook")}
        >
          Order Book
        </button>
        <button
          className={activeTab === "history" ? "active" : ""}
          onClick={() => setActiveTab("history")}
        >
          Trades
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === "orderbook" && (
        <>
          {/* <h4 className="mb-3">Order Book</h4> */}
          <div className="row">
            {/* Bids Section */}
            <div className="">
              {/* <h5>Bids</h5> */}
              <div className="bids-container">
                <div className="bids-header">
                  <div className="bids-column">Price (USDT)</div>
                  <div className="bids-column">Amount ({coinSymbol})</div>
                  <div className="bids-column">Total ({coinSymbol})</div>
                </div>
                <div className="bids-body">
                  {bids.map(([price, quantity], index) => {
                    const maxQuantity = Math.max(
                      ...bids.map(([_, qty]) => qty)
                    );
                    const percentage = calculatePercentage(
                      quantity,
                      maxQuantity
                    );
                    const total = price * quantity;

                    return (
                      <div
                        key={index}
                        className="bids-row"
                        style={{
                          background: `linear-gradient(to left,rgba(32, 172, 149, 0.57) ${percentage}%, transparent ${percentage}%)`,
                        }}
                      >
                        <div
                          className="bids-column"
                          style={{ color: "#20ac95" }}
                        >
                          {Number(price)?.toFixed(Number(price) > 0.001 ? 2 :4)}
                        </div>
                        <div className="bids-column">
                          {Number(quantity)?.toFixed(Number(quantity) > 0.001 ? 2 :4)}
                        </div>
                        <div className="bids-column">
                          {Number(total)?.toFixed(Number(total) > 0.001 ? 2 :4)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            ;{/* Asks Section */}
            <div className="">
              {/* <h5>Asks</h5> */}
              <div className="bids-container">
                <div className="bids-header">
                  <div className="bids-column">Price (USDT)</div>
                  <div className="bids-column">Amount ({coinSymbol})</div>
                  <div className="bids-column">Total ({coinSymbol})</div>
                </div>
                <div className="bids-body">
                  {asks.map(([price, quantity], index) => {
                    const maxQuantity = Math.max(
                      ...asks.map(([_, qty]) => qty)
                    );
                    const percentage = calculatePercentage(
                      quantity,
                      maxQuantity
                    );
                    const total = price * quantity;

                    return (
                      <div
                        key={index}
                        className="bids-row"
                        style={{
                          background: `linear-gradient(to left, rgba(255, 0, 0, 0.5) ${percentage}%, transparent ${percentage}%)`,
                        }}
                        >
                        <div
                          className="bids-column"
                          style={{ color: "#e2464a" }}
                        > 
                         {Number(price)?.toFixed(Number(price) > 0.001 ? 2 :4)}
                        </div>
                        <div className="bids-column">
                          {Number(quantity)?.toFixed(Number(quantity) > 0.001 ? 2 :4)}
                        </div>
                        <div className="bids-column">
                          {Number(total)?.toFixed(Number(total) > 0.001 ? 2 :4)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {activeTab === "history" && <TradeTab {...props} />}
    </div>
  );
};

export default OrderBook;
