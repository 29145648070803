import React, { useEffect, useState } from "react";
import { BiSolidCheckShield } from "react-icons/bi";
import { IoGridOutline, IoStopwatchSharp } from "react-icons/io5";
import { LuPlus, LuRefreshCw, LuSend } from "react-icons/lu";
import { MdPayment } from "react-icons/md";

import { CiEdit } from "react-icons/ci";
import { VscSettings } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { maskEmail, priceDecimalManage } from "../../helper/common";
import NoData from "../../helper/components/NoData";
import Loader from "../components/Loader";
import P2pDropDown from "../modals/P2pDropDown";
import { getP2PCurrencyLimitation } from "../redux/helpers/api_functions_new";
import "./p2pStyle.css";

const P2PTranding = ({ handleOpenModal, mockData, loading, p2pOrders }) => {
  const [activeButton, setActiveButton] = useState("buy");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [fiatPrice, setFiatPrice] = useState("");
  const { profile } = useSelector((state) => state.AuthReducer);
  const [paymnetMethod, setPaymentMethod] = useState("All Payments");
  const [timeFilter, setTimeFilter] = useState("");
  const [selectedCoin, setSelectedCoin] = useState({
    label: "TRY",
    value: "TRY",
    image: "https://cdn2.iconfinder.com/data/icons/world-currency/512/07-512.png",
  });
  const [coinsData, setCoinsData] = useState([{
    label: "TRY",
    value: "TRY",
    image: "https://cdn2.iconfinder.com/data/icons/world-currency/512/07-512.png",
  }]);

  const { user } = useSelector((state) => state.AuthReducer);

  const refreshP2pOrders = async () => {
    await p2pOrders(activeButton, selectedCoin.value, fiatPrice, timeFilter);
  };

  useEffect(() => {
    if (!selectedCoin?.value) return;

    setSelectedIndex(null);
    refreshP2pOrders();

    // return () => clearTimeout(handler); // Cleanup timeout on dependency change
  }, [selectedCoin, fiatPrice, timeFilter, activeButton]);

  const handleOpenDropdown = (index) => {
    setSelectedIndex(index === selectedIndex ? null : index); // Toggle dropdown
  };

  useEffect(() => {
    getP2PCurrencyLimitation()
      .then((res) => {
        // setCurrencyLimit(res.data);
        if (res.data.fiat && res.data.fiat.length > 0) {
          setCoinsData(res.data.fiat);
          setSelectedCoin(res.data.fiat[0]);
        }
      })
      .catch((error) => console.log(error, "<<---------------- error"));
  }, []);

  return (
    <>
      <div id="p2pTrading" className="trading_page p2p-styles">
        <div className="d-md-flex  align-items-center justify-content-between py-3 rounded">
          <div className="d-flex gap-5 mb-1 mb-md-0 align-items-center">
            <ul class="add_post_tabs nav nav-pills">
              <li class="nav-item w-50 text-center">
                <a
                  onClick={() => setActiveButton("buy")}
                  class={`nav-link px-4 text-white buy_current_tab  ${activeButton == "buy" && "active"
                    }`}
                  aria-current="page"
                  href="#"
                >
                  Buy
                </a>
              </li>

              <li class="nav-item w-50 text-center">
                <a
                  onClick={() => setActiveButton("sell")}
                  class={`nav-link px-4 text-white sell_current_tab ${activeButton == "sell" && "active"
                    }`}
                  href="#"
                >
                  Sell
                </a>
              </li>
            </ul>

            <div className="d-flex gap-5">
              {/* <h5>USDT</h5> */}
              {/* <h5>SOL</h5>
            <h5>BUSD</h5>
            <h5>BTC</h5> */}
            </div>
          </div>
          <div className="d-md-flex gap-2">
            <div className="d-flex mb-1 mb-md-0 align-items-center p2p-filter-border">
              <div
                className="dropdown me-2 pe-2"
                style={{ borderRight: "1px solid #474d57" }}
              >
                <button
                  className="btn btn-secondary dropdown-toggle d-flex align-items-center text-white"
                  type="button"
                  id="currencyDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    padding: "0",
                  }}
                >
                  <img
                    src={selectedCoin?.image}
                    alt="USD"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "5px",
                      borderRadius: "50%",
                    }}
                  />
                  {selectedCoin?.value}
                </button>
                <ul
                  className="dropdown-menu custom_dropdown"
                  style={{
                    backgroundColor: "#333",
                    padding: "5px 0px",
                    borderRadius: "5px",
                  }}
                  aria-labelledby="currencyDropdown"
                >
                  {coinsData?.map((item, i) => (
                    <li
                      key={i}
                      onClick={() =>
                        setSelectedCoin({ image: item?.image, value: item?.value, label: item?.label })
                      }
                    >
                      <a
                        className="dropdown-item d-flex align-items-center hover-bg-gray"
                        href="#"
                      >
                        <img
                          src={item?.image}
                          alt="TRY"
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "5px",
                            borderRadius: "50%",
                          }}
                        />
                        {item?.value}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <input
                type="number"
                className="form-control text-white"
                placeholder="Enter Amount"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "white",
                  flex: 1,
                }}
                onChange={(e) => setFiatPrice(e.target.value)}
              />
            </div>

            {/* Right-side Actions */}
            <div className="d-sm-flex align-items-center trading-filter p2p-filter-border">
              <div className="dropdown me-3">
                <button
                  className="btn dropdown-toggle text-white border-0"
                  type="button"
                  id="paymentDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {paymnetMethod}
                </button>
                <ul
                  className="dropdown-menu custom_dropdown"
                  style={
                    {
                      // backgroundColor: "#333",
                      // padding: "5px 0px",
                      // borderRadius: "5px",
                    }
                  }
                  aria-labelledby="paymentDropdown"
                >
                  <li>
                    <a
                      className="dropdown-item hover-bg-gray"
                      href="#!"
                      onClick={() => setPaymentMethod("All Payments")}
                    >
                      All Payments
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item hover-bg-gray"
                      href="#!"
                      onClick={() => setPaymentMethod("Bank")}
                    >
                      Bank
                    </a>
                  </li>
                </ul>
              </div>
              <div className="dropdown me-3">
                <button
                  className="btn dropdown-toggle text-white border-0"
                  type="button"
                  id="paymentDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {timeFilter == ""
                    ? "Select Time Filter"
                    : timeFilter + " Minutes"}
                </button>
                <ul
                  className="dropdown-menu custom_dropdown"
                  style={
                    {
                      // backgroundColor: "#333",
                      // padding: "5px 0px",
                      // borderRadius: "5px",
                    }
                  }
                  aria-labelledby="paymentDropdown"
                >
                  <li>
                    <a
                      className="dropdown-item hover-bg-gray"
                      href="#!"
                      onClick={() => setTimeFilter("")}
                    >
                      All
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item hover-bg-gray"
                      href="#!"
                      onClick={() => setTimeFilter("15")}
                    >
                      15 Minutes
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item hover-bg-gray"
                      href="#!"
                      onClick={() => setTimeFilter("30")}
                    >
                      30 Minutes
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item hover-bg-gray"
                      href="#!"
                      onClick={() => setTimeFilter("45")}
                    >
                      45 Minutes
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item hover-bg-gray"
                      href="#!"
                      onClick={() => setTimeFilter("60")}
                    >
                      60 Minutes
                    </a>
                  </li>
                </ul>
              </div>
              <button className="btn text-white me-3 d-none">
                <VscSettings />
                Filter
              </button>
              <button
                className="btn text-white"
                onClick={() => refreshP2pOrders()}
              >
                <i className="bi bi-arrow-clockwise "></i>
                <LuRefreshCw
                  className={`${loading ? "loading-rotate" : ""}`}
                />{" "}
                Refresh
              </button>

              {profile?._id && (
                <div className="dropdown me-3">
                  <button
                    className="btn dropdown-toggle text-white border-0"
                    type="button"
                    id="paymentDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    More
                  </button>
                  <ul
                    className="dropdown-menu custom_dropdown"
                    style={{
                      backgroundColor: "#333",
                      padding: "5px 0px",
                      borderRadius: "5px",
                    }}
                    aria-labelledby="paymentDropdown"
                  >
                    <li className="">
                      <Link
                        to={"/payment-method"}
                        className="dropdown-item hover-bg-gray"
                      >
                        <MdPayment
                          className="mx-2"
                          style={{ fontSize: "18px" }}
                        />{" "}
                        Payment Method
                      </Link>
                      <Link
                        to={"/advertisers-terms"}
                        className="dropdown-item hover-bg-gray"
                      >
                        <CiEdit className="mx-2" style={{ fontSize: "18px" }} />{" "}
                        Advertisers Terms
                      </Link>

                      <Link
                        className="dropdown-item hover-bg-gray"
                        to="/P2PPostAd"
                      >
                        <LuPlus className="mx-2" style={{ fontSize: "17px" }} />{" "}
                        Post New Ad
                      </Link>
                      <Link
                        className="dropdown-item hover-bg-gray"
                        to="/my-postad"
                      >
                        <IoGridOutline
                          className="mx-2"
                          style={{ fontSize: "16px" }}
                        />{" "}
                        My Ads
                      </Link>
                      <Link
                        className="dropdown-item hover-bg-gray"
                        to="/active-requests"
                      >
                        <LuSend className="mx-2" style={{ fontSize: "17px" }} />{" "}
                        My Requests
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* BUY  */}
        <div className="col-lg-12 col-md-12 col-sm-12 mt-3 table-responsive">
          {
            // activeButton == "sell" ?
            //   <>
            //     <div className="text-light my-5 text-center" style={{ width: "100%" }}>
            //       {/* <img src="/img/timer.png" style={{ height: "200px" }} className="mt-4" /> */}

            //       <Lottie animationData={loadereLottie} loop={true} className="loader-lottie" />
            //       <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl mb-2">
            //         Coming Soon
            //       </h1>
            //       <h5 className="text-lg text-gray-600 mb-8 mt-4">
            //         We're working hard to bring you an amazing selling experience
            //       </h5>
            //     </div>
            //   </>
            //   :
            <table className="table p2p_buy_sell">
              <thead>
                <tr>
                  <th>Advertisers <span className="d-none d-md-block">{loading ? "ee" : "--"}</span></th>
                  <th>Price</th>
                  <th>Available/Limit</th>
                  <th><span className="d-none d-md-block">Payment</span> Method</th>
                  <th>Trade</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <Loader />
                    </td>
                  </tr>
                ) : mockData?.length > 0 ? (
                  mockData?.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td>
                          <div className="d-flex gap-2 align-items-start">
                            <div class="name_p2p_list gray_border">
                              {(item?.user?.name || item?.user?.email)
                                ?.split("")
                                ?.slice(0, 2)
                                ?.join("")
                                ?.toUpperCase()}
                            </div>

                            <div className="product_name d-flex flex-column gap-1">
                              <div className="mb-0 h6 text-white desc_card">
                                {item?.user?.name
                                  ? item?.user?.name.toUpperCase()
                                  : maskEmail(item?.user?.email).toLowerCase()}
                                <span>
                                  <BiSolidCheckShield className="text-green" />
                                </span>
                              </div>

                              <div className="fs-12">
                                <IoStopwatchSharp />
                                Time Period
                                <div className="verticle_line"></div>
                                {item.payment_time_limit} min
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <span className="desc_card text-white">
                              {priceDecimalManage(item.selling_price, false)}
                            </span>
                            <span className="ms-1 text-white">
                              {item.fiat_name}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="product_name d-flex flex-column gap-1">
                            <div className="">
                              <span className="desc_card text-white">
                                {priceDecimalManage(item.total_assets)}
                              </span>
                              <span className="ms-1 text-white">
                                {item?.asset_name}
                              </span>
                            </div>

                            <div className="fs-12">
                              {priceDecimalManage(item.min_order_limit)} {item?.asset_name} -{" "}
                              {priceDecimalManage(item.max_order_limit)} {item?.asset_name}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <ul className="d-flex flex-column gap-2 text-white">
                              {item?.payment_method?.map((method) => (
                                <li className="text-uppercase">
                                  <span className="text-warning me-2 text-bold">
                                    |
                                  </span>
                                  {method}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button
                              disabled={item?.user_id == profile._id}
                              className={
                                activeButton == "buy"
                                  ? "login_btn fs-12 px-4 py-1"
                                  : "login_btn_red fs-12 px-4 py-1"
                              }
                              onClick={() => handleOpenDropdown(index)}
                              style={{ fontWeight: "500" }}
                            >
                              {activeButton == "buy"
                                ? `Buy ${item?.asset_name}`
                                : `Sell ${item?.asset_name}`}
                            </button>
                          </div>
                        </td>
                      </tr>
                      {selectedIndex === index && (
                        <tr>
                          <td colSpan="5">
                            <div className="dropdown-container">
                              <P2pDropDown
                                activeButton={activeButton}
                                data={item ? item : ""}
                                setSelectedIndex={setSelectedIndex}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="5"
                      style={{
                        textAlign: "center",
                        border: "none",
                        padding: "8rem 0px",
                      }}
                    >
                      <NoData text={"No Data Available"} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          }
        </div>

        {/* SELL */}
        {/* {activeButton === "Sell" && (
          <div className="col-lg-12 col-md-12 col-sm-12 mt-3 table-responsive">
            <table className="table p2p_buy_sell">
              <thead>
                <tr>
                  <th>Advertier</th>
                  <th>Price</th>
                  <th>Available/Limit</th>
                  <th>Payment Method</th>
                  <th>
                    Operation{" "}
                    <span className="custom_badge bg-soft-success fs-12">
                      0 Fee(s)
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <Loader />
                    </td>
                  </tr>
                ) : mockData?.length > 0 ? (
                  mockData?.map((item, index) => (
                    <>
                      <tr key={index}>
                        <td>
                          <div className="d-flex gap-2 align-items-start">
                            <div class="circle_backdrop_outline gray_border">
                              {item?.user?.name &&
                                item?.user?.name
                                  ?.split("")
                                  .slice(0, 2)
                                  .join("")
                                  .toUpperCase()}
                            </div>

                            <div className="product_name d-flex flex-column gap-2">
                              <div className="mb-0 h6 text-white desc_card text-capitalize">
                                {item?.user?.name}
                                <span>
                                  <BiSolidCheckShield className="text-green" />
                                </span>
                              </div>

                              <div className=" fs-12 text-white">
                                Time Period
                                <div className="verticle_line"></div>
                                {item.payment_time_limit} min
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="">
                            <span className="desc_card text-white">
                              {item.selling_price}
                            </span>
                            <span className="ms-1 text-white">
                              {item.fiat_name}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="mb-0 d-flex flex-column gap-2 fs-6 text-white">
                            {" "}
                            <span className="">
                              {item.total_assets} {item?.asset_name}
                            </span>{" "}
                            <span className="">
                              {item.min_order_limit} {item?.asset_name} -{" "}
                              {item.max_order_limit} {item?.asset_name}
                            </span>
                          </div>{" "}
                        </td>
                        <td>
                          <div>
                            <ul className="d-flex flex-column gap-2 text-white">
                              {[
                                ...new Set(
                                  item?.payment_method.map(
                                    (elem) => elem.method.payment_method_type
                                  )
                                ),
                              ]?.map((method) => (
                                <li className="text-capitalize">
                                  <span className="text-warning me-2 text-bold">
                                    |
                                  </span>
                                  {method}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </td>
                        <td>
                          <div>
                            <button
                              className="btn btn-outline-secondary bg-danger text-white px-4 border-0"
                              onClick={() => handleOpenDropdown(index)}
                            >
                              Sell USDT
                            </button>
                          </div>
                        </td>
                      </tr>
                      {selectedIndex === index && (
                        <tr>
                          <td colSpan="5">
                            <div className="dropdown-container">
                              <P2pDropDown />
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <NoDataComp text={"No Data Available"} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )} */}

        {/* pagination */}

        {/* <nav aria-label="Page navigation ">
          <ul className="pagination gap-3 justify-content-end mt-2">
            <li className="page-item" id="prevPage">
              <span className="page-link rounded">
                <FaChevronLeft className="text-primary" />
              </span>
            </li>
            <li className="page-item  d-flex gap-1" id="currentPage">
              <span className="page-link btn-pink rounded active">1</span>
              <span className="page-link rounded">2</span>
              <span className="page-link rounded">3</span>
            </li>
            <li className="page-item " id="nextPage ">
              <a className="page-link rounded" href="#">
                <FaChevronRight className="text-primary" />
              </a>
            </li>
          </ul>
        </nav> */}
      </div>
      {/* <P2PBuySell open={showModal1} handleClose={handleCloseModal1} /> */}
    </>
  );
};

export default P2PTranding;
