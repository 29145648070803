import React from "react";
import { BiCopy } from "react-icons/bi";
import {
  FaFacebook,
  FaTwitter,
  FaTimes,
  FaWhatsapp,
  FaLinkedin,
} from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  ThreadsShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const ReferralModal = ({ isOpen, onClose, shareUrl, copyText }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-contentt">
        <button className="close-btn text-white" onClick={onClose}>
          <FaTimes />
        </button>
        <div className="d-flex flex-column gap-3 align-items-center">
          <h2 className="text-white">Share & Refer</h2>
          <div className="w-75">
            <img src="/img/referImg.png" className=" bounce-animation" alt="" />
          </div>
          <div className="d-flex gap-2">
            <FacebookShareButton url={shareUrl}>
              <FaFacebook size={35} className="text-white" />
            </FacebookShareButton>

            <TwitterShareButton url={shareUrl}>
              <FaTwitter size={35} className="text-white" />
            </TwitterShareButton>

            <WhatsappShareButton url={shareUrl}>
              <FaWhatsapp size={35} className="text-white" />
            </WhatsappShareButton>

            <EmailShareButton url={shareUrl}>
              <IoMdMail  size={35} className="text-white" />
            </EmailShareButton>

            <LinkedinShareButton url={shareUrl}>
              <FaLinkedin size={35} className="text-white" />
            </LinkedinShareButton>
          </div>
          <section
            className="d-flex justify-content-between align-items-center border rounded-2 px-4 py-2 mt-3"
            style={{ borderColor: "#bbb" }}
          >
            *Share and invite your friends to sign up via the social media
            above*
          </section>
        </div>
      </div>
    </div>
  );
};

export default ReferralModal;
