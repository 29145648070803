import React from "react";
import Header from "../Header";
import BlogDetail from "./BlogDetail";
import BlogFooter from "./BlogFooter";
import Footer from "../Footer";
import { useLocation } from "react-router-dom";
import educationCards from "./mockData";

const EducationDetail = () => {
  const location = useLocation();
  const cardId = location.state?.id;
  // console.log(cardId, "cardId");
  const cardData = educationCards.find((item) => item.id == cardId);
  
  return (
    <>
      <Header />
      <div className="wrap-privacy d-flex align-items-center ">
        <div className="container py-5">
          <BlogDetail data={cardData} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EducationDetail;
