import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import "./sidebar.css";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import {
  N_GetAllSymbol,
  N_createAddress,
  N_getBlockchain,
  N_getBlockchainData,
  N_depositHistory,
  N_getAllBlockchain,
} from "./redux/helpers/api_functions_new";
import AssetOption from "./components/AssetsDropdown";
import formatDate from "../hooks/formatDate";
import toast from "react-hot-toast";
import FAQAccordion from "./FaqAccordian";

export default function AssetsDeposit() {
  const faqData = [
    {
      question: "How do I deposit funds into my account?",
      answer:
        "To deposit funds, log in to your account and navigate to the Deposit section. You can deposit using a bank transfer, credit/debit card..  Select your preferred method, enter the necessary details, and follow the prompts to complete the deposit.",
    },
    {
      question:
        "What payment methods do you support for deposits and withdrawals?",
      answer:
        "Bitcash supports a range of payment methods, including bank transfers, credit/debit cards (Visa, MasterCard), and cryptocurrencies (Bitcoin, Ethereum, etc.). For detailed instructions on each method, please refer to our deposit/withdrawal guide.",
    },
    {
      question: "How long does it take for my deposit to appear in my account?",
      answer:
        "Deposits usually take between 3 minutes to 1 business day to appear in your account, depending on the method used. Crypto deposits typically take longer due to network confirmations, while bank transfers may take 1–2 business days.",
    },
    {
      question:
        "Why is my withdrawal taking so long to process?",
      answer:
        "Withdrawals may take longer if additional verification is required or if there’s a high volume of withdrawal requests. Cryptocurrency withdrawals are usually processed faster, while bank transfers may take 1–2 business days, depending on your bank.",
    },
    {
      question:
        "Can I withdraw funds to my crypto wallet?",
      answer:
        "Yes, Bitcash supports withdrawals to external cryptocurrency wallets. Simply navigate to the Withdraw section, select the cryptocurrency you want to withdraw, and enter the wallet address. Make sure your wallet supports the chosen currency.",
    },

  ];
  const history = useHistory();
  const [blockchain, setBlockchain] = useState();
  const [currency, setCurrency] = useState();
  const [contract, setContract] = useState();
  const [coindata, setCoinData] = useState();
  const [wallets, setWallets] = useState();
  const [deposithistory, setDepositHistory] = useState();
  const [symbolData, setSymbolData] = useState();
  const [isSelected, setIsSelected] = useState(0);
  const [search, setSearch] = useState("");
  const [showIndex, setShowIndex] = useState(null);
  const { user } = useSelector((state) => state.AuthReducer);
  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    // NotificationManager.success("Copied...");
    toast.success("Copied...");
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    N_GetAllSymbol({ is_deposite: 1 }).then((res) => {
      setWallets(res);
      const coinsData = res.find((item) => item.symbol == "ETH");
      setCoinData(coinsData);
    });
  }, []);

  useEffect(() => {
    if (coindata) {
      let data = {
        user_id: user?.params ? user.params.user_id : user.user_id,
        symbol: coindata.symbol,
        type: "deposit"
      };
      // N_getAllBlockchain(coindata.symbol).then((res) => {
      //   if (res.status == 200) {
      //     setBlockchain(res.data);
      //   }
      // });
      N_getBlockchain(coindata?.symbol).then((res) => {
        if (res.status == 200) {
          setBlockchain(res.data);
        }
      });
      N_depositHistory(data)
        .then((data) => {
          if (data.success) {
            setDepositHistory(data?.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [coindata]);
  const walletsdata =
    wallets &&
    wallets?.filter((product) => {
      return product.symbol.includes(search);
    });
  const onSelect = (item) => {
    setCoinData(item);
    setIsSelected(false);
    setSymbolData("");
  };

  const onNetworkSelect = (item) => {
    N_createAddress(
      user?.params ? user.params.user_id : user.user_id,
      coindata?.symbol
    );
    setSymbolData(item);
    N_getBlockchainData(
      item?.blockchain,
      user?.params ? user.params.user_id : user.user_id,
      coindata?.symbol
    ).then((res) => {
      if (res.status == 200) {
        if (res.currencydata) {
          setCurrency(res.currencydata);
          setContract(res.contractdata);
          setIsSelected(1);
          // } else if (
          //   item.blockchain == coindata?.blockchain
          // ) {
          //   setCurrency(res.currencydata);
          //   setIsSelected(2);
          // } else {
        } else {
          setIsSelected(3);
        }
      }
    });
  };
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container">
            <div className="marginTop">
              <div className="account_page">
                <div className="flex_row mb-4">
                  <div class="d-flex align-items-center gap-2">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => history.goBack()}
                    >
                      <i class="fa-solid fa-arrow-left-long"></i>
                    </span>
                    <p className="text-white fs-2"> Deposit Crypto </p >
                  </div>


                  {/* <div>
                      <a href="/AssetsFiat" className="btn btn-light">
                        Deposit Fiat{" "}
                        <i class="fa-solid fa-arrow-right-long ms-1"></i>
                      </a>
                    </div> */}
                </div>

                <div className="row">
                  <div className="col-lg-7">
                    <div className="cstm_form">
                      <div class="row_flex_div ">
                        <div class="col_left"></div>
                        <div class="col_right">
                          {/* <label for="inputPassword6" class="">
                            Coin
                          </label> */}
                        </div>
                      </div>

                      <div class=" mb-4">
                        <div class="text-lgray">
                          <label for="inputPassword6" class="mb-2">
                            Select Coin
                          </label>
                        </div>
                        <AssetOption
                          walletsdata={walletsdata}
                          coindata={coindata}
                          onSelect={onSelect}
                        />
                        {/* <div class="col_right">
                          <div className="select_box">
                            {" "}
                            <div
                              className="select_box_inner"
                              data-bs-toggle="modal"
                              data-bs-target="#coin_modal"
                            >
                              <div class="d-flex align-items-center">
                                <div>
                                  <img
                                    src={coindata?.icon}
                                    alt="coin symbole"
                                    class="coin_img me-2"
                                  />
                                </div>
                                <div class="product_name">
                                  {coindata?.symbol}{" "}
                                  <span className="unit text-muted">
                                    {coindata?.name}
                                  </span>
                                </div>{" "}
                              </div>
                            </div>
                            <div>
                              {" "}
                              <img
                                src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                class="arrow_img"
                                alt="arrow-icon"
                              />
                            </div>
                          </div>
                        </div> */}
                        <p class="fs-14 mt-3 ms-3 text-white">
                          {wallets &&
                            wallets.map((item) => {
                              if (
                                item.symbol == "USDT" ||
                                item.symbol == "ETH" ||
                                item.symbol == "BNB" ||
                                item.symbol == "TRX" ||
                                item.symbol == "BTC"
                              ) {
                                return (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    class="me-3 "
                                    onClick={() => {
                                      setCoinData(item);
                                      setIsSelected(false);
                                      setSymbolData();
                                    }}
                                  >
                                    <img
                                      src={item.icon}
                                      alt="coin symbole"
                                      class="market_coin_img "
                                      style={{ width: "24px", height: "24px" }}
                                    />{" "}
                                    <span style={{ marginTop: "4px" }}>
                                      {item.symbol}
                                    </span>
                                  </span>
                                );
                              }
                            })}
                        </p>
                      </div>
                      <div class="row_flex_div">
                        <div class="col_left"></div>
                        <div class="col_right">
                          {/* <label for="inputPassword6" class="">
                            Network
                          </label> */}
                        </div>
                      </div>
                      <div class=" mb-4">
                        <div class="text-lgray mb-2">
                          <label for="inputPassword6" class="form-label">
                            Select Network
                          </label>
                        </div>
                        <AssetOption
                          walletsdata={blockchain}
                          coindata={symbolData}
                          onSelect={onNetworkSelect}
                          check={coindata?.is_deposite}
                        />
                        {/* <div class="col_right">
                          <div className="select_box">
                            {" "}
                            <div className="select_box_inner">
                              <div
                                className="select_box_inner"
                                data-bs-toggle="modal"
                                data-bs-target="#network_modal"
                              >
                                <div class="product_name">
                                  {symbolData
                                    ? symbolData.symbol +
                                    " " +
                                    symbolData.blockchain
                                    : "Select Network"}
                                </div>{" "}
                              </div>
                            </div>
                            <div>
                              {" "}
                              <img
                                src="https://assets.staticimg.com/kucoin-main-web/2.0.20/svg/arrow.267b17d2.svg"
                                class="arrow_img"
                                alt="arrow-icon"
                              />
                            </div>
                          </div>
                        </div> */}
                      </div>

                      {isSelected == 1 && coindata?.is_deposite == 1 ? (
                        <div class=" mb-4">
                          <div className="d-flex">
                            <h5 className="ms-auto">
                              <small className="text-lgray">Minimum Deposit : <span className="">{symbolData?.min_deposite_limit} {coindata?.symbol}</span></small>
                            </h5>
                          </div>
                          <div class="col_left"></div>
                          <div class="col_right">
                            <h5>
                              <small className="text-lgray">Address Info</small>
                            </h5>
                            {currency != null ? (
                              <div className="d-flex gap-3 align-items-center jambotron mx-1">
                                <div
                                  style={{ width: "140px" }}
                                  className=" p-2 shadow"
                                >
                                  <div className="card-body">
                                    <img
                                      alt="qr code"
                                      src={`https://api.qrserver.com/v1/create-qr-code/?data=${currency?.wallet_address}&amp;size=30x30`}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <div className="text-lgray mb-1">
                                    Deposit Address
                                  </div>
                                  <h5>
                                    <div className="d-flex gap-2 align-items-center">
                                      <div
                                        className="text-container"
                                        style={{
                                          maxWidth: "75%",
                                          wordBreak: "break-word",
                                          overflowWrap: "break-word",
                                        }}
                                      >
                                        {currency == null
                                          ? "0X000000XX0000000000000000XX00000000000"
                                          : currency?.wallet_address}
                                      </div>
                                      <div
                                        onClick={() =>
                                          copyText(currency?.wallet_address)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <i className="fa fa-copy ms-2"></i>
                                      </div>
                                    </div>
                                  </h5>
                                </div>
                              </div>
                            ) : (
                              <div className="d-flex gap-4 align-items-center flex-column jambotron">
                                <div>
                                  <i
                                    class="fas fa-exclamation-circle"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                </div>
                                <div>Note:</div>
                                <p className="text-center">
                                  You haven't applied for the deposit address
                                  for ETH-Optimism yet. Click the button below
                                  to get an address.
                                </p>
                                {/* <button className="btn login_btn"
                                 onClick={() => {
                                  N_createAddress(
                                    user?.params ? user.params.user_id : user.user_id,
                                    coindata?.symbol
                                  );
                                  setSymbolData(item);
                                  N_getBlockchainData(
                                    item?.blockchain,
                                    user?.params ? user.params.user_id : user.user_id,
                                    coindata?.symbol
                                  ).then((res) => {
                                    if (res.status == 200) {
                                      if (res.contractdata) {
                                        setCurrency(res.currencydata);
                                        setContract(res.contractdata);
                                        setIsSelected(1);
                                        // } else if (
                                        //   item.blockchain == coindata?.blockchain
                                        // ) {
                                        //   setCurrency(res.currencydata);
                                        //   setIsSelected(2);
                                        // } else {
                                      } else {
                                        setIsSelected(3);
                                      }
                                    }
                                  });
                                }}
                                >Get Address</button> */}
                              </div>
                            )}

                            <div className="row">
                              {/* <div className="col-3 m-auto">
                                <div className="custom_card p-2 shadow">
                                  <div className="card-body">
                                  <a href="">
                                  {" "}
                                  <img
                                    src={`https://api.qrserver.com/v1/create-qr-code/?data=${currency?.wallet_address}&amp;size=50x50`}
                                  />
                                </a>
                                    
                                  </div>
                                </div>
                                
                              </div> */}
                            </div>

                            {/* <div className="mt-4">
                        <small className="text-lgray">Recipient Account</small>
                        <p>Funding Account (Main)</p>
                      </div> */}
                            {/* <div className="mt-4">
                        <div className="d-flex justify-content-between">
                          <div>
                            <small className="text-lgray">
                              Deposit Confirmation
                            </small>
                            <p>
                              3 <span className="text-lgray">Block(s)</span>{" "}
                            </p>
                          </div>
                          <div>
                            <small className="text-lgray">
                              Withdrawal Confirmation
                            </small>
                            <p>
                              3 <span className="text-lgray">Block(s)</span>{" "}
                            </p>
                          </div>
                        </div>
                      </div> */}
                            <div className="border-bottom mt-4 "></div>
                            {isSelected == 1 && coindata?.is_deposite == 1 ? (
                              <>
                                <p className=" mt-4">
                                  The deposit will be credited and available for
                                  trading after about 6 confirmation(s).
                                </p>
                                <p className=" mt-2">
                                  The deposit will be unlocked and available for
                                  withdrawal after about 12 confirmation(s).
                                </p>
                                <p className=" mt-2">
                                  Send only {coindata?.symbol} (
                                  {symbolData?.symbol}) to this deposit address.
                                  Other assets will be lost and cannot be
                                  retrieved.
                                </p>
                                {symbolData?.contract_address && (
                                  <div className="mt-2">
                                    <div>
                                      <small className="text-lgray">
                                        Contract Address :
                                      </small>
                                      <p>
                                        {symbolData?.contract_address}{" "}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            copyText(
                                              symbolData?.contract_address
                                            )
                                          }
                                          className=""
                                        >
                                          <i className="fa fa-copy ms-2"></i>
                                        </span>{" "}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                <p className=" fs-12">
                                  Confirm that your network is{" "}
                                  <span className="text-primary">
                                    {currency?.blockchain}
                                  </span>
                                  {symbolData?.contract_address &&
                                    " and contract address is same"}
                                  .
                                </p>
                              </>
                            ) : null}
                          </div>
                        </div>
                      ) : coindata?.is_deposite == 0 ? (
                        <div class=" mb-4">
                          <div class="col_left"></div>
                          <div class="col_right jambotron">
                            <h5>
                              <small className="text-white">
                                Deposits are temporarily unavailable. Any
                                pending deposit orders will be processed as soon
                                as possible after deposits come back online.
                              </small>
                            </h5>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-4">
                    <h5 className="text-white">FAQs</h5>
                    <FAQAccordion faqData={faqData} />{" "}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="flex_row mb-4">
                    <div className="">
                      <div class="">
                        <h3 className="text-white"> Deposit History </h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-auto history_table mt-3">
                  <div className="table_scroll_div">
                    <table className="table global_table">
                      <thead>
                        <tr>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Time</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>
                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Symbol</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>
                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Amount</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>
                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Address</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>
                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Status</span>
                              <div className="mx-1">
                                <div className="position-relative">
                                  <FaSortUp className="sorting_btn" />
                                </div>
                                <div className="position-relative">
                                  <FaSortDown className="sorting_btn" />
                                </div>
                              </div>
                            </div>
                          </th>
                          <th className="text-start">
                            <div className="d-flex align-items-center">
                              <span>Action</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {deposithistory && deposithistory.length > 0 ? (
                          deposithistory.map((item) => (
                            <tr key={item.id}>
                              <td className="text-start">
                                {formatDate(item.createdAt)}
                              </td>
                              <td className="text-start">{item?.symbol}</td>
                              <td className="text-start">
                                {Number(item.amount)?.toFixed(6)}
                              </td>
                              <td className="text-start">
                                {item.to_address
                                  ? `${item.to_address.slice(
                                    0,
                                    6
                                  )}...${item.to_address.slice(-6)}`
                                  : ""}
                                <span
                                  style={{ cursor: "pointer" }}
                                  onClick={() => copyText(item.to_address)}
                                  className=""
                                >
                                  <i className="fa fa-copy ms-2"></i>
                                </span>
                              </td>
                              <td className="text-start text-green">
                                Completed
                              </td>
                              <td>
                                <button
                                  className="detail_btn py-1"
                                  onClick={() => {
                                    openModal();
                                    setModalData(item);
                                  }}
                                >
                                  Details
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6} className="text-center">
                              <img
                                alt="no data found"
                                src="/img/no-data.png"
                                className="no-data-found"
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {deposithistory && deposithistory.length > 4 && (
                      <div className="text-center mt-3">
                        <button
                          className="btn login_btn rounded-4"
                          onClick={() => history.push("/transaction")}
                          style={{
                            padding: "5px 15px",
                            fontSize: "14px",
                          }}
                        >
                          View More
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </main>
      </div>

      <div
        class="modal coin_modal fade networkModal"
        tabindex="-1"
        id="coin_modal"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Coin</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="m-1">
                <div class="input-group ">
                  <span class="input-group-text bg-transparent">
                    <div class="">
                      <i class="fa fa-search text-lgray"></i>
                    </div>
                  </span>
                  <input
                    type="text"
                    class="form-control text-start mp-exide-form bg-white"
                    aria-label="Text input with checkbox"
                    placeholder="Search "
                    onChange={(e) => {
                      setSearch(e.target.value.toUpperCase());
                    }}
                  />
                </div>
              </div>

              <div className="mt-4">
                <h6 className="text-lgray">Popular</h6>
                <p class="fs-14">
                  {wallets &&
                    wallets.map((item) => {
                      if (
                        item.symbol == "USDT" ||
                        item.symbol == "ETH" ||
                        item.symbol == "BNB" ||
                        item.symbol == "TRX" ||
                        item.symbol == "BTC"
                      ) {
                        return (
                          <span
                            style={{ cursor: "pointer", marginRight: "8px" }}
                            class=" me-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              setCoinData(item);
                              setIsSelected(false);
                              setSymbolData();
                            }}
                          >
                            <img
                              src={item.icon}
                              alt="coin symbole"
                              class="market_coin_img"
                            />{" "}
                            {item.symbol}
                          </span>
                        );
                      }
                    })}
                </p>
              </div>

              <div className="custom_scrollbar height250">
                {walletsdata &&
                  walletsdata.map((item) => {
                    if (item.symbol !== "INR") {
                      return (
                        <div
                          class="select_options"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          onClick={() => {
                            setCoinData(item);
                            setIsSelected(false);
                            setSymbolData("");
                          }}
                        >
                          <div>
                            <img
                              src={item.icon}
                              alt="coin symbole"
                              class="market_coin_img"
                            />
                          </div>
                          <div class="product_name">
                            <div class="mb-0 fw-bold">{item.symbol}</div>
                            <div class="text-muted fs-12">{item.name}</div>
                          </div>{" "}
                        </div>
                      );
                    }
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal coin_modal fade" tabindex="-1" id="network_modal">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-bottom-0 pb-0">
              <h4 class="modal-title">Select Network</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="custom_scrollbar height275">
                {blockchain &&
                  blockchain.map((item) => {
                    return (
                      <div
                        class="select_options"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => {
                          N_createAddress(
                            user?.params ? user.params.user_id : user.user_id,
                            coindata?.symbol
                          );
                          setSymbolData(item);
                          N_getBlockchainData(
                            item?.blockchain,
                            user?.params ? user.params.user_id : user.user_id,
                            coindata?.symbol
                          ).then((res) => {
                            if (res.status == 200) {
                              if (res.currencydata) {
                                setCurrency(res.currencydata);
                                setContract(res.contractdata);
                                setIsSelected(1);
                                // } else if (
                                //   item.blockchain == coindata?.blockchain
                                // ) {
                                //   setCurrency(res.currencydata);
                                //   setIsSelected(2);
                                // } else {
                              } else {
                                setIsSelected(3);
                              }
                            }
                          });
                        }}
                      >
                        <div class="product_name d-flex gap-2 align-item-center">
                          <div class="mb-0 fw-bold ">
                            {" "}
                            {item.symbol}{" "}
                            {item.type == "erc20"
                              ? "(ERC20)"
                              : item.type == "trc20"
                                ? "(TRC20)"
                                : item.type == "bep20"
                                  ? " Smart Chain(BEP20)"
                                  : item.type == "grc20"
                                    ? "(GRC20)"
                                    : ""}
                          </div>
                          <div class="text-muted fs-12"> {item.blockchain}</div>
                        </div>{" "}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal */}
      <div
        className={`modal fade no-scroll bg-blur ${isModalOpen ? "show d-block" : ""
          }`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="paymentmodal2"
        aria-hidden={!isModalOpen}
        style={{ display: isModalOpen ? "block" : "none" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content first_registration_section comming-soon-bg p-2">
            <div className="modal-body text-white">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Details</h5>

                <div
                  style={{ textAlign: "end", fontSize: "22px" }}
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setIsModalOpen(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </div>
              </div>

              <div className="my-2 d-flex gap-3 align-items-center"></div>
              <div className="row ">
                <div className="col-4 mb-2">
                  <div className="text-lgray">Symbol</div>
                  <div>{modalData?.symbol?.toUpperCase()}</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Address</div>
                  <div>
                    {" "}
                    {modalData.to_address
                      ? `${modalData.to_address.slice(
                        0,
                        6
                      )}...${modalData.to_address.slice(-6)}`
                      : ""}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => copyText(modalData.to_address)}
                      className=""
                    >
                      <i className="fa fa-copy ms-2"></i>
                    </span>
                  </div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Status</div>
                  <div>Success (100%)</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Update Time</div>
                  <div>{new Date(modalData.createdAt).toLocaleString()}</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Total Amount</div>
                  <div>{Number(modalData?.amount)?.toFixed(6)}</div>
                </div>

                <div className="col-4 mb-2">
                  <div className="text-lgray">Txn. Id</div>
                  <div>
                    {" "}
                    {modalData.tx_id
                      ? `${modalData.tx_id.slice(
                        0,
                        6
                      )}...${modalData.tx_id.slice(-6)}`
                      : ""}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => copyText(modalData.tx_id)}
                      className=""
                    >
                      <i className="fa fa-copy ms-2"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* end modal  */}
    </>
  );
}
