import { toast } from 'react-hot-toast';

const useToastPromise = () => {
  const showToast = (promiseFunction, message) => {
    return toast.promise(promiseFunction(), {
      loading: message.loading || "Processing...",
      success: message.success || "Success!",
      error: message.error || "Something went wrong!",
    });
  };

  return showToast;
};

export default useToastPromise;
