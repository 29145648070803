import React from "react";
import { BiSend } from "react-icons/bi";
import "../modals/p2p.css";
import ChatBox from "./payment-methods/ChatComponent";
import { useHistory } from "react-router-dom";
import { firstLetterUpper, minuteFormat, NumberToFixed } from "../../utils/common";

const CreateOrder1 = ({ handleNext, orderInfo, orderResponse, orderResp }) => {
  const router = useHistory();
  return (
    <>
      <div className="">

        <div className="d-flex flex-column gap-4 mt-3">
          <div className="d-flex justify-content-between align-items-center gray-borders rounded-3 px-4 py-3">
            <section className="d-flex gap-4 align-items-center">
              <span className="first-char"> {orderResp?.sellerInfo?.name ? firstLetterUpper(orderResp?.sellerInfo?.name) : firstLetterUpper(orderResp?.sellerInfo?.email)} </span>
              <h4 className="">{orderResp?.sellerInfo?.name ? orderResp?.sellerInfo?.name.toUpperCase() : orderResp?.sellerInfo?.email.toLowerCase()}</h4>
            </section>
            <button
              className="login_btn px-4 py-1 fs-12"
              onClick={handleNext}
            >
              Chat
            </button>
          </div>
          <div
            className="d-flex flex-column gap-3 gray-borders rounded-3 p-4 col-12"
            style={{ minHeight: "400px", overflow: "auto" }}
          >
            <div className=" p-3">
              <h5>
                <span className={orderInfo?.type == "sell" ? "text-green" : "text-red"}> {orderInfo?.type == "sell" ? "Buy" : "Sell"} </span> {orderResponse?.response?.crypto_currency}
              </h5>
              <p className="d-flex justify-content-between">
                <span>Fiat Amount</span>
                <span>{orderResponse?.response?.fiat_amount_sent} {orderResponse?.response?.fiat_currency}</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Price / {orderResponse?.response?.crypto_currency}</span>
                <span>{orderInfo?.selling_price} {orderInfo?.fiat_name} </span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Receive Quantity</span>
                <span>{NumberToFixed(orderResponse?.response?.crypto_amount_receive)} {orderResponse?.response?.crypto_currency}</span>
              </p>
              {/* <p className="d-flex justify-content-between">
                <span>Total Quantity</span>
                <span>100 USDT</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Fee</span>
                <span>0.05000000 USDT</span>
              </p> */}
            </div>
            <div className="p-3" style={{ borderTop: "1px solid white" }}>
              <p className="d-flex justify-content-between">
                <span>Pay to</span>
              </p>
              <p className="d-flex justify-content-between">
                <span>Seller Name</span>
                <span className="text-capitalize">{orderResp?.sellerInfo?.name || orderResp?.sellerInfo?.email}</span>
              </p>
              {/* <p className="d-flex justify-content-between">
                <span>Digital eRupee wallet VPA</span>
                <span>idfc</span>
              </p> */}
              <p className="d-flex justify-content-between">
                <span>Paying Amount </span>
                <span>{orderResponse?.response?.fiat_amount_sent} {orderResponse?.response?.fiat_currency}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrder1;
