import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NotificationManager } from "react-notifications";
import { API_URL } from "../../constants";
import "react-phone-input-2/lib/bootstrap.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getRound } from "../redux/helpers/helper_functions";
import { BsArrowDownShort, BsArrowUpShort } from "react-icons/bs";
import useHumanReadableNumber from "../../hooks/useHumanReadableNumber";
import TradingViewTicker from "../components/bannerTrading";
export default function Banner(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Check localStorage for "BitcashSubscribed"
    const isSubscribed = localStorage.getItem("BitcashSubscribed");

    // If not found or false, open the modal
    if (!isSubscribed) {
      setIsModalOpen(true);
    } else {
      // setIsModalOpen2(true);
    }
  }, [isModalOpen]);
  const [loader, setLoader] = useState(false);

  const [name, setName] = useState();
  const [lang, setLang] = useState(currentLanguage);
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [tradingExp, setTradingExp] = useState("");
  const [trdMkt, setTrdMkt] = useState("");
  const [file, setFile] = useState(null);
  const formatNumber = useHumanReadableNumber();
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeModal2 = () => {
    setIsModalOpen2(false);
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if file size is greater than 2MB (2 * 1024 * 1024 bytes)
      if (file.size > 2 * 1024 * 1024) {
        // Show toast notification for file size error
        NotificationManager.error(
          "File size exceeds 2 MB. Please select a file smaller than 2 MB."
        );
        return; // Exit function
      }

      setFile(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result); // Set the base64 image
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = () => {
    setSelectedImage(null); // Clear the selected image
  };

  const handleSubscribe = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (!file) {
      // Notify the user if no file is uploaded
      NotificationManager.error("Please upload the file!");
      return; // Exit the function early
    }

    if (!name || !email) {
      // Validate that all required fields are filled
      NotificationManager.error("Please fill in all the required fields!");
      return; // Exit the function early
    }

    // Perform the subscription logic if all fields are valid
    try {
      setLoading(true);
      // console.log("Subscription details:", { name, email, phone, file });
      const formData = new FormData();

      // Append fields to the FormData object
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("fileupload", file);
      formData.append("tradingExperience", tradingExp);
      formData.append("tradingMarketing", trdMkt);
      formData.append("lang", currentLanguage);

      // Store the subscription status in localStorage
      axios
        .post(`${API_URL}api/subscriber-contact`, formData, {
          headers: {
            "content-type": "application/json",
            "cache-control": "no-cache",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          // console.log(res.data);
          NotificationManager.success("You have successfully subscribed!");
          localStorage.setItem("BitcashSubscribed", true);
          setIsModalOpen(false);
          setLoading(false);
        })
        .catch((err) => {
          NotificationManager.error(
            err?.response?.data?.message || "Something went wrong!"
          );
          setLoading(false);
        });

      // Optionally notify the user about the success
    } catch (error) {
      setLoading(false);
      // Handle unexpected errors gracefully
      NotificationManager.error(
        "An error occurred during subscription. Please try again!"
      );
      console.error("Subscription error:", error);
    }
  };
  function formatNumberWithCommas(number, locales = "en-US", options = {}) {
    // Set default options if not provided
    const defaultOptions = {
      minimumFractionDigits: 3, // Ensures small numbers like 0.001 are displayed correctly
      maximumFractionDigits: 4, // Adjust this based on the precision you want
    };

    // Merge user-provided options with default options
    const formatOptions = { ...defaultOptions, ...options };

    return new Intl.NumberFormat(locales, formatOptions).format(number);
  }
  const { isLoggedIn } = useSelector((state) => state.AuthReducer);
  const { coins, campare_currency } = useSelector(
    (state) => state.coinDBReducer
  );
  AOS.init({ duration: 1000 });
  // const { coins } = useSelector((state) => state.coinDBReducer);
  let coins1 = Object.values(coins);

  const banner_child =
    coins1 &&
    coins1.map((item, index) => {
      let usdtprize = coins1 && coins1.find((item) => item.symbol == "USDT");
      return (
        <>
          {item.symbol == "BTC" ||
          item.symbol == "BNB" ||
          item.symbol == "ETH" ||
          item.symbol == "TRX" ? (
            <div className="col-lg-3" key={index}>
              <div className="card banner_child">
                <div className="card-body">
                  <p className="">
                    {item.symbol}/USDT&nbsp;&nbsp;
                    <span
                      className={
                        item.direction == "up" ? "text-green" : "text-red"
                      }
                    >
                      {item.price_change_percentage_1h}%
                    </span>
                  </p>
                  <h5>
                    ${(item.current_price / usdtprize.current_price).toFixed(8)}
                  </h5>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      );
    });

  useEffect(() => {
    if (coins1?.length == 0) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [coins]);

  const market_child =
    coins1 &&
    coins1.map((item, index) => {
      let usdtprize = coins1 && coins1.find((item) => item.symbol == "USDT");
      return (
        <>
          {item.symbol == "BTC" ||
          item.symbol == "BNB" ||
          item.symbol == "ETH" ||
          item.symbol == "TRX" ||
          item.symbol == "SOL" ||
          item.symbol == "Bitcash" ? (
            <tr className="table_row_border">
              <td>
                <img
                  src={item.icon}
                  alt="coin symbole"
                  className="market_coin_img"
                  //style={{ width: "50px" }}
                />
                {item.symbol}
                {/* <span className="name_coin ms-2">{item.name}</span> */}
              </td>
              <td>
                $
                {formatNumberWithCommas(
                  getRound(item?.current_price / usdtprize?.current_price)
                )}
              </td>
              <td>
                <span
                  style={{
                    color: item.direction === "up" ? "#00FF00" : "#FF4500",
                  }}
                >
                  {item.direction === "up" ? "+" : "-"}{" "}
                  {getRound(item.price_change_percentage_1h)}%{" "}
                  {item.direction === "up" ? (
                    <BsArrowUpShort />
                  ) : (
                    <BsArrowDownShort />
                  )}
                </span>
              </td>
            </tr>
          ) : (
            ""
          )}
        </>
      );
    });

  const tableAssets = [
    {
      name: "BTC",
      price: "$100501.568",
      changes: "+0.25%",
      image: "/img/btc.svg",
    },
    {
      name: "Dogecoin",
      price: "$0.40360",
      changes: "-0.26%",
      image: "/img/doge.svg",
    },
    {
      name: "USDC",
      price: "$56.5659",
      changes: "+ 0.56 %",
      image: "/img/usdc.svg",
    },
    {
      name: "XRP",
      price: "$2.2656",
      changes: "+ 0.32 %",
      image: "/img/xrp.svg",
    },
  ];

  return (
    <>
      <div className="page-content">
        <div className="home_page_banner">
          <div className="container d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <div className="row align-items-center mt-5 mt-5">
              <div className="col-lg-8 mb-3">
                <div className="banner_left_inner">
                  <h2 className="bannertxt">
                    {t("Trade")}{" "}
                    <span className="text-gradient">{t("Smarter")}</span>,<br />
                    {t("Faster")}, <span className="">{t("Safer")}</span>
                  </h2>
                  <h3 className="text-sizing mt-3">
                    -- Start Your Crypto Journey{" "}
                    <span className="text-gradient1">Now</span>
                  </h3>
                  <p className="mb-2 mt-5 bannerSubHeading">
                    {t("bannerSubheading1")}
                  </p>
                  <p className="bannerSubHeading">{t("bannerSubheading2")}</p>
                  <div className="mt-4">
                    {!isLoggedIn ? (
                      <>
                        <Link className="btn login_btn px-5" to="/create">
                          Get Start
                        </Link>
                      </>
                    ) : (
                      <a
                        className="btn  login_btn px-4 "
                        href="#trade_now"
                      >
                        {t("TradeNow")}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">

            <div className="d-flex flex-column gap-3 w-100">
              <div className="">
                <table className="table banner_table">
                  <thead>
                    <tr>
                      <th>Coin</th>
                      <th>Price</th>
                      <th>Changes</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          ""
                        </td>
                      </tr>
                    ) : (
                      market_child
                    )}
                  </tbody>
                </table>
                {/* <img
                  src="/bitcash/Frame.png"
                  alt="image"
                  style={{ width: "400px" }}
                /> */}
              </div>
              <div className="common_container">
                <p className="banner_news_heading text-white">News</p>
                <p className="banner_news">
                  Empowering Investors with Crypto Knowledge.
                  </p>
                  <p>
                  Where Crypto Enthusiasts Stay Informed.
                  </p>
                  <p>
                  Stay Ahead with Real-Time Crypto Market News and Updates.
                </p>
              </div>
            </div>
            </div>
           
          </div>
        </div>
        <div>
              <TradingViewTicker />
            </div>
      </div>
      <div className="about_section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="about_text">
                <div className="about_heading">
                  Fast-track your path to{" "}
                  <span className="text-gradient">market</span> and{" "}
                  <br className="d-none d-md-block" />
                  unlock new{" "}
                  <span className="text-gradient">revenue potential</span> with
                  ease
                </div>
              </div>
              <div className="about_card_div">
                <div className="row">
                  <div className="col-md-4">
                    <div className="about_card about_card_border_right mb-2">
                      <img src="/img/l1.svg" className="mb-5" />
                      <div className="about_card_heading">Crypto currency</div>
                      <div className="about_card_content">
                        Cryptocurrency refers to a digital or virtual form of
                        currency that utilizes utilizes.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="about_card about_card_border_right mb-2">
                      <img src="/img/l2.svg" className="mb-5" />
                      <div className="about_card_heading">Decentralized</div>
                      <div className="about_card_content">
                        Cryptocurrency refers to a digital or virtual form of
                        currency that utilizes utilizes.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="about_card mb-2 ">
                      <img src="/img/l3.svg" className="mb-5" />
                      <div className="about_card_heading">Cryptography</div>
                      <div className="about_card_content">
                        Cryptocurrency refers to a digital or virtual form of
                        currency that utilizes utilizes.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" tabindex="-1" id="paymentmodal">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Select Payment Method</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <p>Debit and Credit Cards</p>
            </div>
          </div>
        </div>
      </div>

      {/* register modal */}

      <div>
        {/* Div to trigger modal */}

        {/* Modal */}
        <div
          className={`modal  fade no-scroll bg-blur ${
            isModalOpen2 ? "show d-block" : ""
          }`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="paymentmodal2"
          aria-hidden={!isModalOpen2}
          style={{ display: isModalOpen2 ? "block" : "none" }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content first_registration_section comming-soon-bg p-2">
              <div className="modal-body text-white">
                <div className="first_registration_heading_section">
                  <div className="my-2">
                    <img
                      style={{ width: "80px", marginBottom: "18px" }}
                      src="/img/logo.svg"
                    />
                  </div>
                  <div className="coming_registration_heading">
                    {t("commingSoonHeading")}
                  </div>
                  <div className="coming_registration_subheading mt-4">
                    {t("commingSoonSubheading")}
                  </div>
                  <div className="coming_text mt-5 py-3">{t("ComingSoon")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Backdrop */}
        {isModalOpen2 && (
          <div className="modal-backdrop fade show" onClick={closeModal2}></div>
        )}
      </div>
      {/* end  */}
    </>
  );
}
