import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import "./sidebar.css";
import {
  N_depositHistory,
} from "./redux/helpers/api_functions_new";
import AssetSidebar from "./components/AssetSidebar";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { Pagination, PaginationItem } from "@mui/material";
import moment from "moment";
import toast from "react-hot-toast";

export default function TransactionHistory(props) {
  const { coins } = useSelector((state) => state.coinDBReducer);
  const { switch_theme } = useSelector((state) => state.AuthReducer);
  const [assets, setAssets] = useState();
  // const [networks, setNetworks] = useState();
  const [calendarIsShowing, setCalendarIsShowing] = useState(false);
  const [pageData, setPageData] = useState({
    pagination: {
      page: 1,
      limit: 5,
      total: 0,
    },
    filters: {
      symbol: "",
      blockchain: "",
      type: "", // New filter state
      startDate: "",
      endDate: "",
    },
    data: [],
    loading: true
  });

  const updateState = (key, value) => {
    setPageData((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [key]: value
      }
    }));
  };

  useEffect(() => {
    if (coins) {      
      function updatePair() {
        setAssets(Object.values(coins).filter((data)=>data?.is_deposite || data?.is_withdrawal).map((data) => data.symbol));
      }
      updatePair();
    }
  }, [coins]);


  const assetOptions =
    assets &&
    assets.map((item, i) => {
      return <option value={item} key={i}>{item}</option>;
    });

  const getHistory = useCallback(async () => {
    try {
      const res = await N_depositHistory({
        ...pageData.filters,
        ...pageData.pagination
      });
      console.log(res, "response");

      const totalPages = Math.ceil(res?.total / pageData.pagination.limit);
      setPageData((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: totalPages,
        },
        data: res?.data
      }))
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageData.pagination.page,
    pageData.filters
  ]);

  useEffect(() => {
    setPageData((prev) => ({
      ...prev,
      loading: true
    }));
    getHistory().finally(() => {
      setPageData((prev) => ({
        ...prev,
        loading: false
      }))
    });
  }, [
    getHistory
  ]);


  const copyText = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied...");
  };
  const handlePageChange = (e, value) => {
    setPageData((prevState) => ({
      ...prevState,
      pagination: {
        ...prevState.pagination,
        page: value
      }
    }));
  };

  const renderHistory = useMemo(() => {
    return pageData?.data?.map((item, index) => (
      <tr key={item.tx_id || "----"}>
        <td className="border-bottom-0 text-muted">
          <p>{(pageData.pagination.page - 1) * pageData.pagination.limit + (index + 1)}</p>
        </td>
        <td className="border-bottom-0 text-muted">
          <p>{item.symbol || "----"}</p>
        </td>
        <td className="border-bottom-0 text-muted text-capitalize">
          <p>{item.network || "----"}</p>
        </td>
        <td className="border-bottom-0 text-muted text-capitalize">
          <p className={`px-1 text-center rounded-5 ${item?.type === "deposit"
            ? "border border-success text-white bg-success"
            : "border border-danger text-white bg-danger"
            }`}
            style={{ width: "100px" }}
          >
            {item?.type || "----"}
          </p>
        </td>
        <td className="border-bottom-0 text-muted">
          <p>{parseFloat(item?.amount).toFixed(3)}</p>
        </td>
        <td className="border-bottom-0 text-muted">
          <p>
            {item?.tx_id
              ? <> {item.tx_id.slice(0, 10)}...{item.tx_id.slice(-5)} <span
                style={{ cursor: "pointer" }}
                onClick={() => copyText(item?.tx_id)}
              >
                <i className="fa fa-copy ms-2"></i>
              </span></>
              : "N/A"}
          </p>
        </td>
        <td className="border-bottom-0 text-muted">
          <p style={{textTransform:'uppercase'}}>
           {item?.doneBy}
          </p>
        </td>
        <td className="border-bottom-0 text-muted">
          <p
            className={`px-1 text-center rounded-5 ${+item?.status === 1
              ? "border border-success text-success"
              : item?.transaction_status === "PENDING" ? "border border-warning text-warning" : "border border-danger text-danger"
              }`}
            style={{ width: "100px" }}
          >
            {+item?.status === 1
              ? "Completed"
              : item?.transaction_status || "Failed"}
          </p>
        </td>
        <td className="border-bottom-0 text-muted">
          <p>
            {moment(item.createdAt).format("MMM DD, YYYY")}
            <br />
            <small>{moment(item.createdAt).format("hh:mm:ss")}</small>
          </p>
        </td>
      </tr>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData.data]);

  return (
    <>
      <Header />
      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="marginTop">
            <div className="account_page">
              <div className="container">
                <div className="row">
                  <div className="activity_panel mb-4">
                    <div className="">
                      <div class="custom_pills">
                        <div class="nav nav-pills" id="nav-tab" role="tablist">
                          <div
                            class={`nav-item nav-link fs-14 active`}
                            style={{ height: "35px" }}
                            href="#order_history"
                            data-toggle="tab"
                          >
                            Deposit & Withdraw History
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-content">
                  <div className="tab-pane fade active show" id="open_order">
                    <div className="row cstm_form mb-4">
                      <div className="col-lg-2 mb-2">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            updateState("symbol", e.target.value);
                          }}
                        >
                          <option value="" selected>
                            All Assets
                          </option>
                          {assetOptions}
                        </select>
                      </div>
                      {/* <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            updateState("network", e.target.value);
                          }}
                        >
                          <option value="" selected>
                            All
                          </option>
                        </select>
                      </div> */}
                      <div className="col-lg-2 mb-2">
                        {" "}
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            updateState("type", e.target.value);
                          }}
                        >
                          <option value="" selected>
                            All Types
                          </option>
                          <option value="deposit">Deposit</option>
                          <option value="withdrawal">Withdraw</option>
                        </select>
                      </div>
                      <div className="col-lg-3 mb-2 datefiltergroup">
                        <DateRangePicker
                          onApply={(e, picker) => {
                            const startDate =
                              picker.startDate.format("YYYY-MM-DD");
                            const endDate = picker.endDate.format("YYYY-MM-DD");
                            updateState("startDate", startDate);
                            updateState("endDate", endDate);
                          }}
                          onShow={() => setCalendarIsShowing(true)}
                          onHide={() => setCalendarIsShowing(false)}
                        >
                          <input
                            // ref={inputRef}
                            style={{ height: "41px", cursor: "pointer" }}
                            placeholder="Filter by Date"
                            className="form-control date_picker"
                            value={
                              pageData?.filters?.startDate && pageData?.filters?.endDate
                                ? `${pageData.filters.startDate} - ${pageData.filters.endDate}`
                                : ""
                            }
                          />
                        </DateRangePicker>
                        <img
                          onClick={() => {
                            if (!calendarIsShowing) {
                              updateState("startDate", "");
                              updateState("endDate", "");
                            }
                          }}
                          src={
                            calendarIsShowing
                              ? "/img/calendar-remove-lines.svg"
                              : pageData?.filters?.startDate && pageData?.filters?.endDate
                                ? "/img/calendar-remove-lines.svg"
                                : "/img/schedule.svg"
                          }
                          style={{ filter: switch_theme === "dark" ? "invert(1)" : "" }}
                          className="filterimg"
                          alt=""
                          width={20}
                        />
                      </div>
                    </div>

                    <div className="overflow-auto mt-3 history_table">
                      <div className="">
                        <table className="table global_table history-p2p-tbl">
                          <thead>
                            <tr>
                              <th>S No.</th>
                              <th>Asset</th>
                              <th>Network</th>
                              <th>Transaction Type</th>
                              <th>Amount</th>
                              <th>Transaction ID</th>
                              <th>By</th>
                              <th>Status</th>
                              <th>Transaction On</th>
                            </tr>
                          </thead>

                          <tbody>
                            {/*============= Use this div when no data is found =============== */}
                            {
                              pageData.loading ?

                                <tr>
                                  <td
                                    colSpan={9}
                                    className="text-center border-bottom-0 text-muted"
                                  >
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
                                      <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                : pageData?.data?.length > 0 ? (
                                  renderHistory
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={9}
                                      className="text-center border-bottom-0 text-muted"
                                    >
                                      <img
                                        alt="no data found"
                                        src="/img/no-data.png"
                                        className="no-data-found"
                                      />
                                      <p>No records</p>
                                    </td>
                                  </tr>
                                )
                            }

                            {/*============= Use this div when no data is found =============== */}
                          </tbody>
                        </table>

                        <Pagination
                          hidden={pageData.loading || pageData?.pagination?.total < 2 || pageData?.data?.length <= 0}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          count={pageData.pagination.total}
                          onChange={handlePageChange}
                          renderItem={(item) => (
                            <PaginationItem
                              style={{
                                backgroundColor: !item.selected ? "" : "#552dba",
                                color:item.selected ? "white": switch_theme === "light" ? "black" : "white",
                              }}
                              {...item}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}