import React from "react";
import ProfileSidebar from "./components/ProfileSidebar";
import Header from "./HomeComp/Header";
import { useSelector } from "react-redux";
import { N_getActivityReport } from "./redux/helpers/api_functions_new";

export default function ActivityLog(props) {
  const [activitydata, renderActivityData] = React.useState([]);
  const { user } = useSelector((state) => state.AuthReducer);
  const { webData } = useSelector((state) => state.websiteDBReducer);
  React.useEffect(() => {
    N_getActivityReport(user?.params ? user.params.user_id : user.user_id).then(
      (data) => {
        // console.log("AL:", data);
        renderActivityData(data.params.activity_log);
      }
    );
  }, []);

  return (
    <div>
      <Header {...props} />
      <div className="col-lg-12">
      
          {/* <ProfileSidebar /> */}
        
        <div className="main-content">
          <div className="mt-3">
            <h4 className="mb-3">Activity Logs</h4>

            <div className="card">
              <div className="card-header">All Activity Logs</div>
              <div className="card-body">
                {activitydata == 1 ? (
                  <div class="row">
                    <div class="col-lg-8">
                      <div class="error-msg-wrapper">
                        <div class="error-msg">
                          <h2>Opps! No record found</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="mega_menu_list--">
                  {activitydata
                    ? activitydata.map((item) => (
                        <div className="hero_div d-flex justify-content-around">
                          <div className="">
                            <div className="text-muted">Date</div>
                            {new Date(item.createdAt).toLocaleString()}
                          </div>
                          <div className="">
                            <div className="text-muted">IP</div>

                            <div className="">{item.ip_address}</div>
                          </div>
                          <div className="">
                            <div className="text-muted">Browser</div>

                            <div className="">{item.browser_info}</div>
                          </div>
                          <div className="">
                            <div className="text-muted">OS</div>

                            <div className="">{item.sys_info}</div>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
