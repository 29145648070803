import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./HomeComp/Header";
import AssetSidebar from "./components/AssetSidebar";
import "./sidebar.css";
import { getRound } from "./redux/helpers/helper_functions";
import { Link } from "react-router-dom/cjs/react-router-dom";
import {
  N_DepositUpdate,
  N_transferAmonut,
  N_WalletDepositUpdate,
} from "./redux/helpers/api_functions_new";
import useHumanReadableNumber from "../hooks/useHumanReadableNumber";
import { BiRefresh } from "react-icons/bi";
import BalanceShimmer from "./components/shimmer/BalanceShimmer";
import toast from "react-hot-toast";
import { getUserBalance } from "./redux/actions/coinDBAction";
export default function FundingMain() {
  const humanReadable = useHumanReadableNumber();
  const dispatch = useDispatch();
  const formatNumber = useHumanReadableNumber();
  function formatNumberWithCommas(number, locales = "en-US", options = {}) {
    return new Intl.NumberFormat(locales, options).format(number);
  }
  const { wallet, coins, wallet_loading } = useSelector(
    (state) => state.coinDBReducer
  );
  const { user } = useSelector((state) => state.AuthReducer);
  const [total_usdt, setTotalUsdt] = useState(0);
  const [pnl_selected, setPnlSelected] = useState("daily");
  const [totalPnl, setTotalPnl] = useState({
    daily: 0,
    weekly: 0,
    monthly: 0,
    overall: 0,
  });
  const [from, setFrom] = useState("");
  const [loading, setLoading] = useState(wallet_loading);
  const dropdownRef = useRef(null);
  const [to, setTo] = useState("");
  const [refreshFund, setRefreshFund] = useState(false);
  const [symbol, setSymbol] = useState("");
  const [amount, setAmount] = useState(0);
  const [hide, setHide] = useState(false);
  const [search, setSearch] = useState("");
  const [wallet_details, setwalletDetails] = useState([]);

  useEffect(() => {
    if (!wallet_loading) {
      function getWallets() {
        setLoading(true);
        let total = 0;
        let total_pnl = {
          daily: 0,
          weekly: 0,
          monthly: 0,
          overall: 0,
        };
        let final_wallet_data =
          wallet &&
          Object.keys(wallet)?.map((key, i) => {
            const item = wallet[key];
            let wallet_type = item?.symbol.toUpperCase();
            // let usdt_val = item?.usdtBalance;
            let usdt_val = item?.total_combine_balance || 0;

            let usdt_locked_val = item?.locked * item?.usdtPrice;
            total = total + parseFloat(usdt_val);
            total_pnl.daily += item?.PNL24H;
            total_pnl.weekly += item?.PNL7D;
            total_pnl.monthly += item?.PNL30D;
            total_pnl.overall += item?.PNL;
            return {
              id: item?._id,
              icon: item?.icon,
              symbol: wallet_type,
              name: item?.name,
              status: item?.status,
              locked:
                Math.round(item?.locked * 10000) / 10000 != 0
                  ? Math.round(
                      Math.abs(item?.locked ? item?.locked : 0) * 10000
                    ) / 10000
                  : Math.round(item?.locked * 100000000) / 100000000,
              address: item?.wallet_address,
              balance:
                Math.round(item?.balance * 10000) / 10000 != 0
                  ? Math.round(item?.balance * 10000) / 10000
                  : Math.round(item?.balance * 100000000) / 100000000,
              p2pbalance: item?.p2p_balance ? item?.p2p_balance : 0,
              p2p_locked: item?.p2p_locked ? item?.p2p_locked : 0,
              avl_balance:
                Math.round(item?.balance * 10000) / 10000 != 0
                  ? Math.round(item?.balance * 10000) / 10000
                  : Math.round(item?.balance * 100000000) / 100000000,
              usdt_total_balance: getRound(usdt_val),
              usdt_locked_balance: getRound(usdt_locked_val),
              pnl: {
                daily: item?.PNL24H,
                weekly: item?.PNL7D,
                monthly: item?.PNL30D,
                overall: item?.PNL,
              },
            };
          });
        setwalletDetails(final_wallet_data);
        setTotalPnl(total_pnl);
        setTotalUsdt(getRound(total) || 0);
        setLoading(false);
      }
      getWallets();
    }
  }, [wallet, coins, wallet_loading]);

  const coin_data =
    coins &&
    Object.values(coins).map((item) => {
      return <option value={item.symbol}>{item.symbol}</option>;
    });

  useEffect(() => {
    dispatch(getUserBalance(user?.params ? user.params.user_id : user.user_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    N_DepositUpdate(user?.params ? user.params.user_id : user.user_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // Handle clicking outside of dropdown to close it
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        document.getElementById("pnldropdown").classList.remove("show");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  const walletsdata = useMemo(() => {
    return (
      wallet_details &&
      wallet_details.filter((products) => {
        return products.symbol.includes(search);
      })
    );
  }, [wallet_details, search]);
  const pnlOptions = {
    daily: "24H",
    weekly: "7D",
    monthly: "30D",
  };
  return (
    <>
      <Header />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="container">
            <div className="marginTop">
              <div className="account_page">
                <div className="jambotron box-shadown-dark">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="page-content-title">
                      <h3 className="">Fund Account</h3>
                    </div>
                    <div
                      className="d-flex gap-2 align-items-center cursor_pointer"
                      onClick={(e) => {
                        setRefreshFund(true);
                        N_WalletDepositUpdate(
                          user?.params ? user.params.user_id : user.user_id
                        ).then(() => {
                          dispatch(
                            getUserBalance(
                              user?.params ? user.params.user_id : user.user_id
                            )
                          );
                          toast.success("Balance successfully refreshed!");
                          setTimeout(() => {
                            setRefreshFund(false);
                          }, 500);
                        });
                      }}
                    >
                      <BiRefresh
                        className={refreshFund ? "refresh-spin" : ""}
                        size={30}
                      />
                      <span>Refresh</span>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="flex_row mb-4">
                      <div className="">
                        <span className="assetsUi">
                          {isNaN(total_usdt) || refreshFund || loading ? (
                            <BalanceShimmer />
                          ) : (
                            <>
                            <span className="fs-4 fw-normal">
                              {`${formatNumberWithCommas(total_usdt)} `}
                            </span>{" "}<span className="fs-3">USDT</span>
                            </>
                          )}
                        </span>

                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="col-4">
                        <div>
                          <h3 className="text-lgray">Today's PnL</h3>
                        </div>
                        <div>
                          <h6
                            className={`${
                              +totalPnl?.daily >= 0
                                ? "text-lgray"
                                : "text-danger"
                            }`}
                          >
                            {+totalPnl?.daily >= 0 && "+"}
                            {totalPnl?.daily?.toFixed(2)} USDT
                          </h6>
                        </div>
                      </div>
                      <div className="col-4">
                        <div>
                          <h3 className="text-lgray">7D Cumulative PnL</h3>
                        </div>
                        <div>
                          <h6
                            className={`${
                              +totalPnl?.weekly >= 0
                                ? "text-lgray"
                                : "text-danger"
                            }`}
                          >
                            {+totalPnl?.weekly >= 0 && "+"}
                            {totalPnl?.weekly?.toFixed(2)} USDT
                          </h6>
                        </div>
                      </div>
                      <div className="col-4">
                        <div>
                          <h3 className="text-lgray">30D Cumulative PnL</h3>
                        </div>
                        <div>
                          <h6
                            className={`${
                              +totalPnl?.monthly >= 0
                                ? "text-lgray"
                                : "text-danger"
                            }`}
                          >
                            {+totalPnl?.monthly >= 0 && "+"}
                            {totalPnl?.monthly?.toFixed(2)} USDT
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex mb-5 mt-3">
                  <Link to="/AssetsDeposit" className="login_btn  me-2">
                    Deposit
                  </Link>
                  <Link to="/exchange/btc-usdt" className="login_btn  me-2">
                    Buy Crypto
                  </Link>{" "}
                  <Link to="/AssetsWithdraw" className="login_btn ">
                    Withdraw
                  </Link>
                </div>

                <div className="">
                  <div className="row">
                    <div className="activity_panel mb-4">
                      <div className="search_input_grp">
                        <div class="input-group">
                          <span class="input-group-text">
                            <i class="fa-solid fa-magnifying-glass"></i>
                          </span>
                          <input
                            type="text"
                            placeholder="Search"
                            class="form-control text-start"
                            onChange={(e) => {
                              setSearch(e.target.value.toUpperCase());
                            }}
                          />
                        </div>
                      </div>

                      <div className="">
                        <div class="form-check bal_theme  d-flex justify-content-center gap-2 align-items-center ">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="inlineCheckbox1"
                            checked={hide}
                            onClick={(e) => {
                              setHide(!hide);
                            }}
                          />
                          <label
                            class="form-check-label mt-1"
                            for="inlineCheckbox1"
                          >
                            Hide 0 balances
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-lg-12">
                    <div className="alert alert-success">
                      <div className="d-flex">
                        <div>
                          <i class="fa-regular fa-circle-check text-green me-2"></i>
                        </div>
                        <div className="text-lighter">
                          {" "}
                          To trade tokens, click Transfer to move the assets
                          from your Funding Account to your Trading Account.
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="overflow-auto mt-3  asset_table p-3">
                  <div className="">
                    <table className="table  history-p2p-tbl">
                      <thead>
                        <tr>
                          <th>Coin</th>
                          {/* <th>Total</th> */}
                          <th>Amount | Value</th>
                          <th>Avail. | Frozen</th>
                          <th>P2P Locked</th>
                          <th>
                            PnL
                            <div class="btn-group ms-2" ref={dropdownRef}>
                              <button
                                type="button"
                                className="btn btn-outline-secondary py-0 dropdown-toggle bg-white text-lgray"
                                style={{
                                  minWidth: "60px",
                                  maxWidth: "60px",
                                }}
                                onClick={() =>
                                  document
                                    .getElementById("pnldropdown")
                                    .classList.toggle("show")
                                }
                              >
                                {pnlOptions?.[pnl_selected]}{" "}
                              </button>
                              <div
                                id="pnldropdown"
                                className="p2pDropdown dropdown-menu"
                                style={{
                                  top: "0px",
                                  left: "0px",
                                  minWidth: "60px",
                                  maxWidth: "60px",
                                  position: "absolute",
                                  willChange: "transform",
                                  transform: "translate3d(0px, 23px, 0px)",
                                }}
                              >
                                {Object.keys(pnlOptions).map((key) => (
                                  <button
                                    className="dropdown-item text-muted py-0 select_options"
                                    type="button"
                                    onClick={() => {
                                      document
                                        .getElementById("pnldropdown")
                                        .classList.toggle("show");
                                      setPnlSelected(key);
                                    }}
                                  >
                                    {pnlOptions?.[key]}
                                  </button>
                                ))}
                              </div>
                            </div>
                          </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {walletsdata ? (
                          walletsdata.map((item) => {
                            if (!hide || item?.balance > 0)
                              return (
                                <tr>
                                  <td>
                                    <div class="d-flex align-items-center">
                                      <div>
                                        <img
                                          src={item?.icon}
                                          alt="coin symbole"
                                          class="thumb_img"
                                        />
                                      </div>
                                      <div class="product_name">
                                        <div class="mb-0 text-muted ">
                                          {item?.symbol}
                                        </div>
                                        <div class=" fs-12"> {item?.name}</div>
                                      </div>{" "}
                                    </div>
                                  </td>
                                  <td>
                                    <div class="mb-0 ">
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {!item?.balance ? (
                                          "0.00"
                                        ) : (
                                          <>
                                            {formatNumberWithCommas(
                                              item?.balance
                                            )}{" "}
                                            {/* {item.symbol} */}
                                          </>
                                        )}
                                      </p>

                                      <p
                                        style={{
                                          fontSize: "12px",
                                          marginBottom: "0px",
                                          color: "#878787",
                                        }}
                                      >
                                        {!item?.usdt_total_balance ? (
                                          "0.00"
                                        ) : (
                                          <>{item.usdt_total_balance} USD</>
                                        )}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="mb-0 ">
                                      <p
                                        style={{
                                          fontSize: "14px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {!item?.locked ? "0.00" : item.locked}
                                        {/* {item.symbol} */}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {!item?.usdt_locked_balance ? (
                                          "0.00"
                                        ) : (
                                          <>{item.usdt_locked_balance} USD</>
                                        )}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <p
                                      class="mb-0 "
                                      style={{ fontSize: "14px" }}
                                    >
                                      {item?.p2p_locked
                                        ? item?.p2p_locked
                                        : "0.00"}{" "}
                                      {/* {item.symbol} */}
                                    </p>
                                  </td>
                                  <td>
                                    <div class="mb-0 ">
                                      <p
                                        className={`${
                                          +item?.pnl?.[pnl_selected] >= 0
                                            ? ""
                                            : "text-danger"
                                        }`}
                                        style={{
                                          fontSize: "14px",
                                          marginBottom: "0px",
                                        }}
                                      >
                                        {+item?.pnl?.[pnl_selected] >= 0 && "+"}
                                        {item?.pnl?.[pnl_selected]?.toFixed(
                                          2
                                        )}{" "}
                                        USDT
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <Link
                                      to="/AssetsDeposit"
                                      class="anchor_link text-green"
                                    >
                                      Deposit
                                    </Link>
                                    <div class="verticle_line"></div>
                                    <Link
                                      to="/AssetsWithdraw"
                                      class="anchor_link text-green"
                                    >
                                      Withdraw
                                    </Link>
                                  </td>
                                </tr>
                              );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan={9}
                              className="text-center border-bottom-0 text-muted"
                            >
                              <img
                                alt="no data found"
                                src="/img/no-data.png"
                                className="no-data-found"
                              />
                              <p>No records</p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div class="modal fade" tabindex="-1" id="transfer_modal">
          <div class="modal-dialog  modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">Transfer</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <label className="mt-2">from</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setFrom(e.target.value);
                  }}
                >
                  <option selected> Please Select</option>
                  <option value="funding">Funding Account</option>
                </select>
                <label className="mt-2">to</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setTo(e.target.value);
                  }}
                >
                  <option selected> Please Select</option>
                  <option value="p2p">p2p Account</option>
                  <option value="margin">margin Account</option>
                  <option value="future">future Account</option>
                </select>
                <label className="mt-2">Coin</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setSymbol(e.target.value);
                  }}
                >
                  <option selected> Please Select</option>
                  {coin_data}
                </select>
                <label className="mt-2">Amount</label>
                <input
                  className="form-control"
                  type="number"
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
                <div className="d-flex mt-3">
                  <button
                    className="btn btn-pink"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                      e.preventDefault();
                      if (from == "funding" && to && symbol && amount > 0) {
                        let type = "add";
                        let wallet_type = symbol;
                        let volume = amount;
                        let action = to;
                        N_transferAmonut(
                          type,
                          wallet_type,
                          user?.params ? user.params.user_id : user.user_id,
                          volume,
                          action
                        ).then((data) => {
                          if (data.status == 200) {
                            toast.success(data.message);
                            setTimeout(() => {
                              document.location.reload();
                            }, 2000);
                          } else {
                            toast.error(data.message);
                          }
                        });
                      } else {
                        toast.error("Fill All Data");
                      }
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
