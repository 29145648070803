import React, { useEffect } from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdVerified } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IoWarning } from "react-icons/io5";
import ChatComp from "./payment-methods/ChatComponent";
import { RaiseDisputeP2p } from "../redux/helpers/api_functions_new";
import { NotificationManager } from "react-notifications";
import toast from "react-hot-toast";

const CreateOrder3 = ({ fetchData, orderResp, orderInfo }) => {
  const history = useHistory();
  useEffect(() => {
    fetchData();
  }, []);
  const raiseDispute = () => {
    RaiseDisputeP2p(orderResp?.orderInfo?._id).then((res) => {
      if (res.status == 200) {
        toast.success(res.message);
        fetchData();
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <>
      <div className="row justify-content-center mt-3">
        {orderInfo?.status == "complete" ? (
          <div className="col-lg-6">
            <div
              style={{
                height:
                  orderResp?.orderInfo?.ticket_status !== "inactive" &&
                  "600px",
                overflow: "auto",
                position: "relative",
              }}
              className="d-flex align-items-center flex-column gray-borders p-3 gap-3 py-4 justify-content-center rounded-3"
            >
              <MdVerified size={70} className="text-green bounce_1 mt-4" />
              <h2>
                {orderInfo?.crypto_amount_receive}{" "}
                {orderInfo?.crypto_currency}
              </h2>
              {
                orderResp?.orderInfo?.type == "sell" ? <h4>Deposited into your Account</h4> : <h4>Deposited into buyer Account</h4>
              }
              <button
                className="btn btn-outline-light px-4  d-flex justify-content-between align-items-center gap-3 mb-2"
                onClick={() => history.push("/profile")}
              >
                <span>Check Wallet</span>
              </button>

            </div>
          </div>
        ) : orderInfo?.status == "admin_complete" ? (
          <div className="col-lg-6">
            <div
              style={{
                height:
                  orderResp?.orderInfo?.ticket_status !== "inactive" &&
                  "600px",
                overflow: "auto",
                position: "relative",
              }}
              className="d-flex align-items-center flex-column gray-borders p-3 gap-3 py-4 justify-content-center rounded-3"
            >
              <MdVerified size={70} className="text-green bounce_1 mt-4" />
              <h2>
                {orderInfo?.crypto_amount_receive}{" "}
                {orderInfo?.crypto_currency}
              </h2>

              {
                orderResp?.orderInfo?.type == "sell" ? <h4>Deposited into your Account</h4> : <h4>Deposited into buyer Account</h4>
              }
              <p>The admin has successfully released the funds to buyer account!</p>
              <button
                className="btn btn-outline-light px-4  d-flex justify-content-between align-items-center gap-3 mb-2"
                onClick={() => history.push("/profile")}
              >
                <span>Check Wallet</span>
              </button>

            </div>
          </div>
        ) : (
          <div className="col-lg-6">
            <div
              style={{
                height:
                  orderResp?.orderInfo?.ticket_status !== "inactive" &&
                  "600px",
                overflow: "auto",
                position: "relative",
              }}
              className="d-flex align-items-center flex-column gray-borders rounded-3 p-3 gap-3 py-4 justify-content-center"
            >
              <IoWarning size={70} className="text-warning bounce_1 mt-4" />
              <h2>
                {orderInfo?.crypto_amount_receive}{" "}
                {orderInfo?.crypto_currency}
              </h2>

              <h4>
                {orderInfo?.status == "reject"
                  ? <>{orderResp?.orderInfo?.type == "sell" ? "Seller Rejected Your Request" : "Buyer Rejected Your Request"}</>
                  : "Order Canceled Due to Time Expired"}
              </h4>
              <p className="m-4 mt-2">
                {" "}
                <strong>Reason:</strong> {orderInfo?.receiver_comment}
              </p>
              {orderResp?.orderInfo?.ticket_status == "inactive" && (
                <button
                  className="login_btn fs-14"
                  onClick={() => raiseDispute()}
                >
                  Raise Dispute
                </button>
              )}
            </div>
          </div>
        )}
        {orderResp?.orderInfo?.ticket_status !== "inactive" && (
          <div
            className="gray-borders rounded-3 col-md-6"
            style={{
              height: "600px",
              overflow: "auto",
              position: "relative",
              scrollbarWidth: "none",
              padding: "0px"
            }}
          >
            <ChatComp orderResp={orderResp} userType={"support"} />
          </div>
        )}
        {/* <h6>Rate Your P2P Experience</h6> */}
      </div>
    </>
  );
};

export default CreateOrder3;
