import { useState } from "react";
import { LuFileCheck2 } from "react-icons/lu";
import { MdFileCopy } from "react-icons/md";
import { FiCheckSquare } from "react-icons/fi";

const CopyBtn = ({ text, textEnable }) => {
    const [copy, setCopy] = useState(false);
    const handleCopy = () => {
        navigator.clipboard.writeText(text).then(() => {
            setCopy(true);
            setTimeout(() => {
                setCopy(false);
            }, 500);
        });
    };

    return (
        <span className="eye-box role-btn" onClick={() => handleCopy()}>
            {!copy ? (
                <>
                    <MdFileCopy className="eye-icon mx-1" />
                    {textEnable && "Copy"}
                </>
            ) : (
                <>
                    <FiCheckSquare className="eye-icon mx-1" />
                    {textEnable && "Copied!"}
                </>
            )}
        </span>
    );
};

export default CopyBtn;