import React from "react";
import "./Modal.css"; // For optional styling
import { round } from "../redux/helpers/Math";

const OrderDetailModal = ({ isOpen, onClose, title, orderDetail }) => {
  // console.log(orderDetail, "openorderopenorder");

  if (!isOpen) return null;

  return (
    <div
      className={`modal fade ${isOpen ? "show d-block" : "d-none"}`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onClick={onClose}
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="modal-content "
          style={{ backgroundColor: "rgb(13, 13, 41)" }}
        >
          {/* Modal Header */}
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="">{title}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            ></button>
          </div>

          {/* Modal Body */}
          <div className="modal-body">
            <div className="my-2 d-flex gap-3 align-items-center">
              <div className=" ">
                {orderDetail?.currency_type?.toUpperCase()}/
                {orderDetail?.compare_currency?.toUpperCase()}
              </div>
              <div
                style={{
                  color: orderDetail?.type == "sell" ? "red" : "green",
                  border: `0.5px solid ${
                    orderDetail?.type == "sell" ? "red" : "green"
                  }`,
                  borderRadius: "4px",
                  padding: "2px 6px",
                }}
              >
                {orderDetail?.type?.toUpperCase()}
              </div>
            </div>
            <div className="row ">
              <div className="col-4 mb-2">
                <div className="text-lgray">Order ID</div>
                <div>{orderDetail?._id}</div>
              </div>

              <div className="col-4 mb-2">
                <div className="text-lgray">Status</div>
                <div>
                  {orderDetail?.order_status === 0 ? "Failed" : "Success"}
                </div>
              </div>

              <div className="col-4 mb-2">
                <div className="text-lgray">Order Execution Time</div>
                <div>
                  {new Date(orderDetail.createdAt).toLocaleTimeString()}
                </div>
              </div>

              <div className="col-4 mb-2">
                <div className="text-lgray">Avg. Price</div>
                <div>{round(orderDetail?.raw_price)} USDT</div>
              </div>

              <div className="col-4 mb-2">
                <div className="text-lgray">Filled</div>
                <div>
                  {orderDetail?.order_status === 1 ? "100 %" : "Failed"}
                </div>
              </div>

              <div className="col-4 mb-2">
                <div className="text-lgray">Total</div>
                <div>
                  {orderDetail?.volume
                    ? Number(orderDetail.volume).toFixed(4)
                    : "0.00"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailModal;
