import React, { useEffect, useState } from "react";
import Header from "./HomeComp/Header";
import ProfileSidebar from "./components/ProfileSidebar";
import { BiError, BiMobileAlt, BiSolidCheckShield } from "react-icons/bi";
import { AiOutlineCamera, AiOutlineCloudUpload } from "react-icons/ai";
import {
  N_checkKYCStatus,
  N_getCountry,
  N_profileData,
  N_uploadID,
} from "./redux/helpers/api_functions_new";
import { useSelector } from "react-redux";
import $ from "jquery";
import { NotificationManager, Notifications } from "react-notifications";
import { useHistory } from "react-router-dom";
import AssetSidebar from "./components/AssetSidebar";
import toast from "react-hot-toast";
// import Footer from "./HomeComp/Footer";

import WebCamera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";

export default function AccountVerification(props) {
  const history = useHistory();
  const { user, profile } = useSelector((state) => state.AuthReducer);
  const [loading, setLoading] = React.useState(true);
  const [countryData, setCountry] = React.useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [isverified, setIsverified] = useState(0);
  const [isverified2, setIsverified2] = useState(0);
  const [usercountry, setUserCountry] = useState("india");
  const [id_no, setIdNo] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [type, setType] = useState("");
  const [personalTab, setPersonalTab] = useState(0);
  const [isSubmitPersonalData, setSubmitPersonalData] = useState(0);
  const [id_verification, setIdVerifocation] = useState(0);
  const [filedata, setFileData] = useState(null);
  const [filedataBack, setFileDataBack] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [selfiedata, setSelfieData] = useState(null);

  useEffect(() => {
    N_checkKYCStatus(user?.params ? user.params.user_id : user.user_id).then(
      (d) => {
        if (d.status === 200) {
          setIsverified2(d?.params?.status);
          if (d?.params.status == 2) {
            setPersonalTab(1);
          } else if (d?.params.status == -1) {
            setIsverified(2);
          } else if (d?.params.status == 1) {
            setIsverified(1);
          }

          if (d?.params.status == 0 || d?.params.status == 3) {
            N_getCountry(
              user?.params ? user.params.user_id : user.user_id
            ).then((d) => {
              if (d.status === 200) {
                setCountry(d?.params.country_data);
              }
            });
          }
        }
        setLoading(false);
      }
    );
  }, []);

  const updatePersonal = (e) => {
    if (id_no == "" || first_name == "" || last_name == "") {
      toast.error("Please fill all the fields");
    }
    e.preventDefault();
    N_profileData(
      user?.params ? user.params.user_id : user.user_id,
      type,
      id_no,
      first_name,
      last_name,
      profile?.email,
      usercountry
    ).then((res) => {
      // console.log(res);
      if (res.status == 200) {
        setSubmitPersonalData(2);
      }
    });
  };
  function dataURItoFile(dataURI) {
    let arr = dataURI.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], "selfie.png", { type: mime });
  }
  const [load, setLoad] = useState(false);
  const updateFile = async (e) => {
    try {
      setLoad(true);
      const selfieFile = dataURItoFile(selfiedata);
      const res = await N_uploadID(
        selfieFile,
        filedata,
        filedataBack,
        "docf",
        user?.params ? user.params.user_id : user.user_id
      );

      // console.log(res, "<-----------------------res")
      if (res && res.status === 200) {
        // console.log("res", res);
        toast.success(res.data.message);

        window.location.reload();
        // history.push("/profile");
      } else if (res && res.status === 400) {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log("Upload Error:", error);
      toast.error("Something went wrong!");
    } finally {
      setLoad(false);
    }
  };

  function renderFile(file, location) {

    var reader = new FileReader();
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        $(location).attr("src", e.target.result);
      };
    }
  }

  useEffect(() => {
    renderFile(filedata, "#front-preview");
    renderFile(filedataBack, "#back-preview");
  }, [filedata, filedataBack])

  function readURL(input) {
    const file = input.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB limit

    if (file && file.size > maxSize) {
      return toast.error("File size exceeds 2MB limit.");
    }
    if (file) {
      setFileData(file);
    }
  }

  function readURLBack(input) {
    const file = input.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB limit

    if (file && file.size > maxSize) {
      return toast.error("File size exceeds 2MB limit.");
    }
    if (file) {
      setFileDataBack(file);
    }
  }

  const handleSubmitImages = async () => {
    if (!filedata || !filedataBack) {
      toast.error("Please upload document first!");
    } else {
      setIdVerifocation(3);
    }
  }

  return (
    <>
      <Header {...props} />

      <div className="s-layout">
        <div class="s-layout__sidebar">
          <AssetSidebar />
        </div>

        <main className="s-layout__content">
          <div className="dashboard_header">
            <h3 className="header_title"><spabn className="d-none d-md-block">Individual Identity</spabn> Verification</h3>
          </div>
          <div className="container-fluid">
            <div className="account_page">
              <div class="row px-2">
                <div class="col-lg-6 ">
                  <div style={{ height: "100%" }} className="jambotron-outline">
                    <h4 className="h5 fw-bold mb-0">Basic Verification</h4>
                    <div className="text-white mt-3">Account Privileges</div>
                    <div className="mt-4 text-lgray d-flex justify-content-between">
                      <div>Daily deposit limit</div>
                      <div>5,000 USDT</div>
                    </div>
                    <div className="mt-4 text-lgray d-flex justify-content-between">
                      <div>Monthly deposit limit</div>
                      <div> 50,000 USDT</div>
                    </div>
                    <div className="mt-4 text-lgray d-flex justify-content-between">
                      <div>Daily withdrawal limit</div>
                      <div>5,000 USDT</div>
                    </div>
                    <div className="mt-4 text-lgray d-flex justify-content-between">
                      <div>Trade</div>
                      <div>
                        {" "}
                        <i class="fa-solid fa-check text-green"></i>
                      </div>
                    </div>
                    <div className="mt-4 text-lgray d-flex justify-content-between">
                      <div>Verification Status</div>
                      <div className="d-flex gap-2">
                        {isverified2 == 0 ? (
                          <span className="text-warning">
                            Pending{" "}
                            <i class="fa-solid fa-hourglass-half text-yellow spinner-loader mx-2"></i>
                          </span>
                        ) : isverified2 == 2 ? (
                          <span className="text-red">
                            Rejected <i class="fa-solid fa-xmark text-red"></i>
                          </span>
                        ) : isverified2 == 1 ? (
                          <span className="text-green">
                            Verified{" "}
                            <i class="fa-solid fa-check text-green"></i>
                          </span>
                        ) : isverified2 == -1 ? (
                          <span className="text-warning">
                            Request Pending{" "}
                            <i class="fa-solid fa-hourglass-half text-yellow spinner-loader mx-2"></i>
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="d-grid col-12 my-4">
                      {isverified2 == 0 || isverified2 == 2 ? (
                        <button
                          data-bs-toggle="modal"
                          data-bs-target="#kyc_modal"
                          className="btn login_btn btn-lg  w-full"
                        >
                          Verify
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mt-2 mt-lg-0 identity_accordion">
                  <div style={{ height: "100%" }} class="jambotron-outline">
                    <div class="account_page_subheading mb-2">FAQs</div>
                    <div
                      class="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingOne">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                          >
                            What is identity verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingOne"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            Identity verification is a crucial due diligence
                            process that verifies customer information and
                            background to ensure compliance with anti-money
                            laundering (AML) and combating the financing of
                            terrorism (CFT) regulations. This process enhances
                            account security and reduces the risk of fraudulent
                            and illegal activities.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingTwo">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                          >
                            Why do I need to complete identity verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingTwo"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            {" "}
                            Identity verification is crucial to safeguard the
                            security and integrity of your account. By verifying
                            your personal information and background, we can
                            effectively prevent unauthorized use of your
                            identity for fraudulent or illegal purposes.
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="flush-headingThree">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseThree"
                            aria-expanded="false"
                            aria-controls="flush-collapseThree"
                          >
                            Which documents and information are required for
                            identity verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-headingThree"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            For identity verification, you usually need to
                            provide proof of identity (e.g., passport, driver's
                            license, or ID card) and personal photographs.
                            Additional documents may be required in certain
                            cases. Rest assured that your information is
                            protected and used only for legal and compliance
                            purposes. For any questions about the identity
                            verification requirements of specific institutions,
                            contact them for accurate information.
                          </div>
                        </div>
                      </div>

                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button px-0 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapse4"
                            aria-expanded="false"
                            aria-controls="flush-collapse4"
                          >
                            Where can I find help for problems with identity
                            verification?
                          </button>
                        </h2>
                        <div
                          id="flush-collapse4"
                          class="accordion-collapse collapse"
                          aria-labelledby="flush-collapse4"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div class="accordion-body">
                            If you encounter any problems with identity
                            verification, you can get help by joining our
                            Telegram group. Our customer service team will be
                            happy to answer any questions you may have and
                            provide further support. Please provide us with
                            information about your problems so that we can help
                            resolve your issue as quickly as possible.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {/*=========== Modal of deposit menu==================== */}

      <div
        id="kyc_modal"
        class="modal fade p-0"
        tabindex="-1"
        data-backdrop="static"
        style={{padding:'0px 0px 0px 0px'}}
      >
        <div class="modal-dialog  modal-lg modal-dialog-lg modal-dialog-centered p-0">
          <div
            class="modal-content"
            style={{ overflow: "auto" }}
          >
            <div class="modal-header border-0">
              <h4 class="modal-title text-white">Identity Verification</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="" style={{ minHeight: "375px" }}>
                {/* Choose Types of verfication */}
                {personalTab == 0 ? (
                  <>
                    <div
                      id="verification_type"
                      className={isSubmitPersonalData == 0 ? "" : "d-none"}
                    >
                      <div class="mb-3 cstm_form">
                        <label className="text-muted fs-12 mb-1">
                          <span className="text-red">*</span>Country/Region
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          onChange={(e) => {
                            setUserCountry(e.target.value);
                          }}
                        >
                          {countryData
                            ? countryData.map((country) => (
                              <option
                                value={country}
                                selected={
                                  country == "India" ? "selected" : ""
                                }
                              >
                                {country}
                              </option>
                            ))
                            : ""}
                        </select>
                      </div>

                      <div className="mb-5">
                        <label className="text-muted fs-12">
                          <span className="text-red">*</span>Verify With
                        </label>
                        {/* <a href="/AssetsDeposit" className="text-dark"> */}
                        <div
                          class="modal_select_options p-3 mt-1"
                          onClick={(e) => {
                            setSubmitPersonalData(1);
                            setType("passport");
                          }}
                        >
                          <img
                            src="/img/passport.svg"
                            alt="coin symbole"
                            class="deposit_imgs filter_gray"
                          />
                          <div class="content_div">
                            <div class="mb-0">Passport</div>
                          </div>{" "}
                          <span>
                            <i class="fa-solid fa-arrow-right-long ms-1"></i>
                          </span>
                        </div>
                        {/* </a> */}
                        {/* <a href="/AssetsFiat" className="text-dark"> */}
                        <div
                          class="modal_select_options p-3"
                          onClick={(e) => {
                            setSubmitPersonalData(1);
                            setType("id");
                          }}
                        >
                          <img
                            src="/img/id-card.svg"
                            alt="coin symbole"
                            class="deposit_imgs filter_gray"
                          />
                          <div class="content_div">
                            <div class="mb-0">ID Card</div>
                          </div>{" "}
                          <span>
                            <i class="fa-solid fa-arrow-right-long ms-1"></i>
                          </span>
                        </div>
                        {/* </a> */}

                        {/* <a href="/AssetsFiat" className="text-dark"> */}
                        <div
                          class="modal_select_options p-3"
                          onClick={(e) => {
                            setSubmitPersonalData(1);
                            setType("driverlicense");
                          }}
                        >
                          <img
                            src="/img/dl.svg"
                            alt="coin symbole"
                            class="deposit_imgs filter_gray"
                          />
                          <div class="content_div">
                            <div class="mb-0">Driver's License</div>
                          </div>{" "}
                          <span>
                            <i class="fa-solid fa-arrow-right-long ms-1"></i>
                          </span>
                        </div>
                        {/* </a> */}
                      </div>
                    </div>

                    {/* Fill Personal Info and ID Number */}
                    <div
                      className={isSubmitPersonalData == 1 ? "" : "d-none"}
                      id="personal_info"
                    >
                      <div class="steps_div">
                        <div class="d-flex align-items-center">
                          <div class="circle_no active">1</div>
                          <span class="text-lgray">Personal <span className="d-none d-md-block">Information</span></span>
                        </div>
                        <div class="adjacent_line"></div>
                        <div class="d-flex align-items-center">
                          <div class="circle_no">2</div>
                          <span class="text-lgray">ID <span className="d-none d-md-block">Verification</span></span>
                        </div>
                        <div class="adjacent_line"></div>
                        <div class="d-flex align-items-center">
                          <div class="circle_no">3</div>
                          <span class="text-lgray">Face <span className="d-none d-md-block">Verification</span> </span>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-6">
                          <div className="cstm_form">
                            <div class="form-group">
                              <label for="first_name">First Name</label>
                              <input
                                type="text"
                                class="form-control"
                                id="first_name"
                                name="first_name"
                                onChange={(e) => {
                                  setFirstName(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <div className="cstm_form">
                            <div class="form-group">
                              <label for="last_name">Last Name</label>
                              <input
                                type="text"
                                class="form-control"
                                id="last_name"
                                name="last_name"
                                onChange={(e) => {
                                  setLastName(e.target.value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        {" "}
                        <div class="form-group">
                          <label for="id_no">ID Number</label>
                          <input
                            type="text"
                            class="form-control"
                            id="id_no"
                            name="id_no"
                            onChange={(e) => {
                              setIdNo(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <p className="fs-12 text-lgray mb-3">
                        Please read the User Identity Verification Statement and
                        Privacy Policy to understand how your personal
                        information and biometric data is collected and used for
                        verification purposes. Click the Continue button to
                        indicate that you have read and agree to the statement
                        and policy.
                      </p>
                      <div className="d-grid">
                        <button
                          className="btn login_btn my-2"
                          onClick={(e) => {
                            updatePersonal(e);
                          }}
                        >
                          Continue
                        </button>
                      </div>
                    </div>

                    {/* info Submitted succesfully*/}
                    <div
                      className={isSubmitPersonalData == 2 ? " mt-3" : "d-none"}
                      id="submitted_successfully"
                    >
                      <div className="text-center">
                        <img src="/img/submitted.svg" alt="submitted" />
                      </div>
                      <h4 class="big_heading text-center my-4">
                        Personal Information Submitted
                      </h4>

                      <div className="d-grid">
                        <button
                          className="btn login_btn my-2"
                          onClick={() => {
                            setPersonalTab(1);
                          }}
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </>
                ) : null}
                {personalTab == 1 ? (
                  <>

                    <div class="steps_div">
                      <div class="d-flex align-items-center">
                        <div class="circle_no">1</div>
                        <span class="text-lgray">Personal <span className="d-none d-md-block">Information</span> </span>
                      </div>

                      <div class="adjacent_line"></div>
                      <div class="d-flex align-items-center">
                        <div class={`circle_no ${id_verification != 3 ? "active" : ""}`}>2</div>
                        <span class="text-lgray">
                          ID <span className="d-none d-md-block">Verification</span>
                        </span>
                      </div>

                      <div class="adjacent_line"></div>
                      <div class="d-flex align-items-center">
                        <div class={`circle_no ${id_verification == 3 ? "active" : ""}`}>3</div>
                        <span class="text-lgray">
                          Face <span className="d-none d-md-block">Verification</span>
                        </span>
                      </div>
                    </div>

                    {/* Start Verification */}
                    <div
                      className={id_verification == 0 ? "" : "d-none"}
                      id="start_verification"
                    >
                      <h4 class="big_heading text-center d-flex justify-content-center">
                        <BiSolidCheckShield className="me-2 text-green" /> Start Verfication
                      </h4>
                      <div className="row mt-3">
                        <div className="col-lg-9 m-auto col-12 mb-3">
                          <div className="jambotron">
                            <p>
                              {" "}
                              This process is designed to verify your identity
                              and protect you from identity theft.
                            </p>

                            <p>Please have your ID ready and click 'Start'. </p>
                          </div>

                          <div className="d-flex align-items-center gap-1 my-3">
                            <div>
                              {" "}
                              <BiError className="text-warning fs-4" />{" "}
                            </div>
                            <div>
                              This page will expire in a few minutes if you do
                              not start.
                            </div>
                          </div>

                          <div className="d-grid mb-2">
                            <button
                              className="btn btn-primary btn-lg"
                              onClick={(e) => {
                                setIdVerifocation(2);
                              }}
                            >
                              Start
                            </button>
                          </div>
                          <p className="small text-muted">
                            By clicking “Start” you consent to Jumio collecting
                            and disclosing your biometric data pursuant to its{" "}
                            <a href="#" className="text-info">
                              Privacy Policy
                            </a>
                            .
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* choose method to upload Documents */}
                    <div
                      className={id_verification == 1 ? "d-none" : "d-none"}
                      id="choose_method"
                    >
                      <div class="steps_div">
                        <div class="d-flex align-items-center">
                          <div class="circle_no">1</div>
                          <span class="text-lgray">Personal Information</span>
                        </div>

                        <div class="adjacent_line"></div>
                        <div class="d-flex align-items-center">
                          <div class="circle_no">2</div>
                          <span class="text-lgray">
                            ID Verification & Face Verification
                          </span>
                        </div>

                        <div class="adjacent_line"></div>
                        <div class="d-flex align-items-center">
                          <div class="circle_no active">3</div>
                          <span class="text-lgray">
                            Face Verification
                          </span>
                        </div>
                      </div>

                      <h4 class="big_heading text-center">
                        Choose an Upload Method
                      </h4>
                      <div className="row mt-3">
                        <div className="col-lg-9 m-auto col-12 mb-3">
                          {/* <div className=" mb-2">
                        <a href="#" className="upload_btn active">
                          <BiMobileAlt className="me-1 fs-4" /> Continue On
                          mobile
                        </a>
                      </div>
                      <div className=" mb-2">
                        <a href="#" className="upload_btn">
                          <AiOutlineCamera className="me-1 fs-4" /> Take photo
                          using webcam
                        </a>
                      </div> */}
                          <div className=" mb-2">
                            <button
                              className="upload_btn"
                              onClick={(e) => {
                                setIdVerifocation(2);
                              }}
                            >
                              <AiOutlineCloudUpload className="me-1 fs-4" />{" "}
                              Upload file from this device
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Upload front of ID Card */}
                    <div
                      className={id_verification == 2 ? "" : "d-none"}
                      id="upload_doc"
                    >

                      <div className="row mt-3">
                        <div className="col-lg-6 m-auto col-12 mb-3">
                          <div className="jambotron border-dashed text-center">
                            <div className="dummy-card-photo">
                              {" "}
                              <img
                                src="/img/placeholder-id-front.svg"
                                className="img-fluid"
                                alt="placeholder-id-front"
                                id="front-preview"
                                style={{ height: "150px" }}
                              />
                            </div>
                            <h5 class="big_heading text-center mt-3" style={{ fontSize: "16px" }}>
                             <span className="d-none d-md-block">Upload</span>  front of identity card
                            </h5>
                            <p style={{ fontSize: "14px", lineHeight: "20px", marginTop: "10px" }}>
                              Upload a color image of the entire document.
                              Screenshots are not allowed. JPG, JPEG or PNG
                              format only.
                            </p>
                            <div className="d-grid">
                              <input
                                id="upload_front_adhar"
                                type="file"
                                accept="image/*"
                                onChange={(e) => {                                    
                                  readURL(e);
                                }}
                                className="form-control kyc_image-- mt-3"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 m-auto col-12 mb-3">
                          <div className="jambotron border-dashed text-center">
                            <div className="dummy-card-photo">
                              {" "}
                              <img
                                src="/img/placeholder-id-front.svg"
                                className="img-fluid"
                                alt="placeholder-id-front"
                                id="back-preview"
                                style={{ height: "150px" }}
                              />
                            </div>
                            <h5 class="big_heading text-center mt-3" style={{ fontSize: "16px" }}>
                            <span className="d-none d-md-block"> Upload </span> back of identity card
                            </h5>
                            <p style={{ fontSize: "14px", lineHeight: "20px", marginTop: "10px" }}>
                              Upload a color image of the entire document.
                              Screenshots are not allowed. JPG, JPEG or PNG
                              format only.
                            </p>
                            <div className="d-grid">
                              <input
                                id="upload_front_adhar"
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  readURLBack(e);
                                }}
                                className="form-control kyc_image-- mt-3"
                              />

                            </div>
                          </div>
                        </div>
                        <div className="d-grid">
                          <button
                            className="btn login_btn my-2"
                            onClick={() => {
                              handleSubmitImages()
                            }}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Preview of photo */}
                    <div
                      className={id_verification == 3 ? "" : "d-none"}
                      id="preview_photo"
                    >
                      <div className="row mt-3">
                        <div className="col-lg-9 m-auto col-12 mb-3">
                          <div className="jambotron border-dashed">
                            <div className="photo-preview">
                              {" "}
                              {showCamera || !selfiedata ? (
                                <div
                                  className="camera-photo-view"
                                  style={{ width: "80%" }}
                                >
                                  <WebCamera
                                    idealFacingMode="user"
                                    isImageMirror={true}
                                    onTakePhoto={dataUri => {
                                      setSelfieData(dataUri);
                                      setShowCamera(false);
                                    }}
                                  />
                                </div>
                              )
                                : (
                                  <img
                                    onClick={() => setShowCamera(true)}
                                    src={selfiedata}
                                    className="img-fluid cursor_pointer"
                                    id="check_photo"
                                    alt="placeholder-id-front"
                                    style={{ width: "80%" }}
                                  />
                                )}
                            </div>
                            <h4 class="big_heading text-center">Check Photo</h4>

                            <ul className="my-3">
                              <li>
                                Have you checked if your ID is supported?{" "}
                              </li>
                              <li>Can you read the details?</li>
                              <li>Can you see the whole ID?</li>
                            </ul>

                            <div className="text-center">
                              <button
                                className="btn-outline-pink my-2 me-2 px-3"
                                onClick={(e) => {
                                  setIdVerifocation(2);
                                }}
                                type="button"
                              >
                                Back
                              </button>
                              <button
                                disabled={!selfiedata}
                                className="btn-outline-pink px-3"
                                onClick={(e) => {
                                  updateFile(e);
                                }}
                                type="button"
                              >
                                {
                                  !load ? "Submit" : <> Loading ... </>
                                }
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              {/* <div className="d-grid">
                <a href="#" className="btn btn-warning btn-lg">
                  Continue
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
