import { useState } from "react";

const FAQAccordion = ({ faqData }) => {
  const [showIndex, setShowIndex] = useState(null);

  return (
    faqData?.map((item, index) => (
      <div className="accordion" id="accordionExample">
        <div className="accordion-item" key={index}>
          <h2 className="accordion-header" id={`heading${index}`}>
            <button
              style={{ background: "transparent" }}
              className={`accordion-button  ${showIndex === index ? "" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded={showIndex === index}
              aria-controls={`collapse${index}`}
              onClick={() => setShowIndex(showIndex === index ? null : index)}
            >
              <i className="fa-regular fa-rectangle-list me-2 text-lgray fs-12"></i>
              {item.question}
            </button>
          </h2>
          <div
            id={`collapse${index}`}
            className={`accordion-collapse collapse ${showIndex === index ? "show" : ""}`}
            aria-labelledby={`heading${index}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p>{item.answer}</p>
            </div>
          </div>
        </div>
      </div>
    ))

  );
};

export default FAQAccordion;
