import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { N_addBinficiary, N_getBank } from "./redux/helpers/api_functions_new";
import toast from "react-hot-toast";

export default function InrDeposite(props) {
  const [loading, setLoading] = React.useState(true);
  const [accountNo, setAccountNo] = React.useState();
  const [reaccountNo, setReAccountNo] = React.useState();
  const [account_name, setAccountName] = React.useState();
  const [selectedBank, setSelectedBank] = React.useState();
  const [bankIFCS, setIFSC] = React.useState([]);
  const [banklist, setBank] = React.useState();
  const [accountType, setAccountType] = React.useState();
  const { user } = useSelector((state) => state.AuthReducer);
  useEffect(() => {
    N_getBank(user?.params ? user.params.user_id : user.user_id)
      .then((d) => {
        if (d.status === 200) {
          setBank(d.params.bank);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (accountNo !== reaccountNo) {
      toast.error("Account Number not matched!");
    } else {
      if (accountNo == "" && bankIFCS == "") {
        toast.error("Fill all field!");
      } else {
        setLoading(true);
        N_addBinficiary(
          accountNo,
          reaccountNo,
          selectedBank,
          account_name,
          bankIFCS,
          accountType,
          user?.params ? user.params.user_id : user.user_id
        ).then((d) => {
          // console.log("AddBenificiery", d);
          if (d.status == 200) {
            window.location.reload();
            toast.info(d.message);
          } else {
            toast.error(d.message);
          }
          setLoading(false);
        });
      }
    }
  };
  return (
    <>
      {/* <Header {...props} /> */}
      <div>
        <div className="card">
          <div className="card-header">
            Add your bank account details for IMPS payments
          </div>
          <div className="card-body">
            <div className="row">
              <form
                className="signupform mdfthemetxt"
                method="post"
                id="loginForm"
                autoComplete="off"
                onSubmit={onSubmit}
              >
                <div className="row">                 

                  <div className="col-lg-6">
                    <div className="form-group mb-3">
                      <label for="exampleInputEmail1" className="text-muted">Account Number</label>
                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        placeholder="Enter Account no"
                        value={accountNo}
                        onChange={(e) =>
                          setAccountNo(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          )
                        }
                      />
                      
                    </div>

                    <div className="form-group mb-3">
                      <label for="exampleInputEmail1"  className="text-muted">Re-Enter Account Number</label>
                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        placeholder="Re-Enter Account no"
                        value={reaccountNo}
                        onChange={(e) =>
                          setReAccountNo(
                            e.target.value
                              .replace(/[^0-9.]/g, "")
                              .replace(/(\..*?)\..*/g, "$1")
                          )
                        }
                      />
                      {/* <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.
              </small> */}
                    </div>
                    <div className="form-group mb-3">
                      <label for="exampleInputEmail1" className="text-muted">
                        Account Holder Name
                      </label>
                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        placeholder="Enter Account Name"
                        value={account_name}
                        onChange={(e) => setAccountName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-3">
                      <label for="exampleInputEmail1" className="text-muted">Bank Name</label>
                      <select
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        onChange={(e) => setSelectedBank(e.target.value)}
                      >
                        <option selected>Please Select</option>
                        {banklist?.map((d) => (
                          <option className="">{d.bank_name}</option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group mb-3">
                      <label for="exampleInputEmail1" className="text-muted">Account Type</label>
                      <select
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        onChange={(e) => setAccountType(e.target.value)}
                      >
                        <option selected className="">
                          Please Select
                        </option>
                        <option>Current</option>
                        <option>Saving</option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label for="exampleInputEmail1" className="text-muted">Bank IFSC</label>
                      <input
                        type="text"
                        className="form-control buy-sell-form-bg buy-sell-theme"
                        placeholder="Enter Bank IFSC"
                        value={bankIFCS}
                        onChange={(e) => setIFSC(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <button type="submit" className="btn btn-primary">
                    {loading ? (
                      <i className="loading-icon fas fa-spinner fa-spin mr-2"></i>
                    ) : null}
                    <span id="reg">SUBMIT</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
