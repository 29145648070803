import React from "react";
import Header from "./../HomeComp/Header";
import Footer from "./../HomeComp/Footer";

const ReferralDetail = () => {
  return (
    <>
      <Header />
      <div className="wrap-privacy text-white">
        <div className="terms_border container d-flex flex-column gap-5 py-5">
          <section>
            <h1 className="text-gradient">
              bitcash Referral Program – Earn More, Trade Smarter!
            </h1>
            <h2 className="text-gradient"> Invite. Earn. Expand.</h2>

            <p>
              Turn your network into profits with{" "}
              <b>bitcash’s Referral Program!</b> Every successful referral brings{" "}
              <b>real rewards</b>—help your friends start trading while you both
              earn.
            </p>
          </section>

          <section>
            <h3 className="text-gradient">How to Get Started?</h3>
            <ul className="ms-4">
              <li>
                <b> Sign up or log in</b> to your bitcash account.
              </li>
              <li>
                <b>Get your unique referral link</b> from the{" "}
                <b>Referral Program</b> section.
              </li>
              <li>
                <b>Share your link</b> with friends, family, or trading
                communities.
              </li>
              <li>
                Your referral{" "}
                <b>must complete registration and deposit a minimum of $25</b>{" "}
                to activate rewards.
              </li>
              <li>
                <b>Track your referrals and earnings</b> in real-time from your
                bitcash dashboard.
              </li>
            </ul>
          </section>
          <section>
            <h3 className="text-gradient">How Much Can You Earn?</h3>
            <ul className="ms-4">
              <li>
                <b>On a minimum deposit of $25</b> by the person you refer,{" "}
                <b>
                  both you and your referral will receive $10 in trading credit.
                </b>
              </li>
              <li>
                <b>No referral limits</b>—the more people you refer, the more
                rewards you earn.
              </li>
              <li>
                <b>Fast & Transparent Payouts</b> – Credits are added{" "}
                <b>instantly</b> once the deposit conditions are met.
              </li>
              <li>
                <b>Use Your Trading Credit</b> – The $10 credit can be used to{" "}
                <b>reduce trading fees or enhance your trading experience.</b>
              </li>
            </ul>
          </section>
          <section>
            <h3 className="text-gradient">Expand Your Network & Earn More</h3>
            <p>
              <b>Community Growth = Bigger Rewards</b>
            </p>
            Join our <b>Telegram community</b> for{" "}
            <b>exclusive referral contests</b> with <b>massive prize pools.</b>
            <ul className="ms-4">
              <li>
                <b> Weekly referral challenges</b> with bonus cash rewards.
              </li>
              <li>
                <b>Top referrers</b> get{" "}
                <b>special VIP rewards & exclusive trading perks.</b>
              </li>
              <li>
                <b>Community-Driven Referral Events</b> – Participate in{" "}
                <b>special campaigns</b> for extra earnings.
              </li>
              <li>
                <b>Exclusive Trading Insights & Mentorship</b> – Learn from{" "}
                <b>top traders and mentors</b> in the bitcash network.
              </li>
              <li>
                <b>Referral Leaderboard</b> – Track the{" "}
                <b>top referrers in real-time</b> and compete for additional
                rewards.
              </li>
            </ul>
            <p>
              <b>Join the bitcash Telegram Channel & Stay Updated</b>
            </p>
          </section>
          <section>
            <h3 className="text-gradient">How to Track Your Referrals?</h3>
            <ul className="ms-4">
              <li>Log in to your bitcash account.</li>
              <li>
                Navigate to the <b>Referral Program Dashboard.</b>
              </li>
              <li>
                Monitor your{" "}
                <b>referral count, earnings, and pending rewards</b> in
                real-time.
              </li>
              <li>
                Withdraw your earnings directly to your{" "}
                <b>bitcash Wallet or Bank Account.</b>
              </li>
              <li>
                Get <b>automated reports and updates</b> on your referral
                performance.
              </li>
            </ul>
          </section>
          <section>
            <h3 className="text-gradient">
              Best Ways to Maximize Your Earnings
            </h3>
            <ul className="ms-4">
              <li>
                <b>Share your referral link</b> on social media, trading forums,
                and WhatsApp groups.
              </li>
              <li>
                <b>Create trading content</b> – Write blogs, post videos, or
                share insights about bitcash.
              </li>
              <li>
                <b>Engage in trading communities</b> – Recommend bitcash to
                traders looking for a reliable platform.
              </li>
              <li>
                <b>Use Email & Direct Messages</b> – Reach out to people who
                might benefit from trading with bitcash.
              </li>
              <li>
                <b>Host Trading Session</b>s – Teach beginners how to trade and
                introduce them to bitcash.
              </li>
              <li>
                <b>Target High-Value Traders</b> – Refer experienced traders who
                will actively use the platform, leading to bigger rebates and
                rewards.
              </li>
            </ul>
          </section>
          <section>
            <h3 className="text-gradient">
              Why Choose bitcash’s Referral Program?
            </h3>
            <ul className="ms-4">
              <li>
                <b>Fast Payouts</b> – Get rewards credited instantly after
                meeting the referral conditions.
              </li>
              <li>
                <b>Simple & Effective</b> – No hidden rules, just refer and
                earn.
              </li>
              <li>
                <b>Unlimited Earning Potential</b> – Earn more with every
                referral.
              </li>
              <li>
                <b>Exclusive Community Rewards</b> – Special referral challenges
                & bonus incentives for active referrers.
              </li>
              <li>
                <b>Swap-Free Trading</b> – bitcash is one of the few platforms
                offering <b>Shariah-compliant, interest-free trading.</b>
              </li>
              <li>
                <b>Security & Transparency</b> – 100% <b>safe and verified</b>{" "}
                referral tracking system.
              </li>
            </ul>
          </section>
          <section>
            <h3 className="text-gradient">Start Referring & Earning Today</h3>
            <ul className="ms-4">
              <li>
                <b>Share your unique referral link.</b>
              </li>
              <li>
                <b>Earn unlimited rewards.</b>
              </li>
              <li>
                <b>Build the future of trading with bitcash.</b>
              </li>
            </ul>
          </section>
          <h4>
            <b>Ready to get started? Sign up & start referring now.</b>
          </h4>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReferralDetail;
